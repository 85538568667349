import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  ActionButton,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  Select,
  BackButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { SuratJalanApi } from 'api';

const ListDeliveryOrderSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataDeliveryOrder, setDataDeliveryOrder] = useState([]);
  const [dataGudang, setDataGudang] = useState([]);
  const [selectedGudang, setSelectedGudang] = useState('');
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    const query = selectedGudang
      ? {
          q: searchConfig.key,
          page: paginationConfig.page,
          per_page: paginationConfig.dataLength,
          id_gudang: selectedGudang,
        }
      : {
          q: searchConfig.key,
          page: paginationConfig.page,
          per_page: paginationConfig.dataLength,
        };

    Axios.all([SuratJalanApi.getDO(query), SuratJalanApi.getDropdownGudang()])
      .then(
        Axios.spread((dataDO, gudang) => {
          setDataDeliveryOrder(dataDO.data.data);
          setDataGudang(gudang.data.data);
          setPaginationConfig({
            ...paginationConfig,
            dataCount: dataDO.data.data_count,
            totalPage: dataDO.data.total_page,
          });
        })
      )
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('List Delivery Order Yang Siap Dibuatkan Surat Jalan');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    selectedGudang,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th>Tgl. Delivery Order</Th>
            <Th>No. Delivery Order</Th>
            <Th>Gudang</Th>
            <Th>Customer</Th>
            <Th>Sales</Th>
            <Th>Tgl. Batas Waktu</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataDeliveryOrder.map((val, index) => (
            <tr key={index}>
              <Td>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>
                {val.tgl_delivery_order
                  ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY
                  : '-'}
              </Td>
              <Td>{val.no_delivery_order ?? '-'}</Td>
              <Td>{val.nama_gudang ?? '-'}</Td>
              <Td>{val.nama_customer ?? '-'}</Td>
              <Td>{val.nama_sales ?? '-'}</Td>
              <Td>
                {val.batas_waktu
                  ? DateConvert(new Date(val.batas_waktu)).defaultDMY
                  : '-'}
              </Td>
              <TdFixed>
                <ActionButton
                  size="sm"
                  text="Buat Surat Jalan"
                  className="text-nowrap"
                  onClick={() =>
                    history.push(
                      '/inventory/transaksi/surat-jalan/tambah/' +
                        val.id_delivery_order
                    )
                  }
                />
              </TdFixed>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataDeliveryOrder || dataDeliveryOrder.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <div className="mt-2">
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </div>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className={isPageLoading ? '' : 'mt-4 mb-1'}>
            <Col md={8}>
              <InputSearch
                size="sm"
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-end">
            {!isPageLoading && (
              <Select
                label="Gudang"
                defaultValue={selectedGudang}
                onChange={(e) => {
                  setSelectedGudang(e.target.value);
                  setPaginationConfig({
                    ...paginationConfig,
                    page: 1,
                  });
                }}
              >
                <option value="">Semua Gudang</option>
                {dataGudang.map((val, index) => (
                  <option value={val.id_gudang}>{val.nama_gudang}</option>
                ))}
              </Select>
            )}
            <div className="mb-1 ml-1">
              <BackButton size="sm" onClick={() => history.goBack()} />
            </div>
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default ListDeliveryOrderSuratJalan;
