import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InfoSection, TableSection } from '../Section';

const TitleSection = () => (
  <div className="text-center py-3">
    <b>
      <u>DOKUMEN TRANSFER BAHAN PRODUKSI</u>
    </b>
  </div>
);

const TTDSection = () => {
  const TTDItem = ({ label }) => (
    <div className="text-center" style={{ fontSize: 14 }}>
      <span>{label}</span>
      <div className="my-3 p-5">
        <hr />
      </div>
    </div>
  );

  return (
    <Row className="pt-5">
      <Col>
        <TTDItem label="Diterima," />
      </Col>
      <Col>
        <TTDItem label="Diserahkan," />
      </Col>
      <Col>
        <TTDItem label="Dipesan," />
      </Col>
    </Row>
  );
};

export default class PrintPermintaanBahanProduksi extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <TitleSection />
        <InfoSection dataInfo={this.props.dataInfo} />
        <div className="my-4" />
        <TableSection dataHistory={this.props.dataHistory} />
        <TTDSection />
      </div>
    );
  }
}
