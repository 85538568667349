import { Services } from 'services';

class ConvertBarangApi {
  getPage(params) {
    return Services.get('/convert_barang/page', { params });
  }

  getSingle(params) {
    return Services.get('/convert_barang/single', { params });
  }

  getKode(params) {
    return Services.get('convert_barang/no_baru', { params });
  }

  getDropdownKelompok(params) {
    return Services.get('/convert_barang/kelompok', { params });
  }

  getDropdownItemBarang(params) {
    return Services.get('/convert_barang/item_bahan', { params });
  }

  getDropdownGudang() {
    return Services.get('/gudang/dropdown');
  }

  getStok(params) {
    return Services.get('convert_barang/qty_booking', { params });
  }

  create(values) {
    return Services.post('/convert_barang', values);
  }

  update(values) {
    return Services.put('/convert_barang', values);
  }
}

export default new ConvertBarangApi();
