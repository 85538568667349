import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  ActionButton,
  Approval,
  DatePicker,
  Input,
  SelectSearch,
  TextArea,
  DataStatus,
} from 'components';
import { DateConvert } from 'utilities';
import { MutasiAntarGudangApi } from 'api';

const PageModal = ({
  getInitialData,
  modalConfig,
  processedData,
  setAlertConfig,
  onHide,
}) => {
  const TODAY = DateConvert(new Date()).default;
  const [dataStakeholder, setDataStakeholder] = useState({});
  const [dropdown, setDropdown] = useState({
    bahan: [],
    gudang: [],
  });
  const [modalFetchingStatus, setModalFetchingStatus] = useState({
    loading: false,
    success: true,
  });

  const mappingDropdownGudang = (data) =>
    data.map((val) => ({
      value: val.id_gudang,
      label: val.nama_gudang,
    }));

  const mappingDropdownBahan = (data) =>
    data.map((val) => ({
      value: val.id_item_buaso,
      label: val.nama_item,
      kode_item: val.kode_item,
      dimensi: val.dimensi,
      pj: val.pj,
      lb: val.lb,
      tb: val.tb,
    }));

  const getInitialModalData = () => {
    setModalFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([
      MutasiAntarGudangApi.getDropdown({ tipe: 'bahan' }),
      MutasiAntarGudangApi.getDropdown({ tipe: 'gudang' }),
    ])
      .then(
        Axios.spread((bahan, gudang) => {
          const mapDropdownBahan = mappingDropdownBahan(bahan.data.data);
          const mapDropdownGudang = mappingDropdownGudang(gudang.data.data);

          setDropdown({
            bahan: mapDropdownBahan,
            gudang: mapDropdownGudang,
          });
          setModalFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setModalFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const getStakeholder = (id) => {
    MutasiAntarGudangApi.getSingle({ id_mutasi_antar_gudang: id }).then(
      ({ data }) => setDataStakeholder(data.data)
    );
  };

  useEffect(() => {
    modalConfig.type !== 'detail' && getInitialModalData();
    processedData.id_mutasi_antar_gudang &&
      getStakeholder(processedData.id_mutasi_antar_gudang);
  }, [modalConfig.type, processedData.id_mutasi_antar_gudang]);

  const ModalContent = () => {
    const formInitialValues = {
      tgl: processedData.tgl_mutasi_antar_gudang ?? TODAY,
      no: processedData.no_mutasi_antar_gudang ?? '',
      id_item_buaso: processedData.id_item_buaso ?? '',
      id_gudang_asal: processedData.id_gudang_asal ?? '',
      id_gudang_tujuan: processedData.id_gudang_tujuan ?? '',
      stock_update: 0,
      qty_mutasi: parseInt(processedData.qty_mutasi) || 0,
      catatan_mutasi: processedData.catatan_mutasi ?? '',
    };

    const formValidationSchema = Yup.object().shape({
      tgl: Yup.string().required('Pilih tanggal mutasi antar gudang'),
      no: Yup.string().required('Pilih tanggal untuk menentukan nomor'),
      id_item_buaso: Yup.string().required('Pilih item bahan'),
      id_gudang_asal: Yup.string().required('Pilih gudang asal'),
      id_gudang_tujuan: Yup.string().required('Pilih gudang tujuan'),
      qty_mutasi: Yup.number().required('Masukkan qty. mutasi'),
    });

    const formSubmitHandler = (values, { setSubmitting }) => {
      if (
        parseFloat(values.stock_update) > 0 &&
        parseFloat(values.qty_mutasi) <= parseFloat(values.stock_update)
      ) {
        const finalValues = {
          tgl_mutasi_antar_gudang: values.tgl,
          id_item_buaso: values.id_item_buaso,
          id_gudang_asal: values.id_gudang_asal,
          id_gudang_tujuan: values.id_gudang_tujuan,
          qty_mutasi: values.qty_mutasi,
          catatan_mutasi: values.catatan_mutasi,
        };

        if (modalConfig.type === 'update') {
          finalValues.id_mutasi_antar_gudang =
            processedData.id_mutasi_antar_gudang;
        }

        MutasiAntarGudangApi.save(modalConfig.type, finalValues)
          .then(() => {
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: 'Data berhasil disimpan!',
            });
          })
          .catch(() => {
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: 'Data gagal disimpan!',
            });
          })
          .finally(() => {
            onHide();
            getInitialData();
          });
      } else {
        setSubmitting(false);
        window.alert(
          'Tidak dapat menyimpan data (Stock Update kosong / Qty. Mutasi melebihi Stock Update )'
        );
      }
    };

    // CONTENT FORM
    const FormSection = ({ formik, dropdown }) => {
      const [isNomorLoading, setIsNomorLoading] = useState(false);
      const {
        values,
        errors,
        touched,
        setValues,
        handleChange,
        setFieldValue,
      } = formik;

      const decimalConvert = (value) => {
        const newValue = value.toString().replace(/[^0-9\.]/g, '');
        const convert = newValue.substring(0, 7);

        return convert;
      };

      const getNomorHandler = (tanggal) => {
        setIsNomorLoading(true);
        MutasiAntarGudangApi.getNomor({ tanggal })
          .then((val) => {
            setFieldValue('tgl', tanggal);
            setFieldValue('no', val?.data?.data ?? '-');
          })
          .catch(() => {
            window.alert('Gagal memuat nomor');
            setValues({
              tgl: '',
              no: '',
            });
          })
          .finally(() => setIsNomorLoading(false));
      };

      const getStockUpdate = () => {
        MutasiAntarGudangApi.getStockUpdate({
          id_bahan: values.id_item_buaso,
          id_gudang: values.id_gudang_asal,
        })
          .then((res) =>
            setFieldValue('stock_update', res?.data?.data?.jumlah ?? 0)
          )
          .catch((err) => window.alert('Gagal memuat stock update'));
      };

      useEffect(() => {
        if (modalConfig.type === 'create') {
          getNomorHandler(TODAY);
        }
      }, []);

      useEffect(() => {
        //fetch getStockUpdate jika dropdown
        if (values.id_item_buaso !== '' && values.id_gudang_asal !== '') {
          getStockUpdate();
        }
        return () => {};
      }, [values.id_item_buaso, values.id_gudang_asal]);

      return (
        <>
          <Row>
            <Col md>
              <DatePicker
                label="Tgl. Mutasi"
                selected={values.tgl ? new Date(values.tgl) : ''}
                placeholderText="Pilih tanggal"
                onChange={(date) => {
                  if (modalConfig.type === 'create') {
                    getNomorHandler(DateConvert(new Date(date)).default);
                  }
                  if (modalConfig.type === 'update') {
                    setFieldValue('tgl', DateConvert(new Date(date)).default);
                  }
                }}
                error={Boolean(errors.tgl) && touched.tgl}
                errorText={Boolean(errors.tgl) && touched.tgl && errors.tgl}
              />
            </Col>
            <Col md>
              <Input
                label="No. Mutasi"
                placeholder={
                  isNomorLoading
                    ? 'Memuat nomor . . .'
                    : 'Pilih tanggal untuk menentukan nomor'
                }
                value={isNomorLoading ? 'Memuat nomor . . .' : values.no}
                error={Boolean(errors.no) && touched.no}
                errorText={Boolean(errors.no) && touched.no && errors.no}
                readOnly
              />
            </Col>
          </Row>
          <SelectSearch
            label="Item Bahan"
            name="id_item_buaso"
            placeholder="Pilih item bahan"
            defaultValue={
              dropdown.bahan.find((e) => e.value == values.id_item_buaso) ?? ''
            }
            onChange={(val) => setFieldValue('id_item_buaso', val.value)}
            option={dropdown.bahan}
            error={Boolean(errors.id_item_buaso) && touched.id_item_buaso}
            errorText={
              Boolean(errors.id_item_buaso) &&
              touched.id_item_buaso &&
              errors.id_item_buaso
            }
          />
          <Row>
            <Col md={8}>
              <SelectSearch
                label="Gudang Asal"
                name="id_gudang_asal"
                placeholder="Pilih gudang asal"
                defaultValue={
                  dropdown.gudang.find(
                    (e) => e.value === values.id_gudang_asal
                  ) ?? ''
                }
                onChange={(val) => setFieldValue('id_gudang_asal', val.value)}
                option={dropdown.gudang}
                error={Boolean(errors.id_gudang_asal) && touched.id_gudang_asal}
                errorText={
                  Boolean(errors.id_gudang_asal) &&
                  touched.id_gudang_asal &&
                  errors.id_gudang_asal
                }
              />
            </Col>
            <Col md={4}>
              <Input
                label="Stock Update"
                name="stock_update"
                value={values.stock_update}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <SelectSearch
                label="Gudang Tujuan"
                name="id_gudang_tujuan"
                placeholder="Pilih gudang tujuan"
                defaultValue={
                  dropdown.gudang.find(
                    (e) => e.value === values.id_gudang_tujuan
                  ) ?? ''
                }
                onChange={(val) => setFieldValue('id_gudang_tujuan', val.value)}
                option={dropdown.gudang}
                error={
                  Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan
                }
                errorText={
                  Boolean(errors.id_gudang_tujuan) &&
                  touched.id_gudang_tujuan &&
                  errors.id_gudang_tujuan
                }
              />
            </Col>
            <Col md={4}>
              <Input
                label="Qty. Mutasi"
                id="qty_mutasi"
                name="qty_mutasi"
                value={values.qty_mutasi}
                onChange={(val) => {
                  const value = decimalConvert(val.target.value);
                  setFieldValue('qty_mutasi', value);
                }}
                error={errors.qty_mutasi && touched.qty_mutasi && true}
                errorText={errors.qty_mutasi}
              />
            </Col>
          </Row>
          <TextArea
            label="Catatan Mutasi"
            type="text"
            name="catatan_mutasi"
            value={values.catatan_mutasi}
            onChange={handleChange}
            rows={2}
            error={errors.catatan_mutasi && touched.catatan_mutasi && true}
            errorText={errors.catatan_mutasi}
          />
        </>
      );
    };

    // CONTENT KETIKA MELIHAT DETAIL
    const FormDetail = () => {
      const InfoItem = ({ title, text, line }) => (
        <div className="mb-2">
          <small
            className="text-secondary"
            style={{ opacity: 0.8, textTransform: 'capitalize' }}
          >
            {title}
          </small>
          <div
            className="text-dark"
            style={{
              fontWeight: 500,
            }}
          >
            {text ?? '-'}
          </div>
          {line && <hr className="my-2" />}
        </div>
      );

      return (
        <Col>
          <InfoItem
            title="Tgl. Mutasi"
            text={
              DateConvert(new Date(processedData.tgl_mutasi_antar_gudang))
                .detail
            }
          />
          <InfoItem
            title="No. Mutasi"
            text={processedData.no_mutasi_antar_gudang}
          />
          <InfoItem title="Item Bahan" text={processedData.nama_item} />
          <InfoItem title="Gudang Asal" text={processedData.nama_gudang_asal} />
          <InfoItem
            title="Gudang Tujuan"
            text={processedData.nama_gudang_tujuan}
          />
          <InfoItem title="Qty. Mutasi" text={processedData.qty_mutasi} />
          <InfoItem
            title="Catatan Mutasi"
            text={processedData.catatan_mutasi}
          />
        </Col>
      );
    };

    return (
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              {modalConfig.type === 'detail' ? (
                <FormDetail />
              ) : (
                <FormSection formik={formik} dropdown={dropdown} />
              )}
              <Approval data={dataStakeholder} />
            </Modal.Body>
            {modalConfig.type !== 'detail' && (
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Simpan"
                  loading={formik.isSubmitting}
                />
              </Modal.Footer>
            )}
          </form>
        )}
      </Formik>
    );
  };

  const MODAL_TITLE = {
    create: 'Tambah',
    update: 'Ubah',
    detail: 'Detail',
  };

  return (
    <Modal size="lg" show={modalConfig.show} onHide={onHide}>
      <Modal.Header closeButton>
        <b className="text-capitalize">
          {`${
            MODAL_TITLE[modalConfig?.type ?? 'detail']
          } Data Mutasi Antar Gudang`}
        </b>
      </Modal.Header>
      {modalFetchingStatus.loading ? (
        <DataStatus loading text="Memuat data . . ." />
      ) : modalFetchingStatus.success ? (
        <ModalContent />
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </Modal>
  );
};
export default PageModal;
