import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  ReadButton,
  Pagination,
  Alert,
  ApprovalStatusButton,
  CRUDLayout,
  FilterButton,
  InputSearch,
  TBody,
  Td,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
  DataStatus,
  ActionButton,
} from 'components';
import { useSessionStorage } from 'hooks';
import { ConvertBarangApi } from 'api';
import { DateConvert, PageNumber } from 'utilities';
import { ItemCollapse, ModalFilterConvertBarang } from './Components';
import { withDropdownProvider } from './Context';

const TableBody = ({
  data,
  pagination,
  setPagination,
  history,
  dropdownLoading,
}) => {
  const STATUS = {
    APP: ['outline-success', 'APPROVED'],
    VER: ['outline-success', 'VERIFIED'],
    REV: ['outline-warning', 'REVISED'],
    REJ: ['outline-danger', 'REJECTED'],
    PEN: ['outline-secondary', 'PENDING'],
  };

  return (
    <>
      <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
        List Data Convert Barang
      </div>

      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi Convert Barang</ThFixed>
            <Th>Item Yang Dikonversi</Th>
            <Th>Gudang Asal</Th>
            <Th>Item Hasil Konversi</Th>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => (
              <Tr key={val.id_convert_barang}>
                <Td className="text-center">
                  {PageNumber(pagination.page, pagination.per_page, index)}
                </Td>
                <Td>
                  <div className="d-flex">
                    <ReadButton
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/convert-barang/detail/${val.id_convert_barang}`
                        )
                      }
                    />

                    {val.status_approval === 'REV' && (
                      <UpdateButton
                        disable={dropdownLoading}
                        onClick={() =>
                          history.push(
                            `/inventory/transaksi/convert-barang/ubah/${val.id_convert_barang}`
                          )
                        }
                      />
                    )}
                  </div>
                </Td>
                <Td>
                  <div>
                    {DateConvert(new Date(val.tgl_convert_barang)).defaultDMY}
                  </div>
                  <div>{val.no_convert_barang}</div>
                </Td>
                <Td>{val.nama_item ?? '-'}</Td>
                <Td>{val.nama_gudang ?? '-'}</Td>
                <Td>
                  <ItemCollapse data={val?.detail ?? []} />
                </Td>
                <Td>
                  <ApprovalStatusButton
                    variant={STATUS[val.status_approval ?? 'PEN'][0]}
                  >
                    {STATUS[val.status_approval ?? 'PEN'][1]}
                  </ApprovalStatusButton>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>

      {Boolean(data?.length) && (
        <Pagination
          dataLength={pagination?.per_page}
          dataCount={pagination?.data_count}
          currentPage={pagination?.page}
          totalPage={pagination?.total_page}
          dataNumber={
            pagination?.page * pagination?.per_page - pagination?.per_page + 1
          }
          dataPage={
            pagination?.data_count < pagination?.per_page
              ? pagination?.data_count
              : pagination?.page * pagination?.per_page
          }
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({
              ...prev,
              page: selected + 1,
            }))
          }
          onDataLengthChange={(e) =>
            setPagination((prev) => ({
              ...prev,
              page: 1,
              per_page: e.target.value,
            }))
          }
        />
      )}
    </>
  );
};

const ListConvertItemBarang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const paginationKey = `${location.pathname}_pagination`;

  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_convert_barang_start: undefined,
    tgl_convert_barang_end: undefined,
    id_item_buaso: undefined,
    id_item_barang: undefined,
    id_gudang: undefined,
    status_approval: undefined,
  });

  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const { data_count, total_page, ...deps } = pagination;

  const fetchData = () => {
    setLoading(true);

    const query = { ...pagination };

    ['data_count', 'total_page', 'active'].forEach((key) => delete query[key]);

    ConvertBarangApi.getPage(query)
      .then(({ data }) => {
        setData(data?.data ?? []);
        setPagination((prev) => ({
          ...prev,
          total_page: data?.total_page,
          data_count: data?.data_count,
        }));
      })
      .catch(({ response }) => {
        setAlertConfig({
          text: `Gagal memuat data ${response.data.message ?? ''}`,
          variant: 'danger',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dismissAlert = () => {
    history.replace((location.pathname, { state: null }));
    setAlertConfig((prev) => ({ ...prev, text: '' }));
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  useEffect(() => fetchData(), [JSON.stringify(deps)]);

  useEffect(() => {
    if (location?.state?.alert) {
      setAlertConfig(location.state.alert);
    }

    setNavbarTitle('Convert Barang');
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch onChange={debounce(searchHandler, 1500)} />
          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter((prev) => !prev)}
          />
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <ActionButton
            text="Tambah Data"
            onClick={() =>
              history.push('/inventory/transaksi/convert-barang/tambah')
            }
          />
        </Col>
      </Row>

      <Alert
        showCloseButton
        show={!!alertConfig.text.length}
        text={alertConfig.text}
        variant={alertConfig.variant}
        onClose={dismissAlert}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat data . . ." />
      ) : (
        <TableBody
          data={data}
          pagination={pagination}
          setPagination={setPagination}
          history={history}
        />
      )}

      <ModalFilterConvertBarang
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default withDropdownProvider(ListConvertItemBarang);
