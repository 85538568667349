import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import {
  Alert,
  CRUDLayout,
  DataStatus,
  FilterButton,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { KartuStockMaterialApi } from 'api';
import { useSessionStorage } from 'hooks';
import {
  InfoSection,
  ModalDetailKartuStok,
  ModalFilterKartuStock,
} from './Components';

const KartuStockMaterial = ({ setNavbarTitle }) => {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [dataInfo, setDataInfo] = useState({});
  const [dataGudang, setDataGudang] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [modalDetal, setModalDetail] = useState({
    show: false,
  });
  const [filter, setFilter] = useSessionStorage(`${location.pathname}_filter`, {
    start: undefined,
    end: undefined,
    id_kelompok: undefined,
    nama_kelompok: undefined,
    id_item_buaso: undefined,
    nama_barang: undefined,
    kode_barang: undefined,
    id_gudang: undefined,
    nama_gudang: undefined,
  });
  const [dropdown, setDropdown] = useState({
    loading: false,
    kelompok: [],
    gudang: [],
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getDropdown = () => {
    setDropdown((prev) => ({ ...prev, loading: true }));

    Axios.all([
      KartuStockMaterialApi.getDropdownKelompok(),
      KartuStockMaterialApi.getDropdownGudang(),
    ])
      .then(
        Axios.spread((kelompok, gudang) => {
          setDropdown({
            loading: false,
            kelompok: kelompok?.data?.data?.map((v) => ({
              value: v.id_kelompok,
              label: v.nama_kelompok,
            })),
            gudang: gudang?.data?.data?.map((v) => ({
              value: v.id_gudang,
              label: v.nama_gudang,
            })),
          });
        })
      )
      .catch((err) => {
        setDropdown((prev) => ({ ...prev, loading: false }));
        alert(`Gagal memuat data dropdown ${err.response?.data?.message}`);
      });
  };

  const getData = () => {
    setLoading(true);

    const query = {
      start: filter?.start,
      end: filter?.end,
      id_item_buaso: filter?.id_item_buaso,
      id_gudang: filter?.id_gudang,
    };

    KartuStockMaterialApi.getGudangList(query)
      .then((res) => {
        const { gudang, ...dataInfo } = res?.data?.data;

        setDataInfo(dataInfo ?? {});
        setDataGudang(gudang ?? []);
      })
      .catch((err) => {
        window.alert(`Gagal memuat data ${err.response?.data?.message}`);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Kartu Stock Material');
    getDropdown();

    return () => setLoading(false);
  }, []);

  useEffect(
    () => filter.active && getData(),
    [filter.start, filter.end, filter.id_item_buaso, filter.id_gudang]
  );

  return (
    <>
      <Row>
        <Col>
          <FilterButton
            className="mb-3"
            active={filter.active}
            onClick={() => setShowFilter((prev) => !prev)}
          />
        </Col>
      </Row>

      <Alert
        showCloseButton
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        onClose={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />

      {isLoading ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : (
        <>
          <InfoSection data={dataInfo} start={filter.start} end={filter.end} />
          <TableGudang
            dataGudang={dataGudang}
            dataInfo={dataInfo}
            filter={filter}
            setModalDetail={setModalDetail}
          />
        </>
      )}

      <ModalFilterKartuStock
        dropdown={dropdown}
        show={showFilter}
        setShow={setShowFilter}
        filter={filter}
        setFilter={setFilter}
        resetData={() => {
          setDataInfo({});
          setDataGudang([]);
        }}
      />

      <ModalDetailKartuStok modal={modalDetal} setModal={setModalDetail} />
    </>
  );
};

const TableGudang = ({
  dataGudang = [],
  dataInfo = {},
  filter = {},
  setModalDetail,
}) => (
  <>
    <div className="mt-3" style={{ fontSize: '.9rem' }}>
      List Data Gudang
    </div>

    <CRUDLayout.Table>
      <THead>
        <Tr className="text-center">
          <ThFixed>No.</ThFixed>
          <Th>Gudang</Th>
          <ThFixed className="text-nowrap">Sisa Stok</ThFixed>
          <ThFixed>Aksi</ThFixed>
        </Tr>
      </THead>
      <TBody>
        {dataGudang?.length ? (
          dataGudang.map((val, index) => (
            <Tr key={index}>
              <TdFixed textCenter>{index + 1}</TdFixed>
              <Td>{val.nama_gudang ?? '-'}</Td>
              <Td className="text-nowrap">
                {`
                ${parseFloat(val.jumlah ?? 0)} 
                ${dataInfo.satuan_pakai}
                `}
              </Td>
              <TdFixed>
                <ReadButton
                  onClick={() =>
                    setModalDetail({
                      show: true,
                      data: { ...filter, ...val, ...dataInfo },
                    })
                  }
                />
              </TdFixed>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={4} className="text-center">
              <p style={{ fontSize: '1.2em' }}>
                {!dataGudang?.length && !filter?.active
                  ? 'Mohon filter data terlebih dahulu'
                  : 'Tidak ada data'}
              </p>
            </Td>
          </Tr>
        )}
      </TBody>
    </CRUDLayout.Table>
  </>
);
export default KartuStockMaterial;
