import { Services } from 'services';

class PermintaanBahanProduksiApi {
  // Page
  page(params) {
    return Services.get('/transfer_produksi_bahan/page', { params });
  }

  preform_page(params) {
    return Services.get('/transfer_produksi_bahan/permintaan_page', { params });
  }

  single(params) {
    return Services.get('/transfer_produksi_bahan/single', { params });
  }

  transfer(params) {
    return Services.get('/transfer_produksi_bahan/transfer', { params });
  }

  no_baru(params) {
    return Services.get('/transfer_produksi_bahan/no_baru', { params });
  }

  dropdown(params) {
    return Services.get('/transfer_produksi_bahan/dropdown', { params });
  }

  create(value) {
    return Services.post('/transfer_produksi_bahan', value);
  }

  update(value) {
    return Services.put('/transfer_produksi_bahan', value);
  }

  delete(value) {
    return Services.post('/transfer_produksi_bahan/delete', value);
  }

  update_permintaan(data) {
    return Services.post('/transfer_produksi_bahan/update_permintaan', data);
  }

  qty_booking(params) {
    return Services.get('/transfer_produksi_bahan/qty_booking', { params });
  }

  // Cetak
  getHistoryCetak(params) {
    return Services.get('/transfer_produksi_bahan_cetak', { params });
  }

  getDetailCetak(params) {
    return Services.get('/transfer_produksi_bahan_cetak/single', { params });
  }

  getNomorCetak(params) {
    return Services.get('/transfer_produksi_bahan_cetak/no_baru', { params });
  }

  saveCetak(data) {
    return Services.post('transfer_produksi_bahan_cetak', data);
  }
}

export default new PermintaanBahanProduksiApi();
