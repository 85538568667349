import React, { memo, useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Axios from 'axios';
import {
  IoCloudUploadOutline,
  IoReload,
  IoCloseCircleOutline,
  IoCheckmarkCircleOutline,
} from 'react-icons/io5';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ReadButton,
  BackButton,
  Alert,
  ActionButton,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  DeleteModal,
  Approval,
  ApprovalStatusButton,
  FilterButton,
  InfoItemHorizontal,
} from 'components';
import { PurchaseRequestApi } from 'api';
import { DateConvert, TableNumber } from 'utilities';
import { ModalGambar, ModalFilter } from './Section';
import { tglInputFormat } from './Utils';

// MODAL TAMBAH COMPONENT
const ReadModal = memo(({ show, onHide, idPurchase }) => {
  const [fetch, setFetch] = useState({
    loading: false,
    error: true,
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    index: 0,
  });
  const [dataGambar, setDataGambar] = useState([]);
  const [readData, setReadData] = useState({
    detail: [],
  });

  const getReadData = (id) => {
    setFetch({ loading: true, error: true });

    PurchaseRequestApi.single(id)
      .then((res) => {
        setReadData(res?.data?.data ?? {});
        setFetch({ loading: false, error: false });
      })
      .catch(() => {
        setFetch({ loading: false, error: true });
      });
  };

  useEffect(() => {
    show && idPurchase && getReadData(idPurchase);
  }, [show]);

  const ShowData = ({ title, text }) => (
    <div className="d-flex flex-row">
      <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
        {title}
      </div>
      <span>:</span>
      <div
        className="text-dark"
        style={{
          paddingLeft: 5,
        }}
      >
        {text ? text : '-'}
      </div>
    </div>
  );

  return (
    <>
      <Modal size="xl" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Detail Data Dokumen Purchase Request</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fetch.loading || fetch.error ? (
            <DataStatus
              loading={fetch.loading}
              text={fetch.loading ? 'Memuat data . . .' : 'Data gagal dimuat!'}
            />
          ) : (
            <>
              <div className="mb-2">
                <Row className="mb-2">
                  <Col>
                    <InfoItemHorizontal
                      label="Tgl. Input Data"
                      text={tglInputFormat({
                        created_at: readData.created_at,
                        separator: ' - ',
                      })}
                    />

                    <InfoItemHorizontal
                      label="Tgl. Purchase Request"
                      text={`${
                        DateConvert(new Date(readData.tgl_purchase_request))
                          .detail
                      }`}
                    />

                    <InfoItemHorizontal
                      label="Tgl. Pemakaian"
                      text={
                        DateConvert(new Date(readData.tgl_pemakaian)).detail
                      }
                    />
                  </Col>

                  <Col>
                    <InfoItemHorizontal
                      label="No. Purchase Request"
                      text={readData.no_purchase_request}
                    />

                    <InfoItemHorizontal
                      label="Keperluan"
                      text={readData.keperluan}
                    />
                    <InfoItemHorizontal
                      label="Proyek"
                      text={readData.nama_proyek}
                    />
                  </Col>
                </Row>

                <CRUDLayout.Table>
                  <THead>
                    <Tr className="text-center">
                      <ThFixed>No</ThFixed>
                      <Th>Kode Item</Th>
                      <Th>Nama Item</Th>
                      <Th>Satuan</Th>
                      <Th>Qty</Th>
                      <ThFixed>Aksi</ThFixed>
                    </Tr>
                  </THead>
                  <TBody>
                    {readData.detail.map((val, index) => (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>{val.nama_item}</Td>
                        <Td>{val.nama_satuan}</Td>
                        <Td>
                          <div className="text-right">
                            {parseFloat(val.qty).toPrecision()}
                          </div>
                        </Td>
                        <ThFixed>
                          <ActionButton
                            text={<IoCloudUploadOutline />}
                            size="sm"
                            className="m-1"
                            onClick={() => {
                              setModalConfig({
                                show: true,
                                data: val,
                                index: index,
                              });

                              const image = val.path_gambar.map((item) => {
                                let newStr = item.replace(
                                  'http://',
                                  'https://'
                                );
                                return {
                                  link: newStr,
                                  nama: item,
                                };
                              });
                              setDataGambar(image);
                            }}
                          />
                        </ThFixed>
                      </Tr>
                    ))}
                  </TBody>
                </CRUDLayout.Table>

                <div>
                  <strong>Catatan : </strong>
                  <br></br>
                  {readData.catatan_purchase_request}
                </div>
                <ModalGambar
                  modalConfig={modalConfig}
                  setModalConfig={setModalConfig}
                  list={readData.detail}
                  setDataGambar={setDataGambar}
                  dataGambar={dataGambar}
                  type="DETAIL"
                />
              </div>

              {readData.detail.length > 0 ? <Approval data={readData} /> : ''}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
});

const PurchaseRequest = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  let createAlert = location?.state?.variant;

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  // data
  const [data, setData] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.pr?.filter?.active,
      tgl_purchase_request_mulai:
        location?.state?.pr?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai:
        location?.state?.pr?.filter?.tgl_purchase_request_selesai,
      tgl_pemakaian_mulai: location?.state?.pr?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: location?.state?.pr?.filter?.tgl_pemakaian_selesai,
      keperluan: location?.state?.pr?.filter?.keperluan,
      pengaju: location?.state?.pr?.filter?.pengaju,
      proyek: location?.state?.pr?.filter?.proyek,
      status_approval: location?.state?.pr?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.pr?.filter?.page ?? '1',
      dataLength: location?.state?.pr?.filter?.dataLength ?? '10',
      totalPage: location?.state?.pr?.filter?.totalPage ?? '1',
      dataCount: location?.state?.pr?.filter?.dataCount ?? '0',
    },
  });
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [modalStatus, setModalStatus] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    idPurchase: undefined,
  });
  const [idPurchase, setIdPurchase] = useState('');
  const [deleteData, setDeleteData] = useState([]);
  const [isDeleteData, setIsDeleteData] = useState(false);

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    // setAlertConfig(false);

    // request data ke server
    Axios.all([
      PurchaseRequestApi.getPage({
        q: searchKey,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_purchase_request_mulai:
          dataFilter?.filter?.tgl_purchase_request_mulai,
        tgl_purchase_request_selesai:
          dataFilter?.filter?.tgl_purchase_request_selesai,
        tgl_pemakaian_mulai: dataFilter?.filter?.tgl_pemakaian_mulai,
        tgl_pemakaian_selesai: dataFilter?.filter?.tgl_pemakaian_selesai,
        keperluan: dataFilter?.filter?.keperluan,
        pengaju: dataFilter?.filter?.pengaju,
        proyek: dataFilter?.filter?.proyek,
        status_approval: dataFilter?.filter?.status_approval,
      }),
    ])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data ?? []);
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            },
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle('Purchase Request');

    if (createAlert !== undefined) {
      setAlertConfig({
        variant: location?.state?.variant,
        text: location?.state?.text,
      });
      setShowAlert(true);
    } else {
      return getData();
    }

    getData();

    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
    };
  }, [
    setNavbarTitle,
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.tgl_pemakaian_mulai,
    dataFilter?.filter?.tgl_pemakaian_selesai,
    dataFilter?.filter?.keperluan,
    dataFilter?.filter?.pengaju,
    dataFilter?.filter?.proyek,
    dataFilter?.filter?.status_approval,
  ]);

  const ModalStatus = () => {
    const [dataProgress, setDataProgress] = useState([]);

    const getProgress = () => {
      PurchaseRequestApi.getProgress({ id_purchase_request: idPurchase })
        .then((res) => {
          setDataProgress(res.data.data);
        })
        .catch((err) => {
          setAlertConfig(err);
        })
        .finally(() => {});
    };

    useEffect(() => {
      idPurchase && getProgress();
      return () => {};
    }, []);

    return (
      <>
        <Modal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-info">
              {modalStatus === 'PENDING' ? (
                <h6 className="mb-0 text-info">
                  <IoReload className="mb-1 mr-2" size={20} />
                  Progress (Status ON PROGRESS)
                </h6>
              ) : modalStatus === 'DONE' ? (
                <h6 className="mb-0 text-success">
                  <IoCheckmarkCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status DONE)
                </h6>
              ) : (
                <h6 className="mb-0 text-danger">
                  <IoCloseCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status CANCEL)
                </h6>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <Th>Nama proses</Th>
                  <Th>Tanggal proses</Th>
                  <Th>No dokumen</Th>
                </Tr>
              </THead>
              <TBody>
                {dataProgress !== undefined
                  ? dataProgress.map((val, index) => (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <Td>{val.nama_proses}</Td>
                        <Td>{DateConvert(new Date(val.tgl_proses)).detail}</Td>
                        <Td>{val.no_dokumen}</Td>
                      </Tr>
                    ))
                  : ''}
              </TBody>
            </CRUDLayout.Table>
            <div className="d-flex justify-content-end">
              <BackButton
                onClick={() => {
                  setModalShow(false);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_purchase_request: deleteData.id_purchase_request };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      PurchaseRequestApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal!(${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };
    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        // title={title}
      >
        <div>
          Tgl. Purchase Request :{' '}
          {DateConvert(new Date(deleteData.tgl_purchase_request)).detail}
        </div>
        <div>No. Purchase Request : {deleteData.no_purchase_request}</div>
      </DeleteModal>
    );
  };

  const Table = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : '';
      const convert = data.substring(0, 3);

      if (convert === 'APP')
        return {
          variant: 'outline-success',
          label: 'APPROVED',
        };
      if (convert === 'VER')
        return {
          variant: 'outline-success',
          label: 'VERIFIED',
        };
      if (convert === 'REV')
        return {
          variant: 'outline-warning',
          label: 'REVISION',
        };
      if (convert === 'REJ')
        return {
          variant: 'outline-danger',
          label: 'REJECTED',
        };
      return {
        variant: 'outline-secondary',
        label: 'PENDING',
      };
    };

    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${brg.nama_item} ${brg.qty ? brg.qty : ''} ${
                      brg.nama_satuan ? brg.nama_satuan : ''
                    }` ?? '-'}
                  </li>
                );
              } else {
                return (
                  index <= 1 && (
                    <li key={index} index={index}>
                      {`${brg.nama_item} ${brg.qty ? brg.qty : ''} ${
                        brg.nama_satuan ? brg.nama_satuan : ''
                      }` ?? '-'}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                // fontWeight: "bold",
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
            </div>
          ) : (
            ''
          )}
        </>
      );
    };
    return (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>List Data Purchase Request</b>
        </span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Tgl. Input Data</ThFixed>
              <ThFixed>Informasi Purchase Request</ThFixed>
              <Th>Pengaju</Th>
              <Th>Item Barang</Th>
              <Th>Proyek</Th>
              <ThFixed>Keperluan</ThFixed>
              <ThFixed>Tgl. Pemakaian</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex">
                    <ReadButton
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          idPurchase: val?.id_purchase_request,
                        });
                      }}
                    />

                    {val.status_approval === 'REV' && (
                      <UpdateButton
                        onClick={() =>
                          history.push(
                            `/inventory/transaksi/purchase-request/${val.id_purchase_request}`,
                            { ...location?.state, pr: dataFilter }
                          )
                        }
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed className="text-nowrap">
                  {tglInputFormat({ created_at: val.created_at })}
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_purchase_request
                      ? DateConvert(new Date(val.tgl_purchase_request))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div className="text-left">
                    {val.no_purchase_request ?? '-'}
                  </div>
                </TdFixed>
                <Td>{val.nama_karyawan_pengaju ?? '-'}</Td>
                <Td>
                  {val?.item_barang?.length > 0 ? (
                    <ItemBarangCollapse data={val.item_barang} />
                  ) : (
                    '-'
                  )}
                </Td>
                <Td>{val.nama_proyek ?? '-'}</Td>
                <TdFixed>
                  {val.keperluan.charAt(0).toUpperCase() +
                    val.keperluan.slice(1) ?? '-'}
                </TdFixed>
                <TdFixed>
                  {val.tgl_pemakaian
                    ? DateConvert(new Date(val.tgl_pemakaian)).defaultDMY
                    : '-'}
                </TdFixed>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={checkStatus(val.status_approval).variant}
                  >
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setDataFilter({
                    ...dataFilter,
                    pagination: {
                      ...dataFilter.pagination,
                      page: '1',
                    },
                  });
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              history.push('/inventory/transaksi/purchase-request/tambah', {
                ...location?.state,
                pr: dataFilter,
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      <ReadModal
        show={modalDetail.show}
        idPurchase={modalDetail.idPurchase}
        onHide={() => setModalDetail({ show: false, idPurchase: undefined })}
      />
      <ModalStatus />
      <HapusModal />
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default PurchaseRequest;
