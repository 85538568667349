import {
  Table,
  THead,
  Tr,
  Th,
  Td,
  TdFixed,
  ThFixed,
  TBody,
  UpdateButton,
  DeleteButton,
  DataStatus,
} from 'components';
import { useContext } from 'react';
import { DateConvert } from 'utilities';
import { TransferBahanProduksiContext } from '../Context';
import { removeItemTable } from '../Helpers';
import { useLocalStok } from '../Hooks';

export const TableTransferBahanProduksi = ({
  tipe = 'editable', // editable | readonly
  tipeTransfer, // hardwood | plywood | finishing  | penunjang
  data = [],
  uniqueKey = '', // uniqueKey id permintaan, e.g =>  hardwood: id_permintaan_produksi_hardwood
}) => {
  const { setDataPermintaan, setModalForm } = useContext(
    TransferBahanProduksiContext
  );
  const { incrementStok } = useLocalStok();
  const isEditable = tipe === 'editable';
  const filteredData = [...data]?.filter((v) =>
    Boolean(v.tgl_transfer_produksi_bahan)
  );

  return (
    <Table>
      <THead>
        <Tr className="text-center">
          <ThFixed>No.</ThFixed>
          <ThFixed>Informasi Transfer Bahan Produksi</ThFixed>
          <Th>Item Bahan</Th>
          <Th>Qty. Transfer</Th>
          <Th>Gudang Asal</Th>
          <Th>Gudang Tujuan</Th>
          <Th>Diserahkan Kepada</Th>
          <Th>Keterangan</Th>
          {isEditable && <ThFixed>Aksi</ThFixed>}
        </Tr>
      </THead>

      <TBody>
        {filteredData?.length ? (
          filteredData.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>
                <div>
                  {val.tgl_transfer_produksi_bahan
                    ? DateConvert(new Date(val.tgl_transfer_produksi_bahan))
                        .defaultDMY
                    : '-'}
                </div>
                {val.no_transfer_produksi_bahan}
              </Td>
              <Td>{val.nama_item_buaso}</Td>
              <Td>
                {parseFloat(val.qty_transfer)} {val.nama_satuan ?? ''}
              </Td>
              <Td>{val.nama_gudang_asal}</Td>
              <Td>{val.nama_gudang_tujuan}</Td>
              <Td>{val.diserahkan}</Td>
              <Td>{val.keterangan}</Td>
              {isEditable && (
                <Td>
                  <div className="d-flex justify-content-center align-items-center">
                    <UpdateButton
                      onClick={() =>
                        setModalForm({
                          show: true,
                          type: tipeTransfer,
                          data: val,
                        })
                      }
                    />
                    <DeleteButton
                      onClick={() => {
                        incrementStok({
                          id_barang: val.id_item_buaso,
                          id_gudang: val.id_gudang_asal,
                          amount: val.qty_transfer,
                        });

                        const newData = removeItemTable({
                          data,
                          uniqueKey,
                          values: val,
                        });

                        setDataPermintaan((prev) => ({
                          ...prev,
                          [tipeTransfer]: newData,
                        }));
                      }}
                    />
                  </div>
                </Td>
              )}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={isEditable ? 9 : 8}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
