import { Services } from '../../../services';

class BuasoApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/buaso/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  create(data) {
    return Services.post('/buaso', data);
  }
  update(data) {
    return Services.put('/buaso', data);
  }
  delete(id_buaso) {
    return Services.post('/buaso/delete', id_buaso);
  }
  search(key) {
    return Services.get(`buaso/page/?q=${key}`);
  }
  show(id_buaso) {
    return Services.put('/buaso/show', id_buaso);
  }
  hide(id_buaso) {
    return Services.put('/buaso/hide', id_buaso);
  }
}

export default new BuasoApi();
