import React, { useState, useEffect } from 'react';
import { ActionButton, SelectSearch, Input, DatePicker } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { Modal, Row, Col } from 'react-bootstrap';

const FormSection = ({
  formik,
  text,
  dataStok,
  dropdownItemBahan,
  dropdownGudang,
  dropdownLoading,
  ...rest
}) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = formik;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <DatePicker
            label="Tgl. Setup Stok Awal"
            type="date"
            name="tanggal_mutasi"
            dateFormat="dd/MM/yyyy"
            placeholderText="Masukkan tanggal"
            autoComplete="off"
            selected={
              values.tanggal_mutasi ? new Date(values.tanggal_mutasi) : ''
            }
            // value={values.tanggal_mutasi}
            // onChange={date => setFieldValue('tanggal_mutasi', DateConvert(date).default)}
            onChange={(date) => {
              const value = DateConvert(new Date(date)).default;
              setFieldValue('tanggal_mutasi', value);
            }}
            error={errors.tanggal_mutasi && touched.tanggal_mutasi && true}
            errorText={
              errors.tanggal_mutasi &&
              touched.tanggal_mutasi &&
              errors.tanggal_mutasi
            }
          />
          <SelectSearch
            label="Item Bahan"
            type="select"
            placeholder="Pilih salah satu..."
            name="id_barang"
            option={dropdownItemBahan.map((val) => {
              return {
                label: val.nama_item,
                value: val.id_item_buaso,
                id_satuan_pakai: val.id_satuan_pakai,
                satuan_pakai: val.satuan_pakai,
              };
            })}
            onChange={(val) => {
              setValues({
                ...values,
                id_barang: val.value,
                id_satuan: val.id_satuan_pakai,
                nama_satuan: val.satuan_pakai,
              });
            }}
            error={errors.id_barang && touched.id_barang && true}
            errorText={
              errors.id_barang && touched.id_barang && errors.id_barang
            }
          />
          <Row>
            <Col>
              <Input
                label="Qty."
                type="number"
                name="jumlah"
                placeholder="Masukkan jumlah"
                value={values.jumlah}
                onChange={handleChange}
                error={errors.jumlah && touched.jumlah && true}
                errorText={errors.jumlah && touched.jumlah && errors.jumlah}
              />
            </Col>
            <Col>
              <Input
                key={values.id_item_buaso}
                label="Satuan Pakai"
                name="id_satuan"
                readOnly={true}
                loading={dropdownLoading}
                value={values.nama_satuan}
                onChange={(val) => {
                  setFieldValue('id_satuan', val.id_satuan);
                }}
              />
            </Col>
          </Row>
          {console.log(dataStok)}
          <SelectSearch
            label="Gudang"
            type="select"
            placeholder="Gudang Bahan Baku"
            name="id_gudang"
            // defaultValue={values.id_gudang
            //     ? dataStok.map((val) => ({label: val.nama_gudang, value: val.id_gudang})).find(val => values.id_gudang === val.value)
            //     : ''
            // }
            option={dropdownGudang.map((val) => {
              return {
                label: val.nama_gudang,
                value: val.id_gudang,
              };
            })}
            // onChange={val => setFieldValue('id_gudang', val.value)}
            onChange={(val) => {
              setValues({
                ...values,
                id_gudang: val.value,
              });
            }}
            error={errors.id_gudang && touched.id_gudang && true}
            errorText={
              errors.id_gudang && touched.id_gudang && errors.id_gudang
            }
          />
          <Input
            label="Harga Satuan"
            // type='number'
            id="rupiah"
            name="harga"
            placeholder="Masukkan harga satuan"
            value={RupiahConvert(values?.harga?.toString() ?? '0').detail}
            // onChange={handleChange}
            onChange={(e) => {
              let nilai = e.target.value;
              let convert = RupiahConvert(nilai).default;
              setFieldValue('harga', convert);
            }}
            error={errors.harga && touched.harga && true}
            errorText={errors.harga && touched.harga && errors.harga}
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            {...rest}
            type="submit"
            variant="primary"
            text={text}
            className="mt-2 px-4"
            loading={isSubmitting}
          />
        </Modal.Footer>
      </form>
    </div>
  );
};

export default FormSection;
