import { useState, useEffect } from 'react';
import { Row, Dropdown, Spinner } from 'react-bootstrap';
import { debounce } from 'lodash';
import Axios from 'axios';
import FileSaver from 'file-saver';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ReadButton,
  FilterButton,
  Pagination,
  InputSearch,
  Table,
  Th,
  Td,
} from 'components';
import { PageNumber, DateConvert } from 'utilities';
import { LaporanPenerimaanBarangApi } from 'api';
import {
  ModalFilterPenerimaanBarang,
  ModalDetailPenerimaanBarang,
} from './components';

const LaporanPenerimaanBarang = ({ setNavbarTitle }) => {
  const title = 'Laporan Penerimaan Barang';
  const [isFetchingReport, setIsFetchingReport] = useState(false);
  const [data, setData] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dropdown, setDropdown] = useState({
    item_barang: [],
    vendor: [],
    karyawan: [],
    satuan: [],
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'detail',
    data: {},
  });
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: false,
      status_approval: 'APP',
      tgl_penerimaan_barang_mulai: undefined,
      tgl_penerimaan_barang_selesai: undefined,
      tgl_purchase_order_mulai: undefined,
      tgl_purchase_order_selesai: undefined,
      vendor: undefined,
      item_barang: undefined,
      id_satuan_beli: undefined,
      karyawan_pengaju: undefined,
      karyawan_penerima: undefined,
    },
    pagination: {
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    },
    search: { key: '' },
  });

  // FETCH DROPDOWN FILTER
  const fetchDropdown = () => {
    Axios.all([
      LaporanPenerimaanBarangApi.getDropdownVendor(),
      LaporanPenerimaanBarangApi.getDropdownItemBarang(),
      LaporanPenerimaanBarangApi.getDropdownKaryawan(),
      LaporanPenerimaanBarangApi.getAtribut({ tipe: 'satuan' }),
    ])
      .then(
        Axios.spread((resVendor, resItem, resKaryawan, resSatuan) => {
          const vendor =
            resVendor?.data?.data?.map((val) => ({
              value: val.id_vendor,
              label: val.nama_vendor,
            })) ?? [];
          const item_barang =
            resItem?.data?.data?.map((val) => ({
              value: val.id_item_buaso,
              label: val.nama_item,
            })) ?? [];
          const karyawan =
            resKaryawan?.data?.data?.map((val) => ({
              value: val.id_karyawan,
              label: val.nama_karyawan,
            })) ?? [];
          const satuan =
            resSatuan?.data?.data?.map((val) => ({
              value: val.id_satuan,
              label: val.nama_satuan,
            })) ?? [];

          setDropdown({ vendor, item_barang, karyawan, satuan });
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      );
  };

  // FETCH DATA REPORT SESUAI FILTER
  const getReportData = () => {
    setIsFetchingReport(true);

    LaporanPenerimaanBarangApi.getPage({
      status_approval: 'APP',
      q: dataFilter?.search?.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_penerimaan_barang_mulai:
        dataFilter?.filter?.tgl_penerimaan_barang_mulai,
      tgl_penerimaan_barang_selesai:
        dataFilter?.filter?.tgl_penerimaan_barang_selesai,
      tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai:
        dataFilter?.filter?.tgl_purchase_order_selesai,
      vendor: dataFilter?.filter?.vendor,
      item_barang: dataFilter?.filter?.item_barang,
      id_satuan_beli: dataFilter?.filter?.id_satuan_beli,
      karyawan_pengaju: dataFilter?.filter?.karyawan_pengaju,
      karyawan_penerima: dataFilter?.filter?.karyawan_penerima,
    })
      .then(({ data }) => {
        setData(data?.data ?? []);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        }));
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsFetchingReport(false));
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    LaporanPenerimaanBarangApi.export({
      tipe,
      ...dataFilter.filter,
      active: undefined,
      status_approval: 'APP',
    })
      .then((res) => {
        const data = res.data.data;
        const rawURL = data.split(':')[1];
        const urlFile =
          window.location.protocol === 'http'
            ? `http:${rawURL}`
            : `https:${rawURL}`;

        FileSaver.saveAs(urlFile, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Export Data gagal',
        });
      })
      .finally(() => setLoadingExport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    fetchDropdown();

    return () => {
      setDropdown({ item_barang: [], vendor: [], karyawan: [] });
      setData([]);
    };
  }, [setNavbarTitle]);

  // fetch report on dataFilter change
  useEffect(() => {
    // if (dataFilter.filter.active) getReportData()
    getReportData();
  }, [
    dataFilter?.search?.key,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_penerimaan_barang_mulai,
    dataFilter?.filter?.tgl_penerimaan_barang_selesai,
    dataFilter?.filter?.tgl_purchase_order_mulai,
    dataFilter?.filter?.tgl_purchase_order_selesai,
    dataFilter?.filter?.vendor,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.id_satuan_beli,
    dataFilter?.filter?.karyawan_pengaju,
    dataFilter?.filter?.karyawan_penerima,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
  ]);

  const PageContent = () => {
    return (
      <>
        <div className="font-weight-bold mb-2">List Data Penerimaan Barang</div>
        {/* <TablePenerimaanBarang setModalConfig={setModalConfig} data={[{}]} /> */}
        <Table>
          <thead>
            <tr>
              <Th width="12px">No.</Th>
              <Th width="12px">Informasi Penerimaan Barang</Th>
              <Th width="12px">Informasi Purchase Order</Th>
              <Th>Pembuat</Th>
              <Th>Vendor</Th>
              <Th>Item Barang</Th>
              <Th width="50px">Qty. Penerimaan</Th>
              <Th width="50px">Satuan</Th>
              <Th width="100px">Penerima</Th>
              <Th width="12px">Aksi</Th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((val, index) => (
                <tr key={index}>
                  <Td className="align-middle text-nowrap text-center">
                    {PageNumber(
                      dataFilter?.pagination?.page,
                      dataFilter?.pagination?.dataLength,
                      index
                    )}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <div>
                      {
                        DateConvert(new Date(val.tgl_penerimaan_barang))
                          .defaultDMY
                      }
                      {val.created_at
                        ? ` - ${
                            new Date(val.created_at)
                              .toTimeString()
                              .split(' ')[0]
                          }`
                        : ''}
                    </div>
                    <div>{val.no_penerimaan_barang ?? ''}</div>
                  </Td>
                  <Td className="align-middle text-nowrap">
                    <div>
                      {DateConvert(new Date(val.tgl_purchase_order)).defaultDMY}
                      {val.created_at_purchase_order
                        ? ` - ${
                            new Date(val.created_at_purchase_order)
                              .toTimeString()
                              .split(' ')[0]
                          }`
                        : ''}
                    </div>
                    <div>{val.no_purchase_order ?? ''}</div>
                  </Td>
                  <Td className="align-middle text-nowrap ">
                    {val?.nama_karyawan_pengaju ?? '-'}
                  </Td>
                  <Td className="align-middle text-capitalize text-nowrap">
                    {' '}
                    {val?.nama_vendor ?? '-'}{' '}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val?.nama_item ?? '-'}
                  </Td>
                  <Td className="align-middle text-right">
                    {val.qty_beli ? val.qty_beli.replace('.', ',') : '0'}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {' '}
                    {val.nama_satuan_beli ?? '-'}{' '}
                  </Td>
                  <Td className="align-middle text-nowrap">
                    {val.nama_karyawan ?? '-'}{' '}
                  </Td>
                  <Td className="align-middle text-uppercase text-nowrap">
                    <ReadButton
                      onClick={() =>
                        setModalConfig({
                          show: true,
                          type: 'detail',
                          data: val,
                        })
                      }
                    />
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={10}
                  className="align-middle text-nowrap text-center"
                >
                  Tidak ada data
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Pagination */}
        {data?.length > 0 && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: { ...prev.pagination, page: selected + 1 },
              }))
            }
            onDataLengthChange={(e) =>
              setDataFilter((prev) => ({
                ...prev,
                pagination: {
                  ...prev.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              }))
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <Row className="d-flex ml-1 mb-3">
        <div className="mr-2 mb-1">
          <InputSearch
            placeholder="Cari barang jadi"
            onChange={debounce(
              (e) =>
                setDataFilter((prev) => ({
                  ...prev,
                  pagination: {
                    ...prev.pagination,
                    page: 1,
                  },
                  search: { key: e.target.value },
                })),
              1500
            )}
            onSubmit={(e) => e.preventDefault()}
          />
        </div>

        <FilterButton
          active={dataFilter?.filter?.active}
          onClick={() => setModalConfig({ show: true, type: 'filter' })}
          className="mr-2"
        />

        {/* Export Button */}
        <Dropdown>
          <Dropdown.Toggle
            disabled={loadingExport}
            className="ml-2 mb-2 px-3 btn btn-warning text-white"
          >
            {loadingExport ? (
              <Spinner animation="border" variant="white" size="sm" />
            ) : (
              'Export Data'
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => exportData('excel')}>
              Excel (.xlsx)
            </Dropdown.Item>
            <Dropdown.Item onClick={() => exportData('pdf')}>
              PDF (.pdf)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: '45vh' }}>
          <PageContent />
        </div>
      )}

      {/* modal filter */}
      {modalConfig.show && modalConfig.type === 'filter' && (
        <ModalFilterPenerimaanBarang
          dropdown={dropdown}
          setDropdown={setDropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* Modal detail penerimaan barang */}
      {modalConfig.show && modalConfig.type === 'detail' && (
        <ModalDetailPenerimaanBarang
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  );
};

export default LaporanPenerimaanBarang;
