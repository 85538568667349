import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  BackButton,
  DataStatus,
  Approval,
} from '../../../../components';
import { DeliveryOrderApi } from '../../../../api';
import { useHistory } from 'react-router-dom';
import { DateConvert } from '../../../../utilities';
import { Card, Col, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { PrintDeliveryOrder } from './PrintDeliveryOrder';

const DetailDeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  // data
  const [data, setData] = useState([]);
  // menampung data yang akan dihapus

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });

  const { id } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([DeliveryOrderApi.getFormEdit(id)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle('Delivery Order');

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const TambahData = () => {
    const refPrint = useRef();
    const reactToPrint = useReactToPrint({
      content: () => refPrint.current,
    });
    const handlePrint = () => {
      reactToPrint();
    };

    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          <span style={{ textTransform: 'capitalize' }}>{title}</span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {text ? text : '-'}
        </div>
      </div>
    );

    const ShowDataDo = ({ title, text, line }) => (
      <div className="mb-2">
        <small
          className="text-secondary"
          style={{ opacity: 0.8, textTransform: 'capitalize' }}
        >
          {title}
        </small>
        <div
          className="text-dark"
          style={{
            fontWeight: 500,
          }}
        >
          {text ? text : '-'}
        </div>
        {line && <hr className="my-2" />}
      </div>
    );

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Delivery Order</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {data.stakeholder.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== 'PEN' && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === 'VER' ||
                          val.status_approval === 'REV'
                            ? `Pemeriksa ${
                                val.approval_level !== '0'
                                  ? val.approval_level
                                  : ''
                              }`
                            : val.status_approval === 'APP'
                            ? 'Pengesah'
                            : 'Di Tolak Oleh'
                        }
                        value1={val.nama_karyawan ?? '-'}
                        title2="Catatan"
                        value2={val.catatan ?? '-'}
                      />
                    </Col>
                  )
              )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <>
        <Row>
          <Col lg="6">
            <ShowData
              title="Tgl. sales order"
              text={DateConvert(new Date(data.tgl_sales_order)).detail}
            />
          </Col>
          <Col lg="6">
            <ShowData title="No. sales order" text={data.no_sales_order} />
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <ShowData title="Customer" text={data.nama_customer} />
          </Col>
          <Col lg="6">
            <ShowData title="Sales" text={data.nama_sales} />
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <ShowData
              title="Gudang"
              text={data.nama_gudang ? data.nama_gudang : '-'}
            />
          </Col>
          <Col lg="6">
            <ShowData title="Proyek" text={data.nama_proyek ?? '-'} />
          </Col>
        </Row>

        <hr />
        <form>
          <Row>
            <Col lg="6">
              <ShowDataDo
                title="Tanggal delivery order"
                text={DateConvert(new Date(data.tgl_delivery_order)).detail}
                line={true}
              />
            </Col>
            <Col lg="6">
              <ShowDataDo
                title="No. delivery order"
                text={data.no_delivery_order}
                line={true}
              />
            </Col>
            <Col>
              <ShowDataDo
                title="Catatan delivery order"
                text={data.catatan_delivery_order}
                line={true}
              />
            </Col>
          </Row>
          <div className="mb-4"></div>
          <CRUDLayout.Table>
            <THead>
              <Tr className="text-center">
                <ThFixed>No</ThFixed>
                <Th>Kode barang</Th>
                <Th>Nama barang</Th>
                <Th>Gudang</Th>
                <Th>Qty SO</Th>
                <Th>Satuan</Th>
              </Tr>
            </THead>
            <TBody>
              {data?.detail?.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_barang}</TdFixed>
                  <Td>{val.nama_barang}</Td>
                  <Td>{val.nama_gudang}</Td>
                  <Td>{val.qty}</Td>
                  <Td>{val.nama_satuan}</Td>
                </Tr>
              ))}
            </TBody>
          </CRUDLayout.Table>
          <Approval data={data} />
          <div className="d-flex justify-content-end">
            <div style={{ display: 'none' }}>
              <PrintDeliveryOrder ref={refPrint} idRef={id} printData={data} />
            </div>
            <ActionButton
              size="sm"
              text="Cetak Delivery Order"
              onClick={handlePrint}
            />
            {/* <IoPrintOutline /> */}
          </div>
        </form>
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <b>Detail Delivery Order</b>
        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data !== undefined ? (
          <TambahData />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailDeliveryOrder;
