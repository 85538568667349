import { Services, UploadServices } from '../../../services';

class PurchaseRequestApi {
  getPage(params) {
    return Services.get('/purchase_request/page', { params });
  }

  single(id) {
    return Services.get('/purchase_request/single?id_purchase_request=' + id);
  }

  getNoSurat(id) {
    return Services.get(`/purchase_request/no_baru?tanggal=${id}`);
  }

  getKelompok() {
    return Services.get('/purchase_request/kelompok_bahan');
  }

  getJenis(id) {
    return Services.get(`/purchase_request/jenis_bahan?id_kelompok=${id}`);
  }

  getItem(id) {
    return Services.get(`/purchase_request/item_bahan?id_kelompok=${id}`);
  }

  getProgress(params) {
    return Services.get(`purchase_request/progress`, { params });
  }

  getProyek() {
    return Services.get('/purchase_request/proyek');
  }

  search(key) {
    return Services.get(`/purchase_request/page/?q=${key}`);
  }

  create(data) {
    return Services.post('/purchase_request', data);
  }

  update(data) {
    return Services.put('/purchase_request', data);
  }

  delete(id) {
    return Services.post('/purchase_request/delete', id);
  }

  upload(data) {
    return UploadServices.post('/upload/purchase_request_multiple', data);
  }

  getPengaju() {
    return Services.get('/dropdown/karyawan');
  }
}

export default new PurchaseRequestApi();
