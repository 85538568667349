import { Card, Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';

export const InfoSection = ({
  data,
  start = null,
  end = null,
  showGudang = false,
  showSatuan = false,
}) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <InfoItemHorizontal
              label="Nama Material"
              text={data?.nama_item ?? data?.nama_barang ?? '-'}
            />
            <InfoItemHorizontal
              label="Kode Material"
              text={data?.kode_item ?? data?.kode_barang ?? '-'}
            />
            <InfoItemHorizontal
              label="Periode"
              text={`
              ${start ? DateConvert(new Date(start)).defaultDMY : '-'} s/d  ${
                end ? DateConvert(new Date(end)).defaultDMY : '-'
              }`}
            />

            {showGudang && (
              <InfoItemHorizontal
                label="Gudang"
                text={data?.nama_gudang ?? '-'}
              />
            )}

            {showSatuan && (
              <InfoItemHorizontal
                label="Satuan Pakai"
                text={data?.nama_satuan ?? data?.satuan_pakai ?? '-'}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
