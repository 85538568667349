import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ActionButton, BackButton, DataStatus } from 'components';
import { PermintaanBahanProduksiApi } from 'api';
import {
  InfoSection,
  FormSection,
  TableSection,
  TableHistoryCetak,
} from './Section';

const CetakPermintaanBahanProduksi = ({ setNavbarTitle }) => {
  const location = useLocation();
  const history = useHistory();
  const [dataInfo, setDataInfo] = useState({});
  const [dataHistory, setDataHistory] = useState([]);
  const [dataHistoryCetak, setDataHistoryCetak] = useState([]);
  const [fetchingHistoryCetak, setFetchingHistoryCetak] = useState({
    success: false,
    loading: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // Mapping data untuk info section
  const mappingDataInfo = (data) => {
    return {
      id_jo: data.id_job_order ?? null,
      no_rap: data.no_rap ?? '-',
      no_jo: data.no_job_order ?? '-',
      customer: data.nama_customer ?? '-',
      barang_jadi: data.nama_barang_jadi ?? '-',
    };
  };

  // Mapping data untuk table history
  const mappingDataHistory = (data) =>
    data.map((val) => {
      return {
        id_history: val.id_transfer_produksi_bahan ?? null,
        tgl_transfer: val.tgl_transfer_produksi_bahan ?? null,
        no_transfer: val.no_transfer_produksi_bahan ?? '-',
        kode_barang: val.kode_item_buaso ?? '-',
        nama_barang: val.nama_item_buaso ?? '-',
        gudang_asal: val.nama_gudang_asal ?? '-',
        gudang_tujuan: val.nama_gudang_tujuan ?? '-',
        qty_transfer: val.qty_transfer ? parseFloat(val.qty_transfer) : 0,
        keterangan: '',
        checked: false,
      };
    });

  // Mapping data untuk table history cetak
  const mappingDataHistoryCetak = (data) =>
    data.map((val) => {
      return {
        id_history: val.id_transfer_produksi_bahan_cetak ?? null,
        tgl_dokumen: val.tgl_dokumen,
        no_dokumen: val.no_dokumen,
      };
    });

  // Fetching data history cetak
  const getDataHistoryCetak = (idJo) =>
    PermintaanBahanProduksiApi.getHistoryCetak({ id_job_order: idJo })
      .then(({ data }) => {
        const dataHistory = data.data;
        const mapDataHistory = mappingDataHistoryCetak(dataHistory ?? []);
        setDataHistoryCetak(mapDataHistory ?? []);

        setFetchingHistoryCetak({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchingHistoryCetak({
          loading: false,
          success: false,
        });
        setDataHistoryCetak([]);
      });

  // Mengecek apakah data yang ada didalam state tersedia
  const checkInitialData = async () => {
    const dataState = location?.state?.data;
    const getDataInfo = dataState?.info;
    const getDataHistory = dataState?.history;

    if (Boolean(dataState)) {
      const id = getDataInfo.id_job_order;
      const newDataInfo = mappingDataInfo(getDataInfo ?? {});
      const historyPFS = mappingDataHistory(getDataHistory.history_PFS ?? []);
      const historyPHW = mappingDataHistory(getDataHistory.history_PHW ?? []);
      const historyPPF = mappingDataHistory(getDataHistory.history_PPF ?? []);
      const historyPPW = mappingDataHistory(getDataHistory.history_PPW ?? []);
      const dataHistoryMerged = [
        ...historyPFS,
        ...historyPHW,
        ...historyPPF,
        ...historyPPW,
      ];

      setDataInfo(newDataInfo ?? {});
      setDataHistory(dataHistoryMerged ?? []);
      setFetchingHistoryCetak({
        loading: true,
        success: false,
      });

      await getDataHistoryCetak(id);
    }
  };

  const formInitialValues = {
    tanggal: '',
    nomor: '',
  };

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required('Pilih tanggal'),
    nomor: Yup.string().required('Pilih tangal untuk menentukan nomor'),
  });

  const formSubmitHandler = (values) => {
    const idJo = dataInfo.id_jo;
    const getSelectedData = dataHistory
      .filter((val) => val.checked === true)
      .map((val) => {
        return {
          id_transfer_produksi_bahan: val.id_history,
          keterangan: val.keterangan,
        };
      });

    const finalValues = {
      id_job_order: idJo,
      tgl_dokumen: values.tanggal,
      detail: getSelectedData,
    };

    PermintaanBahanProduksiApi.saveCetak(finalValues)
      .then(({ data }) => {
        const id = data.data.id_transfer_produksi_bahan_cetak;

        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Tambah data berhasil!',
        });

        history.push(
          '/inventory/transaksi/transfer-produksi-bahan/cetak/detail/' + id
        );
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Tambah data gagal!!',
        });
      })
      .finally(() => {
        checkInitialData();
      });
  };

  useEffect(() => {
    checkInitialData();
    setNavbarTitle('Transfer Produksi Bahan');
  }, []);

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Cetak Data Transfer Bahan Produksi</b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <InfoSection dataInfo={dataInfo} />
              <hr />
              <FormSection formik={formik} />
              <hr />
              <TableSection
                formik={formik}
                dataHistory={dataHistory}
                setDataHistory={setDataHistory}
              />
              {fetchingHistoryCetak.loading ? (
                <DataStatus loading text="Memuat data history cetak. . ." />
              ) : fetchingHistoryCetak.success ? (
                <TableHistoryCetak
                  dataHistoryCetak={dataHistoryCetak}
                  alertConfig={alertConfig}
                  setAlertConfig={setAlertConfig}
                />
              ) : (
                <DataStatus text="Data history cetak gagal dimuat!" />
              )}
              <hr />
              <div className="d-flex justify-content-end">
                <ActionButton
                  type="submit"
                  text="Simpan Cetak Produksi"
                  loading={formik.isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default CetakPermintaanBahanProduksi;
