import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  CreateButton,
  UpdateButton,
  ReadButton,
  ThFixed,
  TdFixed,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { MutasiAntarGudangApi } from 'api';
import PageModal from './components/PageModal';

const MutasiAntarGudang = ({ setNavbarTitle }) => {
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPenerimaanSuratJalan, setDataPenerimaanSuratJalan] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    type: '',
    show: false,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    MutasiAntarGudangApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPenerimaanSuratJalan(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('Mutasi Antar Gudang');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const STATUS_APPROVAL = {
    APP: 'APPROVED',
    REJ: 'REJECT',
    REV: 'REVISI',
    VER: 'VERIFIED',
    PEN: 'PENDING',
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            size="md"
            className="col"
            onClick={() => setModalConfig({ show: true, type: 'create' })}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPenerimaanSuratJalan?.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <Th>No</Th>
                <Th>Aksi</Th>
                <ThFixed>Tgl. Mutasi</ThFixed>
                <Th>No. Mutasi</Th>
                <Th>Item Bahan</Th>
                <Th width={200}>Gudang Asal</Th>
                <Th width={150}>Gudang Tujuan</Th>
                <ThFixed>Qty. Mutasi</ThFixed>
                <ThFixed>Satuan</ThFixed>
                <ThFixed>Status Approval</ThFixed>
              </tr>
            </thead>
            <tbody>
              {dataPenerimaanSuratJalan.map((val, index) => (
                <tr key={index}>
                  <TdFixed className="text-center">
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                    .
                  </TdFixed>
                  <Td>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <ReadButton
                        onClick={() => {
                          setProcessedData(val);
                          setModalConfig({
                            show: true,
                            type: 'detail',
                          });
                        }}
                      />
                      {val.status_approval === 'PEN' ||
                      val.status_approval === 'REV' ? (
                        <UpdateButton
                          onClick={() => {
                            setProcessedData(val);
                            setModalConfig({
                              show: true,
                              type: 'update',
                            });
                          }}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  </Td>
                  <Td>
                    {val.tgl_mutasi_antar_gudang
                      ? DateConvert(new Date(val.tgl_mutasi_antar_gudang))
                          .defaultDMY
                      : '-'}
                  </Td>
                  <Td>{val.no_mutasi_antar_gudang ?? '-'}</Td>
                  <Td>{val.nama_item ?? '-'}</Td>
                  <Td>{val.nama_gudang_asal ?? '-'}</Td>
                  <Td>{val.nama_gudang_tujuan ?? '-'}</Td>
                  <Td className="text-right">{val.qty_mutasi ?? '-'}</Td>
                  <Td>{val.nama_satuan ?? '-'}</Td>
                  <Td>{STATUS_APPROVAL[val.status_approval ?? 'PEN']}</Td>
                </tr>
              ))}
            </tbody>
          </Table>

          {!searchConfig.status && (
            <Pagination
              dataLength={paginationConfig.dataLength}
              dataNumber={
                paginationConfig.page * paginationConfig.dataLength -
                paginationConfig.dataLength +
                1
              }
              dataPage={
                paginationConfig.dataCount < paginationConfig.dataLength
                  ? paginationConfig.dataCount
                  : paginationConfig.page * paginationConfig.dataLength
              }
              dataCount={paginationConfig.dataCount}
              currentPage={paginationConfig.page}
              totalPage={paginationConfig.totalPage}
              onPaginationChange={({ selected }) =>
                setPaginationConfig({
                  ...paginationConfig,
                  page: selected + 1,
                })
              }
              onDataLengthChange={(e) =>
                setPaginationConfig({
                  ...paginationConfig,
                  page: 1,
                  dataLength: e.target.value,
                })
              }
            />
          )}
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* Modal */}
      {modalConfig.show && (
        <PageModal
          getInitialData={getInitialData}
          modalConfig={modalConfig}
          processedData={processedData}
          setAlertConfig={setAlertConfig}
          onHide={() => {
            setProcessedData({});
            setModalConfig({
              show: false,
              type: '',
            });
          }}
        />
      )}
    </CRUDLayout>
  );
};

export default MutasiAntarGudang;
