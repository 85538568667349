import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { ConvertBarangApi } from 'api';
import { Approval, BackButton, DataStatus, Alert } from 'components';
import {
  FormBarangAwal,
  FormConvertBarang,
  TableHasilConvert,
} from './Components';
import { DropdownProvider } from './Context';

const ContentDetailConvertBarang = ({ setAlert }) => {
  const history = useHistory();
  const { id_convert_barang } = useParams();
  const { values, setValues } = useFormikContext();
  const [isLoading, setLoading] = useState(true);

  const fetchData = () => {
    setLoading(true);

    ConvertBarangApi.getSingle({ id_convert_barang })
      .then(({ data }) => {
        setValues({
          tgl_convert_barang: data.data.tgl_convert_barang,
          no_convert_barang: data.data.no_convert_barang,
          qty_convert: data.data.qty_convert,
          status_approval: data.data.status_approval,
          catatan_convert_barang: data.data.catatan_convert_barang,
          detail: data.data.detail,
          stakeholder: data.data.stakeholder,
          id_item_buaso: data.data.id_item_buaso,
          nama_item: data.data.nama_item,
          nama_satuan: data.data.nama_satuan,
          id_gudang: data.data.id_gudang,
          nama_gudang: data.data.nama_gudang,
          nama_kelompok: data.data.nama_kelompok,
        });
      })
      .catch(({ response }) =>
        setAlert({
          text: `Data gagal dimuat ${response.data.message ?? ''}`,
          variant: 'danger',
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => !!id_convert_barang && fetchData(), [id_convert_barang]);

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <strong>Detail Data Convert Barang </strong>
            </Col>
            <Col className="d-flex justify-content-end">
              <BackButton onClick={() => history.goBack()} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <DataStatus loading={isLoading} text="Memuat data . . ." />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <strong>Detail Data Convert Barang </strong>
            </Col>
            <Col className="d-flex justify-content-end">
              <BackButton onClick={() => history.goBack()} />
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <FormConvertBarang type="DETAIL" />
        </Card.Body>
      </Card>

      <DropdownProvider>
        <Row className="mt-3">
          <Col md="4">
            <FormBarangAwal type="DETAIL" />
          </Col>

          <Col>
            <TableHasilConvert type="DETAIL" />
          </Col>
        </Row>
      </DropdownProvider>

      <Approval
        title="Convert Barang"
        data={{ stakeholder: values?.stakeholder ?? [] }}
      />
    </>
  );
};

const DetailConvertBarang = ({ setNavbarTitle }) => {
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const initialValues = {
    tgl_convert_barang: null,
    catatan_convert_barang: '',
    detail: [],
  };

  useEffect(() => {
    setNavbarTitle('Convert Barang');
  }, []);

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {() => (
        <>
          <Alert
            showCloseButton
            show={!!alert.text}
            text={alert.text}
            variant={alert.variant}
            onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
          />
          <ContentDetailConvertBarang setAlert={setAlert} />
        </>
      )}
    </Formik>
  );
};

export default DetailConvertBarang;
