import React, { useState, useEffect } from 'react';
import {
  Th,
  Tr,
  Td,
  TBody,
  THead,
  Alert,
  ThFixed,
  TdFixed,
  CRUDLayout,
  DataStatus,
  Pagination,
  CreateModal,
  InputSearch,
  ActionButton,
} from 'components';
import Axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { PageNumber, RupiahConvert, DateConvert } from 'utilities';
import { Row, Col } from 'react-bootstrap';
import { FormSection } from './section';
import { SetupStokAwalApi } from 'api';

const SetupStokAwal = ({ setNavbarTitle }) => {
  const [dataStok, setDataStok] = useState([]);
  const [dropdownItemBahan, setDropdownItemBahan] = useState([]);
  const [dropdownGudang, setDropdownGudang] = useState([]);
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    success: false,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [paginationConfig, setPaginationConfig] = useState({
    currentPage: 1,
    dataLength: 10,
    dataCount: 0,
    totalPage: 1,
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'create',
    title: 'Setup Stok Awal',
    data: {},
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });

  const getInitialData = () => {
    setFetchConfig({ ...fetchConfig, loading: true });
    setDropdownLoading(true);

    Axios.all([
      SetupStokAwalApi.getPage({
        page: paginationConfig.currentPage,
        per_page: paginationConfig.dataLength,
        q: searchConfig.key,
      }),
      SetupStokAwalApi.getDropdownBahan(),
      SetupStokAwalApi.getDropdownGudang(),
    ])
      .then(
        Axios.spread((res, bahan, gudang) => {
          setDataStok(res?.data?.data ?? []);
          setDropdownItemBahan(bahan?.data?.data ?? []);
          setDropdownGudang(gudang?.data?.data ?? []);

          setPaginationConfig({
            ...paginationConfig,
            dataCount: res?.data?.data_count ?? 0,
            totalPage: res?.data?.total_page ?? 1,
          });
          setFetchConfig({
            loading: false,
            success: true,
          });
          setDropdownLoading(false);
        })
      )
      .catch(() => {
        setFetchConfig({
          loading: false,
          success: false,
        });
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
        setDropdownLoading(false);
      });
  };
  //USE EFFECT
  useEffect(() => {
    setNavbarTitle('Setup Stok Awal');
    getInitialData();
  }, [
    setNavbarTitle,
    paginationConfig.currentPage,
    paginationConfig.dataLength,
    searchConfig.key,
    dataStok.nama_satuan,
  ]);
  //FORM VALUES & VALIDATION
  const formInitialData = {
    id_barang: '',
    id_gudang: '',
    jumlah: '',
    id_satuan: '',
    tanggal_mutasi: '',
    harga: '',
  };
  const formValidationSchema = Yup.object().shape({
    id_barang: Yup.string().required('Pilih item bahan'),
    id_gudang: Yup.string().required('Masukan nama gudang'),
    jumlah: Yup.string().required('Masukan jumlah'),
    // id_satuan: Yup.string().required("Masukan tanggal"),
    tanggal_mutasi: Yup.string().required('Masukan tanggal'),
    harga: Yup.string().required('Masukan harga satuan'),
  });
  const formSubmitHandler = (values) => {
    SetupStokAwalApi.create(values)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Tambah data berhasil!',
        });
      })
      .catch(({ response }) => {
        const message = response.data.message ?? '';
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: `Tambah data gagal! (${message ? `(${message})` : ''}`,
        });
      })
      .finally(() => {
        setModalConfig({
          show: false,
          type: 'create',
        });
        getInitialData();
      });
  };

  //MODAL
  const ModalCreate = () => {
    return (
      <CreateModal
        show={modalConfig.type === 'create' ? modalConfig.show : ''}
        onHide={() => setModalConfig({ show: false })}
        type={() => setModalConfig({ type: 'create' })}
        title="Setup Stok Awal"
      >
        <Formik
          initialValues={formInitialData}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <FormSection
              formik={formik}
              dataStok={dataStok}
              onSubmit={formSubmitHandler}
              dropdownItemBahan={dropdownItemBahan}
              dropdownGudang={dropdownGudang}
              dropdownLoading={dropdownLoading}
              text="Simpan"
            />
          )}
        </Formik>
      </CreateModal>
    );
  };

  //TABLE
  const Table = () => {
    return (
      <div>
        <div className="mb-1 mt-1">
          <span style={{ fontSize: '14px' }}>
            <b>List Data Setup Stok Awal</b>
          </span>
        </div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Tgl. Setup Stok Awal</Th>
              <Th>Item Bahan</Th>
              <Th>Qty.</Th>
              <Th>Satuan Pakai</Th>
              <Th>Gudang</Th>
              <Th>Harga Satuan</Th>
            </Tr>
          </THead>
          <TBody>
            {dataStok.length === 0 ? (
              <Tr>
                <Td colSpan={7}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            ) : (
              dataStok.map((val, index) => (
                <Tr key={index}>
                  <TdFixed textCenter>
                    {PageNumber(
                      paginationConfig.currentPage,
                      paginationConfig.dataLength,
                      index
                    )}
                  </TdFixed>
                  <Td width="100px">
                    {val.tanggal_mutasi
                      ? DateConvert(new Date(val.tanggal_mutasi)).defaultDMY
                      : ''}
                  </Td>
                  <Td style={{ minWidth: '150px' }}>
                    {val.nama_barang ?? '-'}
                  </Td>
                  <Td width="60px">{val.jumlah ?? '-'}</Td>
                  <Td width="80px">{val.nama_satuan ?? '-'}</Td>
                  <Td width="180px">{val.nama_gudang ?? '-'}</Td>
                  <Td className="text-right">
                    {val.harga
                      ? RupiahConvert(String(parseInt(val.harga))).detail
                      : 'Rp. 0'}
                  </Td>
                </Tr>
              ))
            )}
          </TBody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={paginationConfig?.dataLength}
          dataNumber={
            paginationConfig?.currentPage * paginationConfig?.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.currentPage * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.currentPage}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig((prev) => ({
              ...prev,
              currentPage: selected + 1,
            }))
          }
          onDataLengthChange={(e) => {
            setPaginationConfig({
              ...paginationConfig,
              currentPage: 1,
              dataLength: e.target.value,
            });
          }}
        />
      </div>
    );
  };
  //RETURN ERROR
  if (fetchConfig.loading || !fetchConfig.success) {
    return (
      <div>
        <CRUDLayout>
          <CRUDLayout.Head>
            <CRUDLayout.HeadSearchSection>
              <Row>
                <Col md={8}>
                  <InputSearch
                    onChange={(e) =>
                      setSearchConfig({ ...searchConfig, key: e.target.value })
                    }
                    onSubmit={(e) => e.preventDefault()}
                  />
                </Col>
              </Row>
            </CRUDLayout.HeadSearchSection>
            <CRUDLayout.HeadButtonSection>
              <ActionButton
                text="Tambah Data"
                onClick={() => setModalConfig({ show: true, type: 'create' })}
              />
            </CRUDLayout.HeadButtonSection>
          </CRUDLayout.Head>
        </CRUDLayout>
        <DataStatus
          loading={fetchConfig.loading}
          text={fetchConfig.loading ? 'Memuat data. . .' : 'Tidak ada data'}
        />
      </div>
    );
  }

  return (
    <div>
      <CRUDLayout>
        <CRUDLayout.Head>
          <CRUDLayout.HeadSearchSection>
            <Row>
              <Col md={8}>
                <InputSearch
                  onChange={(e) =>
                    setSearchConfig({ ...searchConfig, key: e.target.value })
                  }
                  onSubmit={(e) => e.preventDefault()}
                />
              </Col>
            </Row>
          </CRUDLayout.HeadSearchSection>
          <CRUDLayout.HeadButtonSection>
            <ActionButton
              text="Tambah Data"
              onClick={() => setModalConfig({ show: true, type: 'create' })}
            />
          </CRUDLayout.HeadButtonSection>
        </CRUDLayout.Head>
      </CRUDLayout>
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ show: false })}
      />
      {fetchConfig.loading && (
        <DataStatus loading={fetchConfig.loading} text="Memuat Data. . ." />
      )}

      {!fetchConfig.success || dataStok.length <= 0 ? (
        <DataStatus text="Tidak ada data" />
      ) : (
        <Table />
      )}
      <ModalCreate />
    </div>
  );
};

export default SetupStokAwal;
