import React, { useState, useEffect } from 'react';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js';
import HTMLReactParser from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import { TextEditor, ActionButton } from 'components';

const TextEditorSection = ({ formik }) => {
  const { values, isSubmitting, setFieldValue, handleSubmit } = formik;
  const catatan = values.catatan ?? '<p></p>';

  // Memperbaharui nilai catatan & submit
  const submitHandler = () => {
    const convertData = convertToRaw(catatan.getCurrentContent());
    setFieldValue('catatan', draftToHtml(convertData));
    handleSubmit();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <b>Catatan</b>
      {/* <div className="p-2 border">
        {HTMLReactParser(values.catatan)}
      </div> */}
    </>
  );
};

export default TextEditorSection;
