import { ConvertBarangApi } from 'api';

export const fetchStok = ({ values, setValues }) => {
  ConvertBarangApi.getStok({
    id_barang: values.id_item_buaso,
    id_gudang: values.id_gudang,
  }).then(({ data }) =>
    setValues({
      ...values,
      stok: parseFloat(data?.data?.stok ?? 0),
      nilai_persediaan: data?.data?.harga_terbaru ?? 0,
    })
  );
};
