import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, ButtonGroup, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  DatePicker,
  Input,
  InfoItemHorizontal,
  InfoItemVertical,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { PenerimaanSuratJalanApi } from 'api';
import { useIsGuest } from 'hooks';

const PenerimaanSuratJalan = ({ setNavbarTitle }) => {
  const location = useLocation();
  const isGuest = useIsGuest();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPenerimaanSuratJalan, setDataPenerimaanSuratJalan] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [modalConfig, setModalConfig] = useState({
    type: '',
    show: false,
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PenerimaanSuratJalanApi.getPage({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPenerimaanSuratJalan(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('Penerimaan Surat Jalan');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const PageContent = () => {
    const DataTable = () => {
      const getStatusPenerimaan = (val) => {
        switch (val.status_surat_jalan) {
          case 'terima':
            return (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span style={{ color: '#00cc00' }}> SUDAH DITERIMA</span>
                {val?.no_penerimaan_surat_jalan ?? '-'}
              </div>
            );

          case 'batal':
            return (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span style={{ color: '#ff0000' }}> DIBATALKAN</span>
                {val?.no_penerimaan_surat_jalan ?? '-'}
              </div>
            );

          default:
            return (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span style={{ color: '#0408ff' }}> BELUM DITERIMA</span>
              </div>
            );
        }
      };

      return (
        <Table>
          <thead>
            <tr>
              <Th>No</Th>
              <Th width={100}>Informasi Surat Jalan</Th>
              <Th>Informasi Delivery Order</Th>
              <Th width={200}>Customer</Th>
              <Th width={200}>Nama Proyek</Th>
              <Th width={150}>Sales</Th>
              <Th width={100}>Tgl. Batas Waktu</Th>
              <Th>Status Penerimaan</Th>
              <Th>Aksi</Th>
            </tr>
          </thead>
          <tbody>
            {dataPenerimaanSuratJalan.map((val, index) => (
              <tr key={index}>
                <Td>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td>
                  <div>
                    {val.tgl_surat_jalan
                      ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_surat_jalan ?? '-'}</div>
                </Td>
                <Td>
                  <div>
                    {val.tgl_delivery_order
                      ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_delivery_order ?? '-'}</div>
                </Td>
                <Td>{val.nama_customer ?? '-'}</Td>
                <Td>{val.nama_proyek ?? '-'}</Td>
                <Td>{val.nama_sales ?? '-'}</Td>
                <Td>
                  {val.batas_waktu
                    ? DateConvert(new Date(val.batas_waktu)).defaultDMY
                    : '-'}
                </Td>
                <Td>{getStatusPenerimaan(val) ?? '-'}</Td>
                <Td className="align-middle">
                  {val.status_surat_jalan === 'pending' ? (
                    <div>
                      {!isGuest && (
                        <ButtonGroup size="sm" className="btn-block">
                          <ActionButton
                            variant="success"
                            text="Terima"
                            onClick={() => {
                              setProcessedData(val);
                              setModalConfig({
                                show: true,
                                type: 'penerimaan',
                              });
                            }}
                          />
                          <ActionButton
                            variant="danger"
                            text="Batal"
                            onClick={() => {
                              setProcessedData(val);
                              setModalConfig({
                                show: true,
                                type: 'pembatalan',
                              });
                            }}
                          />
                        </ButtonGroup>
                      )}
                    </div>
                  ) : (
                    <ActionButton
                      size="sm"
                      text="Detail"
                      className="col"
                      onClick={() => {
                        setProcessedData(val);
                        setModalConfig({
                          show: true,
                          type: 'detail',
                        });
                      }}
                    />
                  )}
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    };

    if (!dataPenerimaanSuratJalan || dataPenerimaanSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  const PageModal = () => {
    const today = DateConvert(new Date()).default;
    const [dataInfoModal, setDataInfoModal] = useState({});
    const [dataTableModal, setDataTableModal] = useState([]);
    const [modalFetchingStatus, setModalFetchingStatus] = useState({
      loading: false,
      success: true,
    });

    const mappingDataInfo = (data) => {
      return {
        tgl_surat_jalan: data.tgl_surat_jalan
          ? DateConvert(new Date(data.tgl_surat_jalan)).detail
          : '-',
        no_surat_jalan: data.no_surat_jalan ?? '-',
        no_delivery_order: data.no_delivery_order ?? '-',
        no_sales_order: data.no_sales_order ?? '-',
        customer: data.nama_customer ?? '-',
        nama_proyek: data.nama_proyek ?? '-',
        sales: data.nama_sales ?? '-',
        petugas_pengiriman: data.nama_sopir ?? '-',
        armada_pengiriman: `${data?.plat_nomor ?? ''} - ${
          data?.nama_item_aset ?? ''
        }`,
        tgl_penerimaan_surat_jalan: data.tgl_penerimaan_surat_jalan
          ? DateConvert(new Date(data.tgl_penerimaan_surat_jalan)).detail
          : '-',
        no_penerimaan_surat_jalan: data.no_penerimaan_surat_jalan ?? '-',
        batas_waktu: data.batas_waktu ?? '-',
        gudang: data.nama_gudang ?? '-',
      };
    };

    const mappingDataTable = (data) => {
      return data.map((val) => {
        return {
          kode_barang: val.kode_barang ?? '-',
          nama_barang: val.nama_barang ?? '-',
          gudang: val.nama_gudang ?? '-',
          qty: val.qty_sj ? parseInt(val.qty_sj).toFixed(2) : 0,
          satuan: val.nama_satuan ?? '-',
        };
      });
    };

    const getInitialModalData = () => {
      setModalFetchingStatus({
        loading: true,
        success: false,
      });

      PenerimaanSuratJalanApi.getSingle({
        id_surat_jalan: processedData.id_surat_jalan,
      })
        .then(({ data }) => {
          const mapDataInfo = mappingDataInfo(data.data ?? {});
          const mapDataTable = mappingDataTable(data.data.detail ?? []);

          setDataInfoModal(mapDataInfo);
          setDataTableModal(mapDataTable);

          setModalFetchingStatus({
            loading: false,
            success: true,
          });
        })
        .catch(() => {
          setModalFetchingStatus({
            loading: false,
            success: false,
          });
        });
    };

    useEffect(() => {
      getInitialModalData();

      return () => {
        setDataInfoModal({});
        setDataTableModal([]);
      };
    }, []);

    const ModalContent = () => {
      const formInitialValues = {
        tgl: today,
        no: '',
      };

      const formValidationSchema = Yup.object().shape({
        tgl: Yup.string().required('Pilih tanggal penerimaan surat jalan'),
        no: Yup.string().required('Pilih tanggal untuk menentukan nomor'),
      });

      const formSubmitHandler = (values) => {
        const finalValues = {
          id_surat_jalan: processedData.id_surat_jalan,
          tgl_penerimaan_surat_jalan: values.tgl,
        };

        PenerimaanSuratJalanApi.save(modalConfig.type, finalValues)
          .then(() => {
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: 'Data berhasil disimpan!',
            });
          })
          .catch(({ response }) => {
            const message = response.data.message ?? '';
            setAlertConfig({
              show: true,
              variant: 'danger',
              text: `Data gagal disimpan! ${message ? `(${message})` : ''}`,
            });
          })
          .finally(() => {
            setProcessedData({});
            setModalConfig({
              show: false,
              type: '',
            });
            getInitialData();
          });
      };

      const InfoSection = () => {
        return (
          <Row>
            <Col lg>
              <table style={{ fontSize: 14 }}>
                <tbody>
                  <InfoItemHorizontal
                    label="Tgl. Surat Jalan tets"
                    text={dataInfoModal.tgl_surat_jalan}
                  />
                  <InfoItemHorizontal
                    label="No. Surat Jalan"
                    text={dataInfoModal.no_surat_jalan}
                  />
                  <InfoItemHorizontal
                    label="No. Delivery Order"
                    text={dataInfoModal.no_delivery_order}
                  />
                  <InfoItemHorizontal
                    label="No. Sales Order"
                    text={dataInfoModal.no_sales_order}
                  />
                  <InfoItemHorizontal
                    label="Tgl.Batas Waktu"
                    text={
                      DateConvert(new Date(dataInfoModal.batas_waktu)).detail
                    }
                  />
                  <InfoItemHorizontal
                    label="Proyek"
                    text={dataInfoModal.nama_proyek ?? '-'}
                  />
                </tbody>
              </table>
            </Col>
            <Col lg>
              <table style={{ fontSize: 14 }}>
                <tbody>
                  <InfoItemHorizontal
                    label="Customer"
                    text={dataInfoModal.customer}
                  />
                  <InfoItemHorizontal
                    label="Sales"
                    text={dataInfoModal.sales}
                  />
                  <InfoItemHorizontal
                    label="Gudang"
                    text={dataInfoModal.gudang}
                  />
                  <InfoItemHorizontal
                    label="Petugas Pengiriman"
                    text={dataInfoModal.petugas_pengiriman}
                  />
                  <InfoItemHorizontal
                    label="Armada Pengiriman"
                    text={dataInfoModal.armada_pengiriman}
                  />
                </tbody>
              </table>
            </Col>
          </Row>
        );
      };

      const InfoSectionDetail = () => {
        return (
          <Row className="my-2">
            <Col lg>
              <InfoItemVertical
                label="Tgl. Penerimaan Surat Jalan"
                text={dataInfoModal.tgl_penerimaan_surat_jalan}
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="No. Penerimaan Surat Jalan"
                text={dataInfoModal.no_penerimaan_surat_jalan}
              />
            </Col>
          </Row>
        );
      };

      const FormSection = ({ formik }) => {
        const [isNomorLoading, setIsNomorLoading] = useState(true);
        const { values, errors, touched, setValues } = formik;

        const getNomorHandler = (tanggal) => {
          setIsNomorLoading(true);

          PenerimaanSuratJalanApi.getNomor(modalConfig.type, { tanggal })
            .then((val) => {
              setValues({
                tgl: tanggal,
                no: val?.data?.data ?? '-',
              });
            })
            .catch(() => {
              window.alert('Gagal memuat nomor');
              setValues({
                tgl: '',
                no: '',
              });
            })
            .finally(() => setIsNomorLoading(false));
        };

        useEffect(() => {
          getNomorHandler(today);
        }, []);

        return (
          <Row>
            <Col md>
              <DatePicker
                label={`Tgl. ${
                  modalConfig.type.toLowerCase() === 'penerimaan'
                    ? 'Penerimaan'
                    : 'Pembatalan'
                } Surat Jalan`}
                selected={values.tgl ? new Date(values.tgl) : ''}
                placeholderText="Pilih tanggal"
                onChange={(date) =>
                  getNomorHandler(DateConvert(new Date(date)).default)
                }
                error={Boolean(errors.tgl) && touched.tgl}
                errorText={Boolean(errors.tgl) && touched.tgl && errors.tgl}
              />
            </Col>
            <Col md>
              <Input
                label={`No. ${
                  modalConfig.type.toLowerCase() === 'penerimaan'
                    ? 'Penerimaan'
                    : 'Pembatalan'
                } Surat Jalan`}
                placeholder={
                  isNomorLoading
                    ? 'Memuat nomor . . .'
                    : 'Pilih tanggal untuk menentukan nomor'
                }
                value={isNomorLoading ? 'Memuat nomor . . .' : values.no}
                error={Boolean(errors.no) && touched.no}
                errorText={Boolean(errors.no) && touched.no && errors.no}
                readOnly
              />
            </Col>
          </Row>
        );
      };

      const TableSection = () => (
        <Table>
          <thead className="bg-light">
            <tr>
              <Th width={10}>No.</Th>
              <Th>Kode Barang</Th>
              <Th width={225}>Nama Barang</Th>
              <Th>Gudang</Th>
              <Th>Qty. Surat Jalan</Th>
              <Th>Satuan</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableModal.length > 0 ? (
              dataTableModal.map((val, index) => (
                <tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{val.kode_barang}</Td>
                  <Td>{val.nama_barang}</Td>
                  <Td>{val.gudang}</Td>
                  <Td className="text-right">{val.qty}</Td>
                  <Td>{val.satuan}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      );

      // Cek apakah modal bertipe detail
      if (modalConfig.type.toLowerCase() === 'detail') {
        return (
          <Modal.Body>
            <InfoSection />
            <hr className="mb-1" />
            <InfoSectionDetail />
            <TableSection />
          </Modal.Body>
        );
      }

      // Modal bertipe penerimaan/pembatalan
      return (
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <InfoSection />
                <hr className="mb-1" />
                <FormSection formik={formik} />
                <hr className="mt-1" />
                <TableSection />
              </Modal.Body>
              <Modal.Footer>
                {modalConfig.type.toLowerCase() === 'penerimaan' ? (
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Terima Surat Jalan"
                    loading={formik.isSubmitting}
                  />
                ) : (
                  <ActionButton
                    type="submit"
                    variant="danger"
                    text="Batalkan Surat Jalan"
                    loading={formik.isSubmitting}
                  />
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      );
    };

    return (
      <Modal
        size="lg"
        show={modalConfig.show}
        onHide={() => {
          setProcessedData({});
          setModalConfig({
            show: false,
            type: '',
          });
        }}
      >
        <Modal.Header closeButton>
          <b className="text-capitalize">{modalConfig.type} surat jalan</b>
        </Modal.Header>
        {modalFetchingStatus.loading ? (
          <DataStatus loading text="Memuat data . . ." />
        ) : modalFetchingStatus.success ? (
          <ModalContent />
        ) : (
          <DataStatus text="Data gagal dimuat" />
        )}
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {/* Modal */}
      {modalConfig.show && <PageModal />}
    </CRUDLayout>
  );
};

export default PenerimaanSuratJalan;
