import { Services } from 'services';

class TransferPrelimApi {
  get(params) {
    return Services.get('/transfer_prelim/page', { params });
  }

  getPermintaan(params) {
    return Services.get('/transfer_prelim/list_permintaan', { params });
  }

  getSingle(params) {
    return Services.get('/transfer_prelim/single', { params });
  }

  getNomor(params) {
    return Services.get('/transfer_prelim/no_baru', { params });
  }

  getDropdown(params) {
    return Services.get('/transfer_prelim/dropdown', { params });
  }

  save(data) {
    return Services.post('/transfer_prelim', data);
  }
}

export default new TransferPrelimApi();
