import { useState } from 'react';
import { Table, Th, Td, DataStatus, TdFixed } from 'components';
import { DateConvert } from 'utilities';

const TableSection = ({ dataHistory, setDataHistory }) => {
  return (
    <>
      <div className="p-1">
        <b>List Item Transfer Produksi</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Tgl. Transfer</Th>
          <Th>No. Transfer</Th>
          <Th>Kode Barang</Th>
          <Th>Item Barang</Th>
          <Th>Gudang Asal</Th>
          <Th>Gudang Tujuan</Th>
          <Th>Qty Transfer</Th>
          <Th>Keterangan</Th>
        </thead>
        <tbody>
          {dataHistory.length > 0 ? (
            dataHistory.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>
                  {val.tgl_transfer
                    ? DateConvert(new Date(val.tgl_transfer)).defaultDMY
                    : '-'}
                </Td>
                <Td>{val.no_transfer}</Td>
                <Td>{val.kode_barang}</Td>
                <Td>{val.nama_barang}</Td>
                <Td>{val.gudang_asal}</Td>
                <Td>{val.gudang_tujuan}</Td>
                <Td className="text-right">{val.qty_transfer}</Td>
                <Td>{val.keterangan}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableSection;
