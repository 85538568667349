import { Services } from '../../../services';

class PenerimaanReturApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/penerimaan_retur/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  search(key) {
    return Services.get(`/penerimaan_retur/page/?q=${key}`);
  }
  getFaktur(page, dataLength, key) {
    return Services.get(
      `/penerimaan_retur/retur_penjualan/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  searchFaktur(key) {
    return Services.get(`/penerimaan_retur/retur_penjualan/page/?q=${key}`);
  }
  getForm(id) {
    return Services.get(
      `/penerimaan_retur/retur_penjualan_detail/single?id_retur_penjualan=${id}`
    );
  }
  getGudang() {
    return Services.get('/gudang/dropdown');
  }
  getFormEdit(id) {
    return Services.get(`/penerimaan_retur/single?id_penerimaan_retur=${id}`);
  }
  getKode(value) {
    return Services.get(`/penerimaan_retur/no_baru?tanggal=${value}`);
  }
  create(data) {
    return Services.post('/penerimaan_retur', data);
  }
  update(data) {
    return Services.put('/penerimaan_retur', data);
  }
  delete(id) {
    return Services.post('/penerimaan_retur/delete', id);
  }
}

export default new PenerimaanReturApi();
