import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ReadButton,
  DataStatus,
  Alert,
  DeleteModal,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
} from '../../../../components';
import { DeliveryOrderApi } from '../../../../api';
import { useHistory } from 'react-router-dom';
import { DateConvert } from '../../../../utilities';
import { Modal } from 'react-bootstrap';
import {
  IoReload,
  IoCloseCircleOutline,
  IoCheckmarkCircleOutline,
} from 'react-icons/io5';
import { PageNumber } from '../../../../utilities';

const DeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  const history = useHistory();
  const location = useLocation();
  let createAlert = location?.state?.variant;

  // data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState('');
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const [modalStatus, setModalStatus] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const [noRef, setNoRef] = useState(0);
  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    Axios.all([DeliveryOrderApi.getPage(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase();
    if (newStatus === 'REJ') {
      return 'REJECTED';
    }

    if (newStatus === 'REV') {
      return 'REVISI';
    }

    if (newStatus === 'APP') {
      return 'APPROVED';
    }

    if (newStatus === 'VER') {
      return 'VERIFIED';
    }

    return 'PENDING';
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle('Delivery Order');
    if (createAlert !== undefined) {
      setShowAlert(true);
      setAlertConfig({
        variant: location?.state?.variant,
        text: location?.state?.text,
      });

      history.replace((createAlert = undefined));
    } else {
      return getData();
    }
    getData();
    return () => {
      setIsLoading(false);
      setIsSearching(false);
      setShowAlert(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // Modal Hapus
  const HapusModal = () => {
    // id dari data yang ingin dihapus
    const idData = deleteData.id_delivery_order;
    const value = { id_delivery_order: idData };
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // request hapus data ke server

    const deleteDataHandler = () => {
      setBtnLoading(true);
      DeliveryOrderApi.delete(value)
        .then(() => {
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal! (${err?.response?.data?.message})`,
          });
        })
        .finally(() => {
          // menampilkan alert
          setShowAlert(true);
          // menutup hapus modal
          setIsDeleteData(false);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title="Delivery Order"
      >
        <div>No. Delivery Order : {deleteData.no_delivery_order}</div>
        <div>Nama Customer : {deleteData.nama_customer}</div>
      </DeleteModal>
    );
  };

  const ModalStatus = () => {
    const [lodaingModal, setLodaingModal] = useState(true);
    const [dataProgress, setDataProgress] = useState({});

    const getProgress = () => {
      setLodaingModal(true);
      DeliveryOrderApi.getProgress(noRef)
        .then((res) => {
          setDataProgress(res.data.data);
        })
        .catch((err) => {
          setAlertConfig(err);
        })
        .finally(() => {
          setLodaingModal(false);
        });
    };

    useEffect(() => {
      if (noRef !== 0) {
        getProgress();
      }
      return () => {
        setLodaingModal(false);
      };
    }, []);

    return (
      <>
        <Modal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-info">
              {modalStatus === 'PROGRESS' ? (
                <h6 className="mb-0 text-info">
                  <IoReload className="mb-1 mr-2" size={20} />
                  Progres (Status ON PROGRES)
                </h6>
              ) : modalStatus === 'DONE' ? (
                <h6 className="mb-0 text-success">
                  <IoCheckmarkCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status DONE)
                </h6>
              ) : (
                <h6 className="mb-0 text-danger">
                  <IoCloseCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status CANCELED)
                </h6>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {lodaingModal === true ? (
              <DataStatus text="Memuat Data..." />
            ) : (
              <CRUDLayout.Table>
                <THead>
                  <Tr className="text-center">
                    <ThFixed>No</ThFixed>
                    <Th>Nama proses</Th>
                    <Th>Tanggal proses</Th>
                    <Th>No dokumen</Th>
                  </Tr>
                </THead>
                <TBody>
                  <Tr>
                    <TdFixed>1</TdFixed>
                    <Td>Sales Order (SO)</Td>
                    <Td>
                      {
                        DateConvert(new Date(dataProgress.tgl_sales_order))
                          .detail
                      }
                    </Td>
                    <Td>{dataProgress.no_sales_order}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>2</TdFixed>
                    <Td>Delivery Order (DO)</Td>
                    <Td>
                      {
                        DateConvert(new Date(dataProgress.tgl_delivery_order))
                          .detail
                      }
                    </Td>
                    <Td>{dataProgress.no_delivery_order}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>3</TdFixed>
                    <Td>Faktur Penjualan</Td>
                    <Td>
                      {
                        DateConvert(new Date(dataProgress.tgl_faktur_penjualan))
                          .detail
                      }
                    </Td>
                    <Td>{dataProgress.no_faktur_penjualan}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>4</TdFixed>
                    <Td>Surat Jalan</Td>
                    <Td>
                      {dataProgress.tgl_surat_jalan !== null
                        ? DateConvert(new Date(dataProgress.tgl_surat_jalan))
                            .detail
                        : ''}
                    </Td>
                    <Td>{dataProgress.no_surat_jalan}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>5</TdFixed>
                    <Td>Penerimaan Faktur</Td>
                    <Td>
                      {dataProgress.tgl_penerimaan_faktur !== null
                        ? DateConvert(
                            new Date(dataProgress.tgl_penerimaan_faktur)
                          ).detail
                        : ''}
                    </Td>
                    <Td>{dataProgress.no_penerimaan_faktur}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>6</TdFixed>
                    <Td>Pembatalan Faktur</Td>
                    <Td>
                      {dataProgress.tgl_pembatalan_faktur !== null
                        ? DateConvert(
                            new Date(dataProgress.tgl_pembatalan_faktur)
                          ).detail
                        : ''}
                    </Td>
                    <Td>{dataProgress.no_pembatalan_faktur}</Td>
                  </Tr>
                  <Tr>
                    <TdFixed>7</TdFixed>
                    <Td>Faktur Piutang</Td>
                    <Td>
                      {dataProgress.tgl_faktur_piutang !== null
                        ? DateConvert(new Date(dataProgress.tgl_faktur_piutang))
                            .detail
                        : ''}
                    </Td>
                    <Td>{dataProgress.no_faktur_piutang}</Td>
                  </Tr>
                </TBody>
              </CRUDLayout.Table>
            )}

            <div className="d-flex justify-content-end">
              <BackButton
                onClick={() => {
                  setModalShow(false);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Tgl. Delivery Order</Th>
              <ThFixed>No Delivery Order</ThFixed>
              <Th>Gudang</Th>
              <Th>Customer</Th>
              <Th>Sales</Th>
              <Th>Batas Waktu</Th>
              <Th>Status Approval</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/delivery-order/detail-delivery-order/${val.id_delivery_order}`
                        );
                      }}
                    />
                    {(val.status_approval === 'REV' ||
                      !val.status_approval) && (
                      <UpdateButton
                        onClick={() =>
                          history.push(
                            `/inventory/transaksi/delivery-order/edit-delivery-order/${val.id_delivery_order}`
                          )
                        }
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  {DateConvert(new Date(val.tgl_delivery_order)).defaultDMY}
                </TdFixed>
                <TdFixed>{val.no_delivery_order}</TdFixed>
                <Td className="align-middle">
                  {val.nama_gudang ? val.nama_gudang : '-'}
                </Td>
                <Td className="align-middle">{val.nama_customer}</Td>
                <Td className="align-middle">{val.nama_sales}</Td>
                <TdFixed>
                  {DateConvert(new Date(val.batas_waktu)).defaultDMY}
                </TdFixed>
                <Td className="align-middle">
                  {generateStatusApproval(val.status_approval)}
                </Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataCount={totalData}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataLength(e.target.value);
            }}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push('/inventory/transaksi/delivery-order/list-so');
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      <HapusModal />
      <ModalStatus />
    </CRUDLayout>
  );
};

export default DeliveryOrder;
