import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import {
  ActionButton,
  Alert,
  Approval,
  BackButton,
  DataStatus,
} from 'components';
import { Formik, useFormikContext } from 'formik';
import {
  FormBarangAwal,
  FormConvertBarang,
  TableHasilConvert,
} from './Components';
import { withDropdownProvider } from './Context';
import { ConvertBarangApi } from 'api';

const ContentUpdateConvertBarang = ({
  history,
  setAlert,
  id_convert_barang,
}) => {
  const { values, handleSubmit, isSubmitting, setValues } = useFormikContext();
  const [isLoading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);

    ConvertBarangApi.getSingle({ id_convert_barang })
      .then(({ data }) => {
        setValues({
          tgl_convert_barang: data.data.tgl_convert_barang,
          no_convert_barang: data.data.no_convert_barang,
          qty_convert: data.data.qty_convert,
          status_approval: data.data.status_approval,
          catatan_convert_barang: data.data.catatan_convert_barang,
          detail: data.data.detail,
          stakeholder: data.data.stakeholder,
          id_kelompok: data.data.id_kelompok,
          id_item_buaso: data.data.id_item_buaso,
          nama_item: data.data.nama_item,
          id_satuan: data.data.id_satuan,
          nama_satuan: data.data.nama_satuan,
          id_gudang: data.data.id_gudang,
          nama_gudang: data.data.nama_gudang,
        });
      })
      .catch(({ response }) =>
        setAlert({
          text: `Data gagal dimuat ${response.data.message ?? ''}`,
          variant: 'danger',
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id_convert_barang) {
      return fetchData();
    }

    return setLoading(false);
  }, [id_convert_barang]);

  if (isLoading) {
    return (
      <Card>
        <Card.Header>
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <strong>Ubah Data Convert Barang </strong>
            </Col>
            <Col className="d-flex justify-content-end">
              <BackButton onClick={() => history.goBack()} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <DataStatus loading={isLoading} text="Memuat data . . ." />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <strong>Ubah Data Convert Barang </strong>
            </Col>
            <Col className="d-flex justify-content-end">
              <BackButton onClick={() => history.goBack()} />
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <FormConvertBarang type="UPDATE" />
        </Card.Body>
      </Card>

      <Row className="mt-3">
        <Col md="4">
          <FormBarangAwal type="UPDATE" />
        </Col>

        <Col>
          <TableHasilConvert />
        </Col>
      </Row>

      <Approval
        title="Convert Barang"
        data={{ data: values.stakeholder ?? [] }}
      />

      <Row className="d-flex justify-content-end mx-2 mt-3">
        <ActionButton
          className="px-4"
          text="Simpan"
          loading={isSubmitting}
          onClick={handleSubmit}
        />
      </Row>
    </>
  );
};

const UpdateConvertBarang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_convert_barang } = useParams();
  const [alert, setAlert] = useState({
    text: '',
    variant: 'danger',
  });

  const initialValues = {
    tgl_convert_barang: new Date(),
    no_convert_barang: '',
    catatan_convert_barang: '',
    id_kelompok: null,
    id_item_buaso: null,
    nama_satuan: '',
    id_gudang: null,
    stok: 0,
    nilai_persediaan: 0,
    qty_convert: 0,
    detail: [],
  };

  const onSubmitForm = (values, { setSubmitting }) => {
    const finalValues = { ...values, id_convert_barang };

    ConvertBarangApi.update(finalValues)
      .then((res) =>
        history.replace('/inventory/transaksi/convert-barang', {
          alert: {
            variant: 'primary',
            text: 'Berhasil ubah data Convert Barang',
          },
        })
      )
      .catch(({ response }) =>
        setAlert({
          variant: 'danger',
          text: response.data.message
            ? `Gagal tambah data Convert Barang ${response.data.message}`
            : 'Gagal tambah data Convert Barang ',
        })
      )
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setNavbarTitle('Convert Barang');
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitForm}
    >
      {() => (
        <>
          <Alert
            showCloseButton
            show={!!alert.text.length}
            variant={alert.variant}
            text={alert.text}
          />
          <ContentUpdateConvertBarang
            history={history}
            id_convert_barang={id_convert_barang}
            setAlert={setAlert}
          />
        </>
      )}
    </Formik>
  );
};

export default withDropdownProvider(UpdateConvertBarang);
