export const generateColumnSisa = (data, saldoAwal = 0) => {
  let currentSaldo = saldoAwal;

  const generatedData = data.map((item) => {
    if (parseInt(item.jumlah) > 0) {
      currentSaldo += parseInt(item.jumlah);
      item.sisa = parseInt(currentSaldo);
    } else {
      currentSaldo -= Math.abs(parseInt(item.jumlah));
      item.sisa = parseInt(currentSaldo);
    }

    return item;
  });

  return generatedData;
};
