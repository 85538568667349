import { useState, useEffect, useCallback } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import {
  InfoItemHorizontal,
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
} from 'components';
import Logo from 'assets/image/LogoMGP.jpeg';
import Temaja from 'assets/image/signature/AnakAgungNgurahTemajaPutra.png';
import Nukayasa from 'assets/image/signature/IKetutNukayasa.png';
import Subawa from 'assets/image/signature/IPutuSubawa.png';
import { DateConvert, RupiahConvert } from 'utilities';
import { PenerimaanBarangApi } from 'api';

const KopSuratSection = () => (
  <div className="d-flex mx-2 mb-1">
    <div>
      <img src={Logo} width={100} alt="logo" />
    </div>
    <div style={{ marginLeft: 30, fontSize: 13.5 }}>
      <p>
        <b>PT. MARDIKA GRIYA PRASTA</b>
        <br />
        Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia <br />
        Phone +62(0)361 467-342 <br />
        Fax (0361) 46730 <br />
        Email sales@mgpwoodworks.com <br />
      </p>
    </div>
  </div>
);

const InfoSection = ({ dataPO }) => (
  <Row>
    <Col lg={6} md={12}>
      <InfoItemHorizontal
        width={130}
        label="No. Purchase Order"
        text={dataPO.no_purchase_order ?? '-'}
      />
      <InfoItemHorizontal
        width={130}
        label="Tanggal"
        text={
          dataPO.tgl_purchase_order
            ? DateConvert(new Date(dataPO.tgl_purchase_order)).detail
            : '-'
        }
      />
      <InfoItemHorizontal
        width={130}
        label="Tgl. Jatuh Tempo"
        text={
          dataPO.tgl_jatuh_tempo
            ? DateConvert(new Date(dataPO.tgl_jatuh_tempo)).detail
            : '-'
        }
      />
      <InfoItemHorizontal
        width={130}
        label="Refrensi PR"
        text={dataPO.no_purchase_request ? dataPO.no_purchase_request : '-'}
      />
    </Col>
    <Col lg={6} md={12}>
      <InfoItemHorizontal
        width={130}
        label="Kepada"
        text={
          <>
            {dataPO.nama_vendor ?? '-'}
            <br />
            {dataPO.alamat_vendor ?? '-'}
          </>
        }
      />
    </Col>
  </Row>
);

const CatatanSection = ({ dataPO, calculateListPO }) => {
  const calcPO = calculateListPO();

  return (
    <>
      <div className="mb-1">
        Pesanan ini selambat-lambatnya telah sampai pada :
      </div>
      <Row>
        <Col lg={6} md={12}>
          <InfoItemHorizontal
            label="Tanggal"
            text={
              dataPO.tgl_pengiriman
                ? DateConvert(new Date(dataPO.tgl_pengiriman)).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="Pambayaran"
            text={dataPO.pembayaran ?? '-'}
          />
          <InfoItemHorizontal
            label="Dikirim ke"
            text={dataPO.alamat_tujuan_pengiriman ?? '-'}
          />
        </Col>
        <Col lg={6} md={12}>
          <InfoItemHorizontal
            label="Sub Total"
            text={
              RupiahConvert(parseFloat(calcPO.subTotalPO).toString())
                .getWithComa
            }
          />
          <InfoItemHorizontal
            label={`Diskon ${calcPO.parseDiskon}%`}
            text={
              RupiahConvert(parseFloat(calcPO.nilaiDiskon).toString())
                .getWithComa
            }
          />
          <InfoItemHorizontal
            label="Pengenaan Pajak"
            text={
              RupiahConvert(parseFloat(calcPO.setelahDiskon).toString())
                .getWithComa
            }
          />
          <InfoItemHorizontal
            label={`PPN ${calcPO.parsePPN}%`}
            text={
              RupiahConvert(parseFloat(calcPO.nilaiPPN).toString()).getWithComa
            }
          />
          <InfoItemHorizontal
            label={`DP ${calcPO.parseDP}%`}
            text={
              RupiahConvert(parseFloat(calcPO.nilaiDP).toString()).getWithComa
            }
          />
          <InfoItemHorizontal
            label={<b>Total</b>}
            text={
              RupiahConvert(parseFloat(calcPO.setelahDP).toString()).getWithComa
            }
          />
        </Col>
      </Row>
    </>
  );
};

const TTDSection = () => {
  const TTDItems = ({ title, value, jabatan, src }) => (
    <Col className="text-center">
      <div>{title}</div>
      <div>
        <img src={src} height="80" />
      </div>
      <div>
        <u>{value}</u>
      </div>
      <div>{jabatan}</div>
    </Col>
  );

  return (
    <Row className="mt-5 pt-3">
      <TTDItems
        title="Disahkan,"
        value="A.A Ngurah Temaja Putra"
        src={Temaja}
        jabatan="Direktur"
      />
      <TTDItems
        title="Disetujui,"
        value="I Ketut Nukayasa"
        src={Nukayasa}
        jabatan="Manager Adm & Keu"
      />
      <TTDItems
        title="Dibuat Oleh"
        value="Putu Subawa"
        src={Subawa}
        jabatan="Procurement"
      />
    </Row>
  );
};

const TablePurchaseOrder = ({ listPO }) => {
  return (
    <>
      <div className="py-2 mt-2">
        <span>Kami tempatkan order pembelian dengan sbb :</span>
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode</ThFixed>
            <Th>Nama Barang/Jasa</Th>
            <Th>Qty</Th>
            <Th>Satuan</Th>
            <Th>Harga Satuan</Th>
            <Th>Jumlah Harga</Th>
          </Tr>
        </THead>
        <TBody>
          {listPO && listPO?.length > 0 ? (
            listPO.map((val, index) => {
              const jumlah_harga =
                parseFloat(val.qty_order ?? 0) *
                parseFloat(val.harga_kesepakatan ?? 0);
              return (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>{val.kode_item ?? '-'}</TdFixed>
                  <Td width={200}>{val.nama_item ?? '-'}</Td>
                  <Td textRight>
                    {val.qty_order
                      ? parseFloat(val.qty_order).toPrecision()
                      : '-'}
                  </Td>
                  <Td>{val.satuan_beli ?? '-'}</Td>
                  <Td textRight>
                    {val.harga_kesepakatan
                      ? RupiahConvert(String(val.harga_kesepakatan)).getWithComa
                      : '-'}
                  </Td>
                  <Td textRight>
                    {jumlah_harga
                      ? RupiahConvert(String(jumlah_harga)).getWithComa
                      : '-'}
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

// Main Component
const ModalDetailPurchaseOrder = ({ modalConfig, setModalConfig }) => {
  const id_purchase_order = modalConfig.id_purchase_order;
  const [dataPO, setDataPO] = useState({});
  const [listPO, setListPO] = useState([]);
  const [loading, setLoading] = useState(true);

  const calculateListPO = useCallback(() => {
    const parseDiskon = parseFloat(dataPO.diskon ?? 0);
    const parsePPN = parseFloat(dataPO.ppn ?? 0);
    const parseDP = parseFloat(dataPO.dp ?? 0);

    const subTotalPO = listPO?.reduce(
      (subtotal, { harga_kesepakatan, qty_order }) =>
        subtotal + parseFloat(harga_kesepakatan) * parseFloat(qty_order),
      0
    );
    const nilaiDiskon =
      parseFloat(parseDiskon / 100) * parseInt(subTotalPO ?? 0);
    const setelahDiskon =
      parseFloat(subTotalPO ?? 0) - parseFloat(nilaiDiskon ?? 0);
    const nilaiPPN =
      parseFloat(parsePPN / 100) * parseFloat(setelahDiskon ?? 0);
    const setelahPPN =
      parseFloat(setelahDiskon ?? 0) + parseFloat(nilaiPPN ?? 0);
    const nilaiDP = parseFloat(parseDP / 100) * parseFloat(setelahPPN ?? 0);
    const setelahDP = parseFloat(setelahPPN ?? 0) - parseFloat(nilaiDP ?? 0);

    return {
      parseDiskon,
      parsePPN,
      parseDP,
      subTotalPO,
      nilaiDiskon,
      setelahDiskon,
      nilaiPPN,
      setelahPPN,
      nilaiDP,
      setelahDP,
    };
  }, [listPO]);

  useEffect(() => {
    if (id_purchase_order) {
      PenerimaanBarangApi.getSinglePO({ id_purchase_order })
        .then((res) => {
          setDataPO(res.data.data ?? {});
          setListPO(res.data.data.po_detail ?? []);
        })
        .catch(({ response }) => console.log(response))
        .finally(() => setLoading(false));
    }
    return () => {};
  }, []);

  return (
    <Modal
      show={modalConfig.show}
      size="xl"
      onHide={() => setModalConfig((prev) => ({ ...prev, show: false }))}
    >
      <Modal.Header closeButton>Detail Purchase Order</Modal.Header>
      <Modal.Body className="px-5">
        {loading ? (
          <DataStatus loading={loading} text="Memuat Data . . ." />
        ) : (
          <>
            <KopSuratSection />
            <hr className="mt-0" />
            <InfoSection dataPO={dataPO} />
            <TablePurchaseOrder listPO={listPO} />
            <CatatanSection dataPO={dataPO} calculateListPO={calculateListPO} />
            <InfoItemHorizontal label="Keterangan" text={dataPO.keterangan} />
            <TTDSection />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailPurchaseOrder;
