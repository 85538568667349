import { createContext } from 'react';
import { ConvertBarangApi } from 'api';
import { useQuery } from 'react-query';

export const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 10 * 60 * 1000; // 10 MINUTES
  const dropdownApproval = [
    { value: 'APP', label: 'APPROVED' },
    { value: 'PEN', label: 'PENDING' },
    { value: 'VER', label: 'VERIFIED' },
    { value: 'REV', label: 'REVISE' },
    { value: 'REJ', label: 'REJECT' },
  ];

  const dropdownKelompok = useQuery(['dropdown', 'kelompok'], () =>
    ConvertBarangApi.getDropdownKelompok().then((res) =>
      res.data.data.map((val) => ({
        value: val.id_kelompok,
        label: val.nama_kelompok,
      }))
    )
  );

  const dropdownGudang = useQuery(['dropdown', 'gudang'], () =>
    ConvertBarangApi.getDropdownGudang().then((res) => {
      const data = res.data.data.map((val) => ({
        value: val.id_gudang,
        label: val.nama_gudang,
        stok: val.stok ?? 0,
        nilai_persediaan: val.nilai_persediaan ?? 0,
      }));

      return data;
    })
  );

  const dropdownItems = useQuery(
    ['dropdown', 'item'],
    () =>
      ConvertBarangApi.getDropdownItemBarang().then((res) => {
        const data = res.data.data.map((val) => ({
          value: val.id_item_buaso,
          label: val.nama_item,
          id_kelompok: val.id_kelompok,
          nama_satuan: val.nama_satuan,
        }));
        return data;
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  return (
    <DropdownContext.Provider
      value={{
        dropdownItems,
        dropdownKelompok,
        dropdownGudang,
        dropdownApproval,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const withDropdownProvider = (Component) => (props) => {
  return (
    <DropdownProvider>
      <Component {...props} />
    </DropdownProvider>
  );
};
