import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DatePicker, Input } from 'components';
import { DateConvert } from 'utilities';
import { PermintaanBahanProduksiApi } from 'api';

const FormSection = ({ formik }) => {
  const today = DateConvert(new Date()).default;
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const { values, setValues } = formik;

  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    PermintaanBahanProduksiApi.getNomorCetak({ tanggal: date })
      .then(({ data }) => {
        const nomor = data.data;

        setValues({
          ...values,
          nomor: nomor,
          tanggal: date,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: '',
          tanggal: '',
        });
        window.alert('Nomor gagal dimuat');
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    getNomorHandler(today);

    return () => {};
  }, []);

  return (
    <div>
      <Row>
        <Col md>
          <DatePicker
            label="Tgl. Dokumen"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ''}
            onChange={(date) => {
              const newDate = DateConvert(date).default;
              getNomorHandler(newDate);
            }}
          />
        </Col>
        <Col md>
          <Input
            readOnly
            label="No. Dokumen"
            placeholder={
              isNomorLoading
                ? 'Memuat nomor . . .'
                : 'Pilih tanggal untuk menentukan nomor'
            }
            value={isNomorLoading ? 'Memuat nomor . . .' : values.nomor}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FormSection;
