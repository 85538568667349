import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, ActionButton, Table, Th, Td, DataStatus } from 'components';
import { DateConvert } from 'utilities';

const TableHistoryCetak = ({
  dataHistoryCetak,
  alertConfig,
  setAlertConfig,
}) => {
  const history = useHistory();

  return (
    <>
      <div className="p-1">
        <b>List History Cetak</b>
      </div>
      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <Table>
        <thead className="bg-light">
          <Th style={{ width: 10 }}>No</Th>
          <Th>Tgl. Dokumen</Th>
          <Th>No. Dokumen</Th>
          <Th>Aksi</Th>
        </thead>
        <tbody>
          {dataHistoryCetak.length > 0 ? (
            dataHistoryCetak.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  {val.tgl_dokumen
                    ? DateConvert(new Date(val.tgl_dokumen)).defaultDMY
                    : '-'}
                </Td>
                <Td>{val.no_dokumen}</Td>
                <Td style={{ width: 30 }}>
                  <ActionButton
                    size="sm"
                    className="text-nowrap"
                    text="Cetak Ulang"
                    onClick={() =>
                      history.push(
                        '/inventory/transaksi/transfer-produksi-bahan/cetak/detail/' +
                          val.id_history
                      )
                    }
                  />
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableHistoryCetak;
