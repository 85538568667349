import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';
import { useHistory } from 'react-router-dom';

const InfoSection = ({ dataInfo }) => {
  const history = useHistory();
  return (
    <Row>
      <Col md>
        <InfoItemHorizontal
          width={150}
          label="Tgl. Permintaan Prelim"
          text={
            dataInfo?.tgl_permintaan_prelim
              ? DateConvert(new Date(dataInfo?.tgl_permintaan_prelim)).detail
              : '-'
          }
        />
        <InfoItemHorizontal
          width={150}
          label="No. Permintaan Prelim"
          text={dataInfo?.no_permintaan_prelim ?? '-'}
        />
        <div
          onClick={() =>
            history.push(
              '/inventory/transaksi/transfer-prelim/transfer/detail-spk/' +
                dataInfo.id_spk
            )
          }
        >
          <InfoItemHorizontal
            width={150}
            textStyle={{
              color: 'rgb(119,168,248)',
              cursor: 'pointer',
              fontSize: 14,
            }}
            label="No. SPK"
            text={dataInfo?.no_spk ?? '-'}
          />
        </div>
      </Col>
      <Col md>
        <InfoItemHorizontal
          width={150}
          label="Item Prelim"
          text={`${
            dataInfo.kode_item_prelim ? `(${dataInfo.kode_item_prelim})` : '-'
          } ${dataInfo.nama_item_prelim ?? '-'}`}
        />
        <InfoItemHorizontal
          width={150}
          label="Qty. Permintaan"
          text={
            dataInfo.qty_permintaan
              ? `${parseFloat(dataInfo.qty_permintaan)} ${
                  dataInfo.nama_satuan_permintaan ?? ''
                }`
              : '-'
          }
        />
        <InfoItemHorizontal
          width={150}
          label="Qty. Transfer"
          text={
            dataInfo.qty_tertransfer
              ? `${parseFloat(dataInfo.qty_tertransfer)} ${
                  dataInfo.nama_satuan_permintaan ?? ''
                }`
              : '-'
          }
        />
      </Col>
    </Row>
  );
};

export default InfoSection;
