import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { ActionButton, Alert, BackButton } from 'components';
import { Formik } from 'formik';
import {
  FormBarangAwal,
  FormConvertBarang,
  TableHasilConvert,
} from './Components';
import { withDropdownProvider } from './Context';
import { ConvertBarangApi } from 'api';

const TambahConvertBarang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [alert, setAlert] = useState({
    text: '',
    variant: 'danger',
  });

  const initialValues = {
    tgl_convert_barang: new Date(),
    no_convert_barang: '',
    catatan_convert_barang: '',
    id_kelompok: null,
    id_item_buaso: null,
    nama_satuan: '',
    id_gudang: null,
    stok: 0,
    nilai_persediaan: 0,
    qty_convert: 0,
    detail: [],
  };

  const onSubmitForm = (values, { setSubmitting }) => {
    ConvertBarangApi.create(values)
      .then(() =>
        history.replace('/inventory/transaksi/convert-barang', {
          alert: {
            variant: 'primary',
            text: 'Berhasil tambah data Convert Barang',
          },
        })
      )
      .catch(({ response }) =>
        setAlert({
          variant: 'danger',
          text: response.data.message
            ? `Gagal tambah data Convert Barang ${response.data.message}`
            : 'Gagal tambah data Convert Barang ',
        })
      )
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setNavbarTitle('Convert Barang');
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmitForm}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <Alert
            showCloseButton
            show={!!alert.text.length}
            variant={alert.variant}
            text={alert.text}
          />
          <Card>
            <Card.Header>
              <Row className="d-flex justify-content-between align-items-center">
                <Col>
                  <strong>Tambah Data Convert Barang </strong>
                </Col>
                <Col className="d-flex justify-content-end">
                  <BackButton onClick={() => history.goBack()} />
                </Col>
              </Row>
            </Card.Header>

            <Card.Body>
              <FormConvertBarang type="CREATE" />
            </Card.Body>
          </Card>

          <Row className="mt-3">
            <Col md="4">
              <FormBarangAwal type="CREATE" />
            </Col>

            <Col>
              <TableHasilConvert />
            </Col>
          </Row>

          <Row className="d-flex justify-content-end mx-2 mt-3">
            <ActionButton
              className="px-4"
              text="Simpan"
              loading={isSubmitting}
              onClick={handleSubmit}
            />
          </Row>
        </>
      )}
    </Formik>
  );
};

export default withDropdownProvider(TambahConvertBarang);
