import { Table, Th, Td, DataStatus, TdFixed } from 'components';

const TableSection = ({ formik, dataTable, setDataTable }) => {
  const { values, setFieldValue } = formik;

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);

    return convert;
  };

  const onQtySJChange = (value, index) => {
    const newData = dataTable.map((val, i) => {
      return i === index ? { ...val, qty_surat_jalan: value } : { ...val };
    });

    setDataTable(newData);
  };

  return (
    <>
      <div className="p-1">
        <b>List Item Delivery Order</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Kode Barang</Th>
          <Th>Item Barang</Th>
          <Th>Satuan</Th>
          {/* <Th>Qty. DO</Th>
          <Th>Qty. Terkirim</Th>
          <Th>Qty. Sisa DO</Th> */}
          <Th>Qty. Surat Jalan</Th>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            dataTable.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.kode_item}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.satuan}</Td>
                {/* <Td className="text-right">{val.qty_do}</Td>
                <Td className="text-right">{val.qty_terkirim}</Td>
                <Td className="text-right">{val.qty_sisa_do}</Td> */}
                <Td className="text-right">{val.qty_surat_jalan}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableSection;
