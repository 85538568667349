import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  Alert,
  DataStatus,
  FilterButton,
  THead,
  Tr,
  ThFixed,
  Th,
  Td,
  TBody,
  Pagination,
} from 'components';
import { TableNumber, DateConvert } from 'utilities';
import { PermintaanBahanProduksiApi } from 'api';
import { useSessionStorage } from 'hooks';
import { ModalFilterTransferBahanProduksi } from './Components';
import { withDropdownTransferBahanProduksiProvider } from './Context';

const TransferBahanProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const PAGINATION_KEY = `${location.pathname}_pagination`;

  const [isPageLoading, setPageLoading] = useState(true);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',
    data_count: '0',
    total_page: '1',

    active: false,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    tgl_transfer_produksi_bahan_start: undefined,
    tgl_transfer_produksi_bahan_end: undefined,
    item_job_order: undefined,
    id_item_buaso: undefined,
    id_gudang_asal: undefined,
    id_karyawan: undefined,
  });

  const { data_count, total_page, ...deps } = pagination;

  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const getInitialData = () => {
    setPageLoading(true);

    const query = { ...pagination };

    ['data_count', 'total_page', 'active'].forEach((key) => delete query[key]);

    PermintaanBahanProduksiApi.page(query)
      .then((res) => {
        setDataSeleksiVendor(res.data.data ?? []);
        setPagination((prev) => ({
          ...prev,
          data_count: res.data.data_count,
          total_page: res.data.total_page,
        }));
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  const checkAlert = () => {
    const alertState = location?.state?.alert;
    if (alertState) {
      setAlert({ text: alertState.text, variant: alertState.variant });
    }
  };

  const dismissAlert = () => {
    history.replace((location.pathname, { state: null }));
    setAlert((prev) => ({ ...prev, text: '' }));
  };

  useEffect(() => getInitialData(), [JSON.stringify(deps)]);

  useEffect(() => {
    setNavbarTitle('Transfer Bahan Produksi');
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch onChange={debounce(searchHandler, 1500)} />
          <FilterButton
            active={pagination.active}
            onClick={() => setModalFilter(true)}
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <CreateButton
            onClick={() =>
              history.push(
                '/inventory/transaksi/transfer-bahan-produksi/permintaan-produksi-list'
              )
            }
          />
        </Col>
      </Row>
      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={dismissAlert}
      />
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataSeleksiVendor.length > 0 ? (
        <TableContent
          pagination={pagination}
          data={dataSeleksiVendor}
          setPagination={setPagination}
        />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter && (
        <ModalFilterTransferBahanProduksi
          show={modalFilter}
          setShow={setModalFilter}
          filter={pagination}
          setFilter={setPagination}
        />
      )}
    </CRUDLayout>
  );
};

const TableContent = ({ data, pagination, setPagination }) => {
  return (
    <>
      <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
        List Data Transfer Produksi
      </div>

      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th style={{ minWidth: 300 }}>Item Job Order</Th>
            <ThFixed>Informasi Transfer Bahan Produksi</ThFixed>
            <Th style={{ minWidth: 300 }}>Item Bahan</Th>
            <ThFixed>Gudang Asal</ThFixed>
            <ThFixed>Gudang Tujuan</ThFixed>
            <Th style={{ minWidth: 250 }}>Diserahkan Kepada</Th>
            <Th style={{ minWidth: 300 }}>Keterangan</Th>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => {
              return (
                <Tr key={val.id_transfer_produksi_bahan}>
                  <Td className="text-center">
                    {TableNumber(pagination.page, pagination.per_page, index)}
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_job_order
                        ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_job_order}</div>
                  </Td>

                  <Td>
                    {val.item_job_order}{' '}
                    {val.qty_jo ? parseFloat(val.qty_jo) : ''}{' '}
                    {val.nama_satuan_item_jo ?? ''}
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_transfer_produksi_bahan
                        ? DateConvert(new Date(val.tgl_transfer_produksi_bahan))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_transfer_produksi_bahan} </div>
                  </Td>

                  <Td>{`${val.nama_item_buaso} ${parseFloat(
                    val.qty_transfer
                  )} ${val.nama_satuan} `}</Td>

                  <Td className="text-nowrap">{val.nama_gudang_asal ?? '-'}</Td>

                  <Td className="text-nowrap">
                    {val.nama_gudang_tujuan ?? '-'}
                  </Td>

                  <Td>{val.diserahkan ?? '-'}</Td>

                  <Td>{val.keterangan ?? '-'}</Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>

      <Pagination
        dataLength={pagination?.per_page}
        dataCount={pagination?.data_count}
        currentPage={pagination?.page}
        totalPage={pagination?.total_page}
        dataNumber={
          pagination?.page * pagination?.per_page - pagination?.per_page + 1
        }
        dataPage={
          pagination?.data_count < pagination?.per_page
            ? pagination?.data_count
            : pagination?.page * pagination?.per_page
        }
        onPaginationChange={({ selected }) =>
          setPagination((prev) => ({
            ...prev,
            page: selected + 1,
          }))
        }
        onDataLengthChange={(e) =>
          setPagination((prev) => ({
            ...prev,
            page: 1,
            per_page: e.target.value,
          }))
        }
      />
    </>
  );
};

export default withDropdownTransferBahanProduksiProvider(
  TransferBahanProduksiList
);
