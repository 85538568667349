import { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Checkbox, DatePicker, Input, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { SuratJalanApi } from 'api';

const FormSection = ({
  formik,
  fetchingStatus,
  formRendered,
  setFormRendered,
  dataPetugas,
  dataArmada,
}) => {
  const today = DateConvert(new Date()).default;
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const { values, errors, touched, setValues, setFieldValue } = formik;

  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    SuratJalanApi.getNomor({ tanggal: date })
      .then((no) => {
        const nomor = no.data.data;

        setValues({
          ...values,
          nomor: nomor,
          tanggal: date,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: '',
          tanggal: '',
        });
        window.alert('Nomor gagal dimuat');
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  useEffect(() => {
    !fetchingStatus.loading &&
      formRendered.toString() === '0' &&
      getNomorHandler(today);

    return () => {
      setFormRendered(formRendered + 1);
      setIsNomorLoading(false);
    };
  }, []);

  return (
    <div>
      <Row>
        <Col md>
          <DatePicker
            label="Tgl. Surat Jalan"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ''}
            onChange={(date) => {
              const newDate = DateConvert(date).default;
              getNomorHandler(newDate);
            }}
            error={Boolean(errors.tanggal) && touched.tanggal}
            errorText={
              Boolean(errors.tanggal) && touched.tanggal && errors.tanggal
            }
          />
        </Col>
        <Col md>
          <Input
            readOnly
            label="No. Surat Jalan"
            placeholder={
              isNomorLoading
                ? 'Memuat nomor . . .'
                : 'Pilih tanggal untuk menentukan nomor'
            }
            value={isNomorLoading ? 'Memuat nomor . . .' : values.nomor}
            error={Boolean(errors.nomor) && touched.nomor}
            errorText={
              Boolean(errors.nomor) &&
              touched.nomor &&
              errors.petugas_pengiriman
            }
          />
        </Col>
      </Row>
      {console.log(errors)}
      <Row>
        <Col md>
          <SelectSearch
            label="Petugas Pengiriman"
            placeholder="Pilih petugas pengiriman"
            option={dataPetugas}
            defaultValue={dataPetugas.find(
              (val) => val.value === values.petugas_pengiriman
            )}
            onChange={(val) => setFieldValue('petugas_pengiriman', val.value)}
            error={
              Boolean(errors.petugas_pengiriman) && touched.petugas_pengiriman
            }
            errorText={
              Boolean(errors.petugas_pengiriman) &&
              touched.petugas_pengiriman &&
              errors.petugas_pengiriman
            }
          />
        </Col>
        <Col md>
          <SelectSearch
            label="Armada Pengiriman"
            placeholder="Pilih armada pengiriman"
            option={dataArmada}
            defaultValue={dataArmada.find(
              (val) => val.value === values.armada_pengiriman
            )}
            onChange={(val) => setFieldValue('armada_pengiriman', val.value)}
            isDisabled={values.diambil_customer}
            error={
              Boolean(errors.armada_pengiriman) && touched.armada_pengiriman
            }
            errorText={
              Boolean(errors.armada_pengiriman) &&
              touched.armada_pengiriman &&
              errors.armada_pengiriman
            }
          />

          <Form.Group className="mb-3" id="formGridCheckbox">
            <Form.Check
              type="checkbox"
              label="Diambil Langsung Oleh Customer"
              checked={values.diambil_customer}
              onChange={() => {
                setFieldValue('diambil_customer', !values.diambil_customer);
                setFieldValue('armada_pengiriman', null);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default FormSection;
