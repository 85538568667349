import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DatePicker, SelectSearch, FilterModal } from 'components';
import { LaporanStockUpdateApi } from 'api';

const ModalFilterStokTerbaru = ({
  dropdown,
  setDropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
}) => {
  const [loading, setLoading] = useState({ jenis: false, item_buaso: false });

  const filter = data?.filter;

  const formInitialValues = {
    tanggal: filter?.tanggal ?? null,
    id_kelompok: filter?.id_kelompok ?? null,
    id_jenis: filter?.id_jenis ?? null,
    id_item_buaso: filter.id_item_buaso ?? '',
    id_gudang: filter.id_gudang ?? '0',
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: { ...values, active: true },
      });
    } else {
      setData({ ...data, filter: { ...values, active: false } });
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: Yup.object().shape({
      id_item_buaso: Yup.string().required('Filter Item Bahan diperlukan!'),
    }),
    onSubmit: formSubmitHandler,
  });

  const { values, errors, touched, setValues, handleSubmit } = formik;

  const onResetButtonClick = () => {
    setData((prev) => ({
      ...prev,
      filter: {
        tanggal: undefined,
        id_item_buaso: undefined,
        id_kelompok: undefined,
        id_jenis: undefined,
        id_gudang: undefined,
      },
    }));

    setModalConfig(false);
  };

  const getJenisData = (value) => {
    setLoading({ jenis: true, item_buaso: true });

    LaporanStockUpdateApi.getDropdown({ tipe: 'jenis', id_kelompok: value })
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          jenis: res.data.data?.map((val) => ({
            ...val,
            value: val.id_jenis,
            label: val.nama_jenis,
          })),
        }))
      )
      .catch(() => setDropdown((prev) => ({ ...prev, jenis: [] })))
      .finally(() => setLoading({ jenis: false, item_buaso: false }));
  };

  const getItemData = (value) => {
    setLoading((prev) => ({ ...prev, item_buaso: true }));

    LaporanStockUpdateApi.getDropdown({ tipe: 'item_buaso', id_jenis: value })
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          item_buaso: res.data.data?.map((val) => ({
            ...val,
            value: val.id_item_buaso,
            label: val.nama_item,
          })),
        }))
      )
      .catch(() => setDropdown((prev) => ({ ...prev, item_buaso: [] })))
      .finally(() => setLoading((prev) => ({ ...prev, item_buaso: false })));
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        label="Tanggal"
        type="date"
        placeholderText="dd/mm/yyyy"
        dateFormat="dd/MM/yyyy"
        selected={values?.tanggal}
        onChange={(selectedDate) => {
          console.log(selectedDate);
          setValues({ ...values, tanggal: selectedDate });
        }}
      />

      <SelectSearch
        label="Kelompok"
        placeholder="Pilih Kelompok"
        defaultValue={
          values.id_kelompok
            ? dropdown.kelompok.find((val) => val.value === values.id_kelompok)
            : null
        }
        option={dropdown?.kelompok ?? []}
        onChange={(val) => {
          setValues({
            ...values,
            id_kelompok: val.value,
            id_jenis: '',
            id_item_buaso: '',
          });
          getJenisData(val.value);
        }}
      />

      <SelectSearch
        label="Jenis"
        placeholder="Pilih Jenis"
        defaultValue={
          values.id_jenis
            ? dropdown.jenis.find((val) => val.value === values.id_jenis)
            : null
        }
        option={dropdown.jenis ?? []}
        onChange={(val) => {
          setValues({ ...values, id_jenis: val.value, id_item_buaso: '' });
          getItemData(val.value);
        }}
        isDisabled={Boolean(values.id_kelompok) ? false : true}
        loading={loading.jenis}
      />

      <SelectSearch
        label="Item Bahan"
        placeholder="Pilih bahan"
        defaultValue={
          values.id_item_buaso
            ? dropdown.item_buaso.find(
                (val) => val.value === values.id_item_buaso
              )
            : null
        }
        option={dropdown.item_buaso ?? []}
        onChange={(val) => setValues({ ...values, id_item_buaso: val.value })}
        isDisabled={Boolean(values.id_kelompok) ? false : true}
        loading={loading.item_buaso}
        error={Boolean(errors.id_item_buaso && touched.id_item_buaso) && true}
        errorText={
          Boolean(errors.id_item_buaso && touched.id_item_buaso) &&
          errors.id_item_buaso
        }
      />

      <SelectSearch
        key={values.id_gudang}
        label="Gudang"
        placeholder="Pilih gudang"
        defaultValue={
          values.id_gudang
            ? dropdown.gudang.find((val) => val.value === values.id_gudang)
            : null
        }
        option={dropdown.gudang ?? []}
        onChange={(val) => setValues({ ...values, id_gudang: val.value })}
      />
    </FilterModal>
  );
};

export default ModalFilterStokTerbaru;
