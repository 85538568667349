import { useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';

export const ExportButton = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const exportData = () => {};

  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={loadingExport}
        className="ml-2 mb-2 px-3 btn btn-warning text-white"
      >
        {loadingExport ? (
          <Spinner animation="border" variant="white" size="sm" />
        ) : (
          'Export Data'
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => exportData('excel')}>
          Excel (.xlsx)
        </Dropdown.Item>
        <Dropdown.Item onClick={() => exportData('pdf')}>
          PDF (.pdf)
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
