import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Axios from 'axios';
import { PurchaseRequestApi } from 'api';
import {
  ActionButton,
  Alert,
  Approval,
  BackButton,
  CRUDLayout,
  DataStatus,
  DeleteButton,
  Input,
  Select,
  SelectSearch,
  TBody,
  Td,
  TdFixed,
  TextArea,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { ModalGambar } from './Section';
import { tglInputFormat } from './Utils';

const UbahPurchaseRequest = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [dataIsLoading, setDataIsLoading] = useState({
    jenis: false,
    item: false,
  });
  const [data, setData] = useState([]);
  const [dataProyek, setDataProyek] = useState([]);
  const [dataNo, setDataNo] = useState('');
  const [dataDetail, setDataDetail] = useState({
    kelompok: [],
    jenis: [],
    item: [],
  });
  const [dataList, setDataList] = useState([]);
  const [dataGambar, setDataGambar] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    index: 0,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });
  const [isDropdownDisabled, setIsDropdownDisabled] = useState({
    jenis: true,
    item: true,
  });

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      PurchaseRequestApi.single(id),
      PurchaseRequestApi.getKelompok(),
      PurchaseRequestApi.getProyek(),
    ])
      .then(
        Axios.spread((res, kelompok, pry) => {
          const data = res.data.data.detail;
          const mapData = data.map((val) => ({
            id_item_buaso: val.id_item_buaso,
            id_purchase_request: val.id_purchase_request,
            id_purchase_request_detail: val.id_purchase_request_detail,
            kode_item: val.kode_item,
            nama_item: val.nama_item,
            nama_satuan: val.nama_satuan,
            dimensi: val.dimensi,
            lb: val.lb,
            pj: val.pj,
            tb: val.tb,
            qty: val.qty,
            qty_ordered: val.qty_ordered,
            path_gambar: val.path_gambar ?? [],
            view_gambar: val.path_gambar ?? [],
          }));

          setData(res.data.data);
          setDataList(mapData);
          setDataDetail({
            kelompok: kelompok.data.data,
            jenis: [],
            item: [],
          });
          setDataProyek(pry.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Ubah Purchase Request');
    getData();
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const formInitialValues = {
    created_at: data.created_at,
    id_purchase_request: data.id_purchase_request,
    tgl_purchase_request: data.tgl_purchase_request,
    no_purchase_request: data.no_purchase_request,
    tgl_pemakaian: data.tgl_pemakaian,
    keperluan: data.keperluan,
    catatan_purchase_request: data.catatan_purchase_request,
    id_kelompok: '',
    id_jenis: '',
    id_item_buaso: '',
    id_proyek: data.id_proyek,
    qty: '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_purchase_request: Yup.date().required(
      'Masukkan Tanggal Purchase Request'
    ),
    tgl_pemakaian: Yup.date().required('Masukan Tanggal Pemakaian'),
    keperluan: Yup.string().required('Pilih Keperluan'),
    id_proyek: Yup.string().required('Pilih Proyek'),
  });

  // KIRIM DATA BARU KE SERVER
  const formSubmitHandler = (values) => {
    const finalValues = {
      ...values,
      detail: dataList.map((val) => {
        return {
          id_item_buaso: val.id_item_buaso,
          id_purchase_request: val.id_purchase_request,
          id_purchase_request_detail: val.id_purchase_request_detail,
          path_gambar: Object.values(val.path_gambar),
          qty: val.qty,
        };
      }),
    };

    PurchaseRequestApi.update(finalValues)
      .then(() => {
        history.push('/inventory/transaksi/purchase-request', {
          variant: 'primary',
          text: 'Purchase Request berhasil diubah',
          status: true,
        });
      })
      .catch(({ response }) => {
        const message = response?.data?.message;

        setAlertConfig({
          variant: 'danger',
          text: `Ubah Purchase Request gagal! ${message ? `(${message})` : ''}`,
        });
        setShowAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDataItem = (id) => {
    setDataIsLoading({
      item: true,
    });

    PurchaseRequestApi.getItem(id)
      .then((res) =>
        setDataDetail({
          ...dataDetail,
          item: res.data.data,
        })
      )
      .catch((err) => alert('Data Item gagal dimuat | ' + err))
      .finally(() => {
        setDataIsLoading({
          item: false,
        });
        setIsDropdownDisabled({ item: false });
      });
  };

  const getList = (id, kode, nama, satuan, qty, path_gambar) => {
    if (id != null && qty != null) {
      setDataList([
        ...dataList,
        {
          id_item_buaso: id,
          kode_item: kode,
          nama_item: nama,
          nama_satuan: satuan,
          path_gambar: [],
          view_gambar: [],
          qty: parseFloat(qty).toPrecision(),
        },
      ]);
    } else {
      alert('Item dan Qty tidak boleh kosong');
    }
  };

  const deleteList = (index) => {
    const array = [...dataList];
    array.splice(index, 1);
    setDataList(array);
  };

  const getNomor = (id) => {
    const date = Date.parse(id);

    if (isNaN(date) === false) {
      var dataDate = new Date(date)
        .toISOString()
        .split('T')[0]
        .split('-')
        .join('/');
      PurchaseRequestApi.getNoSurat(dataDate)
        .then((res) => {
          setDataNo(res.data.data);
        })
        .catch((err) => alert(err));
    }
  };

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 6);

    return convert;
  };

  return (
    <>
      <Alert
        showCloseButton
        show={showAlert}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card>
        <Card.Header className="d-flex flex-row justify-content-between">
          <span>Ubah Purchase Request</span>
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </Card.Header>

        <Card.Body>
          {isLoading === true ? (
            <DataStatus loading={true} text="Memuat data..." />
          ) : (
            <>
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <Row>
                        <Col lg="6">
                          <Input
                            readOnly
                            label="Tanggal Input Data"
                            name="created_at"
                            value={tglInputFormat({
                              created_at: values.created_at,
                              separator: ' - ',
                            })}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <Input
                            label="Tanggal Purchase Request"
                            type="date"
                            name="tgl_purchase_request"
                            value={values.tgl_purchase_request}
                            onChange={(e) => {
                              const value = e.target.value;

                              setFieldValue('tgl_purchase_request', value);
                              setFieldValue(
                                'no_purchase_request',
                                getNomor(value)
                              );
                            }}
                            onBlur={() => {
                              setFieldValue('no_purchase_request', dataNo);
                            }}
                          />
                        </Col>

                        <Col lg="6">
                          <Input
                            label="No. Purchase Request"
                            name="no_purchase_request"
                            value={values.no_purchase_request}
                            readOnly={true}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Input
                            label="Tanggal Pemakaian"
                            type="date"
                            name="tgl_pemakaian"
                            value={values.tgl_pemakaian}
                            onChange={handleChange}
                            error={
                              errors.tgl_pemakaian &&
                              touched.tgl_pemakaian &&
                              true
                            }
                            errorText={errors.tgl_pemakaian}
                          />
                        </Col>

                        <Col lg="6">
                          <Select
                            label="Keperluan"
                            name="keperluan"
                            onChange={(e) => {
                              const value = e.target.value;

                              setFieldValue('keperluan', value);
                            }}
                            defaultValue={values.keperluan}
                            error={
                              errors.keperluan && touched.keperluan && true
                            }
                            errorText={errors.keperluan}
                          >
                            <option value="" hidden>
                              Pilih Keperluan
                            </option>
                            <option value="stok">Stok</option>
                            <option value="produksi">Produksi</option>
                            <option value="Pengadaan Aset">
                              Pengadaan Aset
                            </option>
                            <option value="Pengadaan Barang Non Aset">
                              Pengadaan Barang Non Aset
                            </option>
                            <option value="Perbaikan Dan Pemeliharaan Alat Dan Mesin">
                              Perbaikan Dan Pemeliharaan Alat Dan Mesin
                            </option>
                            <option value="Perawatan Gedung Dan Kantor">
                              Perawatan Gedung Dan Kantor
                            </option>
                          </Select>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <SelectSearch
                            label="Proyek"
                            name="id_proyek"
                            placeholder="Pilih proyek"
                            onChange={(val) => {
                              setFieldValue('id_proyek', val.value);
                            }}
                            option={dataProyek.map((val) => {
                              return {
                                value: val.id_proyek,
                                label: val.nama_proyek,
                              };
                            })}
                            defaultValue={{
                              value: values.id_proyek ?? '',
                              label: data.nama_proyek ?? 'Pilih Proyek',
                            }}
                            error={
                              errors.id_proyek && touched.id_proyek && true
                            }
                            errorText={errors.id_proyek && touched.id_proyek}
                          />
                        </Col>
                        <Col>
                          <TextArea
                            label="Catatan"
                            type="text"
                            name="catatan_purchase_request"
                            placeholder="Masukkan Catatan Purchase Request"
                            rows={2}
                            value={values.catatan_purchase_request}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="2">
                          <SelectSearch
                            label="Kelompok"
                            name="id_kelompok"
                            placeholder="Pilih Kelompok"
                            onChange={(val) => {
                              setFieldValue('id_kelompok', val.value);
                              setFieldValue('id_jenis', '');
                              setFieldValue('id_item_buaso', '');
                              getDataItem(val.value);
                            }}
                            option={dataDetail.kelompok.map((val) => {
                              return {
                                value: val.id_kelompok,
                                label: val.nama_kelompok,
                                flag: val.flag,
                              };
                            })}
                          />
                        </Col>

                        <Col lg="3">
                          <SelectSearch
                            label="Item"
                            name="id_item"
                            placeholder="Pilih Item"
                            onChange={(val) => {
                              setFieldValue('id_item', val.value);
                              setFieldValue('nama_satuan', val.satuan_beli);
                              setFieldValue('kode_item', val.kode_item);
                              setFieldValue('nama_item', val.label);
                            }}
                            option={dataDetail.item.map((val) => {
                              return {
                                value: val.id_item_buaso,
                                label: val.nama_item,
                                satuan_beli: val.satuan_beli,
                                kode_item: val.kode_item,
                              };
                            })}
                            loading={dataIsLoading.item}
                            isDisabled={isDropdownDisabled.jenis}
                          />
                        </Col>

                        <Col lg="2">
                          <Input
                            label="Satuan"
                            name="nama_satuan"
                            value={values.nama_satuan}
                            readOnly={true}
                          />
                        </Col>

                        <Col lg="1">
                          <Input
                            label="Qty"
                            type="text"
                            name="qty"
                            value={values.qty}
                            onChange={(e) => {
                              const value = e.target.value;
                              const convert = decimalConvert(value);
                              setFieldValue('qty', convert);
                            }}
                          />
                        </Col>

                        <Col lg="1">
                          <label></label>
                          <ActionButton
                            onClick={(e) => {
                              e.preventDefault();

                              getList(
                                values.id_item,
                                values.kode_item,
                                values.nama_item,
                                values.nama_satuan,
                                values.qty
                              );
                            }}
                            variant="primary"
                            text="Tambah"
                          />
                        </Col>
                      </Row>
                    </div>

                    <small>List Item Purchase Request</small>
                    <CRUDLayout.Table>
                      <THead>
                        <Tr className="text-center">
                          <ThFixed>No</ThFixed>
                          <Th>Kode Item</Th>
                          <Th>Nama Item</Th>
                          <Th>Satuan</Th>
                          <Th>Qty</Th>
                          <Th>Aksi</Th>
                        </Tr>
                      </THead>
                      <TBody>
                        {dataList.map((val, index) => (
                          <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <TdFixed>{val.kode_item}</TdFixed>
                            <Td>{val.nama_item}</Td>
                            <Td>{val.nama_satuan}</Td>
                            <Td>
                              <div className="text-right">
                                {parseFloat(val.qty).toPrecision()}
                              </div>
                            </Td>
                            <TdFixed>
                              <div className="d-flex">
                                <ActionButton
                                  text={<IoCloudUploadOutline />}
                                  size="sm"
                                  className="m-1"
                                  onClick={() => {
                                    setModalConfig({
                                      show: true,
                                      data: val,
                                      index: index,
                                    });

                                    const image = val.view_gambar.map(
                                      (item) => {
                                        let newStr =
                                          item instanceof Object
                                            ? item
                                            : item.replace(
                                                'http://',
                                                'https://'
                                              );

                                        return item instanceof Object
                                          ? item
                                          : {
                                              link: newStr,
                                              nama: item,
                                            };
                                      }
                                    );

                                    setDataGambar(image);
                                  }}
                                />
                                <DeleteButton
                                  onClick={() => {
                                    deleteList(index);
                                  }}
                                />
                              </div>
                            </TdFixed>
                          </Tr>
                        ))}
                      </TBody>
                    </CRUDLayout.Table>

                    <Approval data={data} />

                    <div className="d-flex justify-content-end">
                      <ActionButton
                        type="submit"
                        variant="primary"
                        text="Simpan"
                        className="mt-2 px-4"
                        loading={isSubmitting}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            </>
          )}
        </Card.Body>

        <ModalGambar
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          list={dataList}
          setList={setDataList}
          setDataGambar={setDataGambar}
          dataGambar={dataGambar}
          type="update"
        />
      </Card>
    </>
  );
};

export default UbahPurchaseRequest;
