import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SelectSearch, Input, DatePicker } from 'components';
import { DateConvert } from 'utilities';
import { TransferPrelimApi } from 'api';

const FormSection = ({ formik, dataInfo }) => {
  const today = DateConvert(new Date()).default;
  const { values, errors, touched, setFieldValue, setValues } = formik;
  const [dataGudang, setDataGudang] = useState([]);
  const [loading, setLoading] = useState({
    gudang: false,
    nomor: false,
  });

  const mappingGudang = (data) =>
    data.map((val) => {
      return {
        label: `${val.nama_gudang ?? ''} ${
          val.jumlah ? `(${val.jumlah} ${val.kode_satuan})` : ''
        }`,
        value: val.id_gudang,
        stok: val.jumlah,
        satuan: val.nama_satuan,
      };
    });

  const getNomorHandler = async (date) => {
    setLoading({
      ...loading,
      nomor: true,
    });

    return await TransferPrelimApi.getNomor({ tanggal: date })
      .then((res) => {
        const nomor = res.data.data;

        setValues({
          ...values,
          tgl_transfer: date,
          no_transfer: nomor,
        });
      })
      .catch(() => {
        window.alert('Nomor gagal dimuat');

        setValues({
          ...values,
          tgl_transfer: '',
          no_transfer: '',
        });
      })
      .finally(() => {
        setLoading({
          ...loading,
          nomor: false,
        });
      });
  };

  const getGudangHandler = async () => {
    setLoading({
      ...loading,
      gudang: true,
    });

    return await TransferPrelimApi.getDropdown({
      tipe: 'gudang',
      id_item_buaso: dataInfo.id_item_buaso_prelim,
    })
      .then((res) => {
        const gudang = res.data.data;
        const mapGudang = mappingGudang(gudang ?? []);

        setDataGudang(mapGudang);
      })
      .catch(() => {
        window.alert('Gudang gagal dimuat');
      })
      .finally(() => {
        setLoading({
          ...loading,
          gudang: false,
        });
      });
  };

  const qtyConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 10);

    return convert;
  };

  useEffect(async () => {
    await getGudangHandler();
    await getNomorHandler(today);
  }, []);

  return (
    <div>
      <Row>
        <Col md>
          <Row>
            {/* Tanggal Transfer Prelim */}
            <Col md>
              <DatePicker
                label="Tgl. Transfer Prelim"
                placeholderText="Pilih tg. transfer"
                selected={
                  values.tgl_transfer ? new Date(values.tgl_transfer) : ''
                }
                onChange={(date) => getNomorHandler(DateConvert(date).default)}
                error={Boolean(errors.tgl_transfer && touched.tgl_transfer)}
                errorText={
                  Boolean(errors.tgl_transfer && touched.tgl_transfer) &&
                  errors.tgl_transfer
                }
              />
            </Col>
            {/* No. Transfer Prelim */}
            <Col md>
              <Input
                readOnly
                label="No. Transfer Prelim"
                value={
                  loading.nomor ? 'Memuat data . . .' : values.no_transfer ?? ''
                }
                placeholder="Pilih tgl. untuk menentukan nomor"
                error={Boolean(errors.no_transfer && touched.no_transfer)}
                errorText={
                  Boolean(errors.no_transfer && touched.no_transfer) &&
                  errors.no_transfer
                }
              />
            </Col>
          </Row>

          {/* Gudang Asal */}
          <SelectSearch
            label="Gudang Asal"
            placeholder="Pilih gudang asal"
            loading={loading.gudang}
            option={dataGudang}
            defaultValue={
              values.id_gudang_asal
                ? dataGudang.find((val) => val.value === values.id_gudang_asal)
                : ''
            }
            onChange={(val) => {
              setValues({
                ...values,
                id_gudang_asal: val.value,
                qty_stok: val.stok,
                satuan: val.satuan,
              });
            }}
            error={Boolean(errors.id_gudang_asal && touched.id_gudang_asal)}
            errorText={
              Boolean(errors.id_gudang_asal && touched.id_gudang_asal) &&
              errors.id_gudang_asal
            }
          />

          {/* Gudang Tujuan */}
          <SelectSearch
            label="Gudang Tujuan"
            placeholder="Pilih gudang tujuan"
            loading={loading.gudang}
            option={dataGudang}
            defaultValue={
              values.id_gudang_tujuan
                ? dataGudang.find(
                    (val) => val.value === values.id_gudang_tujuan
                  )
                : ''
            }
            onChange={(val) => setFieldValue('id_gudang_tujuan', val.value)}
            error={Boolean(errors.id_gudang_tujuan && touched.id_gudang_tujuan)}
            errorText={
              Boolean(errors.id_gudang_tujuan && touched.id_gudang_tujuan) &&
              errors.id_gudang_tujuan
            }
          />
        </Col>
        <Col md>
          {/* Item Prelim */}
          <Input
            readOnly
            label="Item Prelim"
            placeholder="Masukan item prelim"
            value={`${
              dataInfo.kode_item_prelim ? `(${dataInfo.kode_item_prelim})` : '-'
            } ${dataInfo.nama_item_prelim ?? '-'}`}
          />
          <Row>
            {/* Qty. Stok */}
            <Col md>
              <Input
                readOnly
                label="Qty. Stok"
                placeholder="Pilih gudang untuk menentukan qty stok"
                value={values.qty_stok}
              />
            </Col>

            {/* Satuan Stok */}
            <Col md>
              <Input
                readOnly
                label="Satuan. Stok"
                placeholder="Pilih gudang untuk menentukan satuan stok"
                value={values.satuan}
              />
            </Col>
          </Row>
          <Row>
            {/* Qty. Transfer */}
            <Col md>
              <Input
                label="Qty. Transfer"
                placeholder="Masukan qty transfer"
                value={values.qty_transfer}
                onChange={(e) => {
                  const value = e.target.value;
                  const newValue = qtyConvert(value);
                  setFieldValue('qty_transfer', newValue);
                }}
                error={Boolean(errors.qty_transfer && touched.qty_transfer)}
                errorText={
                  Boolean(errors.qty_transfer && touched.qty_transfer) &&
                  errors.qty_transfer
                }
              />
            </Col>

            {/* Satuan Transfer */}
            <Col md>
              <Input
                readOnly
                label="Satuan. Transfer"
                placeholder="Pilih gudang untuk menentukan satuan transfer"
                value={values.satuan}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FormSection;
