import { Fragment, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { InfoSection } from './InfoSection';
import {
  DataStatus,
  Table,
  THead,
  Tr,
  Th,
  ThFixed,
  TdFixed,
  Td,
  TBody,
} from 'components';
import { DateConvert } from 'utilities';
import { KartuStockMaterialApi } from 'api';
import { generateColumnSisa } from '../Helpers';

export const ModalDetailKartuStok = ({ modal, setModal }) => {
  const [loading, setLoading] = useState(false);
  const [dataInfo, setDataInfo] = useState(modal.data ?? {});
  const [data, setData] = useState([]);

  useEffect(() => {
    const query = {
      dari: modal?.data?.start,
      sampai: modal?.data?.end,
      id_barang: modal?.data?.id_item_buaso,
      id_gudang: modal?.data?.id_gudang,
      id_kelompok: modal?.data?.id_kelompok,
    };

    if (Object.values(query).every((item) => item !== undefined)) {
      setLoading(true);

      KartuStockMaterialApi.getKartuStockMaterial(query)
        .then((res) => {
          const table = res?.data?.data?.table;

          setLoading(false);
          setDataInfo({ ...modal?.data, ...res?.data?.data?.head });
          setData(generateColumnSisa(table?.body ?? [], table.saldo_awal ?? 0));
        })
        .catch((err) => {
          setLoading(false);
          window.alert(
            `Gagal memuat data ${err?.response?.data?.message ?? ''}`
          );
        });
    }

    return () => {
      setData([]);
    };
  }, [modal.data]);

  return (
    <Modal
      show={modal.show}
      size="xl"
      onHide={() => setModal({ show: false, data: {} })}
    >
      <Modal.Header closeButton> Detail Kartu Stock Material </Modal.Header>
      <Modal.Body>
        {loading ? (
          <DataStatus loading={loading} text="Memuat data ..." />
        ) : (
          <Fragment>
            <InfoSection
              showGudang
              showSatuan
              data={dataInfo}
              start={modal?.data?.start}
              end={modal?.data?.end}
            />
            <hr />
            <Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No.</ThFixed>
                  <ThFixed>Tanggal</ThFixed>
                  <ThFixed>No. Referensi</ThFixed>
                  <Th>Keterangan</Th>
                  <ThFixed>Masuk</ThFixed>
                  <ThFixed>Keluar</ThFixed>
                  <ThFixed>Sisa</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {data?.length ? (
                  data.map((val, index) => {
                    const floatJumlah = parseFloat(val.jumlah);
                    return (
                      <Tr key={index}>
                        <TdFixed textCenter>{index + 1}</TdFixed>
                        <Td>
                          {val.tanggal
                            ? DateConvert(new Date(val.tanggal)).custom
                            : '-'}
                        </Td>
                        <Td>{val.no_ref_transaksi}</Td>
                        <Td>{val.keterangan}</Td>
                        <TdFixed>{floatJumlah > 0 ? floatJumlah : 0}</TdFixed>
                        <TdFixed>
                          {floatJumlah < 0 ? Math.abs(floatJumlah) : 0}
                        </TdFixed>
                        <TdFixed>{val.sisa}</TdFixed>
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={7} className="text-center">
                      <p style={{ fontSize: '1.2em' }}>Tidak ada data</p>
                    </Td>
                  </Tr>
                )}
              </TBody>
            </Table>
          </Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};
