// React
import React, { useState, useEffect, useRef } from 'react';

// Component
import { Row, Col, Modal, Card } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  TBody,
  Table,
  Th,
  Td,
  Input,
  DatePicker,
  ReadButton,
  THead,
  Tr,
  ThFixed,
  TdFixed,
  Pagination,
  SelectSearch,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';

// API
import axios from 'axios';
import { LaporanDeliveryOrderApi } from 'api';

// Print
import { useReactToPrint } from 'react-to-print';

// View
import { Cetak } from './Cetak';

const LaporanDeliveryOrder = ({ setNavbarTitle }) => {
  const title = 'Laporan Delivery Order';
  const componentRef = useRef();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loadingDropdown, setLoadingDropdown] = useState(true);
  const [dataDropdown, setDataDropdown] = useState({
    customer: [
      {
        id_customer: 'all',
        nama_customer: 'Semua Data',
      },
    ],
    karyawan: [
      {
        id_sales: 'all',
        nama_karyawan: 'Semua Data',
      },
    ],
  });
  const [filterSelected, setFilterSelected] = useState({
    status: 'b',
    no_delivery_order: '',
    tanggal_do_start: `${DateConvert(new Date()).defaultYear}-${
      DateConvert(new Date()).defaultMonth
    }-01`,
    tanggal_do_end: DateConvert(new Date()).default,
    status_approval: 'all',
    id_customer: 'all',
    id_sales: 'all',
  });

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // Modal
  const [readModalShow, setReadModalShow] = useState({
    show: false,
    id_delivery_order: '',
  });

  // Print
  const [printData, setPrintData] = useState(0);
  const [isPrint, setIsPrint] = useState(false);

  const getInitialData = () => {
    const query = {
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    };

    if (filterSelected.status === 'a') {
      query.no_delivery_order = filterSelected.no_delivery_order;
    } else {
      query.tanggal_do_start = filterSelected.tanggal_do_start;
      query.tanggal_do_end = filterSelected.tanggal_do_end;
      query.status_approval = filterSelected.status_approval;
      query.id_customer = filterSelected.id_customer;
      query.id_sales = filterSelected.id_sales;
    }

    setIsPageLoading(true);

    LaporanDeliveryOrderApi.page(query)
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const getDropdown = () => {
    setLoadingDropdown(true);

    axios
      .all([
        LaporanDeliveryOrderApi.dropdown({ tipe: 'customer' }),
        LaporanDeliveryOrderApi.dropdown({ tipe: 'karyawan' }),
      ])
      .then(
        axios.spread((customer, karyawan) =>
          setDataDropdown({
            ...dataDropdown,
            customer: [
              {
                id_customer: 'all',
                nama_customer: 'Semua Data',
              },
              ...customer.data.data,
            ],
            karyawan: [
              {
                id_karyawan: 'all',
                nama_karyawan: 'Semua Data',
              },
              ...karyawan.data.data,
            ],
          })
        )
      )
      .catch(() =>
        setDataDropdown({
          ...dataDropdown,
          customer: [
            {
              id_customer: 'all',
              nama_customer: 'Semua Data',
            },
          ],
          karyawan: [
            {
              id_karyawan: 'all',
              nama_karyawan: 'Semua Data',
            },
          ],
        })
      )
      .finally(() => setLoadingDropdown(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
    getDropdown();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    paginationConfig.page,
    paginationConfig.dataLength,
    filterSelected.status,
  ]);

  const SelectFilter = (props) => (
    <select
      {...props}
      className={`custom-select custom-select-sm ${props.className}`}
    >
      {props.children}
    </select>
  );

  const reactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  });

  const handleDataPrint = (id_delivery_order) => {
    LaporanDeliveryOrderApi.single({ id_delivery_order }).then((res) =>
      setPrintData(res.data.data)
    );

    setIsPrint(true);
  };

  const handlePrint = () => {
    reactToPrint();
  };

  const PageContent = () => {
    const DataTable = () => (
      <>
        <Table>
          <thead>
            <tr>
              <Th width="12px">No</Th>
              <Th width="12px">Aksi</Th>
              <Th width="12px">Tgl. Delivery Order</Th>
              <Th width="12px">No. Delivery Order</Th>
              <Th>Gudang</Th>
              <Th width="12px">No. Sales Order</Th>
              <Th>Customer</Th>
              <Th>Sales</Th>
              <Th width="12px">Tgl. Batas Waktu</Th>
              <Th width="12px">Status Approval</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <Td className="align-middle text-nowrap text-center">
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td className="align-middle text-center">
                  {isPrint === true ? (
                    <>
                      <div style={{ display: 'none' }}>
                        <Cetak
                          ref={componentRef}
                          idRef={val.id_delivery_order}
                          printData={printData}
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <ReadButton
                    onClick={() => {
                      setReadModalShow({
                        show: true,
                        id_delivery_order: val.id_delivery_order,
                      });
                      handleDataPrint(val.id_delivery_order);
                    }}
                  />
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.tgl_delivery_order
                    ? DateConvert(new Date(val.tgl_delivery_order.toString()))
                        .defaultDMY
                    : '-'}
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.no_delivery_order ? val.no_delivery_order : '-'}
                </Td>
                <Td className="align-middle">
                  {val.nama_gudang ? val.nama_gudang : '-'}
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.no_sales_order ? val.no_sales_order : '-'}
                </Td>
                <Td className="align-middle">
                  {val.nama_customer ? val.nama_customer : '-'}
                </Td>
                <Td className="align-middle">
                  {val.nama_sales ? val.nama_sales : '-'}
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.batas_waktu
                    ? DateConvert(new Date(val.batas_waktu.toString()))
                        .defaultDMY
                    : '-'}
                </Td>
                <Td className="align-middle text-uppercase text-center text-nowrap">
                  {val.status_approval === 'REV'
                    ? 'Revisi'
                    : val.status_approval === 'PEN'
                    ? 'Pending'
                    : val.status_approval === 'APP'
                    ? 'Approved'
                    : 'Reject'}
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return <DataTable />;
  };

  const ReadModal = () => {
    const [isLoadingRead, setIsLoadingRead] = useState(false);
    const [isErrorFetch, setIsErrorFetch] = useState(false);
    const [readData, setReadData] = useState({
      detail: [],
    });

    useEffect(() => {
      getReadData(readModalShow.id_delivery_order);
    }, []);

    const getReadData = (id_delivery_order) => {
      setIsLoadingRead(true);

      LaporanDeliveryOrderApi.single({ id_delivery_order })
        .then((res) => {
          setReadData(res.data.data);
          setIsLoadingRead(false);
        })
        .catch(() => {
          setIsLoadingRead(false);
          setIsErrorFetch(true);
        });
    };

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Delivery Order</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {readData.stakeholder === undefined
                ? 'Memuat Data'
                : readData.stakeholder.map(
                    (val, index) =>
                      index !== 0 &&
                      val.status_approval !== 'PEN' && (
                        <Col sm>
                          <InfoItem
                            title1={
                              val.status_approval === 'VER' ||
                              val.status_approval === 'REV'
                                ? `Pemeriksa ${
                                    val.approval_level !== '0'
                                      ? val.approval_level
                                      : ''
                                  }`
                                : val.status_approval === 'APP'
                                ? 'Pengesah'
                                : 'Di Tolak Oleh'
                            }
                            value1={val.nama_karyawan ?? '-'}
                            title2="Catatan"
                            value2={val.catatan ?? '-'}
                          />
                        </Col>
                      )
                  )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          {title}
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            paddingLeft: 5,
          }}
        >
          {text ? text : '-'}
        </div>
      </div>
    );

    return (
      <>
        <Modal
          size="xl"
          show={readModalShow.show}
          onHide={() => {
            setReadModalShow({
              ...readModalShow,
              show: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{`Detail ${title}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoadingRead || isErrorFetch ? (
              <DataStatus
                loading={isLoadingRead}
                text={isLoadingRead ? 'Memuat data...' : 'Data gagal dimuat'}
              />
            ) : (
              <>
                <div className="mb-2">
                  <Row>
                    <Col lg="6">
                      <ShowData
                        title="Tgl. Sales Order"
                        text={
                          DateConvert(new Date(readData.tgl_sales_order)).detail
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <ShowData
                        title="No. Sales Order"
                        text={readData.no_sales_order}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <ShowData
                        title="Customer"
                        text={readData.nama_customer}
                      />
                    </Col>
                    <Col lg="6">
                      <ShowData title="Sales" text={readData.nama_sales} />
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col lg="6">
                      <ShowData
                        title="Tanggal Delivery Order"
                        text={
                          DateConvert(new Date(readData.tgl_delivery_order))
                            .detail
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <ShowData
                        title="No. Delivery Order"
                        text={readData.no_delivery_order}
                      />
                    </Col>
                  </Row>
                  <hr />

                  <ShowData
                    title="Catatan Delivery Order"
                    text={readData.catatan_delivery_order}
                  />
                  <hr />

                  <CRUDLayout.Table>
                    <THead>
                      <Tr className="text-center">
                        <ThFixed>No</ThFixed>
                        <Th>Kode Barang</Th>
                        <Th>Nama Barang</Th>
                        <Th>Gudang</Th>
                        <Th>Qty SO</Th>
                        <Th>Satuan</Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {readData.detail.map((val, index) => (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <TdFixed>{val.kode_barang}</TdFixed>
                          <Td>
                            {/* {val.dimensi === "3"
                              ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                              : val.dimensi === "0"
                                ? val.nama_item
                                : val.dimensi === "1"
                                  ? `${val.nama_item} (${val.pj})`
                                  : val.dimensi === "2"
                                    ? `${val.nama_item} (${val.pj} X ${val.lb})`
                                    : ""} */}
                            {val.nama_barang}
                          </Td>
                          <Td>{val.nama_gudang}</Td>
                          <Td>
                            <div className="text-right">{val.qty}</div>
                          </Td>
                          <Td>{val.nama_satuan}</Td>
                        </Tr>
                      ))}
                    </TBody>
                  </CRUDLayout.Table>
                </div>
                <FormCard />
              </>
            )}
          </Modal.Body>
          {!isLoadingRead && !isErrorFetch && (
            <Modal.Footer>
              <ActionButton
                type="button"
                variant="primary"
                text="Cetak Delivery Order"
                onClick={handlePrint}
              />
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <div className="mb-2">
        <Card>
          <Card.Header>
            <small className="font-weight-bold">
              Pencarian Data Berdasarkan No. Transaksi
            </small>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={8}>
                <Input
                  label="No. Delivery Order"
                  onChange={(e) =>
                    setFilterSelected({
                      ...filterSelected,
                      no_delivery_order: e.target.value,
                    })
                  }
                />
              </Col>
              <Col xs={4}>
                <br />
                <ActionButton
                  text="Cari"
                  size="sm"
                  onClick={() => {
                    setPaginationConfig({
                      ...paginationConfig,
                      page: '1',
                    });
                    setFilterSelected({
                      ...filterSelected,
                      status: 'a',
                    });

                    getInitialData();
                  }}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          {loadingDropdown ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <Card.Header>
                <small className="font-weight-bold">
                  Pencarian Data Berdasarkan Periode dan Status
                </small>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={2}>
                    <DatePicker
                      label="Periode Awal"
                      type="date"
                      placeholderText="dd/mm/yyyy"
                      value={filterSelected.tanggal_do_start}
                      onChange={(e) =>
                        setFilterSelected({
                          ...filterSelected,
                          tanggal_do_start: DateConvert(e).default,
                        })
                      }
                    />
                  </Col>
                  <Col lg={2}>
                    <DatePicker
                      label="Periode Akhir"
                      type="date"
                      placeholderText="dd/mm/yyyy"
                      value={filterSelected.tanggal_do_end}
                      onChange={(e) =>
                        setFilterSelected({
                          ...filterSelected,
                          tanggal_do_end: DateConvert(e).default,
                        })
                      }
                    />
                  </Col>
                  <Col lg={2}>
                    <small>Status Approval</small>
                    <br />
                    <SelectFilter
                      defaultValue={filterSelected.status_approval}
                      onChange={(e) =>
                        setFilterSelected({
                          ...filterSelected,
                          status_approval: e.target.value,
                        })
                      }
                    >
                      <option value="PEN">Pending</option>
                      <option value="REV">Revisi</option>
                      <option value="APP">Approved</option>
                      <option value="REJ">Reject</option>
                      <option value="all">Semua Status</option>
                    </SelectFilter>
                  </Col>
                  <Col lg={2}>
                    <SelectSearch
                      label="Customer"
                      placeholder="Semua data"
                      onChange={(e) =>
                        setFilterSelected({
                          ...filterSelected,
                          id_customer: e.value,
                        })
                      }
                      option={
                        dataDropdown.customer &&
                        dataDropdown.customer.map((val) => {
                          return {
                            value: val.id_customer,
                            label: val.nama_customer,
                          };
                        })
                      }
                    />
                  </Col>
                  <Col lg={2}>
                    <SelectSearch
                      label="Sales"
                      placeholder="Semua data"
                      onChange={(e) =>
                        setFilterSelected({
                          ...filterSelected,
                          id_sales: e.value,
                        })
                      }
                      option={
                        dataDropdown.karyawan &&
                        dataDropdown.karyawan.map((val) => {
                          return {
                            value: val.id_karyawan,
                            label: val.nama_karyawan,
                          };
                        })
                      }
                    />
                  </Col>
                  <Col lg={2}>
                    <br />
                    <ActionButton
                      text="Cari"
                      size="sm"
                      onClick={() => {
                        if (
                          filterSelected.tanggal_do_start !== '' &&
                          (filterSelected.tanggal_do_end !== '') !== '' &&
                          filterSelected.status_approval !== ''
                        ) {
                          setPaginationConfig({
                            ...paginationConfig,
                            page: '1',
                          });
                          setFilterSelected({
                            ...filterSelected,
                            status: 'b',
                          });

                          getInitialData();
                        } else {
                          window.alert('Semua Bidang wajib diisi');
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </>
          )}
        </Card>
      </div>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <PageContent />
          {readModalShow.show && <ReadModal />}
        </>
      )}
    </CRUDLayout>
  );
};

export default LaporanDeliveryOrder;
