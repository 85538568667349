import { Row, Col } from 'react-bootstrap';
import { IoCheckboxOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { InfoItemVertical, InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';

const InfoSection = ({ dataInfo }) => (
  <>
    <Row>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItemHorizontal
              label="No. Sales Order"
              text={dataInfo.no_sales_order}
            />
            <InfoItemHorizontal
              label="No. Delivery Order"
              text={dataInfo.no_delivery_order}
            />
            <InfoItemHorizontal label="Gudang" text={dataInfo.gudang} />
          </tbody>
        </table>
      </Col>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItemHorizontal label="Customer" text={dataInfo.customer} />
            <InfoItemHorizontal label="Sales" text={dataInfo.sales} />
            <InfoItemHorizontal
              label="Proyek"
              text={dataInfo.nama_proyek ?? '-'}
            />
            <InfoItemHorizontal
              label="Tgl. Batas Waktu"
              text={
                dataInfo.tgl_batas_waktu
                  ? DateConvert(new Date(dataInfo.tgl_batas_waktu)).detail
                  : '-'
              }
            />
          </tbody>
        </table>
      </Col>
    </Row>

    <hr />

    <Row>
      <Col md>
        <InfoItemVertical
          label="Tgl. Surat Jalan"
          text={
            dataInfo.tgl_surat_jalan
              ? DateConvert(new Date(dataInfo.tgl_surat_jalan)).detail
              : '-'
          }
        />
      </Col>
      <Col md>
        <InfoItemVertical
          label="No. Surat Jalan"
          text={dataInfo.no_surat_jalan}
        />
      </Col>
    </Row>

    <Row>
      <Col md>
        <InfoItemVertical
          label="Petugas Pengiriman"
          text={dataInfo.petugas_pengiriman}
        />
      </Col>
      <Col md>
        <InfoItemVertical
          label="Armada Pengiriman"
          text={
            dataInfo.diambil_customer
              ? 'Tidak Ada Armada Digunakan'
              : dataInfo.armada_pengiriman
          }
        />
        <InfoItemVertical
          label="Diambil Oleh Customer"
          text={
            dataInfo.diambil_customer ? (
              <IoCheckboxOutline />
            ) : (
              <IoCloseCircleOutline />
            )
          }
        />
      </Col>
    </Row>
  </>
);

export default InfoSection;
