import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import { IoAddOutline, IoEyeOutline } from 'react-icons/io5';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  BackButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { TransferPrelimApi } from 'api';
import { InfoSection, TableSection } from '../Section';

const ListPermintaanTransferPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPermintaanTransferPrelim, setDataPermintaanTransferPrelim] =
    useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    id: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    TransferPrelimApi.getPermintaan({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPermintaanTransferPrelim(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase();
    if (newStatus === 'REJ') {
      return 'REJECTED';
    }

    if (newStatus === 'REV') {
      return 'REVISI';
    }

    if (newStatus === 'APP') {
      return 'APPROVED';
    }

    if (newStatus === 'VER') {
      return 'VERIFIED';
    }

    return 'PENDING';
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('List Permintaan Prelim Available');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const ModalSection = () => {
    const ID = modalConfig.id;
    const [dataInfoModal, setDataInfoModal] = useState({});
    const [dataTableModal, setDataTableModal] = useState([]);
    const [fetchingStatusModal, setFetchingStatusModal] = useState({
      loading: true,
      success: false,
    });

    const getInitialData = async () => {
      setFetchingStatusModal({
        loading: true,
        success: false,
      });

      return await TransferPrelimApi.getSingle({ id_permintaan_prelim: ID })
        .then((res) => {
          const data = res.data.data;

          setDataInfoModal(data ?? {});
          setDataTableModal(data.histori ?? []);
          setFetchingStatusModal({
            loading: false,
            success: true,
          });
        })
        .catch(() => {
          setFetchingStatusModal({
            loading: false,
            success: false,
          });
        });
    };

    useEffect(() => {
      getInitialData();
    }, []);

    const ModalTitle = () => (
      <Modal.Header closeButton>
        <b>Detail Permintaan Prelim</b>
      </Modal.Header>
    );

    if (fetchingStatusModal.loading || !fetchingStatusModal.success) {
      return (
        <Modal
          size="xl"
          show={modalConfig.show}
          onHide={() =>
            setModalConfig({
              show: false,
              id: '',
            })
          }
        >
          <ModalTitle />
          <Modal.Body>
            {fetchingStatusModal.loading ? (
              <DataStatus loading text="Memuat data . . ." />
            ) : (
              <DataStatus text="Data gagal dimuat!" />
            )}
          </Modal.Body>
        </Modal>
      );
    }

    return (
      <Modal
        size="xl"
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            show: false,
            id: '',
          })
        }
      >
        <ModalTitle />
        <Modal.Body>
          <InfoSection dataInfo={dataInfoModal} />
          <hr />
          <TableSection dataTable={dataTableModal} />
        </Modal.Body>
      </Modal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th>Tgl. Permintaan Prelim</Th>
            <Th>No. Permintaan Prelim</Th>
            <Th>No. SPK</Th>
            <Th>Item Prelim</Th>
            <Th>Qty. Permintaan</Th>
            <Th>Qty. Transfer</Th>
            <Th>Durasi Prelim</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {dataPermintaanTransferPrelim.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>
                {val.tgl_permintaan_prelim
                  ? DateConvert(new Date(val.tgl_permintaan_prelim)).defaultDMY
                  : '-'}
              </Td>
              <Td>{val.no_permintaan_prelim ?? '-'}</Td>
              <Td>
                <span
                  style={{
                    color: 'rgb(119,168,248)',
                    cursor: val.no_spk ? 'pointer' : 'default',
                  }}
                  onClick={
                    val.no_spk
                      ? () =>
                          history.push(
                            '/inventory/transaksi/transfer-prelim/transfer/detail-spk/' +
                              val.id_spk
                          )
                      : null
                  }
                >
                  {val.no_spk ?? '-'}
                </span>
              </Td>
              <Td>{`${
                val.kode_item_prelim ? `(${val.kode_item_prelim})` : '-'
              } ${val.nama_item_prelim ?? '-'}`}</Td>
              <Td>
                {val.qty_permintaan
                  ? `${parseFloat(val.qty_permintaan)} ${
                      val.kode_satuan_prelim ?? ''
                    }`
                  : '-'}
              </Td>
              <Td>
                {val.qty_tertransfer
                  ? `${parseFloat(val.qty_tertransfer)} ${
                      val.kode_satuan_prelim ?? ''
                    }`
                  : '-'}
              </Td>
              <Td>
                {val.qty_durasi ? `${parseFloat(val.qty_durasi)} Hari` : '-'}
              </Td>
              <Td>
                {parseFloat(val.qty_permintaan ?? 0) -
                  parseFloat(val.qty_tertransfer ?? 0) <=
                0 ? (
                  <ActionButton
                    tooltip
                    size="sm"
                    tooltipText="Detail permintaan prelim"
                    variant="outline-primary"
                    onClick={() =>
                      setModalConfig({
                        show: true,
                        id: val.id_permintaan_prelim,
                      })
                    }
                  >
                    <IoEyeOutline />
                  </ActionButton>
                ) : (
                  <ActionButton
                    tooltip
                    size="sm"
                    tooltipText="Tambah transfer prelim"
                    onClick={() =>
                      history.push(
                        '/inventory/transaksi/transfer-prelim/transfer/' +
                          val.id_permintaan_prelim
                      )
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                )}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (
      !dataPermintaanTransferPrelim ||
      dataPermintaanTransferPrelim.length < 1
    ) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {/* Modal */}
      {modalConfig.show && <ModalSection />}
    </CRUDLayout>
  );
};

export default ListPermintaanTransferPrelim;
