import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoSection = ({ dataInfo }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td width={125}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  return (
    <Row>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItem title="No. Sales Order" value={dataInfo.no_sales_order} />
            <InfoItem
              title="No. Delivery Order"
              value={dataInfo.no_delivery_order}
            />
            <InfoItem title="Gudang" value={dataInfo.gudang} />
          </tbody>
        </table>
      </Col>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItem title="Customer" value={dataInfo.customer} />
            <InfoItem title="Sales" value={dataInfo.sales} />
            <InfoItem title="Proyek" value={dataInfo.nama_proyek ?? '-'} />
            <InfoItem
              title="Tgl. Batas Waktu"
              value={
                dataInfo.tgl_batas_waktu
                  ? DateConvert(new Date(dataInfo.tgl_batas_waktu)).detail
                  : '-'
              }
            />
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default InfoSection;
