import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Modal, Row, Col } from 'react-bootstrap';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  DataStatus,
  ActionButton,
  Approval,
} from 'components';
import { DateConvert } from 'utilities';
import { SuratJalanApi } from 'api';
import { InfoSection, FormSection, TableSection } from './Section';

const UbahSuratJalan = ({ setNavbarTitle }) => {
  const today = DateConvert(new Date()).default;
  const history = useHistory();
  const { id } = useParams();
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataArmada, setDataArmada] = useState([]);
  const [dataPetugas, setDataPetugas] = useState([]);
  const [dataStakeholder, setDataStakeholder] = useState([]);
  const [formRendered, setFormRendered] = useState(0);
  const [showModalQty, setShowModalQty] = useState(false);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const mappingDataInfo = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? '-',
      id_petugas_pengiriman: data.id_supir ?? null,
      id_armada_pengiriman: data.id_kendaraan ?? null,
      tgl_sales_order: data.tgl_sales_order ?? null,
      tgl_surat_jalan: data.tgl_surat_jalan ?? null,
      no_sales_order: data.no_sales_order ?? '-',
      no_surat_jalan: data.no_surat_jalan ?? '-',
      no_delivery_order: data.no_delivery_order ?? '-',
      customer: data.nama_customer ?? '-',
      sales: data.nama_sales ?? '-',
      gudang: data.nama_gudang ?? '-',
      tgl_batas_waktu: data.batas_waktu ?? null,
      diambil_customer: data.diambil_customer ?? false,
      nama_proyek: data.nama_proyek ?? '-',
    };
  };

  const mappingDataTable = (data) =>
    data.map((val) => {
      const qtyDo = val.qty_do ? parseFloat(val.qty_do) : 0;
      const qtyTerkirim = val.qty_sj ? parseFloat(val.qty_sj) : 0;
      const qtySisaDo = parseFloat(qtyDo - qtyTerkirim);
      const qtySJ = val.qty ? parseFloat(val.qty) : '-';

      return {
        id_delivery_order_detail: val.id_delivery_order_detail ?? null,
        id_delivery_order: val.id_delivery_order ?? null,
        kode_item: val.kode_barang ?? '-',
        nama_item: val.nama_barang ?? '-',
        satuan: val.nama_satuan ?? '',
        qty_do: qtyDo,
        qty_terkirim: qtyTerkirim,
        qty_sisa_do: qtySisaDo,
        qty_surat_jalan: qtySJ,
      };
    });

  const mappingDataPetugas = (data) =>
    data.map((val) => {
      return {
        label: val.nama_karyawan,
        value: val.id_karyawan,
      };
    });

  const mappingDataArmada = (data) =>
    data.map((val) => {
      return {
        label: `${val.plat_nomor} - ${val.nama_item_aset}`,
        value: val.id_item_aset,
      };
    });

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([
      SuratJalanApi.getSingle({ id_surat_jalan: id }),
      SuratJalanApi.getDropdownPetugas(),
      SuratJalanApi.getDropdownArmada(),
    ])
      .then(
        Axios.spread((dataDO, petugas, armada) => {
          const getData = dataDO.data.data;
          const getDataStakeholder = dataDO.data.data;
          const getPetugas = petugas.data.data;
          const getArmada = armada.data.data;
          const mapDataInfo = mappingDataInfo(getData ?? {});
          const mapDataTable = mappingDataTable(getData.detail ?? []);
          const mapDataPetugas = mappingDataPetugas(getPetugas ?? []);
          const mapDataArmada = mappingDataArmada(getArmada ?? []);

          setDataStakeholder(getDataStakeholder ?? []);
          setDataInfo(mapDataInfo);
          setDataTable(mapDataTable);
          setDataPetugas(mapDataPetugas);
          setDataArmada(mapDataArmada);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!',
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  console.log(dataInfo);
  const formInitialValues = {
    tanggal: dataInfo.tgl_surat_jalan,
    nomor: dataInfo.no_surat_jalan,
    petugas_pengiriman: dataInfo.id_petugas_pengiriman,
    armada_pengiriman: dataInfo.id_armada_pengiriman,
    diambil_customer: dataInfo.diambil_customer,
  };

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required('Pilih tanggal'),
    nomor: Yup.string().required('Pilih tanggal untuk menentukan nomor'),
    petugas_pengiriman: Yup.string().required('Pilih petugas pengiriman'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValues = {
      id_surat_jalan: id,
      id_supir: values.petugas_pengiriman,
      id_petugas_gudang: values.petugas_pengiriman,
      id_kendaraan: values.armada_pengiriman,
      tgl_surat_jalan: values.tanggal,
    };

    SuratJalanApi.update(finalValues)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!',
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal diubah!',
        });
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle('Surat Jalan');
  }, []);

  const ModalQty = () => (
    <Modal show={showModalQty} onHide={() => setShowModalQty(false)}>
      <Modal.Header closeButton>
        <b className="text-danger">Peringatan</b>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="mb-3">
          <IoAlertCircleOutline size={75} className="mb-2 text-danger" />
          <br />
          <b>Qty. Surat Jalan Tidak Valid!</b>
          <br />
          <small>
            <b>QTY. SURAT JALAN</b> tidak boleh lebih dari <b>QTY. SISA DO</b>{' '}
            dan tidak boleh bernilai 0!
          </small>
        </p>
      </Modal.Body>
    </Modal>
  );

  const CatatanApprovalCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Surat Jalan</b>
        </Card.Header>
        <Card.Body>
          {dataStakeholder.length > 1 ? (
            <Row>
              {dataStakeholder.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== 'PEN' && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === 'VER' ||
                          val.status_approval === 'REV'
                            ? `Pemeriksa ${
                                val.approval_level !== '0'
                                  ? val.approval_level
                                  : ''
                              }`
                            : val.status_approval === 'APP'
                            ? 'Pengesah'
                            : 'Di Tolak Oleh'
                        }
                        value1={val.nama_karyawan ?? '-'}
                        title2="Catatan"
                        value2={val.catatan ?? '-'}
                      />
                    </Col>
                  )
              )}
            </Row>
          ) : (
            <div className="d-flex justify-content-center">
              <DataStatus text="Tidak ada catatan" />
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Ubah Data Surat Jalan</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body className="pb-5">
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <InfoSection dataInfo={dataInfo} />
                  <hr className="mb-1" />
                  <FormSection
                    formik={formik}
                    formRendered={formRendered}
                    fetchingStatus={fetchingStatus}
                    setFormRendered={setFormRendered}
                    dataArmada={dataArmada}
                    dataPetugas={dataPetugas}
                  />
                  <hr className="mt-1" />
                  <TableSection
                    formik={formik}
                    dataTable={dataTable}
                    setDataTable={setDataTable}
                  />
                  <hr />
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="success"
                      text="Ubah Surat Jalan"
                      loading={formik.isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Card.Body>
        <ModalQty />
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && (
        <Approval data={dataStakeholder} />
      )}
    </>
  );
};

export default UbahSuratJalan;
