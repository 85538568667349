import { Services, UploadServices } from 'services';

class BuasoApi {
  getPage(params) {
    return Services.get('/penerimaan_barang/page', { params });
  }
  search(key) {
    return Services.get(`/penerimaan_barang/page/?q=${key}`);
  }
  getPagePO(params) {
    return Services.get('/penerimaan_barang/purchase_order', { params });
  }
  getSinglePO(params) {
    return Services.get('/purchase_order/single', { params });
  }
  getKelompokFilter() {
    return Services.get('penerimaan_barang/dropdown?tipe=kelompok');
  }
  searchPO(key) {
    return Services.get(`/penerimaan_barang/purchase_order?q=${key}`);
  }
  getAtribut(tipe) {
    return Services.get(`/penerimaan_barang/dropdown?tipe=${tipe}`);
  }
  getSingle(atribut, value) {
    return Services.get(`/penerimaan_barang/single/?${atribut}=${value}`);
  }
  getSinglePenerimaan(atribut, value) {
    return Services.get(
      `/penerimaan_barang/penerimaan_barang/?${atribut}=${value}`
    );
  }
  getNo(tanggal) {
    return Services.get(`/penerimaan_barang/no_baru?tanggal=${tanggal}`);
  }
  create(data) {
    return Services.post('/penerimaan_barang', data);
  }
  update(data) {
    return Services.put('/penerimaan_barang/', data);
  }
  upload(data) {
    return UploadServices.post('/upload/inventori', data);
  }
  getDropdownVendor() {
    return Services.get('/dropdown/vendor');
  }
  getDropdownItemBarang() {
    return Services.get('/dropdown/material');
  }
  getDropdownKaryawan() {
    return Services.get('/dropdown/karyawan');
  }
}

export default new BuasoApi();
