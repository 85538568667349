import { useContext } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
  Tr,
  Td,
  SelectSearch,
  Input,
  InputCurrency,
  ActionButton,
} from 'components';
import { DropdownContext } from '../Context';

export const InputTableConvert = () => {
  const { setValues: setValuesParent } = useFormikContext();
  const { dropdownItems, dropdownGudang } = useContext(DropdownContext);

  const initialValues = {
    id_item_buaso: null,
    nama_item: '',
    id_gudang: null,
    nama_gudang: '',
    nama_satuan: '',
    qty_convert: 0,
    nilai_persediaan: 0,
  };

  const validationSchema = Yup.object({
    id_item_buaso: Yup.string().required(),
    id_gudang: Yup.string().required(),
    qty_convert: Yup.string().required(),
    nilai_persediaan: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        setValuesParent((prev) => ({
          ...prev,
          detail: [values, ...prev.detail],
        }))
      }
    >
      {({ values, errors, handleChange, setValues, handleSubmit }) => (
        <Tr>
          <Td></Td>
          <td className="p-0">
            <SelectSearch
              placeholder="Pilih salah satu ..."
              loading={dropdownItems?.isLoading}
              option={dropdownItems?.data ?? []}
              onChange={(val) =>
                setValues((prev) => ({
                  ...prev,
                  id_item_buaso: val.value,
                  nama_item: val.label,
                  nama_satuan: val.nama_satuan ?? '',
                }))
              }
              error={!!errors.id_item_buaso}
            />
          </td>

          <td className="p-0">
            <Input value={values.nama_satuan} readOnly />
          </td>

          <td className="p-0">
            <SelectSearch
              placeholder="Pilih salah satu ..."
              loading={dropdownGudang.isLoading}
              option={dropdownGudang?.data}
              onChange={(val) =>
                setValues((prev) => ({
                  ...prev,
                  id_gudang: val.value,
                  nama_gudang: val.label,
                }))
              }
              error={!!errors.id_gudang}
            />
          </td>
          <td className="p-0">
            <Input
              type="number"
              readOnly={!values.id_gudang}
              value={values.qty_convert}
              name="qty_convert"
              onChange={handleChange}
              error={!!errors.qty_convert}
            />
          </td>
          <td className="p-0">
            <InputCurrency
              className="text-right"
              readOnly={!values.id_gudang}
              value={values.nilai_persediaan}
              name="nilai_persediaan"
              onChange={(val) =>
                setValues({ ...values, nilai_persediaan: val })
              }
              error={!!errors.nilai_persediaan}
            />
          </td>
          <Td>
            <ActionButton size="sm" onClick={handleSubmit}>
              <IoAddOutline />
            </ActionButton>
          </Td>
        </Tr>
      )}
    </Formik>
  );
};
