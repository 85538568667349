import React, { useState, useEffect, useMemo } from 'react';
import { Table, Spinner, Modal, ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import ReactSelect from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, ActionButton } from 'components';
import { RupiahConvert } from 'utilities';
import { SuratPerjanjianKerjaApi } from 'api';

const TableSPK = ({
  formik,
  dataSPK,
  setDataSPK,
  dataPrelim,
  processedData,
  setModalPrelim,
  setProcessedData,
  modalConfig,
  setModalConfig,
}) => {
  const { values, submitCount, setValues, setFieldValue } = formik;
  const [ppnSPK, setPpnSPK] = useState(values.ppn_spk);
  const [isFetching, setIsFetching] = useState(true);
  const [editedData, setEditedData] = useState({});
  const [dataBarangJadi, setDataBarangJadi] = useState([]);
  const [deletedData, setDeletedData] = useState({
    status: false,
    index: '',
    data: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };
  // Fetch data awal server
  const getInitialData = () => {
    setIsFetching(true);

    SuratPerjanjianKerjaApi.getDropdownBarangJadi()
      .then(({ data }) => {
        setDataBarangJadi(data.data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data item barang jadi',
        });
      });
  };

  // Menangani konversi menjadi desimal
  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);

    return convert;
  };

  const getGrandTotalPrelim = useMemo(
    () =>
      dataPrelim.reduce((prev, current) => {
        const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
        const newHarga = current.harga ? parseFloat(current.harga) : 0;
        const newQtyDurasi = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0),
    [dataPrelim]
  );

  // Menangani perhitungan grand total tabel
  const getGrandTotal = () => {
    const grandTotal = dataSPK.reduce((prev, current) => {
      const count = parseInt(current.harga_rate) * parseFloat(current.qty);
      const total = prev + count;

      return total;
    }, 0);

    return grandTotal + getGrandTotalPrelim;
  };

  const getGrandTotalAfterDiskon = () => {
    const total = getGrandTotal();
    const diskon = values.diskon_spk ?? 0;
    const countDiskon = (diskon * total) / 100;
    const afterDiskon = parseInt(total) - parseInt(countDiskon);

    return { after_diskon: afterDiskon, total_diskon: countDiskon };
  };

  // Menangani perhitungan grand total setelah ppn
  const getGrandTotalAfterPPN = () => {
    const total = getGrandTotalAfterDiskon().after_diskon;
    const ppn = ppnSPK ?? 0;
    const countPPN = (ppn * total) / 100;
    const afterPPN = parseInt(total) + parseInt(countPPN);

    return { after_ppn: afterPPN, total_ppn: countPPN };
  };

  // Menangani perhitungan grand total setelah ppn
  const getGrandTotalAfterDP = () => {
    const total = getGrandTotalAfterPPN().after_ppn;
    const dp = values.uang_muka ?? 0;
    const countDP = (dp * total) / 100;
    const afterDP = parseInt(total) - parseInt(countDP);

    return { after_dp: afterDP, total_dp: countDP };
  };

  useEffect(() => {
    // getInitialData();
    setFieldValue('ppn_spk', ppnSPK);

    return () => {};
  }, [submitCount]);

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '13px',
      }}
    >
      {props.children}
    </td>
  );

  const SelectSearch = (props) => (
    <ReactSelect
      {...props}
      options={props.options ? props.options : ''}
      classNamePrefix={props.error ? 'react-select-invalid' : 'react-select'}
      noOptionsMessage={() => 'Tidak ada data'}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 28,
          maxHeight: 31,
          fontSize: 14,
        }),
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 5,
          margin: 0,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 0,
          paddingLeft: 5,
          paddingRight: 5,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 13,
        }),
      }}
    />
  );

  const Input = ({ error, ...props }) => (
    <input
      {...props}
      className={`${props.className} form-control form-control-sm ${
        error ? 'is-invalid' : ''
      }`}
    />
  );

  // Template form
  const FormTable = ({ formik, button, nomor }) => {
    const { values, errors, touched, setValues, setFieldValue, handleChange } =
      formik;
    const Button = button;

    // Menangani perhitungan total
    const getTotal = () => {
      const qty = values.qty ? values.qty : 0;
      const price = values.harga_rate ? values.harga_rate : 0;
      const total = parseFloat(qty) * parseInt(price);

      return RupiahConvert(parseInt(total).toString()).detail;
    };

    return (
      <tr className="bg-light">
        <Td className="px-1 align-middle text-center">{nomor}</Td>
        <Td className="px-1 align-middle">{values.kode_item}</Td>
        <Td style={{ minWidth: 175 }}>
          <SelectSearch
            placeholder="Pilih item barang jadi"
            defaultValue={
              values.id_item
                ? {
                    value: values.id_item,
                    label: values.nama_item,
                  }
                : ''
            }
            options={dataBarangJadi?.map((val) => {
              return {
                value: val.id_item_buaso,
                label: val.nama_item,
                kode: val.kode_item,
                unit: val.nama_satuan,
                qty: val.qty,
                harga_rate: val.harga_terbaru ?? 0,
              };
            })}
            onChange={(val) => {
              setValues({
                ...values,
                id_item: val.value,
                nama_item: val.label,
                kode_item: val.kode,
                unit: val.unit,
                harga_rate: val.harga_rate,
              });
            }}
            error={errors.id_item && touched.id_item && true}
          />
        </Td>
        <Td style={{ width: 60 }}>
          <Input
            placeholder="Qty"
            value={values.qty}
            onChange={(e) => {
              const value = e.target.value;
              const convert = decimalConvert(value);
              setFieldValue('qty', convert);
            }}
            error={errors.qty && touched.qty && true}
          />
        </Td>
        <Td className="px-1 align-middle">{values.unit}</Td>
        <Td>
          <Input
            name="uraian"
            placeholder="Uraian"
            value={values.uraian}
            onChange={handleChange}
          />
        </Td>
        <Td style={{ width: 150 }}>
          <Input
            placeholder="Harga rate"
            value={
              values.harga_rate
                ? RupiahConvert(parseInt(values.harga_rate).toString()).detail
                : ''
            }
            onChange={(e) => {
              const value = e.target.value;
              const convert = RupiahConvert(value.toString()).default;
              convert
                ? setFieldValue('harga_rate', convert)
                : setFieldValue('harga_rate', '');
            }}
            error={errors.harga_rate && touched.harga_rate && true}
          />
        </Td>
        <Td className="px-1 align-middle text-right">{getTotal()}</Td>
        <Td className="align-middle">
          <Button />
        </Td>
      </tr>
    );
  };

  // Menangani form tambah
  const CreateFormTable = ({ dataSPK, setDataSPK, setAlertConfig }) => {
    const noTable = dataSPK ? dataSPK.length + 1 : 1;

    // Data awal form
    const formInitialValues = {
      id_item: '',
      kode_item: '',
      nama_item: '',
      qty: '',
      unit: '',
      uraian: '',
      harga_rate: '',
    };

    // Skema validasi form
    const formValidationSchema = Yup.object().shape({
      id_item: Yup.string().required(),
      qty: Yup.string().required(),
      harga_rate: Yup.string().required(),
    });

    // Menangani submit form
    const formSubmitHandler = (values) => {
      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil ditambah!',
        });
        setDataSPK([...dataSPK, { ...values }]);
      }, 200);
    };

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => (
          <FormTable
            formik={formik}
            nomor={noTable}
            button={() => (
              <div className="d-flex justify-content-center">
                <ActionButton
                  tooltip
                  size="sm"
                  className="col"
                  tooltipText="Tambah"
                  text={
                    formik.isSubmitting ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      <IoAddOutline />
                    )
                  }
                  onClick={formik.handleSubmit}
                  disable={formik.isSubmitting}
                />
              </div>
            )}
          />
        )}
      </Formik>
    );
  };

  // Menangani form ubah
  const UpdateFormTable = ({
    editedData,
    setEditedData,
    dataSPK,
    setDataSPK,
    setAlertConfig,
  }) => {
    // Data awal form
    const formInitialValues = {
      id_item: editedData.id_item ? editedData.id_item : '',
      kode_item: editedData.kode_item ? editedData.kode_item : '',
      nama_item: editedData.nama_item ? editedData.nama_item : '',
      qty: editedData.qty ? editedData.qty : '',
      unit: editedData.unit ? editedData.unit : '',
      uraian: editedData.uraian ? editedData.uraian : '',
      harga_rate: editedData.harga_rate ? editedData.harga_rate : '',
    };
    // Skema validasi form
    const formValidationSchema = Yup.object().shape({
      nama_item: Yup.string().required(),
      qty: Yup.string().required(),
      harga_rate: Yup.string().required(),
    });

    // Menangani submit form
    const formSubmitHandler = (values) => {
      const index = editedData.index;
      const updatedData = dataSPK;
      updatedData[index] = { ...values };

      setTimeout(() => {
        setDataSPK(updatedData);
        setEditedData({});
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!',
        });
      }, 200);
    };

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => (
          <FormTable
            formik={formik}
            nomor={editedData.index + 1}
            button={() => (
              <ButtonGroup>
                <ActionButton
                  tooltip
                  size="sm"
                  variant="outline-success"
                  tooltipText="Simpan"
                  text={
                    formik.isSubmitting ? (
                      <Spinner size="sm" animation="border" variant="success" />
                    ) : (
                      <IoCheckmarkOutline />
                    )
                  }
                  onClick={formik.handleSubmit}
                  disable={formik.isSubmitting}
                />
                <ActionButton
                  tooltip
                  size="sm"
                  variant="outline-danger"
                  tooltipText="Batal"
                  text={<IoCloseOutline />}
                  onClick={() => setEditedData({})}
                />
              </ButtonGroup>
            )}
          />
        )}
      </Formik>
    );
  };

  const DeleteModal = ({
    deletedData,
    setDeletedData,
    dataSPK,
    setDataSPK,
  }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Menangani submit hapus data
    const deleteDataHandler = () => {
      setIsSubmitting(true);

      setTimeout(() => {
        const filter = dataSPK.filter(
          (val, index) => index !== deletedData.index
        );

        setDataSPK(filter);
        setDeletedData({
          ...deletedData,
          status: false,
        });
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Hapus data berhasil!',
        });
      }, 200);
    };

    return (
      <Modal
        show={deletedData.status}
        onHide={() =>
          setDeletedData({
            ...deletedData,
            status: false,
          })
        }
      >
        <Modal.Header closeButton className="d-flex align-items-center">
          <b className="text-danger">Hapus Data</b>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Hapus data dengan nama: <br />
            <b>{deletedData.data}</b> <br />
            <small className="text-danger">
              *Data yang telah dihapus tidak dapat diembalikan
            </small>
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="m-1"
            onClick={() =>
              setDeletedData({
                ...deletedData,
                status: false,
              })
            }
          />
          <ActionButton
            variant="danger"
            text="Hapus"
            className="m-1"
            loading={isSubmitting}
            onClick={deleteDataHandler}
          />
        </Modal.Footer>
      </Modal>
    );
  };

  // Menampilkan data pada tabel
  const TableContent = ({ index, val }) => {
    // Menangani perhitungan total
    const getTotal = () => {
      const qty = val.qty ? val.qty : 0;
      const price = val.harga_rate ? val.harga_rate : 0;
      const total = parseFloat(qty) * parseInt(price);

      return RupiahConvert(parseInt(total).toString()).detail;
    };

    return (
      <tr>
        <Td className="text-center">{index + 1}</Td>
        <Td className="px-1">{val.kode_item}</Td>
        <Td className="px-1">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setProcessedData({
                ...val,
                index: index,
                id_barang_jadi: val.id_item,
              });
              setModalConfig({
                show: true,
                type: 'analisa',
                title: (
                  <span className="text-primary">Analisa Barang Jadi SPK</span>
                ),
              });
            }}
          >
            {val.nama_item}
          </a>
        </Td>
        <Td className="px-1 text-right">{val.qty}</Td>
        <Td className="px-1">{val.unit}</Td>
        <Td className="px-1">{val.uraian}</Td>
        <Td className="px-1 text-right">
          {RupiahConvert(parseInt(val.harga_rate).toString()).detail}
        </Td>
        <Td className="px-1 text-right">{getTotal()}</Td>
        {/* <Td> */}
        {/* <ButtonGroup> */}
        {/* <ActionButton
            tooltip
            size="sm"
            variant="success"
            tooltipText="Ubah"
            className="col"
            text={<IoPencilOutline />}
            onClick={() =>
              setEditedData({
                ...val,
                index,
              })
            }
          /> */}
        {/* <ActionButton
              tooltip
              size="sm"
              variant="danger"
              tooltipText="Hapus"
              text={<IoTrashOutline />}
              onClick={() =>
                setDeletedData({
                  status: true,
                  index: index,
                  data: val.nama_item,
                })
              }
            /> */}
        {/* </ButtonGroup> */}
        {/* </Td> */}
      </tr>
    );
  };

  console.log(values);

  // Menampilkan keseluruhan data & fitur tabel
  return (
    <>
      <div className="px-1 py-2">
        <b>List Item SPK</b>
      </div>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      <Table bordered>
        <thead className="bg-light">
          <tr>
            <Th rowSpan={2} className="text-center" style={{ width: 30 }}>
              No
            </Th>
            <Th rowSpan={2} className="text-center" style={{ width: 80 }}>
              Kode Item Barang Jadi
            </Th>
            <Th rowSpan={2} className="text-center">
              Nama Item Barang Jadi
            </Th>
            <Th colSpan={2} className="text-center">
              Volume
            </Th>
            <Th rowSpan={2} className="text-center">
              Uraian
            </Th>
            <Th rowSpan={2} className="text-center">
              Harga Rate
            </Th>
            <Th rowSpan={2} className="text-center">
              Sub Total Rate
            </Th>
          </tr>
          <tr>
            <Th className="text-center">Qty</Th>
            <Th className="text-center">Unit</Th>
          </tr>
        </thead>
        <tbody>
          {dataSPK.map((val, index) => {
            // Menangani row yang diubah
            if (editedData.index === index) {
              return (
                <UpdateFormTable
                  key={index}
                  editedData={editedData}
                  setEditedData={setEditedData}
                  dataSPK={dataSPK}
                  setDataSPK={setDataSPK}
                  alertConfig={alertConfig}
                  setAlertConfig={setAlertConfig}
                />
              );
            }

            // Tampilkan data kedalam tabel
            return <TableContent key={index} index={index} val={val} />;
          })}

          <tr>
            <Td className="text-center">
              {dataSPK && dataSPK.length > 0 ? dataSPK.length + 1 : 1}
            </Td>
            <Td className="px-1">-</Td>
            <Td className="px-1">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setModalPrelim({ show: true });
                }}
              >
                PRELIM
              </a>
            </Td>
            <Td className="px-1 text-right">1</Td>
            <Td className="px-1">-</Td>
            <Td className="px-1">-</Td>
            <Td className="px-1 text-right">
              {RupiahConvert(parseInt(getGrandTotalPrelim).toString()).detail}
            </Td>
            <Td className="px-1 text-right">
              {RupiahConvert(parseInt(getGrandTotalPrelim).toString()).detail}
            </Td>
            {/* <Td></Td> */}
          </tr>

          {/* Menampilkan & menangani form tambah */}
          {/* <CreateFormTable
            dataSPK={dataSPK}
            setDataSPK={setDataSPK}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
          /> */}
        </tbody>
        <tfoot>
          {/* Diskon */}
          <tr>
            <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
              Total
            </Th>
            <Th className="text-right">
              {RupiahConvert(parseInt(getGrandTotal()).toString()).detail}
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="pr-2 px-2 text-right">
              Diskon ({parseFloat(values.diskon_spk).toPrecision()} %)
            </Th>
            <Th className="text-right" style={{ padding: 2 }}>
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDiskon().total_diskon).toString()
                ).detail
              }
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
              Total Setelah Diskon
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDiskon().after_diskon).toString()
                ).detail
              }
            </Th>
          </tr>

          {/* PPN */}
          <tr>
            <Th colSpan={7} className="pr-2 px-2 text-right">
              PPN ({parseFloat(values.ppn_spk).toPrecision()} %)
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterPPN().total_ppn).toString()
                ).detail
              }
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
              Total Setelah Pajak
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterPPN().after_ppn).toString()
                ).detail
              }
            </Th>
          </tr>

          {/* DP */}
          <tr>
            <Th colSpan={7} className="pr-2 px-2 text-right">
              Uang Muka ({parseFloat(values.uang_muka).toPrecision()} %)
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDP().total_dp).toString()
                ).detail
              }
            </Th>
          </tr>
          <tr>
            <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
              Sisa
            </Th>
            <Th className="text-right">
              {
                RupiahConvert(
                  parseInt(getGrandTotalAfterDP().after_dp).toString()
                ).detail
              }
            </Th>
          </tr>
        </tfoot>
      </Table>

      {/* Modal konfirmasi hapus data */}
      <DeleteModal
        deletedData={deletedData}
        setDeletedData={setDeletedData}
        dataSPK={dataSPK}
        setDataSPK={setDataSPK}
      />
    </>
  );
};

export default TableSPK;
