import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { KartuStockMaterialApi } from 'api';

export const ModalFilterKartuStock = ({
  dropdown,
  show,
  setShow,
  filter,
  setFilter,
  resetData,
}) => {
  const defaultDropdown = null;
  const [dropdownItems, setDropdownItems] = useState({
    loading: false,
    data: [],
  });
  const optKelompok = dropdown?.kelompok ?? [];
  const optGudang = dropdown?.gudang ?? [];
  const optItems = dropdownItems?.data ?? [];

  const initialValues = {
    start: filter.start,
    end: filter.end,
    id_kelompok: filter.id_kelompok,
    id_item_buaso: filter.id_item_buaso,
    id_gudang: filter.id_gudang,
  };

  const validationSchema = yup.object().shape({
    start: yup.string().required(),
    end: yup.string().required(),
    id_kelompok: yup.string().required(),
    id_item_buaso: yup.string().required(),
    id_gudang: yup.string().required(),
  });

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, active: false });
    setShow(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, setFieldValue, setValues, handleSubmit, resetForm } = formik;

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      start: undefined,
      end: undefined,
      id_kelompok: undefined,
      id_item_buaso: undefined,
      id_gudang: undefined,
    }));
    setShow(false);
    resetForm();
    resetData();
  };

  useEffect(() => {
    const id_kelompok = values?.id_kelompok;

    if (id_kelompok) {
      setDropdownItems((prev) => ({
        ...prev,
        loading: true,
      }));

      KartuStockMaterialApi.getDropdownMaterial({ id_kelompok })
        .then((res) =>
          setDropdownItems({
            loading: false,
            data: res?.data?.data?.map((v) => ({
              value: v.id_item_buaso,
              label: v.nama_item,
            })),
          })
        )
        .catch((err) => {
          alert(`Gagal memuat data dropdown ${err.response?.data?.message}`);
          setDropdownItems((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    }
  }, [values?.id_kelompok]);

  return (
    <FilterModal
      show={show}
      setShow={setShow}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Periode"
        placeholderText="Pilih Tanggal Periode"
        monthsShown={2}
        startDate={values.start ? new Date(values.start) : ''}
        endDate={values.end ? new Date(values.end) : ''}
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('start', startDate);
          setFieldValue('end', endDate);
        }}
        error={
          (formik.errors.start && formik.touched.start) ||
          (formik.errors.end && formik.touched.end)
        }
      />

      <SelectSearch
        label="Kelompok Item"
        loading={dropdown.loading}
        placeholder="Pilih Kelompok Item"
        option={optKelompok}
        defaultValue={
          values.id_kelompok
            ? optKelompok?.find((v) => values.id_kelompok === v.value)
            : defaultDropdown
        }
        onChange={({ value }) => {
          setValues((prev) => ({
            ...prev,
            id_kelompok: value,
            id_item_buaso: null,
          }));
        }}
        error={formik.errors.id_kelompok && formik.touched.id_kelompok}
      />

      <SelectSearch
        isDisabled={!values.id_kelompok}
        label="Nama Material"
        placeholder="Pilih Material"
        option={optItems}
        loading={dropdownItems.loading}
        defaultValue={
          values.id_item_buaso
            ? optItems?.find((v) => values.id_item_buaso === v.value)
            : defaultDropdown
        }
        onChange={({ value }) => setFieldValue('id_item_buaso', value)}
        error={formik.errors.id_item_buaso && formik.touched.id_item_buaso}
      />

      <SelectSearch
        label="Gudang"
        placeholder="Pilih Gudang"
        loading={dropdown.loading}
        option={optGudang}
        defaultValue={
          values.id_gudang
            ? optGudang?.find((v) => values.id_gudang === v.value)
            : defaultDropdown
        }
        onChange={({ value, label }) =>
          setValues((prev) => ({
            ...prev,
            id_gudang: value,
            nama_gudang: label,
          }))
        }
        error={formik.errors.id_gudang && formik.touched.id_gudang}
      />
    </FilterModal>
  );
};
