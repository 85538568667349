import { Services } from '../../../services';

class PenerimaanSuratJalanApi {
  getPage(params) {
    return Services.get('penerimaan_surat_jalan/page', { params });
  }

  // UNTUK GET SINGLE PENERIMAAN SJ, MENGGUNAKAN API GET DETAIL SJ
  getSingle(params) {
    return Services.get('surat_jalan/single', { params });
  }

  // UNTUK GET NOMOR PENERIMAAN / PEMBATALAN SURAT JALAN
  getNomor(type, params) {
    const endpoint =
      type === 'pembatalan' ? 'no_baru_pembatalan' : 'no_baru_penerimaan';
    return Services.get(`penerimaan_surat_jalan/${endpoint}`, { params });
  }

  // UNTUK POST PENERIMAAN / PEMBATALAN SURAT JALAN
  save(type, data) {
    const endpoint = type === 'pembatalan' ? 'pembatalan' : 'penerimaan';
    return Services.post(`penerimaan_surat_jalan/${endpoint}`, data);
  }
}

export default new PenerimaanSuratJalanApi();
