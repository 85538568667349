import { useContext } from 'react';
import {
  THead,
  Tr,
  Th,
  Td,
  ThFixed,
  TdFixed,
  TBody,
  DataStatus,
  ActionButton,
  Table,
  SelectSearch,
} from 'components';
import { IoAddOutline } from 'react-icons/io5';
import {
  DropdownTransferBahanProduksiContext,
  TransferBahanProduksiContext,
} from '../../Context';

export const TablePermintaanHW = ({ data = [] }) => {
  const { dropdownStatusPermintaan } = useContext(
    DropdownTransferBahanProduksiContext
  );
  const { setModalForm, setDataPermintaan } = useContext(
    TransferBahanProduksiContext
  );

  const onChangeStatusPermintaan = ({ value, index }) => {
    const copyData = [...data];
    copyData[index]['status_permintaan'] = value;

    setDataPermintaan((prv) => ({
      ...prv,
      hardwood: copyData,
    }));
  };

  return (
    <Table>
      <THead>
        <Tr className="text-center">
          <ThFixed rowSpan={2}>No.</ThFixed>
          <Th rowSpan={2}>Deskripsi</Th>
          <Th rowSpan={2}>Spesifikasi Kayu</Th>
          <Th rowSpan={2}>Part Kayu</Th>
          <Th rowSpan={2}>Tipe Finishing</Th>
          <Th rowSpan={2}>Tipe Sisi</Th>
          <Th colSpan={3}>Final Dimensi</Th>
          <Th colSpan={3}>Raw Dimensi</Th>
          <Th rowSpan={2}>Qty. Permintaan</Th>
          <Th rowSpan={2}>Status Permintaan</Th>
          <ThFixed rowSpan={2}>Aksi</ThFixed>
        </Tr>
        <Tr>
          <Th>T</Th>
          <Th>W</Th>
          <Th>L</Th>

          <Th>T</Th>
          <Th>W</Th>
          <Th>L</Th>
        </Tr>
      </THead>

      <TBody>
        {data?.length ? (
          data.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>{val.deskripsi}</Td>
              <Td>{val.nama_jenis_kayu}</Td>
              <Td>{val.nama_part_kayu}</Td>
              <Td>{val.nama_finishing_barang_jadi ?? '-'}</Td>
              <Td>{val.nama_tipe_sisi}</Td>
              <Td>{parseFloat(val.t_final)}</Td>
              <Td>{parseFloat(val.w_final)}</Td>
              <Td>{parseFloat(val.l_final)}</Td>
              <Td>{parseFloat(val.t_raw)}</Td>
              <Td>{parseFloat(val.w_raw)}</Td>
              <Td>{parseFloat(val.l_raw)}</Td>
              <Td textRight>
                {parseFloat(val.qty_raw)} {val.nama_satuan ?? 'Batang'}
              </Td>
              <td className="p-0 align-self-center">
                <SelectSearch
                  option={dropdownStatusPermintaan}
                  defaultValue={dropdownStatusPermintaan?.find(
                    ({ value }) => value === val.status_permintaan
                  )}
                  onChange={({ value }) =>
                    onChangeStatusPermintaan({ value, index })
                  }
                />
              </td>
              <Td>
                <div className="d-flex justify-content-center align-items-center">
                  <ActionButton
                    size="sm"
                    className="m-1"
                    onClick={() =>
                      setModalForm({
                        show: true,
                        type: 'hardwood',
                        data: val,
                      })
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                </div>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={15}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
