import { Table, Th, Td, DataStatus, TdFixed } from 'components';

const TableSection = ({ dataTable }) => {
  return (
    <>
      <div className="p-1">
        <b>List Item Delivery Order</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Kode Barang</Th>
          <Th>Item Barang</Th>
          <Th>Satuan</Th>
          <Th>Qty. Surat Jalan</Th>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            dataTable.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.kode_item}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.satuan}</Td>
                <Td className="text-right">{val.qty_surat_jalan}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableSection;
