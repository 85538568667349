import { useState } from 'react';
import { PermintaanBahanProduksiApi } from 'api';

const fetchGudangStok = async ({ id_barang, id_gudang }) => {
  return await PermintaanBahanProduksiApi.qty_booking({
    id_barang,
    id_gudang,
  })
    .then((res) => parseFloat(res.data.data.jumlah))
    .catch(() => alert('Gagal memuat Qty. Stok tersedia'));
};

export const useLocalStok = () => {
  const [stok, setStok] = useState({});

  const generateStokKey = ({ id_barang, id_gudang }) =>
    `${id_barang}_${id_gudang}`;

  const getStok = async ({ id_barang, id_gudang }) => {
    const stokKey = generateStokKey({ id_barang, id_gudang });

    if (stok.hasOwnProperty(stokKey)) {
      return stok[stokKey];
    } else {
      const serverStok = await fetchGudangStok({ id_barang, id_gudang });
      setStok((prev) => ({ ...prev, [stokKey]: serverStok }));
      return serverStok;
    }
  };

  const incrementStok = ({ id_barang, id_gudang, amount }) => {
    const stokKey = generateStokKey({ id_barang, id_gudang });

    setStok((prev) => ({
      ...prev,
      [stokKey]: prev[stokKey] + amount,
    }));
  };

  const decrementStok = ({ id_barang, id_gudang, amount }) => {
    const stokKey = generateStokKey({ id_barang, id_gudang });
    setStok((prev) => ({
      ...prev,
      [stokKey]: prev[stokKey] - amount,
    }));
  };

  return { getStok, incrementStok, decrementStok };
};
