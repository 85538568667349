import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  TextArea,
  Select,
  SelectSearch,
  DeleteButton,
} from 'components';
import { PurchaseRequestApi } from 'api';
import { ModalGambar } from './Section';

const TambahPurchaseRequest = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [dataNo, setDataNo] = useState('');
  const [list, setList] = useState([]);
  const [dataKelompok, setDataKelompok] = useState([]);
  const [dataProyek, setDataProyek] = useState([]);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState({
    jenis: false,
    item: false,
  });
  const [isDropdownDisabled, setIsDropdownDisabled] = useState({
    jenis: true,
    item: true,
  });
  const [dataGambar, setDataGambar] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    index: 0,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });

  const getData = () => {
    Axios.all([
      PurchaseRequestApi.getKelompok(),
      PurchaseRequestApi.getProyek(),
    ])
      .then(
        Axios.spread((kelompok, pry) => {
          setDataKelompok(kelompok.data.data);
          setDataProyek(pry.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data Kelompok gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getItem = (id) => {
    setIsLoading({
      item: true,
    });
    PurchaseRequestApi.getItem(id)
      .then((res) => setItem(res.data.data))
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data Item Tidak Ada',
        });
        setShowAlert(true);
        setItem([]);
      })
      .finally(() => {
        setIsLoading({
          item: false,
        });
        setIsDropdownDisabled({ item: false });
        setShowAlert(false);
      });
  };

  const getNomor = (id) => {
    const date = Date.parse(id);

    if (isNaN(date) == false) {
      var dataDate = new Date(date)
        .toISOString()
        .split('T')[0]
        .split('-')
        .join('/');
      PurchaseRequestApi.getNoSurat(dataDate)
        .then((res) => {
          setDataNo(res.data.data);
        })
        .catch((err) => alert(err));
    }
  };

  const getList = (id, kode, nama, satuan, qty) => {
    if (id != null && qty != null) {
      setList([
        ...list,
        {
          id_item_buaso: id,
          kode_item: kode,
          nama_item: nama,
          satuan_beli: satuan,
          qty: parseFloat(qty).toPrecision(),
          path_gambar: [],
          view_gambar: [],
        },
      ]);
    } else {
      alert('Item dan Qty tidak boleh kosong');
    }
  };

  const deleteList = (index) => {
    const array = [...list];
    array.splice(index, 1);
    setList(array);
  };

  useEffect(() => {
    setNavbarTitle('Tambah Purchase Request');
    getData();
  }, [setNavbarTitle]);

  const formInitialValues = {
    no_purchase_request: '',
    tgl_purchase_request: '',
    keperluan: '',
    catatan_purchase_request: '',
    tgl_pemakaian: '',
    id_proyek: '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_purchase_request: Yup.date().required(
      'Masukkan Tanggal Purchase Request'
    ),
    tgl_pemakaian: Yup.date().required('Masukan Tanggal Pemakaian'),
    id_proyek: Yup.string().required('Masukan Proyek'),
    keperluan: Yup.string().required('Pilih Keperluan'),
  });

  const formSubmitHandler = (values) => {
    if (list.length > 0) {
      const finalValues = {
        ...values,
        detail: list.map((val) => {
          return {
            id_item_buaso: val.id_item_buaso,
            id_purchase_request: val.id_purchase_request,
            id_purchase_request_detail: val.id_purchase_request_detail,
            path_gambar: Object.values(val.path_gambar),
            qty: val.qty,
          };
        }),
      };

      PurchaseRequestApi.create(finalValues)
        .then(() => {
          history.push('/inventory/transaksi/purchase-request', {
            variant: 'primary',
            text: 'Purchase Request berhasil ditambah',
            status: true,
          });
        })
        .catch(({ response }) => {
          const message = response?.data?.message;

          setAlertConfig({
            variant: 'danger',
            text: `Tambah Purchase Request gagal! ${
              message ? `(${message})` : ''
            }`,
          });
          setShowAlert(true);
        });
    } else {
      setShowAlert(true);
      setAlertConfig({
        variant: 'danger',
        text: 'List Item Purchase Request wajib diisi minimal 1',
      });
    }
  };

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 6);

    return convert;
  };

  return (
    <>
      <Alert
        showCloseButton
        show={showAlert}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card>
        <Card.Header className="d-flex flex-row justify-content-between">
          <span>Tambah Purchase Request</span>

          <BackButton
            onClick={() => {
              history.push('/inventory/transaksi/purchase-request', {
                ...location?.state,
              });
            }}
          />
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Row>
                    <Col lg="6">
                      <Input
                        label="Tanggal Purchase Request"
                        type="date"
                        name="tgl_purchase_request"
                        onChange={(e) => {
                          const value = e.target.value;

                          setFieldValue('tgl_purchase_request', value);
                          setFieldValue('no_purchase_request', getNomor(value));
                        }}
                        onBlur={() => {
                          setFieldValue('no_purchase_request', dataNo);
                        }}
                        error={
                          errors.tgl_purchase_request &&
                          touched.tgl_purchase_request &&
                          true
                        }
                        errorText={errors.tgl_purchase_request}
                      />
                    </Col>

                    <Col lg="6">
                      <Input
                        label="No. Purchase Request"
                        name="no_purchase_request"
                        value={values.no_purchase_request}
                        readOnly={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <Input
                        label="Tanggal Pemakaian"
                        type="date"
                        name="tgl_pemakaian"
                        onChange={handleChange}
                        error={
                          errors.tgl_pemakaian && touched.tgl_pemakaian && true
                        }
                        errorText={errors.tgl_pemakaian}
                      />
                    </Col>

                    <Col lg="6">
                      <Select
                        label="Keperluan"
                        name="keperluan"
                        onChange={(e) => {
                          const value = e.target.value;

                          setFieldValue('keperluan', value);
                        }}
                        error={errors.keperluan && touched.keperluan && true}
                        errorText={errors.keperluan}
                      >
                        <option value="" hidden>
                          Pilih Keperluan
                        </option>
                        <option value="stok">Stok</option>
                        <option value="produksi">Produksi</option>
                        <option value="Pengadaan Aset">Pengadaan Aset</option>
                        <option value="Pengadaan Barang Non Aset">
                          Pengadaan Barang Non Aset
                        </option>
                        <option value="Perbaikan Dan Pemeliharaan Alat Dan Mesin">
                          Perbaikan Dan Pemeliharaan Alat Dan Mesin
                        </option>
                        <option value="Perawatan Gedung Dan Kantor">
                          Perawatan Gedung Dan Kantor
                        </option>
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SelectSearch
                        label="Proyek"
                        name="id_proyek"
                        placeholder="Pilih proyek"
                        onChange={(val) => {
                          setFieldValue('id_proyek', val.value);
                        }}
                        option={dataProyek.map((val) => {
                          return {
                            value: val.id_proyek,
                            label: val.nama_proyek,
                          };
                        })}
                        error={errors.id_proyek && touched.id_proyek && true}
                        errorText={errors.id_proyek && touched.id_proyek}
                      />
                    </Col>
                    <Col>
                      <TextArea
                        label="Catatan"
                        type="text"
                        name="catatan_purchase_request"
                        rows={2}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="2">
                      <SelectSearch
                        label="Kelompok"
                        name="id_kelompok"
                        placeholder="Pilih Kelompok"
                        onChange={(val) => {
                          setFieldValue('id_kelompok', val.value);
                          setFieldValue('id_jenis', '');
                          setFieldValue('id_item_buaso', '');
                          getItem(val.value);
                        }}
                        option={dataKelompok.map((val) => {
                          return {
                            value: val.id_kelompok,
                            label: val.nama_kelompok,
                            flag: val.flag,
                          };
                        })}
                      />
                    </Col>

                    <Col lg="3">
                      <SelectSearch
                        label="Item"
                        name="id_item"
                        placeholder="Pilih Item"
                        onChange={(val) => {
                          setFieldValue('id_item', val.value);
                          setFieldValue('nama_satuan', val.satuan_beli);
                          setFieldValue('kode_item', val.kode_item);
                          setFieldValue('nama_item', val.label);
                        }}
                        option={item.map((val) => {
                          return {
                            value: val.id_item_buaso,
                            label: val.nama_item,
                            satuan_beli: val.satuan_beli,
                            kode_item: val.kode_item,
                          };
                        })}
                        loading={isLoading.item}
                        isDisabled={isDropdownDisabled.item}
                      />
                    </Col>

                    <Col lg="2">
                      <Input
                        label="Satuan"
                        name="nama_satuan"
                        value={values.nama_satuan}
                        readOnly={true}
                      />
                    </Col>

                    <Col lg="1">
                      <Input
                        label="Qty"
                        type="text"
                        name="qty"
                        value={values.qty}
                        onChange={(e) => {
                          const value = e.target.value;
                          const convert = decimalConvert(value);
                          setFieldValue('qty', convert);
                        }}
                      />
                    </Col>

                    <Col lg="1">
                      <label></label>
                      <ActionButton
                        onClick={(e) => {
                          e.preventDefault();

                          getList(
                            values.id_item,
                            values.kode_item,
                            values.nama_item,
                            values.nama_satuan,
                            values.qty
                          );
                        }}
                        variant="primary"
                        text="Tambah"
                      />
                    </Col>
                  </Row>
                </div>

                <small>List Item Purchase Request</small>
                <CRUDLayout.Table>
                  <THead>
                    <Tr className="text-center">
                      <ThFixed>No</ThFixed>
                      <Th>Kode Item</Th>
                      <Th>Nama Item</Th>
                      <Th>Satuan</Th>
                      <Th>Qty</Th>
                      <Th>Aksi</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {list.map((val, index) => (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>{val.nama_item}</Td>
                        <Td>{val.satuan_beli}</Td>
                        <Td>
                          <div className="text-right">{val.qty}</div>
                        </Td>
                        <TdFixed>
                          <div className="d-flex">
                            <ActionButton
                              text={<IoCloudUploadOutline />}
                              size="sm"
                              className="m-1"
                              onClick={() => {
                                setModalConfig({
                                  show: true,
                                  data: val,
                                  index: index,
                                });
                                setDataGambar(val.view_gambar);
                              }}
                            />
                            <DeleteButton
                              onClick={() => {
                                deleteList(index);
                              }}
                            />
                          </div>
                        </TdFixed>
                      </Tr>
                    ))}
                  </TBody>
                </CRUDLayout.Table>
                <div className="d-flex justify-content-end mb-4">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Simpan"
                    loading={isSubmitting}
                    className="mt-2 px-4"
                  />
                </div>
              </form>
            )}
          </Formik>
        </Card.Body>

        <ModalGambar
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          list={list}
          setList={setList}
          setDataGambar={setDataGambar}
          dataGambar={dataGambar}
        />
      </Card>
    </>
  );
};

export default TambahPurchaseRequest;
