import { useState } from 'react';
import { Table, Th, Td, DataStatus, TdFixed } from 'components';
import { DateConvert } from 'utilities';

const TableSection = ({ dataHistory, setDataHistory }) => {
  const [isAllChecked, setIsAllChecked] = useState(false);

  const onCheckedHandler = (index) => {
    const newData = dataHistory.map((val, i) => {
      return i === index ? { ...val, checked: !val.checked } : { ...val };
    });

    setDataHistory(newData);
  };

  const onCheckedAllHandler = () => {
    const newData = dataHistory.map((val) => {
      return { ...val, checked: !isAllChecked };
    });

    setDataHistory(newData);
    setIsAllChecked(!isAllChecked);
  };

  const onKeteranganChangeHandler = (value, index) => {
    const newData = dataHistory.map((val, i) => {
      return i === index ? { ...val, keterangan: value } : { ...val };
    });

    setDataHistory(newData);
  };

  const Checkbox = ({ checked, onClick }) => (
    <div className="form-check" onClick={onClick}>
      <input
        type="checkbox"
        className="form-check-input mt-0"
        style={{ width: 20, height: 20 }}
        defaultChecked={checked}
      />
    </div>
  );

  return (
    <>
      <div className="p-1">
        <b>List Item Transfer Produksi</b>
      </div>
      <Table>
        <thead className="bg-light">
          <Th>No</Th>
          <Th>Tgl. Transfer</Th>
          <Th>No. Transfer</Th>
          <Th>Kode Barang</Th>
          <Th>Item Barang</Th>
          <Th>Gudang Asal</Th>
          <Th>Gudang Tujuan</Th>
          <Th>Qty Transfer</Th>
          <Th>Keterangan</Th>
          <Th className="pt-0">
            <div className="mb-3 d-flex justify-content-center align-items-center">
              <Checkbox
                checked={isAllChecked}
                onClick={() => onCheckedAllHandler()}
              />
            </div>
          </Th>
        </thead>
        <tbody>
          {dataHistory.length > 0 ? (
            dataHistory.map((val, index) => (
              <tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>
                  {val.tgl_transfer
                    ? DateConvert(new Date(val.tgl_transfer)).defaultDMY
                    : '-'}
                </Td>
                <Td>{val.no_transfer}</Td>
                <Td>{val.kode_barang}</Td>
                <Td>{val.nama_barang}</Td>
                <Td>{val.gudang_asal}</Td>
                <Td>{val.gudang_tujuan}</Td>
                <Td className="text-right">{val.qty_transfer}</Td>
                <Td>
                  <textarea
                    className="form-control form-control-sm"
                    style={{ height: '100%' }}
                    onChange={(e) => {
                      const value = e.target.value;
                      onKeteranganChangeHandler(value, index);
                    }}
                  />
                </Td>
                <Td className="p-0">
                  <div className="d-flex justify-content-center align-items-center">
                    <Checkbox
                      checked={val.checked}
                      onClick={() => onCheckedHandler(index)}
                    />
                  </div>
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default TableSection;
