import { Services } from 'services';

class RealisasiSisaProduksiApi {
  get(params) {
    return Services.get('/realisasi_sisa_produksi/page', { params });
  }

  getSingle(params) {
    return Services.get('/realisasi_sisa_produksi/single', { params });
  }

  // GET DATA LIST TRANSFER SISA PRODUKSI
  getListTransfer(params) {
    return Services.get('/realisasi_sisa_produksi/list_transfer', { params });
  }

  getNomor(params) {
    return Services.get('/realisasi_sisa_produksi/no_baru', { params });
  }

  getDropdownGudang() {
    return Services.get('/realisasi_sisa_produksi/dropdown');
  }

  save(data) {
    return Services.post('/realisasi_sisa_produksi', data);
  }
}

export default new RealisasiSisaProduksiApi();
