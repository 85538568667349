import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  DataStatus,
  TextArea,
} from '../../../../components';
import { DeliveryOrderApi } from '../../../../api';
import { useHistory } from 'react-router-dom';
import { DateConvert } from '../../../../utilities';
import { Card, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';

const TambahDeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();
  console.log(location);

  // data
  const [data, setData] = useState([]);
  // menampung data yang akan dihapus

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });

  const { id_sales_order, id_gudang } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([DeliveryOrderApi.getFormData({ id_sales_order, id_gudang })])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle('Delivery Order');

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const TambahData = () => {
    const [isNomorLoading, setIsNomorLoading] = useState(false);
    const [kode, setKode] = useState('');

    const getNomor = (value, setFieldValue) => {
      setIsNomorLoading(true);

      DeliveryOrderApi.getKode(value)
        .then(({ data }) => {
          setKode(data.data);
          setFieldValue('no_delivery_order', data.data);
        })
        .catch((err) => alert('Gagal mengambil nomor Delivery Order'))
        .finally(() => setIsNomorLoading(false));
    };

    const formInitialValues = {
      id_sales_order: id_sales_order,
      tgl_delivery_order: '',
      no_delivery_order: '',
      catatan_delivery_order: '',
    };

    const formValidationSchema = Yup.object().shape({
      tgl_delivery_order: Yup.string().required(
        'Masukan tanggal Delivery Order '
      ),
      no_delivery_order: Yup.string().required('Masukan no Delivery Order '),
      // catatan_delivery_order: Yup.string().required("Masukan catatan Delivery Order"),
    });

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      const detail = data.detail?.map((val) => {
        return {
          id_sales_order: val.id_sales_order,
          id_sales_order_detail: val.id_sales_order_detail,
          qty: parseInt(val.qty_item),
        };
      });
      const finalValues = {
        ...values,
        detail: detail,
        id_gudang,
      };
      DeliveryOrderApi.create(finalValues)
        .then(() => {
          history.push('/inventory/transaksi/delivery-order', {
            variant: 'primary',
            text: 'Data berhasil di tambah',
            status: true,
          });
        })
        .catch((err) => {
          history.push('/inventory/transaksi/delivery-order', {
            variant: 'danger',
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const ShowData = ({ title, text }) => (
      <tr>
        <td width={125}>{title}</td>
        <td className="pr-2">:</td>
        <td>{text}</td>
      </tr>
    );

    return (
      <>
        <Row>
          <Col md>
            <table style={{ fontSize: 14 }}>
              <tbody>
                <ShowData title="No. Sales Order" text={data.no_sales_order} />
                <ShowData
                  title="Tgl. Sales Order"
                  text={DateConvert(new Date(data.tgl_sales_order)).detail}
                />
                <ShowData
                  title="Gudang"
                  text={
                    location?.state?.data?.nama_gudang
                      ? location?.state?.data?.nama_gudang
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col md>
            <table style={{ fontSize: 14 }}>
              <tbody>
                <ShowData title="Customer" text={data.nama_customer} />
                <ShowData title="Sales" text={data.nama_sales} />
                <ShowData title="Proyek" text={data.nama_proyek ?? '-'} />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col lg="6">
                  <Input
                    label="Tanggal Delivery Order"
                    type="date"
                    name="tgl_delivery_order"
                    onChange={(e) => {
                      const value = e.target.value;

                      setFieldValue('tgl_delivery_order', value);
                      getNomor(value, setFieldValue);
                    }}
                    error={
                      errors.tgl_delivery_order &&
                      touched.tgl_delivery_order &&
                      true
                    }
                    errorText={errors.tgl_delivery_order}
                  />
                </Col>
                <Col lg="6">
                  <Input
                    label="No. Delivery Order"
                    type="text"
                    name="no_delivery_order"
                    placeholder={
                      isNomorLoading
                        ? 'Memuat . . . '
                        : values.tgl_delivery_order
                        ? kode
                        : 'Pilih tanggal terlebih dahulu'
                    }
                    readOnly={true}
                    onChange={(e) => {
                      setFieldValue('no_delivery_order', e.target.value);
                    }}
                    error={
                      errors.no_delivery_order &&
                      touched.no_delivery_order &&
                      true
                    }
                    errorText={errors.no_delivery_order}
                  />
                </Col>
              </Row>

              <small>
                List Item Sales Order{' '}
                {location?.state?.data?.nama_gudang
                  ? `untuk ${location?.state?.data?.nama_gudang}`
                  : ''}
              </small>
              <CRUDLayout.Table>
                <THead>
                  <Tr className="text-center">
                    <ThFixed>No</ThFixed>
                    <Th>Kode barang</Th>
                    <Th>Nama barang</Th>
                    <Th>Qty SO</Th>
                    <Th>Satuan</Th>
                  </Tr>
                </THead>
                <TBody>
                  {data?.detail?.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_barang}</TdFixed>
                      <Td>{val.nama_barang}</Td>
                      <Td>{parseInt(val.qty_item)}</Td>
                      <Td>{val.nama_satuan}</Td>
                    </Tr>
                  ))}
                </TBody>
              </CRUDLayout.Table>
              <TextArea
                label="Catatan Delivery Order"
                type="text"
                name="catatan_delivery_order"
                onChange={handleChange}
                rows={2}
                error={
                  errors.catatan_delivery_order &&
                  touched.catatan_delivery_order &&
                  true
                }
                errorText={errors.catatan_delivery_order}
              />
              <div className="d-flex justify-content-end">
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Simpan"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <b>Tambah Delivery Order</b>

        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data.length !== 0 ? (
          <TambahData />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahDeliveryOrder;
