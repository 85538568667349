import React, { forwardRef, useEffect } from 'react';
import Logo from 'assets/image/LogoMGP.jpeg';
import { Col, Row } from 'react-bootstrap';
import { DateConvert } from 'utilities';
import { TableStokTerbaru } from '.';

const PrintStokTerbaru = forwardRef(({ dataFilter, dataTable }, ref) => {
  const KopSurat = () => {
    return (
      <div className="d-flex justify-content-start">
        <div>
          <img src={Logo} width={110} />
        </div>
        <div style={{ marginLeft: 30, textAlign: 'left' }}>
          <p>
            <b>PT. MARDIKA GRIYA PRASTA</b>
            <br />
            Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia <br />
            Telp (0361) 467342, 467343
            <br />
            Fax (0361) 467301
            <br />
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSurat />

      <div className="text-center h5 font-weight-bold mb-0">
        <u>Laporan Stok Terbaru</u>
      </div>
      <div className="text-center mb-2">
        {dataFilter?.tanggal && (
          <span>{DateConvert(new Date(dataFilter?.tanggal)).defaultDMY}</span>
        )}
      </div>

      <TableStokTerbaru data={dataTable} />
    </div>
  );
});

export default PrintStokTerbaru;
