import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import Axios from 'axios';
import { PurchaseRequestApi } from 'api';

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [dataKeperluan, setDataKeperluan] = useState([
    { label: 'Semua', value: undefined },
    { label: 'Stok', value: 'stok' },
    { label: 'Produksi', value: 'produksi' },
  ]);
  const [dataPengaju, setDataPengaju] = useState([
    { label: 'Semua', value: undefined },
  ]);
  const [dataProyek, setDataProyek] = useState([
    { label: 'Semua', value: undefined },
  ]);
  const [dataStatusApproval, setDataStatusApproval] = useState([
    {
      label: 'Semua',
      value: undefined,
    },
    {
      label: 'APPROVED',
      value: 'APP',
    },
    {
      label: 'VERIFIED',
      value: 'VER',
    },
    {
      label: 'REVISION',
      value: 'REV',
    },
    {
      label: 'REJECTED',
      value: 'REJ',
    },
    {
      label: 'PENDING',
      value: 'PEN',
    },
  ]);
  const [loading, setLoading] = useState(true);
  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([PurchaseRequestApi.getPengaju(), PurchaseRequestApi.getProyek()])
      .then(
        Axios.spread((pengaju, proyek) => {
          const mapPengaju = pengaju?.data?.data
            ? pengaju.data.data.map((val) => ({
                label: val?.nama_karyawan,
                value: val?.id_karyawan,
              }))
            : [];
          const mapProyek = proyek?.data?.data
            ? proyek.data.data.map((val) => ({
                label: val?.nama_proyek,
                value: val?.id_proyek,
              }))
            : [];

          setDataPengaju([...dataPengaju, ...mapPengaju]);
          setDataProyek([...dataProyek, ...mapProyek]);
        })
      )
      .finally(() => setLoading(false));
  };
  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    tgl_purchase_request_mulai: data?.filter?.tgl_purchase_request_mulai,
    tgl_purchase_request_selesai: data?.filter?.tgl_purchase_request_selesai,
    tgl_pemakaian_mulai: data?.filter?.tgl_pemakaian_mulai,
    tgl_pemakaian_selesai: data?.filter?.tgl_pemakaian_selesai,
    keperluan: data?.filter?.keperluan,
    pengaju: data?.filter?.pengaju,
    proyek: data?.filter?.proyek,
    status_approval: data?.filter?.status_approval,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTanggalPRChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_purchase_request_mulai: startDate,
      tgl_purchase_request_selesai: endDate,
    });
  };
  const onTanggalPemakaianChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_pemakaian_mulai: startDate,
      tgl_pemakaian_selesai: endDate,
    });
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_purchase_request_mulai: undefined,
      tgl_purchase_request_selesai: undefined,
      tgl_pemakaian_mulai: undefined,
      tgl_pemakaian_selesai: undefined,
      keperluan: undefined,
      pengaju: undefined,
      proyek: undefined,
      status_approval: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Purchase Request"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih Tanggal Purchase Request"
            startDate={
              values.tgl_purchase_request_mulai
                ? new Date(values.tgl_purchase_request_mulai)
                : ''
            }
            endDate={
              values.tgl_purchase_request_selesai
                ? new Date(values.tgl_purchase_request_selesai)
                : ''
            }
            onChange={(dates) => onTanggalPRChange(dates, values, setValues)}
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Tgl. Pemakaian"
            dateFormat="dd/MM/yyyy"
            placeholderText="Pilih Tanggal Pemakaian"
            startDate={
              values.tgl_pemakaian_mulai
                ? new Date(values.tgl_pemakaian_mulai)
                : ''
            }
            endDate={
              values.tgl_pemakaian_selesai
                ? new Date(values.tgl_pemakaian_selesai)
                : ''
            }
            onChange={(dates) =>
              onTanggalPemakaianChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.keperluan}
            label="Keperluan"
            placeholder="Pilih Keperluan"
            defaultValue={dataKeperluan.find(
              (val) => val.value === values.keperluan
            )}
            option={dataKeperluan}
            onChange={(val) => setFieldValue('keperluan', val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.pengaju}
            label="Pengaju"
            placeholder="Pilih Pengaju"
            defaultValue={dataPengaju.find(
              (val) => val.value === values.pengaju
            )}
            option={dataPengaju}
            onChange={(val) => setFieldValue('pengaju', val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.proyek}
            label="Proyek"
            placeholder="Pilih Proyek"
            defaultValue={dataProyek.find((val) => val.value === values.proyek)}
            option={dataProyek}
            onChange={(val) => setFieldValue('proyek', val.value)}
            loading={loading}
          />
          <SelectSearch
            key={values.status_approval}
            label="Status Approval"
            placeholder="Pilih Status Approval"
            defaultValue={dataStatusApproval.find(
              (val) => val.value === values.status_approval
            )}
            option={dataStatusApproval}
            onChange={(val) => setFieldValue('status_approval', val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
