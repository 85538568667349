import { Services } from '../../../services';

class LaporanDeliveryOrderApi {
  page(params) {
    return Services.get('/laporan_delivery_order/page', { params });
  }

  single(params) {
    return Services.get('/laporan_delivery_order/single', { params });
  }

  dropdown(params) {
    return Services.get('/laporan_delivery_order/dropdown', { params });
  }
}

export default new LaporanDeliveryOrderApi();
