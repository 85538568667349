import { Fragment, useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PermintaanBahanProduksiApi } from 'api';
import { TableTransferBahanProduksi } from '../TableTransferBahanProduksi';
import { TransferBahanProduksiContext } from '../../Context';
import { TablePermintaanBPF } from './TablePermintaanBPF';

export const TabBahanPenunjangFinishing = () => {
  const { dataJobOrder, dataPermintaan } = useContext(
    TransferBahanProduksiContext
  );
  const id_permintaan_produksi = dataJobOrder.id_permintaan_produksi;
  const [history, setHistory] = useState([]);

  // FETCH HISTORY
  useEffect(() => {
    if (id_permintaan_produksi) {
      PermintaanBahanProduksiApi.transfer({
        id_permintaan_produksi,
        ref_bahan: 'bpf',
      })
        .then((res) => setHistory(res.data.data ?? []))
        .catch(({ response }) =>
          alert(
            `Gagal memuat data History Bahan Penunjang Finishing ${response.data.message}`
          )
        );
    }
  }, [id_permintaan_produksi]);

  return (
    <Fragment>
      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <small>List Data Permintaan Bahan Penunjang Finishing</small>
        </Col>
      </Row>
      <TablePermintaanBPF data={dataPermintaan?.penunjang ?? []} />

      <small className="mt-2">List Data Transfer Bahan Produksi</small>
      <TableTransferBahanProduksi
        tipe="editable"
        tipeTransfer="penunjang"
        data={dataPermintaan?.penunjang}
        uniqueKey="id_permintaan_produksi_finishing"
      />

      <small className="mt-2">History Transfer Bahan Produksi</small>
      <TableTransferBahanProduksi tipe="readonly" data={history} />
    </Fragment>
  );
};
