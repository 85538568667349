import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { Modal, Row, Col } from 'react-bootstrap';
import Axios from 'axios';
import ImageViewer from 'react-simple-image-viewer';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  ReadModal,
  UpdateButton,
  Approval,
  ApprovalStatusButton,
  FilterButton,
} from 'components';
import { PageNumber, DateConvert } from 'utilities';
import { ModalFilter } from './Section';
import { TimeStamp } from './Components';
import { PenerimaanBarangApi } from 'api';
import { tglInputFormat } from './Utils';

const PenerimaanBarang = ({ setNavbarTitle }) => {
  const history = useHistory();
  const title = 'Penerimaan Barang';
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [data, setData] = useState([]);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.penerimaan?.filter?.active,
      tgl_penerimaan_barang_mulai:
        location?.state?.penerimaan?.filter?.tgl_penerimaan_barang_mulai,
      tgl_penerimaan_barang_selesai:
        location?.state?.penerimaan?.filter?.tgl_penerimaan_barang_selesai,
      tgl_purchase_order_mulai:
        location?.state?.penerimaan?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai:
        location?.state?.penerimaan?.filter?.tgl_purchase_order_selesai,
      vendor: location?.state?.penerimaan?.filter?.vendor,
      item_barang: location?.state?.penerimaan?.filter?.item_barang,
      status_approval: location?.state?.penerimaan?.filter?.status_approval,
      karyawan_pengaju: location?.state?.penerimaan?.filter?.karyawan_pengaju,
    },
    pagination: {
      page: location?.state?.penerimaan?.filter?.page ?? '1',
      dataLength: location?.state?.penerimaan?.filter?.dataLength ?? '10',
      totalPage: location?.state?.penerimaan?.filter?.totalPage ?? '1',
      dataCount: location?.state?.penerimaan?.filter?.dataCount ?? '0',
    },
  });

  const [showModal, setShowModal] = useState(false);
  const [dataDetail, setDataDetail] = useState({ id_purchase: 0 });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    Axios.all([
      PenerimaanBarangApi.getPage({
        q: searchConfig.key,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_penerimaan_barang_mulai:
          dataFilter?.filter?.tgl_penerimaan_barang_mulai,
        tgl_penerimaan_barang_selesai:
          dataFilter?.filter?.tgl_penerimaan_barang_selesai,
        tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
        tgl_purchase_order_selesai:
          dataFilter?.filter?.tgl_purchase_order_selesai,
        vendor: dataFilter?.filter?.vendor,
        item_barang: dataFilter?.filter?.item_barang,
        status_approval: dataFilter?.filter?.status_approval,
        karyawan_pengaju: dataFilter?.filter?.karyawan_pengaju,
      }),
    ])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data ?? []);
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            },
          });
          searchConfig.status &&
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: `Hasil dari pencarian: ${searchConfig.key}`,
            });
        })
      )
      .catch((err) => alert(err?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  const searchHandler = (e) => {
    setSearchConfig({ ...searchConfig, key: e.target.value });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_penerimaan_barang_mulai,
    dataFilter?.filter?.tgl_penerimaan_barang_selesai,
    dataFilter?.filter?.tgl_purchase_order_mulai,
    dataFilter?.filter?.tgl_purchase_order_selesai,
    dataFilter?.filter?.vendor,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.status_approval,
    dataFilter?.filter?.karyawan_pengaju,
  ]);
  // MODAL UBAH COMPONENT
  const DetailModal = () => {
    const [currentImage, setCurrentImage] = useState('');
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [readData, setReadData] = useState({
      qty_beli: '0.00',
      qty_pakai: '0.00',
      tgl_purchase_order: '',
      tgl_penerimaan_barang: '',
      qty_order: '0.00',
    });
    const [loadingModal, setLoadingModal] = useState(false);

    const getDetail = () => {
      if (dataDetail.id_purchase !== 0) {
        PenerimaanBarangApi.getSinglePenerimaan(
          'id_penerimaan_barang',
          dataDetail.id_penerimaan
        )
          .then((res) => setReadData(res.data.data))
          .catch((err) => alert(err.response.data.message))
          .finally(() => setLoadingModal(false));
      }
    };

    useEffect(() => {
      setLoadingModal(true);
      getDetail();
    }, [dataDetail]);

    const ShowData = ({ title, text, line }) => (
      <div>
        <small
          className="text-secondary"
          style={{ opacity: 0.8, textTransform: 'capitalize' }}
        >
          {title}
        </small>
        <div className="text-dark">
          <small>{text ? text : '-'}</small>
        </div>
        {line && <hr className="my-2" />}
      </div>
    );

    const closeImageViewer = () => {
      setIsViewerOpen(false);
    };
    const openImageViewer = useCallback((index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);

    return (
      <ReadModal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        title={title}
      >
        <Modal.Body>
          {loadingModal === true ? (
            <>
              <DataStatus loading={true} text="Memuat data..." />
            </>
          ) : (
            <>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Purchase Order"
                    line={true}
                    text={`
                       ${
                         readData.tgl_purchase_order
                           ? DateConvert(new Date(readData.tgl_purchase_order))
                               .detail
                           : '-'
                       } 
                       ${readData.created_at_purchase_order ? '-' : ''} 
                       ${
                         readData.created_at_purchase_order
                           ? TimeStamp(readData.created_at_purchase_order)
                           : ''
                       }`}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Purchase Order"
                    line={true}
                    text={
                      readData.no_purchase_order
                        ? readData.no_purchase_order
                        : '-'
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Input Data"
                    line={true}
                    text={tglInputFormat({
                      created_at: readData.created_at_penerimaan_barang,
                      separator: ' - ',
                    })}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Penerimaan Barang"
                    line={true}
                    text={
                      readData.tgl_penerimaan_barang
                        ? DateConvert(new Date(readData.tgl_penerimaan_barang))
                            .detail
                        : '-'
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Penerimaan Barang"
                    line={true}
                    text={
                      readData.no_penerimaan_barang
                        ? readData.no_penerimaan_barang
                        : '-'
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <ShowData
                    title="Item"
                    line={true}
                    text={readData.nama_item}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <ShowData
                    title="Vendor"
                    line={true}
                    text={readData.nama_vendor ? readData.nama_vendor : '-'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="No. Surat Jalan"
                    line={true}
                    text={
                      readData.no_surat_jalan ? readData.no_surat_jalan : '-'
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Petugas Penerima"
                    line={true}
                    text={readData.nama_karyawan ? readData.nama_karyawan : '-'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Gudang Penerima"
                    line={true}
                    text={readData.nama_gudang ? readData.nama_gudang : '-'}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Qty Purchase Order"
                    line={true}
                    text={
                      readData.qty_order
                        ? readData.qty_order + ' ' + readData.nama_satuan_order
                        : '-'
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Beli"
                    line={true}
                    text={
                      readData.qty_beli
                        ? `${readData.qty_beli} ${readData.nama_satuan_beli}`
                        : '-'
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Pakai"
                    line={true}
                    text={
                      readData.qty_pakai
                        ? `${readData.qty_pakai} ${readData.nama_satuan_pakai}`
                        : '-'
                    }
                  />
                </Col>
              </Row>
              <div className="row justify-content-center mt-2">
                <Col lg="10">
                  <div className="text-center">
                    <img
                      src={readData.foto_surat_jalan}
                      className="img-fluid"
                      width="400"
                      onClick={() => openImageViewer(0)}
                      alt="foto surat jalan"
                    />
                    {isViewerOpen && (
                      <ImageViewer
                        src={[readData.foto_surat_jalan]}
                        currentIndex={currentImage}
                        disableScroll={false}
                        onClose={closeImageViewer}
                      />
                    )}
                  </div>
                </Col>
              </div>
              <Approval data={readData} />
            </>
          )}
        </Modal.Body>
      </ReadModal>
    );
  };
  // Tabel
  const Table = () => {
    const checkStatus = (stat) => {
      let data = stat ? stat.toUpperCase() : '';
      const convert = data.substring(0, 3);

      if (convert === 'APP') {
        // return "APPROVED";
        return {
          variant: 'outline-success',
          label: 'APPROVED',
        };
      }
      if (convert === 'VER') {
        // return "VERIFIED";
        return {
          variant: 'outline-success',
          label: 'VERIFIED',
        };
      }
      if (convert === 'REV') {
        // return "REVISI";
        return {
          variant: 'outline-warning',
          label: 'REVISI',
        };
      }
      if (convert === 'REJ') {
        // return "REJECT";
        return {
          variant: 'outline-danger',
          label: 'REJECT',
        };
      }

      // return "PENDING";
      return {
        variant: 'outline-secondary',
        label: 'PENDING',
      };
    };

    return (
      <>
        <span style={{ fontSize: '14px' }}>
          <b>List Data Penerimaan Barang</b>
        </span>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Tgl. Input Data</ThFixed>
              <ThFixed>Informasi Penerimaan Barang</ThFixed>
              <ThFixed>Informasi Purchase Order</ThFixed>
              <ThFixed>Informasi Purchase Request</ThFixed>
              <Th>Nama Pembuat</Th>
              <Th>Vendor</Th>
              <Th>Item Barang</Th>
              <Th>Qty. penerima</Th>
              <Th>Satuan</Th>
              <Th>Nama Penerimaan</Th>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={val.id_penerimaan_barang}>
                <TdFixed>
                  {PageNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex flex-row">
                    {val.status_approval === 'REV' ? (
                      <>
                        <ReadButton
                          onClick={() => {
                            setShowModal(true);
                            setDataDetail({
                              id_purchase: val.id_purchase_order_detail,
                              id_penerimaan: val.id_penerimaan_barang,
                            });
                          }}
                        />
                        <UpdateButton
                          onClick={() =>
                            history.push(
                              `/inventory/transaksi/penerimaan-barang/ubah/${val.id_penerimaan_barang}`,
                              {
                                id_purchase_order_detail:
                                  val.id_purchase_order_detail,
                              }
                            )
                          }
                        />
                      </>
                    ) : (
                      <ReadButton
                        onClick={() => {
                          setShowModal(true);
                          setDataDetail({
                            id_purchase: val.id_purchase_order_detail,
                            id_penerimaan: val.id_penerimaan_barang,
                          });
                        }}
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed className="text-nowrap">
                  {tglInputFormat({
                    created_at: val.created_at,
                    separator: ' - ',
                  })}
                </TdFixed>
                <TdFixed>
                  <div>
                    {DateConvert(new Date(val.tgl_penerimaan_barang)).custom}
                  </div>
                  <div>{val.no_penerimaan_barang ?? '-'}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {`
                    ${DateConvert(new Date(val.tgl_purchase_order)).custom}
                    ${val.created_at_purchase_order ? '-' : ''} 
                    ${
                      val.created_at_purchase_order
                        ? TimeStamp(val.created_at_purchase_order)
                        : ''
                    }`}
                  </div>
                  <div>{val.no_purchase_order ?? '-'}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {`
                    ${
                      val?.tgl_purchase_request
                        ? DateConvert(new Date(val.tgl_purchase_request)).custom
                        : ''
                    }
                    ${
                      val.created_at_purchase_request
                        ? TimeStamp(val.created_at_purchase_request)
                        : ''
                    }`}
                  </div>
                  <div>{val.no_purchase_request ?? '-'}</div>
                </TdFixed>
                <Td style={{ minWidth: '200px' }}>
                  {val.nama_karyawan_pengaju ?? '-'}
                </Td>
                <Td style={{ minWidth: '200px' }}>{val.nama_vendor}</Td>
                <Td style={{ minWidth: '200px' }}>{val.nama_item ?? '-'}</Td>
                <Td>
                  <div className="text-right">
                    {val.qty_beli ? val.qty_beli.replace('.', ',') : '0'}
                  </div>
                </Td>
                <Td>{val.nama_satuan_beli ?? '-'}</Td>
                <Td style={{ minWidth: '200px' }}>
                  {val.nama_karyawan ?? '-'}
                </Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={checkStatus(val.status_approval).variant}
                  >
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex align-items-center">
            <InputSearch
              onChange={debounce(searchHandler, 1500)}
              onSubmit={(e) => e.preventDefault()}
            />
            <div className="ml-3">
              <FilterButton
                active={dataFilter?.filter?.active}
                onClick={() => setShowModalFilter(true)}
              />
            </div>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              history.push('/inventory/transaksi/penerimaan-barang/list', {
                ...location.state,
                penerimaan: dataFilter,
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setAlertConfig({ ...alertConfig, show: false });
        }}
      />

      {/* Table Section */}
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data ? (
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Server error" />
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
      <DetailModal />
    </CRUDLayout>
  );
};

export default PenerimaanBarang;
