import { IoAddOutline } from 'react-icons/io5';
import { Table, TdFixed } from 'components';
import {
  THead,
  Tr,
  Th,
  ThFixed,
  TBody,
  Td,
  ActionButton,
  DataStatus,
  SelectSearch,
} from 'components';
import { useContext } from 'react';
import {
  DropdownTransferBahanProduksiContext,
  TransferBahanProduksiContext,
} from '../../Context';

export const TablePermintaanFS = ({ data = [] }) => {
  const { dropdownStatusPermintaan } = useContext(
    DropdownTransferBahanProduksiContext
  );
  const { setModalForm, setDataPermintaan } = useContext(
    TransferBahanProduksiContext
  );

  const onChangeStatusPermintaan = ({ value, index }) => {
    const copyData = [...data];
    copyData[index]['status_permintaan'] = value;

    setDataPermintaan((prv) => ({
      ...prv,
      factory: copyData,
    }));
  };

  return (
    <Table>
      <THead>
        <Tr className="text-center">
          <ThFixed>No.</ThFixed>
          <Th>Item Barang</Th>
          <Th className="text-nowrap">Qty. Permintaan</Th>
          <Th width={150}>Status Permintaan</Th>
          <ThFixed>Aksi</ThFixed>
        </Tr>
      </THead>

      <TBody>
        {data?.length ? (
          data.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>{val.nama_item}</Td>
              <TdFixed textRight>
                {parseFloat(val.qty)} {val.nama_satuan}
              </TdFixed>
              <td className="p-0 align-self-center">
                <SelectSearch
                  option={dropdownStatusPermintaan}
                  defaultValue={dropdownStatusPermintaan?.find(
                    ({ value }) => value === val.status_permintaan
                  )}
                  onChange={({ value }) =>
                    onChangeStatusPermintaan({ value, index })
                  }
                />
              </td>
              <Td>
                <div className="d-flex justify-content-center align-items-center">
                  <ActionButton
                    size="sm"
                    className="m-1"
                    onClick={() =>
                      setModalForm({
                        show: true,
                        type: 'factory',
                        data: val,
                      })
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                </div>
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={5}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
