import { useFormikContext } from 'formik';
import { IoTrashBinOutline } from 'react-icons/io5';
import { Card } from 'react-bootstrap';
import {
  ActionButton,
  CRUDLayout,
  THead,
  TBody,
  Tr,
  ThFixed,
  Th,
  Td,
} from 'components';
import { RupiahConvert } from 'utilities';
import { InputTableConvert } from './InputTableConvert';

export const TableHasilConvert = ({ type }) => {
  const { values, setValues } = useFormikContext();
  const typeDetail = type === 'DETAIL';

  const handleDeleteItem = (index) =>
    setValues((prev) => ({
      ...prev,
      detail: prev?.detail?.filter((_, idx) => idx !== index),
    }));

  return (
    <>
      <div className="mb-1 font-weight-bold" style={{ fontSize: '.9rem' }}>
        Item Hasil Convert
      </div>
      <Card>
        <Card.Body>
          <CRUDLayout.Table>
            <THead>
              <Tr className="text-center">
                <ThFixed>No.</ThFixed>
                <Th width={200}>Item Barang</Th>
                <Th>Satuan</Th>
                <Th width={200}>Gudang</Th>
                <Th>Qty.</Th>
                <Th>Nilai Persediaan</Th>
                {!typeDetail && <ThFixed>Aksi</ThFixed>}
              </Tr>
            </THead>
            <TBody>
              {!typeDetail && <InputTableConvert />}
              {values.detail?.map((val, index) => (
                <Tr key={val.id_convert_barang_detail ?? index}>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td>{val.nama_gudang}</Td>
                  <Td textRight>{val.qty_convert}</Td>
                  <Td textRight>
                    {
                      RupiahConvert(String(parseFloat(val.nilai_persediaan)))
                        .detail
                    }
                  </Td>

                  {!typeDetail && (
                    <Td>
                      <ActionButton
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <IoTrashBinOutline />
                      </ActionButton>
                    </Td>
                  )}
                </Tr>
              ))}
            </TBody>
          </CRUDLayout.Table>
        </Card.Body>
      </Card>
    </>
  );
};
