import { Services } from '../../../services';

class RegItemBahanApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/bahan/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getBuaso() {
    return Services.get('/buaso');
  }
  getBahan() {
    return Services.get('/bahan/dropdown');
  }
  getSatuan() {
    return Services.get('/satuan/dropdown');
  }
  getKelompok() {
    return Services.get('/bahan/kelompok?id_buaso=2');
  }
  getJenis(data) {
    return Services.get(`/bahan/jenis?id_kelompok=${data}`);
  }
  getPabrikan() {
    return Services.get('/pabrikan/dropdown');
  }
  generateKodeItem() {
    return Services.get('/bahan/no_baru');
  }
  create(data) {
    return Services.post('/bahan', data);
  }
  update(data) {
    return Services.put('/bahan', data);
  }
  delete(id_bahan) {
    return Services.post('/bahan/delete', id_bahan);
  }
  search(key) {
    return Services.get(`bahan/page/?q=${key}`);
  }
  show(id_bahan) {
    return Services.put('/bahan/show', id_bahan);
  }
  hide(id_bahan) {
    return Services.put('/bahan/hide', id_bahan);
  }
  export() {
    return Services.get('/bahan/xlsx');
  }
}

export default new RegItemBahanApi();
