import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoAddOutline } from 'react-icons/io5';
import Axios from 'axios';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  InputSearch,
  BackButton,
  TdFixed,
  DataStatus,
  Tr,
  ThFixed,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  FilterButton,
} from 'components';
import { PageNumber, DateConvert } from 'utilities';
import {
  ModalDetailPurchaseOrder,
  ModalFilter,
} from './Section Purchase Order';
import { TimeStamp } from './Components';
import { PenerimaanBarangApi } from 'api';
import { tglInputFormat } from './Utils';
import { decimalFormatLocale } from './Utils/numberFormatLocale';

const ListPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const title = 'Penerimaan Barang';
  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;
  const [isLoading, setIsLoading] = useState(false);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [modaPOConfig, setModalPOConfig] = useState({
    show: false,
    id_purchase_order: null,
  });
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.po?.filter?.active,
      tgl_purchase_order_mulai:
        location?.state?.po?.filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai:
        location?.state?.po?.filter?.tgl_purchase_order_selesai,
      tgl_purchase_request_mulai:
        location?.state?.po?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai:
        location?.state?.po?.filter?.tgl_purchase_request_selesai,
      vendor: location?.state?.po?.filter?.vendor,
      item_barang: location?.state?.po?.filter?.item_barang,
    },
    pagination: {
      page: location?.state?.penerimaan?.filter?.page ?? '1',
      dataLength: location?.state?.penerimaan?.filter?.dataLength ?? '10',
      totalPage: location?.state?.penerimaan?.filter?.totalPage ?? '1',
      dataCount: location?.state?.penerimaan?.filter?.dataCount ?? '0',
    },
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });

  const getData = () => {
    setIsLoading(true);
    Axios.all([
      PenerimaanBarangApi.getPagePO({
        q: searchConfig.key,
        page: dataFilter?.pagination?.page,
        per_page: dataFilter?.pagination?.dataLength,
        tgl_purchase_order_mulai: dataFilter?.filter?.tgl_purchase_order_mulai,
        tgl_purchase_order_selesai:
          dataFilter?.filter?.tgl_purchase_order_selesai,
        tgl_purchase_request_mulai:
          dataFilter?.filter?.tgl_purchase_request_mulai,
        tgl_purchase_request_selesai:
          dataFilter?.filter?.tgl_purchase_request_selesai,
        vendor: dataFilter?.filter?.vendor,
        item_barang: dataFilter?.filter?.item_barang,
        karyawan_pengaju: dataFilter?.filter?.karyawan_pengaju,
      }),
      // PenerimaanBarangApi.getKelompokFilter(),
    ])
      .then(
        Axios.spread((res) => {
          setData(res.data.data ?? []);
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            },
          });
          searchConfig.status &&
            setAlertConfig({
              show: true,
              variant: 'primary',
              text: `Hasil dari pencarian: ${searchConfig.key}`,
            });
          // setFilterData(filter.data.data);
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  const searchHandler = (e) =>
    setSearchConfig({ ...searchConfig, key: e.target.value });

  useEffect(() => {
    setNavbarTitle(title);
    getData();

    if (registerAlertConfig !== undefined)
      setAlertConfig({ ...alertConfig, show: true, registerAlertConfig });

    return () => setIsLoading(false);
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_order_mulai,
    dataFilter?.filter?.tgl_purchase_order_selesai,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.vendor,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.karyawan_pengaju,
  ]);

  const Table = () => (
    <>
      <div className="mt-3 mb-1 font-weight-bold">
        List Purchase Order Yang Siap Dibuatkan Penerimaan Barang
      </div>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Tgl. Input Data</ThFixed>
            <ThFixed>Informasi Purchase Order</ThFixed>
            <ThFixed>Informasi Purchase Request</ThFixed>
            <Th>Karyawan Pengaju</Th>
            <Th>Vendor</Th>
            <Th>Item Barang</Th>
            <Th>Satuan</Th>
            <Th>Qty. Purchase Order</Th>
            <Th>Qty. Menunggu Persetujuan</Th>
            <Th>Qty. Diterima</Th>
            <Th>Qty. Sisa</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => {
            const qty =
              parseFloat(val.qty_belum_diterima ?? 0) +
              parseFloat(val.qty_diterima ?? 0);

            const qty_sisa = parseFloat(val.qty_order ?? 0) - parseFloat(qty);

            return (
              <Tr key={`${index}${val.id_purchase_order_detail}`}>
                <TdFixed textCenter>
                  {PageNumber(
                    dataFilter?.pagination?.page,
                    dataFilter?.pagination?.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Penerimaan Barang</Tooltip>}
                  >
                    <Button
                      variant={
                        parseInt(val.qty_diterima ?? 0) === 0
                          ? 'primary'
                          : parseFloat(val.qty_diterima) ===
                            parseFloat(val.qty_order)
                          ? 'success'
                          : 'warning'
                      }
                      size="sm"
                      className="m-1"
                      disabled={
                        parseFloat(qty_sisa) === 0 ||
                        parseFloat(val.qty_belum_diterima > 0)
                          ? true
                          : false
                      }
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/penerimaan-barang/tambah/${val.id_purchase_order_detail}`,
                          { qty_sisa: qty_sisa }
                        )
                      }
                    >
                      <IoAddOutline />
                    </Button>
                  </OverlayTrigger>
                </TdFixed>
                <TdFixed className="text-nowrap">
                  {tglInputFormat({
                    created_at: val.created_at_purchase_order,
                    separator: ' - ',
                  })}
                </TdFixed>
                <TdFixed>
                  <div
                    className="text-primary"
                    role="button"
                    onClick={() =>
                      setModalPOConfig({
                        show: true,
                        id_purchase_order: val.id_purchase_order,
                      })
                    }
                  >
                    <div>
                      {DateConvert(new Date(val.tgl_purchase_order)).custom}
                    </div>
                    <u>{val.no_purchase_order ?? '-'}</u>
                  </div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {`
                    ${DateConvert(new Date(val.tgl_purchase_request)).custom}
                    ${
                      val.created_at_purchase_request
                        ? `- ${TimeStamp(val.created_at_purchase_request)}`
                        : ''
                    }`}
                  </div>
                  <div>{val.no_purchase_request ?? '-'}</div>
                </TdFixed>
                <Td style={{ minWidth: '100px' }}>{val.pengaju_pr ?? '-'}</Td>
                <Td style={{ minWidth: '250px' }}>{val.nama_vendor}</Td>
                <Td style={{ minWidth: '300px' }}> {val.nama_item} </Td>
                <Td style={{ minWidth: '80px' }}>{val.satuan_beli}</Td>
                <Td style={{ minWidth: '80px' }}>
                  <div className="text-right">
                    {val.qty_order
                      ? decimalFormatLocale(val.qty_order, 2)
                      : '0,00'}
                  </div>
                </Td>
                <Td style={{ minWidth: '80px' }}>
                  <div className="text-right">
                    {val.qty_belum_diterima
                      ? decimalFormatLocale(val.qty_belum_diterima)
                      : '0,00'}
                  </div>
                </Td>
                <Td style={{ minWidth: '80px' }}>
                  <div className="text-right">
                    {val.qty_diterima
                      ? decimalFormatLocale(val.qty_diterima)
                      : '0,00'}
                  </div>
                </Td>
                <Td style={{ minWidth: '80px' }}>
                  <div className="text-right">
                    {qty_sisa ? decimalFormatLocale(qty_sisa) : '0,00'}
                  </div>
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </CRUDLayout.Table>
      <Pagination
        dataLength={dataFilter?.pagination?.dataLength}
        dataNumber={
          dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
          dataFilter?.pagination?.dataLength +
          1
        }
        dataPage={
          dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
            ? dataFilter?.pagination?.dataCount
            : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
        }
        dataCount={dataFilter?.pagination?.dataCount}
        currentPage={dataFilter?.pagination?.page}
        totalPage={dataFilter?.pagination?.totalPage}
        onPaginationChange={({ selected }) =>
          setDataFilter({
            ...dataFilter,
            pagination: { ...dataFilter.pagination, page: selected + 1 },
          })
        }
        onDataLengthChange={(e) =>
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              page: 1,
              dataLength: e.target.value,
            },
          })
        }
      />
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex align-items-center">
            <InputSearch
              placeholder="Cari barang jadi"
              onChange={debounce(searchHandler, 1500)}
              onSubmit={(e) => e.preventDefault()}
            />
            <div className="ml-3">
              <FilterButton
                active={dataFilter?.filter?.active}
                onClick={() => setShowModalFilter(true)}
              />
            </div>
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() =>
              history.push('/inventory/transaksi/penerimaan-barang', {
                ...location.state,
                po: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data && data?.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {modaPOConfig.show && (
        <ModalDetailPurchaseOrder
          modalConfig={modaPOConfig}
          setModalConfig={setModalPOConfig}
        />
      )}
    </CRUDLayout>
  );
};

export default ListPurchaseOrder;
