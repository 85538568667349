import { Row, Col } from 'react-bootstrap';

const InfoSection = ({ dataInfo }) => {
  const InfoItem = ({ title, value }) => (
    <tr>
      <td width={125}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  return (
    <Row>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItem title="No. RAP" value={dataInfo.no_rap} />
            <InfoItem title="No. Job Order" value={dataInfo.no_jo} />
          </tbody>
        </table>
      </Col>
      <Col md>
        <table style={{ fontSize: 14 }}>
          <tbody>
            <InfoItem title="Customer" value={dataInfo.customer} />
            <InfoItem title="Barang Jadi" value={dataInfo.barang_jadi} />
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default InfoSection;
