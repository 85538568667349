// React
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Component
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  TdFixed,
  DataStatus,
  Tr,
  ReadButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  ReadModal,
} from '../../../../components';
import { Modal, Row, Col } from 'react-bootstrap';
import { PageNumber, DateConvert, RupiahConvert } from '../../../../utilities';

// API
import { RealisasiSisaProduksiApi } from '../../../../api';

const RealisasiSisaProduksi = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const location = useLocation();
  const title = 'Realisasi Sisa Produksi';

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);

  // data jenis
  const [data, setData] = useState([]);

  // DETAIL MODAL STATE
  const [showModal, setShowModal] = useState(false);
  const [dataDetail, setDataDetail] = useState({});

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    RealisasiSisaProduksiApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: res.data.data_count,
          totalPage: res.data.total_page,
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    checkAlert();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  // MODAL DETAIL COMPONENT
  const DetailModal = () => {
    const [readData, setReadData] = useState({});
    const [loadingModal, setLoadingModal] = useState(false);

    const getDetail = () => {
      RealisasiSisaProduksiApi.getSingle({
        id_realisasi_sisa_produksi: dataDetail.id_realisasi_sisa_produksi,
      })
        .then((res) => {
          setReadData(res?.data?.data ?? {});
        })
        .catch((err) =>
          alert(err?.response?.data?.message ?? 'Tidak dapat memuat data')
        )
        .finally(() => setLoadingModal(false));
    };

    useEffect(() => {
      setLoadingModal(true);
      getDetail();
    }, [dataDetail]);

    const ShowData = ({ title, text, line }) => (
      <div>
        <small
          className="text-secondary"
          style={{ opacity: 0.8, textTransform: 'capitalize' }}
        >
          {title}
        </small>
        <div className="text-dark">
          <small>{text ? text : '-'}</small>
        </div>
        {line && <hr className="my-2" />}
      </div>
    );

    return (
      <ReadModal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        title={title}
      >
        <Modal.Body>
          {loadingModal ? (
            <DataStatus loading={true} text="Memuat data..." />
          ) : (
            <>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Realisasi Sisa Produksi"
                    line={true}
                    text={
                      DateConvert(
                        new Date(readData?.tgl_realisasi_sisa_produksi ?? '')
                      ).detail
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Realisasi Sisa Produksi"
                    line={true}
                    text={readData?.no_realisasi_sisa_produksi ?? '-'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Transfer Sisa Produksi"
                    line={true}
                    text={
                      readData.tgl_transfer_sisa_produksi
                        ? DateConvert(
                            new Date(readData.tgl_transfer_sisa_produksi)
                          ).detail
                        : '-'
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Transfer Sisa Produksi"
                    line={true}
                    text={readData?.no_transfer_sisa_produksi ?? '-'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <ShowData
                    title="No. Job Order"
                    line={true}
                    text={readData?.no_job_order ?? '-'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Item Bahan"
                    line={true}
                    text={readData?.nama_item ?? '-'}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Qty. Realisasi Sisa Produksi"
                    line={true}
                    text={`${readData?.qty_realisasi ?? '0'} ${
                      readData?.nama_satuan ?? ''
                    }`}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Gudang Asal"
                    line={true}
                    text={readData?.nama_gudang_asal ?? '-'}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Gudang Tujuan"
                    line={true}
                    text={readData?.nama_gudang_tujuan ?? '-'}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Harga Satuan Sisa Produksi"
                    line={true}
                    text={
                      RupiahConvert(
                        String(
                          parseInt(readData?.harga_satuan_sisa_produksi || 0)
                        )
                      ).detail
                    }
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
      </ReadModal>
    );
  };

  // Tabel
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Tgl. Realisasi Sisa Produksi</Th>
              <Th>No. Realisasi Sisa Produksi</Th>
              <Th>Item Sisa Produksi</Th>
              <Th>Gudang Asal</Th>
              <Th>Gudang Tujuan</Th>
              <Th>Qty Realisasi</Th>
              <Th>Harga Satuan Sisa Produksi</Th>
              <Th>Jumlah</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {PageNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex flex-row">
                    <ReadButton
                      onClick={() => {
                        setShowModal(true);
                        setDataDetail(val);
                      }}
                    />
                  </div>
                </TdFixed>
                <Td>
                  {val.tgl_realisasi_sisa_produksi
                    ? DateConvert(new Date(val.tgl_realisasi_sisa_produksi))
                        .defaultDMY
                    : '-'}
                </Td>
                <TdFixed>{val?.no_realisasi_sisa_produksi ?? '-'}</TdFixed>
                <Td>{val?.nama_item ?? '-'}</Td>
                <Td>{val?.nama_gudang_asal ?? '-'}</Td>
                <Td>{val?.nama_gudang_tujuan ?? '-'}</Td>
                <Td>
                  <div className="text-right">{`${
                    val?.qty_realisasi?.replace('.', ',') ?? 0
                  } ${val?.nama_satuan ?? ''}`}</div>
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(parseInt(val?.harga_satuan_sisa_produksi || 0))
                    ).detail
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(
                        parseInt(val.harga_satuan_sisa_produksi) *
                          val.qty_realisasi
                      )
                    ).detail
                  }
                </Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push(
                '/inventory/transaksi/realisasi-sisa-produksi/list-transfer-sisa-produksi'
              );
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
          // CLEAR HISTORY LOCATION
          history.replace('/inventory/transaksi/realisasi-sisa-produksi', {
            registerAlertConfig: undefined,
          });
        }}
      />

      {/* Table Section */}
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      {showModal && <DetailModal />}
    </CRUDLayout>
  );
};

export default RealisasiSisaProduksi;
