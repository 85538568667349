import { DateConvert } from 'utilities';

export const removeItemTable = ({ data = [], uniqueKey, values }) => {
  const keysToRemove = [
    'tgl_transfer_produksi_bahan',
    'no_transfer_produksi_bahan',
    'id_item_buaso',
    'nama_item_buaso',
    'id_gudang_tujuan',
    'nama_gudang_tujuan',
    'id_gudang_asal',
    'nama_gudang_asal',
    'qty_available',
    'qty_transfer',
    'nama_satuan',
    'id_karyawan',
    'diserahkan',
    'keterangan',
  ];
  const tempData = [...data];

  const findIndex = tempData.findIndex(
    (dt) => dt[uniqueKey] === values[uniqueKey]
  );

  keysToRemove.forEach((key) => delete tempData[findIndex][key]);

  return tempData;
};

export const formatValues = (dataPermintaan) => {
  const { hardwood, plywood, penunjang, finishing } = dataPermintaan;

  const filterTransferValue = (data) =>
    data?.filter((v) => Boolean(v.no_transfer_produksi_bahan));

  const mapTransferValue = (data, ref_bahan) =>
    data?.map((v) => ({
      ref_bahan,
      no_transfer_produksi_bahan: v.no_transfer_produksi_bahan,
      tgl_transfer_produksi_bahan: DateConvert(v.tgl_transfer_produksi_bahan)
        .default,
      id_permintaan_produksi: v.id_permintaan_produksi,
      id_item_buaso: v.id_item_buaso,
      id_gudang_asal: v.id_gudang_asal,
      id_gudang_tujuan: v.id_gudang_tujuan,
      qty_transfer: v.qty_transfer,
      id_karyawan: v.id_karyawan,
      id_permintaan_produksi_bahan: v.id_permintaan_produksi_bahan,
      keterangan: v.keterangan,
    })) ?? [];

  const finalValue = {
    hardwood: mapTransferValue(filterTransferValue(hardwood), 'bhw'),
    plywood: mapTransferValue(filterTransferValue(plywood), 'bpw'),
    factory_supply: mapTransferValue(filterTransferValue(penunjang), 'bfs'),
    finishing: mapTransferValue(filterTransferValue(finishing), 'bpf'),
  };

  return finalValue;
};

export const formatUpdateStatusPermintaan = (dataPermintaan) => {
  const { hardwood, plywood, penunjang, factory } = dataPermintaan;

  const result = {
    hardwood: hardwood?.map((hw) => ({
      id_permintaan_produksi_hardwood: hw.id_permintaan_produksi_hardwood,
      status_permintaan: hw.status_permintaan,
    })),

    plywood: plywood?.map((pw) => ({
      id_permintaan_produksi_plywood: pw.id_permintaan_produksi_plywood,
      status_permintaan: pw.status_permintaan,
    })),

    finishing: factory?.concat(penunjang)?.map((fs) => ({
      id_permintaan_produksi_finishing:
        fs.id_permintaan_produksi_finishing ??
        fs.id_permintaan_produksi_fs ??
        fs.id_permintaan_produksi_bpf,
      status_permintaan: fs.status_permintaan,
    })),
  };

  return result;
};
