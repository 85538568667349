import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { useContext } from 'react';
import { DropdownContext } from '../Context';

export const ModalFilterConvertBarang = ({
  show,
  setShow,
  filter,
  setFilter,
}) => {
  const defaultDropdown = { value: null, label: 'Semua' };

  const { dropdownItems, dropdownGudang, dropdownApproval } =
    useContext(DropdownContext);

  const optItems = [defaultDropdown].concat(dropdownItems?.data);

  const optGudang = [defaultDropdown].concat(dropdownGudang.data);

  const optApproval = [defaultDropdown].concat(dropdownApproval);

  const initialValues = {
    tgl_convert_barang_start: filter?.tgl_convert_barang_start,
    tgl_convert_barang_end: filter?.tgl_convert_barang_end,
    id_item_buaso: filter?.id_item_buaso,
    id_item_barang: filter?.id_item_barang,
    id_gudang: filter?.id_gudang,
    status_approval: filter?.status_approval,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_convert_barang_start: undefined,
      tgl_convert_barang_end: undefined,
      id_item_buaso: undefined,
      id_item_barang: undefined,
      id_gudang: undefined,
      status_approval: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Convert Barang"
            placeholderText="Pilih Tanggal Convert Barang"
            startDate={
              values.tgl_convert_barang_start
                ? new Date(values.tgl_convert_barang_start)
                : ''
            }
            endDate={
              values.tgl_convert_barang_end
                ? new Date(values.tgl_convert_barang_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_convert_barang_start', startDate);
              setFieldValue('tgl_convert_barang_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Item Yang Dikonversi"
            placeholder="Pilih Item Yang Dikonversi"
            loading={dropdownItems.isLoading}
            option={optItems}
            defaultValue={
              values.id_item_buaso
                ? optItems?.find((v) => values.id_item_buaso === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_item_buaso', value)}
          />

          <SelectSearch
            label="Gudang Asal"
            placeholder="Pilih Gudang Asal"
            option={optGudang}
            defaultValue={
              values.id_gudang
                ? optGudang?.find((v) => values.id_gudang === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_gudang', value)}
          />

          <SelectSearch
            label="Item Hasil Konversi"
            placeholder="Pilih Item Hasil Konversi"
            loading={dropdownItems.isLoading}
            option={optItems}
            defaultValue={
              values.id_item_barang
                ? optItems?.find((v) => values.id_item_barang === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_item_barang', value)}
          />

          <SelectSearch
            label="Status Approval"
            placeholder="Pilih Status Approval"
            option={optApproval}
            defaultValue={
              values.status_approval
                ? optApproval.find((v) => values.status_approval === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('status_approval', value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
