import React, { useState, useEffect } from 'react';
import {
  CRUDLayout,
  Input,
  InputSearch,
  InputBackLabel,
  SelectSearch,
  ExportButton,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Select,
  Alert,
  Switch,
  Pagination,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
} from '../../../components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import { RegItemBahanApi } from 'api';
import { PageNumber, TableNumber } from 'utilities';
import { Modal } from 'react-bootstrap';
import FileSaver from 'file-saver';
import { useIsGuest } from 'hooks';
import axios from 'axios';

const { REACT_APP_EXPORT_BASE_URL } = process.env;

const RegistrasiItemBahan = ({ setNavbarTitle }) => {
  const title = 'Item Barang';
  const isGuest = useIsGuest();
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState('');
  const [updateData, setUpdateData] = useState([]);
  const [deleteData, setDeleteData] = useState([]);

  const [kodeItem, setKodeItem] = useState([]);
  const [buasoData, setBuasoData] = useState([]);
  const [bahanData, setBahanData] = useState([]);
  const [satuanData, setSatuanData] = useState([]);
  const [kelompokData, setKelompokData] = useState([]);
  const [pabrikanData, setPabrikanData] = useState([]);
  const [dimensiData, setDimensiData] = useState([
    { dimensi: '0', nama_dimensi: 'Non Dimensi' },
    { dimensi: '1', nama_dimensi: '1 Dimensi' },
    { dimensi: '2', nama_dimensi: '2 Dimensi' },
    { dimensi: '3', nama_dimensi: '3 Dimensi' },
  ]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const fetchDropdown = () => {
    axios
      .all([
        RegItemBahanApi.getBuaso(),
        // RegItemBahanApi.getBahan(),
        RegItemBahanApi.getSatuan(),
        RegItemBahanApi.getKelompok(),
        RegItemBahanApi.getPabrikan(),
      ])
      .then(
        axios.spread(
          (
            buaso,
            // bahan,
            satuan,
            kelompok,
            pabrikan
          ) => {
            setBuasoData(buaso.data.data);
            // setBahanData(bahan.data.data);
            setSatuanData(satuan.data.data);
            setKelompokData(kelompok.data.data);
            setPabrikanData(pabrikan.data.data);
          }
        )
      );
  };

  const getData = () => {
    setIsLoading(true);

    Axios.all([
      RegItemBahanApi.getPage(page, dataLength, searchKey),
      RegItemBahanApi.generateKodeItem(),
    ])
      .then(
        Axios.spread((res, kodeItem) => {
          setData(res.data.data);
          setKodeItem(kodeItem.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  const getExportData = () => {
    setIsLoading(true);
    RegItemBahanApi.export()
      .then((val) => {
        const urlFile = REACT_APP_EXPORT_BASE_URL + val.data.file;
        FileSaver.saveAs(urlFile, val.data.file);
      })
      .catch((res) => alert(res))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const changeDataStatus = (status, id) => {
    setShowAlert(false);

    const value = {
      id_item_buaso: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
      setShowAlert(true);
    };

    status === true
      ? RegItemBahanApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : RegItemBahanApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  useEffect(() => {
    setNavbarTitle(title);
    fetchDropdown();
  }, [setNavbarTitle]);

  useEffect(() => {
    getData();
  }, [page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_satuan_pakai: Yup.string().required('Pilih Satuan Pakai'),
    id_kelompok: Yup.string().required('Pilih Kelompok'),
    id_jenis: Yup.string().required('Pilih Jenis'),
    id_satuan_beli: Yup.string().required('Pilih Satuan Beli'),
    id_pabrikan: Yup.string().required('Pilih Pabrikan'),
    dimensi: Yup.string().required('Masukkan Dimensi'),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    const [jenisData, setJenisData] = useState([]);
    const [isFetchingJenis, setIsFetchingJenis] = useState(false);

    const getDataJenis = (id) => {
      if (id) {
        setIsFetchingJenis(true);
        RegItemBahanApi.getJenis(id)
          .then((res) => setJenisData(res.data.data))
          .catch(({ response }) =>
            window.alert(
              response.data.message ?? 'Tidak dapat memuat data Jenis'
            )
          )
          .finally(() => setIsFetchingJenis(false));
      }
    };

    const decimalConvert = (value) => {
      const newValue = value.toString().replace(/[^0-9\.]/g, '');
      const convert = newValue.substring(0, 7);

      return convert;
    };

    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_buaso: '2', // SET ID BUASO BAHAN
      kode_item: kodeItem,
      nama_item: '',
      keterangan: '',
      id_satuan_pakai: '',
      id_kelompok: '',
      id_jenis: '',
      tipe: '',
      id_satuan_beli: '',
      id_pabrikan: '',
      dimensi: '',
      pj: 0,
      lb: 0,
      tb: 0,
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      /* 
            CEK DIMENSI
            JIKA 3 DIMENSI MAKA GUNAKAN VALUE PJ, LB, TB
            JIKA 2 DIMENSI MAKA SET VALUE TB = 0
            JIKA 1 DIMENSI MAKA SET VALUE LB, TB = 0
            */
      if (values.dimensi == 0) {
        values.pj = 0;
        values.lb = 0;
        values.tb = 0;
      } else if (values.dimensi == 1) {
        values.lb = 0;
        values.tb = 0;
      } else if (values.dimensi == 2) {
        values.tb = 0;
      }
      let finalValues = {
        ...values,
        id_satuan: values.id_satuan_pakai,
      };
      RegItemBahanApi.create(finalValues)
        .then((res) => {
          setAlertConfig({
            variant: 'primary',
            text: 'Tambah data berhasil!',
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsCreateForm(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <CreateModal
        size="xl"
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="container">
                <Modal.Body>
                  <div className="row ">
                    <div className="col-md-6 col-sm-12">
                      <Select
                        label="BUASO"
                        name="id_buaso"
                        onChange={handleChange}
                        errorText={errors.id_buaso}
                        defaultValue={values.id_buaso} // ISI DEFAULT VALUE DENGAN id_buaso yang memiliki value bahan
                        disabled={true}
                      >
                        {/* OPTIONS */}
                        {buasoData.map((e) => (
                          <option key={e.id_buaso} value={e.id_buaso}>
                            {e.kode_buaso} - {e.nama_buaso}
                          </option>
                        ))}
                      </Select>

                      <Input
                        label="Kode Bahan"
                        type="text"
                        name="kode_item"
                        placeholder="Kode Item"
                        value={values.kode_item}
                        // readOnly={true}
                        onChange={handleChange}
                      />

                      <Input
                        label="Nama Item"
                        type="text"
                        name="nama_item"
                        placeholder="Nama Item"
                        value={values.nama_item}
                        onChange={handleChange}
                        error={errors.nama_item && touched.nama_item && true}
                        errorText={errors.nama_item}
                      />

                      <SelectSearch
                        label="Kelompok Barang"
                        name="id_kelompok"
                        placeholder="Pilih Kelompok"
                        onChange={(val) => {
                          setFieldValue('id_kelompok', val.value);
                          getDataJenis(val.value);
                        }}
                        option={kelompokData.map((val) => {
                          return {
                            value: val.id_kelompok,
                            label: val.nama_kelompok,
                          };
                        })}
                        defaultValue=""
                        error={
                          errors.id_kelompok && touched.id_kelompok && true
                        }
                        errorText={
                          errors.id_kelompok &&
                          touched.id_kelompok &&
                          errors.id_kelompok
                        }
                      />

                      <SelectSearch
                        key={values.id_kelompok}
                        loading={isFetchingJenis}
                        isDisabled={values.id_kelompok ? false : true}
                        label="Jenis Barang"
                        name="id_jenis"
                        placeholder={
                          values.id_kelompok
                            ? 'Pilih Jenis'
                            : 'Pilih Kelompok Terlebih Dahulu'
                        }
                        onChange={(val) => setFieldValue('id_jenis', val.value)}
                        option={jenisData.map((val) => {
                          return {
                            value: val.id_jenis,
                            label: val.nama_jenis,
                          };
                        })}
                        defaultValue=""
                        error={errors.id_jenis && touched.id_jenis && true}
                        errorText={
                          errors.id_jenis && touched.id_jenis && errors.id_jenis
                        }
                      />

                      <Input
                        label="Keterangan"
                        type="text"
                        name="keterangan"
                        placeholder="Keterangan"
                        value={values.keterangan}
                        onChange={handleChange}
                        error={errors.keterangan && touched.keterangan && true}
                        errorText={errors.keterangan}
                      />
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <SelectSearch
                        label="Pabrikan"
                        name="id_pabrikan"
                        placeholder="Pilih Pabrikan"
                        onChange={(val) =>
                          setFieldValue('id_pabrikan', val.value)
                        }
                        option={pabrikanData.map((val) => {
                          return {
                            value: val.id_pabrikan,
                            label: val.nama_pabrikan,
                          };
                        })}
                        defaultValue=""
                        error={
                          errors.id_pabrikan && touched.id_pabrikan && true
                        }
                        errorText={
                          errors.id_pabrikan &&
                          touched.id_pabrikan &&
                          errors.id_pabrikan
                        }
                      />

                      <SelectSearch
                        label="Satuan Pakai"
                        name="id_satuan_pakai"
                        placeholder="Pilih Satuan Pakai"
                        onChange={(val) => {
                          setFieldValue('id_satuan_pakai', val.value);
                        }}
                        // MAPPING OPTIONS
                        // OPTIONS BERUPA VALUE DAN LABEL
                        option={satuanData.map((val) => {
                          return {
                            value: val.id_satuan,
                            label: val.nama_satuan,
                          };
                        })}
                        defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        error={
                          errors.id_satuan_pakai &&
                          touched.id_satuan_pakai &&
                          true
                        }
                        errorText={
                          errors.id_satuan_pakai &&
                          touched.id_satuan_pakai &&
                          errors.id_satuan_pakai
                        }
                      />

                      <SelectSearch
                        label="Satuan Beli"
                        name="id_satuan_beli"
                        placeholder="Pilih Satuan Beli"
                        onChange={(val) => {
                          setFieldValue('id_satuan_beli', val.value);
                        }}
                        // MAPPING OPTIONS
                        // OPTIONS BERUPA VALUE DAN LABEL
                        option={satuanData.map((val) => {
                          return {
                            value: val.id_satuan,
                            label: val.nama_satuan,
                          };
                        })}
                        defaultValue=""
                        error={
                          errors.id_satuan_beli &&
                          touched.id_satuan_beli &&
                          true
                        }
                        errorText={
                          errors.id_satuan_beli &&
                          touched.id_satuan_beli &&
                          errors.id_satuan_beli
                        }
                      />

                      <SelectSearch
                        label="Dimensi"
                        name="dimensi"
                        placeholder="Pilih Dimensi"
                        onChange={(val) => {
                          setFieldValue('dimensi', val.value);
                        }}
                        // MAPPING OPTIONS
                        // OPTIONS BERUPA VALUE DAN LABEL
                        option={dimensiData.map((val) => {
                          return {
                            value: val.dimensi,
                            label: val.nama_dimensi,
                          };
                        })}
                        defaultValue=""
                        error={errors.dimensi && touched.dimensi && true}
                        errorText={
                          errors.dimensi && touched.dimensi && errors.dimensi
                        }
                      />

                      <div className="row">
                        <div className="col-md-4">
                          <InputBackLabel
                            readOnly={
                              values.dimensi === '1' ||
                              values.dimensi === '2' ||
                              values.dimensi === '3'
                                ? false
                                : true
                            }
                            type="text"
                            label="Panjang"
                            name="pj"
                            backLabel="cm"
                            min={0}
                            value={
                              values.dimensi === '1' ||
                              values.dimensi === '2' ||
                              values.dimensi === '3'
                                ? values.pj
                                : 0
                            }
                            onChange={(val) => {
                              const value = decimalConvert(val.target.value);
                              setFieldValue('pj', value);
                            }}
                            error={errors.pj && touched.pj && true}
                            errorText={errors.pj}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputBackLabel
                            readOnly={
                              values.dimensi === '2' || values.dimensi === '3'
                                ? false
                                : true
                            }
                            type="text"
                            label="Lebar"
                            name="lb"
                            backLabel="cm"
                            min={0}
                            value={
                              values.dimensi === '2' || values.dimensi === '3'
                                ? values.lb
                                : 0
                            }
                            onChange={(val) => {
                              const value = decimalConvert(val.target.value);
                              setFieldValue('lb', value);
                            }}
                            error={errors.lb && touched.lb && true}
                            errorText={errors.lb}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputBackLabel
                            readOnly={values.dimensi === '3' ? false : true}
                            type="text"
                            step="1"
                            label="Tebal"
                            name="tb"
                            backLabel="cm"
                            min={0}
                            value={values.dimensi === '3' ? values.tb : 0}
                            onChange={(val) => {
                              const value = decimalConvert(val.target.value);
                              setFieldValue('tb', value);
                            }}
                            error={errors.tb && touched.tb && true}
                            errorText={errors.tb}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="primary"
                      text="Tambah"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </Modal.Footer>
              </div>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const [jenisData, setJenisData] = useState([]);
    const [isFetchingJenis, setIsFetchingJenis] = useState(false);

    const getDataJenis = (id) => {
      if (id !== undefined) {
        setIsFetchingJenis(true);
        RegItemBahanApi.getJenis(id)
          .then((res) => setJenisData(res.data.data))
          .catch((err) => setJenisData([]))
          .finally(() => setIsFetchingJenis(false));
      } else {
        setIsFetchingJenis(true);
        RegItemBahanApi.getJenis(updateData.id_kelompok)
          .then((res) => setJenisData(res.data.data))
          .catch((err) => setJenisData([]))
          .finally(() => setIsFetchingJenis(false));
      }
    };

    useEffect(() => {
      getDataJenis();
      return () => {};
    }, []);

    const decimalConvert = (value) => {
      const newValue = value.toString().replace(/[^0-9\.]/g, '');
      const convert = newValue.substring(0, 7);

      return convert;
    };

    const formInitialValues = {
      id_buaso: updateData.id_buaso, // SET ID BUASO BAHAN
      kode_item: updateData.kode_item,
      nama_item: updateData.nama_item,
      keterangan: updateData.keterangan,
      id_satuan_pakai: updateData.id_satuan_pakai,
      id_kelompok: updateData.id_kelompok,
      id_jenis: updateData.id_jenis,
      tipe: updateData.tipe,
      id_satuan_beli: updateData.id_satuan_beli,
      id_pabrikan: updateData.id_pabrikan,
      dimensi: updateData.dimensi,
      pj: updateData.pj,
      lb: updateData.lb,
      tb: updateData.tb,
      nama_item_pure: updateData.nama_item_pure,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      /* 
            CEK DIMENSI
            JIKA 3 DIMENSI MAKA GUNAKAN VALUE PJ, LB, TB
            JIKA 2 DIMENSI MAKA SET VALUE TB = 0
            JIKA 1 DIMENSI MAKA SET VALUE LB, TB = 0
            */
      if (values.dimensi == 0) {
        values.pj = 0;
        values.lb = 0;
        values.tb = 0;
      } else if (values.dimensi == 1) {
        values.lb = 0;
        values.tb = 0;
      } else if (values.dimensi == 2) {
        values.tb = 0;
      }

      const finalValues = {
        id_item_buaso: updateData.id_item_buaso,
        ...values,
        id_satuan: values.id_satuan_pakai,
      };

      RegItemBahanApi.update(finalValues)
        .then((res) => {
          setAlertConfig({
            variant: 'primary',
            text: 'Ubah data berhasil!',
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Ubah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsUpdateForm(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <UpdateModal
        size="xl"
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="container">
                <Modal.Body>
                  <div className="row ">
                    <div className="col-md-6 col-sm-12">
                      <Select
                        label="BUASO"
                        name="id_buaso"
                        onChange={handleChange}
                        errorText={errors.id_buaso}
                        defaultValue={values.id_buaso} // ISI DEFAULT VALUE DENGAN id_buaso yang memiliki value bahan
                        disabled={true}
                      >
                        {/* OPTIONS */}
                        {buasoData.map((e) => (
                          <option key={e.id_buaso} value={e.id_buaso}>
                            {e.kode_buaso} - {e.nama_buaso}
                          </option>
                        ))}
                      </Select>

                      <Input
                        label="Kode Bahan"
                        type="text"
                        name="kode_item"
                        placeholder="Kode Item"
                        value={values.kode_item}
                        onChange={handleChange}
                      />

                      <Input
                        label="Nama Item"
                        type="text"
                        name="nama_item"
                        placeholder="Nama Item"
                        value={values.nama_item}
                        onChange={handleChange}
                        error={errors.nama_item && touched.nama_item && true}
                        errorText={errors.nama_item}
                      />

                      <SelectSearch
                        label="Kelompok Barang"
                        name="id_kelompok"
                        placeholder="Pilih Kelompok"
                        onChange={(val) => {
                          setFieldValue('id_kelompok', val.value);
                          setFieldValue('id_jenis', '');
                          getDataJenis(val.value);
                        }}
                        option={kelompokData.map((val) => {
                          return {
                            value: val.id_kelompok,
                            label: val.nama_kelompok,
                          };
                        })}
                        defaultValue={{
                          value: updateData.id_kelompok ?? '',
                          label:
                            updateData.id_kelompok === ''
                              ? 'Pilih Kelompok'
                              : kelompokData.find(
                                  (val) =>
                                    val.id_kelompok === updateData.id_kelompok
                                )?.nama_kelompok ?? 'Pilih Kelompok',
                        }}
                        error={
                          errors.id_kelompok && touched.id_kelompok && true
                        }
                        errorText={
                          errors.id_kelompok &&
                          touched.id_kelompok &&
                          errors.id_kelompok
                        }
                      />

                      <SelectSearch
                        key={values.id_kelompok}
                        loading={isFetchingJenis}
                        isDisabled={values.id_kelompok ? false : true}
                        label="Jenis Barang"
                        name="id_jenis"
                        placeholder="Pilih Jenis"
                        onChange={(val) => setFieldValue('id_jenis', val.value)}
                        option={jenisData.map((val) => {
                          return {
                            value: val.id_jenis,
                            label: val.nama_jenis,
                          };
                        })}
                        defaultValue={
                          updateData.id_jenis
                            ? jenisData
                                .map((data) => ({
                                  value: data.id_jenis,
                                  label: data.nama_jenis,
                                }))
                                .find((data) => data.value === values.id_jenis)
                            : null
                        }
                        error={errors.id_jenis && touched.id_jenis && true}
                        errorText={
                          errors.id_jenis && touched.id_jenis && errors.id_jenis
                        }
                      />

                      <Input
                        label="Keterangan"
                        type="text"
                        name="keterangan"
                        placeholder="Keterangan"
                        value={values.keterangan}
                        onChange={handleChange}
                        error={errors.keterangan && touched.keterangan && true}
                        errorText={errors.keterangan}
                      />
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <SelectSearch
                        label="Pabrikan"
                        name="id_pabrikan"
                        placeholder="Pilih Pabrikan"
                        onChange={(val) => {
                          setFieldValue('id_pabrikan', val.value);
                        }}
                        option={pabrikanData.map((val) => {
                          return {
                            value: val.id_pabrikan,
                            label: val.nama_pabrikan,
                          };
                        })}
                        defaultValue={{
                          value: values.id_pabrikan,
                          label: values.id_pabrikan
                            ? pabrikanData.find(
                                (val) => val.id_pabrikan === values.id_pabrikan
                              )?.nama_pabrikan ?? 'Pilih Pabrikan'
                            : 'Pilih Pabrikan',
                        }}
                        error={
                          errors.id_pabrikan && touched.id_pabrikan && true
                        }
                        errorText={
                          errors.id_pabrikan &&
                          touched.id_pabrikan &&
                          errors.id_pabrikan
                        }
                      />

                      <SelectSearch
                        label="Satuan Pakai"
                        name="id_satuan_pakai"
                        placeholder="Pilih Satuan Pakai"
                        onChange={(val) => {
                          setFieldValue('id_satuan_pakai', val.value);
                        }}
                        // MAPPING OPTIONS
                        // OPTIONS BERUPA VALUE DAN LABEL
                        option={satuanData.map((val) => {
                          return {
                            value: val.id_satuan,
                            label: val.nama_satuan,
                          };
                        })}
                        // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                        defaultValue={{
                          value: updateData.id_satuan_pakai ?? '',
                          label:
                            updateData.id_satuan_pakai === ''
                              ? 'Pilih Data Satuan Pakai'
                              : updateData.satuan_pakai,
                        }}
                        error={
                          errors.id_satuan_pakai &&
                          touched.id_satuan_pakai &&
                          true
                        }
                        errorText={
                          errors.id_satuan_pakai &&
                          touched.id_satuan_pakai &&
                          errors.id_satuan_pakai
                        }
                      />

                      <SelectSearch
                        label="Satuan Beli"
                        name="id_satuan_beli"
                        placeholder="Pilih Satuan Beli"
                        onChange={(val) => {
                          setFieldValue('id_satuan_beli', val.value);
                        }}
                        // MAPPING OPTIONS
                        // OPTIONS BERUPA VALUE DAN LABEL
                        option={satuanData.map((val) => {
                          return {
                            value: val.id_satuan,
                            label: val.nama_satuan,
                          };
                        })}
                        defaultValue={{
                          value: updateData.id_satuan_beli ?? '',
                          label:
                            updateData.id_satuan_beli === ''
                              ? 'Pilih Satuan'
                              : satuanData.find(
                                  (val) =>
                                    val.id_satuan === updateData.id_satuan_beli
                                )?.nama_satuan ?? 'Pilih Satuan',
                        }}
                        error={
                          errors.id_satuan_beli &&
                          touched.id_satuan_beli &&
                          true
                        }
                        errorText={
                          errors.id_satuan_beli &&
                          touched.id_satuan_beli &&
                          errors.id_satuan_beli
                        }
                      />

                      <SelectSearch
                        label="Dimensi"
                        name="dimensi"
                        placeholder="Pilih Dimensi"
                        onChange={(val) => {
                          setFieldValue('dimensi', val.value);
                        }}
                        // MAPPING OPTIONS
                        // OPTIONS BERUPA VALUE DAN LABEL
                        option={dimensiData.map((val) => {
                          return {
                            value: val.dimensi,
                            label: val.nama_dimensi,
                          };
                        })}
                        defaultValue={{
                          value: updateData.dimensi ?? '',
                          label:
                            updateData.dimensi === ''
                              ? 'Pilih Dimensi'
                              : dimensiData.find(
                                  (val) => val.dimensi === updateData.dimensi
                                ).nama_dimensi,
                        }}
                        error={errors.dimensi && touched.dimensi && true}
                        errorText={
                          errors.dimensi && touched.dimensi && errors.dimensi
                        }
                      />

                      <div className="row">
                        <div className="col-md-4">
                          <InputBackLabel
                            readOnly={
                              values.dimensi === '1' ||
                              values.dimensi === '2' ||
                              values.dimensi === '3'
                                ? false
                                : true
                            }
                            type="text"
                            label="Panjang"
                            name="pj"
                            backLabel="cm"
                            min={0}
                            value={
                              values.dimensi === '1' ||
                              values.dimensi === '2' ||
                              values.dimensi === '3'
                                ? values.pj
                                : 0
                            }
                            onChange={(val) => {
                              const value = decimalConvert(val.target.value);
                              setFieldValue('pj', value);
                            }}
                            error={errors.pj && touched.pj && true}
                            errorText={errors.pj}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputBackLabel
                            readOnly={
                              values.dimensi === '2' || values.dimensi === '3'
                                ? false
                                : true
                            }
                            type="text"
                            label="Lebar"
                            name="lb"
                            backLabel="cm"
                            min={0}
                            value={
                              values.dimensi === '2' || values.dimensi === '3'
                                ? values.lb
                                : 0
                            }
                            onChange={(val) => {
                              const value = decimalConvert(val.target.value);
                              setFieldValue('lb', value);
                            }}
                            error={errors.lb && touched.lb && true}
                            errorText={errors.lb}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputBackLabel
                            readOnly={values.dimensi === '3' ? false : true}
                            type="text"
                            step="0.1"
                            label="Tebal"
                            name="tb"
                            backLabel="cm"
                            min={0}
                            value={values.dimensi === '3' ? values.tb : 0}
                            onChange={(val) => {
                              const value = decimalConvert(val.target.value);
                              setFieldValue('tb', value);
                            }}
                            error={errors.tb && touched.tb && true}
                            errorText={errors.tb}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="success"
                      text="Ubah"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </Modal.Footer>
              </div>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_buaso: deleteData.id_item_buaso };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      RegItemBahanApi.delete(deleteValue)
        .then(() => {
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal!(${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsDeleteData(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Bahan : {deleteData.kode_item}</div>
        <div>Nama Item Bahan : {deleteData.nama_item}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              {!isGuest && <ThFixed>Aksi</ThFixed>}
              <Th>Kode Bahan</Th>
              <Th>Name Item Barang</Th>
              <Th>Kelompok</Th>
              <Th>Jenis</Th>
              <Th>Satuan Pakai</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  {!isGuest && (
                    <Td>
                      <div className="d-flex justify-content-center">
                        <UpdateButton
                          onClick={() => {
                            setUpdateData(val);
                            setIsUpdateForm(true);
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setDeleteData(val);
                            setIsDeleteData(true);
                          }}
                        />

                        <Switch
                          id={val.id_item_buaso}
                          checked={val.is_hidden ? false : true}
                          onChange={() =>
                            changeDataStatus(val.is_hidden, val.id_item_buaso)
                          }
                        />
                      </div>
                    </Td>
                  )}
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.nama_jenis}</Td>
                  <Td>{val.satuan_pakai}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index === data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index === 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <ExportButton onClick={() => getExportData()} />
          <CreateButton
            onClick={() => {
              setUpdateData({ id_item_buaso: 0 });
              setIsCreateForm(true);
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      {isCreateForm && <TambahModal />}
      {isUpdateForm && <UbahModal />}
      <HapusModal />
    </CRUDLayout>
  );
};

export default RegistrasiItemBahan;
