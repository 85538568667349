import { Card, Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';

export const InfoDetailJobOrder = ({ data = {} }) => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <InfoItemHorizontal label="No. SPK" text={data.no_spk} />
            <InfoItemHorizontal label="No. RAP" text={data.no_rap} />
            <InfoItemHorizontal label="Customer" text={data.nama_customer} />
            <InfoItemHorizontal label="Proyek" text={data.nama_proyek} />
            <InfoItemHorizontal
              label="Tgl. Permintaan Produksi"
              text={
                data.tgl_permintaan_produksi
                  ? DateConvert(new Date(data.tgl_permintaan_produksi)).detail
                  : '-'
              }
            />
            <InfoItemHorizontal
              label="No. Permintaan Produksi"
              text={data.no_permintaan_produksi}
            />
          </Col>

          <Col>
            <InfoItemHorizontal
              label="Tgl. Job Order"
              text={
                data.tgl_job_order
                  ? DateConvert(new Date(data.tgl_job_order)).detail
                  : '-'
              }
            />
            <InfoItemHorizontal
              label="No. Job Order"
              text={data.no_job_order}
            />
            <InfoItemHorizontal
              label="Item Job Order"
              text={`${data.nama_barang_jadi} ${
                data.qty_rap ? parseFloat(data.qty_rap) : ''
              } ${data.nama_satuan ?? ''}`}
            />
            <InfoItemHorizontal
              label="Tgl. Pengerjaan Job Order"
              text={`${
                data.tgl_permintaan_produksi
                  ? DateConvert(new Date(data.tgl_permintaan_produksi)).detail
                  : '-'
              } s/d ${
                data.tgl_selesai
                  ? DateConvert(new Date(data.tgl_selesai)).detail
                  : '-'
              }`}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
