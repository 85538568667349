/* eslint-disable */

import Logo from '../assets/image/LogoSadhana.png';
import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';

import {
  // Buaso
  Buaso,
  Satuan,
  Kelompok,
  Jenis,
  Pabrikan,
  Gudang,

  // Registrasi BUASO
  RegistrasiItemBahan,

  // Delivery Order
  DeliveryOrder,
  ListSo,
  TambahDeliveryOrder,
  EditDeliveryOrder,
  DetailDeliveryOrder,

  // Purchase Request
  PurchaseRequest,
  TambahPurchaseRequest,
  UbahPurchaseRequest,

  // Surat Jalan
  ListSuratJalan,
  ListDeliveryOrderSuratJalan,
  TambahSuratJalan,
  UbahSuratJalan,
  DetailSuratJalan,

  // Pengembalian Barang
  PengembalianBarang,
  TambahPengembalianBarang,
  EditPengembalianBarang,
  DetailPengembalianBarang,

  // Mapping Unit Produksi
  MappingUnitProduksi,

  // Penerimaan & Pembatalan Retur
  ListFakturDiBatalkan,
  PenerimaanRetur,
  ListReturPenjulan,
  TambahPenerimaanRetur,
  EditPenerimaanRetur,
  DetailPenerimaanRetur,

  // Penerimaan Barang
  PenerimaanBarang,
  ListPurchaseOrder,
  TambahPenerimaanBarang,
  UbahPenerimaanBarang,

  // Permintaan Bahan Produksi
  PermintaanBahanProduksi,
  DataPermintaanBahanProduksi,
  CetakPermintaanBahanProduksi,
  DetailCetakPermintaanProduksi,

  // Realisasi Sisa produksi
  RealisasiSisaProduksi,
  ListTransferSisaProduksi,

  // Penerimaan Surat Jalan
  PenerimaanSuratJalan,

  // Mutasi Antar Gudang
  MutasiAntarGudang,

  // Transfer Prelim
  ListTransferPrelim,
  ListPermintaanTransferPrelim,
  TambahTransferPrelim,
  DetailSPKSection,

  // Setup Stok Awal
  SetupStokAwal,

  // Convert Barang
  ListConvertItemBarang,
  TambahConvertBarang,
  UpdateConvertBarang,
  DetailConvertBarang,

  // Laporan
  KartuStockMaterial,
  LaporanPurchaseRequest,
  LaporanDeliveryOrder,
  StokTerbaru,
  LaporanPenerimaanBarang,
  TransferBahanProduksiList,
  TBPPermintaanProduksiList,
  TambahTransferBahanProduksi,
} from 'pages/Inventory';

export default {
  LOGO: Logo,
  MODUL: 'Inventory',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'INV'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['SUPA', 'INV'],
      menu: [
        {
          text: 'BUASO',
          link: '/inventory/master/buaso',
          hak: ['SUPA', 'INV_MAS_BUA'],
        },
        {
          text: 'Satuan',
          link: '/inventory/master/satuan',
          hak: ['SUPA', 'INV_MAS_SAT'],
        },
        {
          text: 'Kelompok Bahan',
          link: '/inventory/master/kelompok-bahan',
          hak: ['SUPA', 'INV_MAS_KEL'],
        },
        {
          text: 'Jenis Bahan',
          link: '/inventory/master/jenis-bahan',
          hak: ['SUPA', 'INV_MAS_JEN'],
        },
        {
          text: 'Pabrikan',
          link: '/inventory/master/pabrikan',
          hak: ['SUPA', 'INV_MAS_PAB'],
        },
        {
          text: 'Gudang',
          link: '/inventory/master/gudang',
          hak: ['SUPA', 'INV_MAS_GUD'],
        },
        {
          text: 'Item Barang',
          link: '/inventory/transaksi/register-item-bahan',
          hak: ['SUPA', 'INV_REG_BHN'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: [
        'SUPA',
        'INV',
        'INV_TRN_PR',
        'INV_TRN_DO',
        'INV_TRN_SJ',
        'INV_TRN_PBJ',
        'INV_TRN_TPB',
        'INV_TRN_RSP',
        'INV_TRN_PSJ',
      ],
      menu: [
        {
          text: 'Purchase Request',
          link: '/inventory/transaksi/purchase-request',
          hak: ['SUPA', 'INV_TRN_PR'],
        },

        {
          text: 'Delivery Order',
          link: '/inventory/transaksi/delivery-order',
          hak: ['SUPA', 'INV_TRN_DO'],
        },

        {
          text: 'Surat Jalan',
          link: '/inventory/transaksi/surat-jalan',
          hak: ['SUPA', 'INV_TRN_SJ'],
        },
        {
          text: 'Penerimaan Barang',
          link: '/inventory/transaksi/penerimaan-barang',
          hak: ['SUPA', 'INV_TRN_PBJ'],
        },
        {
          text: 'Transfer Bahan Produksi',
          link: '/inventory/transaksi/transfer-bahan-produksi',
          hak: ['SUPA', 'INV_TRN_TPB'],
        },
        {
          text: 'Transfer Prelim',
          link: '/inventory/transaksi/transfer-prelim',
          hak: ['SUPA', 'INV_TRN_TPL'],
        },
        {
          text: 'Realisasi Sisa Produksi',
          link: '/inventory/transaksi/realisasi-sisa-produksi',
          hak: ['SUPA', 'INV_TRN_RSP'],
        },
        {
          text: 'Penerimaan Surat Jalan',
          link: '/inventory/transaksi/penerimaan-surat-jalan',
          hak: ['SUPA', 'INV_TRN_PSJ'],
        },
        {
          text: 'Mutasi Antar Gudang',
          link: '/inventory/transaksi/mutasi-antar-gudang',
          hak: ['SUPA', 'INV_TRN_MAG'],
        },
        {
          text: 'Setup Stok Awal',
          link: '/inventory/transaksi/setup-stok-awal',
          hak: ['SUPA', 'INV_TRN_SSA'],
        },
        {
          text: 'Convert Barang',
          link: '/inventory/transaksi/convert-barang',
          hak: ['SUPA', 'INV_TRN_CVB'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentTextOutline />,
      hak: ['SUPA', 'INV'],
      menu: [
        {
          text: 'Kartu Stock Material',
          link: '/inventory/laporan/kartu-stock-material',
          hak: ['SUPA', 'INV_LAP_KST'],
        },
        {
          text: 'Stok Terbaru',
          link: '/inventory/laporan/stok-terbaru',
          hak: ['SUPA', 'INV_RPT_SU'],
        },
        {
          text: 'Purchase Request',
          link: '/inventory/laporan/purchase-request',
          hak: ['SUPA', 'INV_RPT_LPR'],
        },
        {
          text: 'Delivery Order',
          link: '/inventory/laporan/delivery-order',
          hak: ['SUPA', 'INV_RPT_DO'],
        },
        {
          text: 'Penerimaan Barang',
          link: '/inventory/laporan/penerimaan-barang',
          hak: ['SUPA', 'INV_RPT_PBJ'],
        },
      ],
    },
  ],

  ROUTES: [
    // MASTER
    {
      exact: true,
      path: '/inventory/master/buaso',
      page: Buaso,
      hak: ['SUPA', 'INV_MAS_BUA'],
    },
    {
      exact: true,
      path: '/inventory/master/satuan',
      page: Satuan,
      hak: ['SUPA', 'INV_MAS_SAT'],
    },
    {
      exact: true,
      path: '/inventory/master/kelompok-bahan',
      page: Kelompok,
      hak: ['SUPA', 'INV_MAS_KEL'],
    },
    {
      exact: true,
      path: '/inventory/master/jenis-bahan',
      page: Jenis,
      hak: ['SUPA', 'INV_MAS_JEN'],
    },
    {
      exact: true,
      path: '/inventory/master/pabrikan',
      page: Pabrikan,
      hak: ['SUPA', 'INV_MAS_PAB'],
    },
    {
      exact: true,
      path: '/inventory/master/gudang',
      page: Gudang,
      hak: ['SUPA', 'INV_MAS_GUD'],
    },

    // TRANSAKSI
    {
      exact: true,
      path: '/inventory/transaksi/mapping-unit-produksi',
      page: MappingUnitProduksi,
      hak: ['SUPA', 'INV_REG_BHN'],
    },
    // Registrasi BUASO
    {
      exact: true,
      path: '/inventory/transaksi/register-item-bahan',
      page: RegistrasiItemBahan,
      hak: ['SUPA', 'INV_REG_BHN'],
    },

    // Delivery Order
    {
      exact: true,
      path: '/inventory/transaksi/delivery-order',
      page: DeliveryOrder,
      hak: ['SUPA', 'INV_TRN_DO'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/delivery-order/list-so',
      page: ListSo,
      hak: ['SUPA', 'INV_TRN_DO'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/delivery-order/tambah-delivery-order/:id_sales_order/:id_gudang',
      page: TambahDeliveryOrder,
      hak: ['SUPA', 'INV_TRN_DO'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/delivery-order/edit-delivery-order/:id',
      page: EditDeliveryOrder,
      hak: ['SUPA', 'INV_TRN_DO'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/delivery-order/detail-delivery-order/:id',
      page: DetailDeliveryOrder,
      hak: ['SUPA', 'INV_TRN_DO'],
    },

    // Surat Jalan
    {
      exact: true,
      path: '/inventory/transaksi/surat-jalan',
      page: ListSuratJalan,
      hak: ['SUPA', 'INV_TRN_SJ'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/surat-jalan/delivery-order',
      page: ListDeliveryOrderSuratJalan,
      hak: ['SUPA', 'INV_TRN_SJ'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/surat-jalan/tambah/:id',
      page: TambahSuratJalan,
      hak: ['SUPA', 'INV_TRN_SJ'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/surat-jalan/ubah/:id',
      page: UbahSuratJalan,
      hak: ['SUPA', 'INV_TRN_SJ'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/surat-jalan/detail/:id',
      page: DetailSuratJalan,
      hak: ['SUPA', 'INV_TRN_SJ'],
    },

    // Purchase Request
    {
      exact: true,
      path: '/inventory/transaksi/purchase-request',
      page: PurchaseRequest,
      hak: ['SUPA', 'INV_TRN_PR'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/purchase-request/tambah',
      page: TambahPurchaseRequest,
      hak: ['SUPA', 'INV_TRN_PR'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/purchase-request/:id',
      page: UbahPurchaseRequest,
      hak: ['SUPA', 'INV_TRN_PR'],
    },

    // Pengembalian Barang
    {
      exact: true,
      path: '/inventory/transaksi/pengembalian-barang',
      page: PengembalianBarang,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/pengembalian-barang/faktur-dibatalkan/tambah-pengembalian-barang/:id',
      page: TambahPengembalianBarang,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/pengembalian-barang/edit-pengembalian-barang/:id',
      page: EditPengembalianBarang,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/pengembalian-barang/detail-pengembalian-barang/:id',
      page: DetailPengembalianBarang,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/pengembalian-barang/faktur-dibatalkan',
      page: ListFakturDiBatalkan,
      hak: ['SUPA', 'INV_REG_OVH'],
    },

    // Penerimaan Retur
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-retur',
      page: PenerimaanRetur,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-retur/list-faktur-penjualan/tambah-penerimaan-retur/:id',
      page: TambahPenerimaanRetur,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-retur/edit-penerimaan-retur/:id',
      page: EditPenerimaanRetur,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-retur/detail-penerimaan-retur/:id',
      page: DetailPenerimaanRetur,
      hak: ['SUPA', 'INV_REG_OVH'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-retur/list-faktur-penjualan',
      page: ListReturPenjulan,
      hak: ['SUPA', 'INV_REG_OVH'],
    },

    // Penerimaan Barang
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-barang',
      page: PenerimaanBarang,
      hak: ['SUPA', 'INV_TRN_PBJ'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-barang/list',
      page: ListPurchaseOrder,
      hak: ['SUPA', 'INV_TRN_PBJ'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-barang/tambah/:id',
      page: TambahPenerimaanBarang,
      hak: ['SUPA', 'INV_TRN_PBJ'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-barang/ubah/:id',
      page: UbahPenerimaanBarang,
      hak: ['SUPA', 'INV_TRN_PBJ'],
    },

    // Transfer Produksi Bahan
    {
      exact: true,
      path: '/inventory/transaksi/transfer-bahan-produksi',
      page: TransferBahanProduksiList,
      hak: ['SUPA', 'INV_TRN_TPB'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/transfer-bahan-produksi/permintaan-produksi-list',
      page: TBPPermintaanProduksiList,
      hak: ['SUPA', 'INV_TRN_TPB'],
    },

    {
      exact: true,
      path: '/inventory/transaksi/transfer-bahan-produksi/tambah/:id_permintaan_produksi',
      page: TambahTransferBahanProduksi,
      hak: ['SUPA', 'INV_TRN_TPB'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/transfer-bahan-produksi/cetak',
      page: CetakPermintaanBahanProduksi,
      hak: ['SUPA', 'INV_TRN_TPB'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/transfer-bahan-produksi/cetak/detail/:id',
      page: DetailCetakPermintaanProduksi,
      hak: ['SUPA', 'INV_TRN_TPB'],
    },

    // Transfer Prelim
    {
      exact: true,
      path: '/inventory/transaksi/transfer-prelim',
      page: ListTransferPrelim,
      hak: ['SUPA', 'INV_TRN_TPL'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/transfer-prelim/permintaan',
      page: ListPermintaanTransferPrelim,
      hak: ['SUPA', 'INV_TRN_TPL'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/transfer-prelim/transfer/:id',
      page: TambahTransferPrelim,
      hak: ['SUPA', 'INV_TRN_TPL'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/transfer-prelim/transfer/detail-spk/:id',
      page: DetailSPKSection,
      hak: ['SUPA', 'INV_TRN_TPL'],
    },

    // Realisasi Sisa Produksi
    {
      exact: true,
      path: '/inventory/transaksi/realisasi-sisa-produksi',
      page: RealisasiSisaProduksi,
      hak: ['SUPA', 'INV_TRN_RSP'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/realisasi-sisa-produksi/list-transfer-sisa-produksi',
      page: ListTransferSisaProduksi,
      hak: ['SUPA', 'INV_TRN_RSP'],
    },

    // Penerimaan Surat Jalan
    {
      exact: true,
      path: '/inventory/transaksi/penerimaan-surat-jalan',
      page: PenerimaanSuratJalan,
      hak: ['SUPA', 'INV_TRN_PSJ'],
    },

    // Mutasi Antar Gudang
    {
      exact: true,
      path: '/inventory/transaksi/mutasi-antar-gudang',
      page: MutasiAntarGudang,
      hak: ['SUPA', 'INV_TRN_MAG'],
    },

    // Setup Stok Awal
    {
      exact: true,
      path: '/inventory/transaksi/setup-stok-awal',
      page: SetupStokAwal,
      hak: ['SUPA', 'INV_TRN_SSA'],
    },

    // Convert Barang
    {
      exact: true,
      path: '/inventory/transaksi/convert-barang',
      page: ListConvertItemBarang,
      hak: ['SUPA', 'INV_TRN_CVB'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/convert-barang/tambah',
      page: TambahConvertBarang,
      hak: ['SUPA', 'INV_TRN_CVB'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/convert-barang/ubah/:id_convert_barang',
      page: UpdateConvertBarang,
      hak: ['SUPA', 'INV_TRN_CVB'],
    },
    {
      exact: true,
      path: '/inventory/transaksi/convert-barang/detail/:id_convert_barang',
      page: DetailConvertBarang,
      hak: ['SUPA', 'INV_TRN_CVB'],
    },

    // LAPORAN
    {
      exact: true,
      path: '/inventory/laporan/kartu-stock-material',
      page: KartuStockMaterial,
      hak: ['SUPA', 'INV_LAP_KST'],
    },
    {
      exact: true,
      path: '/inventory/laporan/purchase-request',
      page: LaporanPurchaseRequest,
      hak: ['SUPA', 'INV_RPT_LPR'],
    },
    {
      exact: true,
      path: '/inventory/laporan/delivery-order',
      page: LaporanDeliveryOrder,
      hak: ['SUPA', 'INV_RPT_DO'],
    },
    {
      exact: true,
      path: '/inventory/laporan/stok-terbaru',
      page: StokTerbaru,
      hak: ['SUPA', 'INV_RPT_SU'],
    },
    {
      exact: true,
      path: '/inventory/laporan/penerimaan-barang',
      page: LaporanPenerimaanBarang,
      hak: ['SUPA', 'INV_RPT_PBJ'],
    },
  ],
};
