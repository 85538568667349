import { useEffect, useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { InfoItemVertical, Input, SelectSearch } from 'components';
import { RupiahConvert } from 'utilities';
import { DropdownContext } from '../Context';
import { fetchStok } from '../Helpers';

export const FormBarangAwal = ({ type }) => {
  const { dropdownItems, dropdownGudang, dropdownKelompok } =
    useContext(DropdownContext);
  const { values, setValues } = useFormikContext();
  const { id_item_buaso, id_gudang } = values;

  useEffect(() => {
    if (id_item_buaso && id_gudang) {
      fetchStok({ values, setValues });
    }
  }, [id_gudang, id_item_buaso]);

  const optionItems =
    dropdownItems?.data?.filter(
      ({ id_kelompok }) => values.id_kelompok === id_kelompok
    ) ?? [];

  if (type === 'DETAIL') {
    return (
      <>
        <div className="mb-1 font-weight-bold" style={{ fontSize: '.9rem' }}>
          Item Barang Awal
        </div>
        <Card>
          <Card.Body>
            <InfoItemVertical
              label="Kelompok Barang"
              text={values.nama_kelompok}
            />
            <InfoItemVertical label="Item Barang" text={values.nama_item} />
            <InfoItemVertical label="Satuan" text={values.nama_satuan} />
            <InfoItemVertical label="Gudang" text={values.nama_gudang} />

            <Row>
              <Col>
                <InfoItemVertical label="Stok" text={values.stok} />
              </Col>
              <Col>
                <InfoItemVertical
                  label="Nilai Persediaan"
                  text={RupiahConvert(String(values.nilai_persediaan)).detail}
                />
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <InfoItemVertical
                  label="Qty. Convert"
                  text={values.qty_convert}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }

  return (
    <>
      <div className="mb-1 font-weight-bold" style={{ fontSize: '.9rem' }}>
        Item Barang Awal
      </div>
      <Card>
        <Card.Body>
          <SelectSearch
            key={dropdownKelompok?.data?.length}
            loading={dropdownKelompok.isLoading}
            label="Kelompok Barang"
            defaultValue={
              values?.id_kelompok
                ? dropdownKelompok?.data?.find(
                    ({ value }) => value === values.id_kelompok
                  )
                : null
            }
            option={dropdownKelompok?.data}
            placeholder="Pilih Kelompok Barang"
            onChange={(val) =>
              setValues((prev) => ({ ...prev, id_kelompok: val.value }))
            }
          />

          <SelectSearch
            key={values.id_kelompok}
            loading={dropdownItems.isLoading}
            isDisabled={!values.id_kelompok}
            defaultValue={
              values?.id_item_buaso
                ? optionItems?.find(
                    ({ value }) => value === values.id_item_buaso
                  )
                : null
            }
            option={optionItems}
            label="Item Barang"
            placeholder="Pilih Item Barang"
            onChange={(val) =>
              setValues((prev) => ({
                ...prev,
                id_item_buaso: val.value,
                nama_satuan: val.nama_satuan ?? '',
              }))
            }
          />

          <Row>
            <Col md="6">
              <Input label="Satuan" readOnly value={values.nama_satuan} />
            </Col>
          </Row>

          <SelectSearch
            isDisabled={!values.id_kelompok}
            label="Gudang"
            loading={dropdownGudang.isLoading}
            placeholder="Pilih Gudang"
            defaultValue={
              values.id_gudang
                ? dropdownGudang?.data?.find(
                    ({ value }) => value === values.id_gudang
                  )
                : null
            }
            option={dropdownGudang?.data}
            onChange={(val) => {
              setValues((prev) => ({
                ...prev,
                id_gudang: val.value,
              }));
            }}
          />

          <Row>
            <Col>
              <Input readOnly label="Stok" value={values.stok} />
            </Col>

            <Col>
              <Input
                readOnly
                label="Nilai Persediaan"
                value={values.nilai_persediaan}
              />
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <Input
                type="number"
                label="Qty. Convert"
                value={values.qty_convert}
                onChange={({ target }) =>
                  setValues((prev) => ({ ...prev, qty_convert: target.value }))
                }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
