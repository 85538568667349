import { Services } from '../../../services';

class JenisApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/jenis/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getDataKelompok() {
    return Services.get('/jenis/kelompok');
  }
  create(data) {
    return Services.post('/jenis', data);
  }
  update(data) {
    return Services.put('/jenis', data);
  }
  delete(id_jenis) {
    return Services.post('/jenis/delete', id_jenis);
  }
  search(key) {
    return Services.get(`jenis/page/?q=${key}`);
  }
}

export default new JenisApi();
