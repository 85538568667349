import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import * as Yup from 'yup';
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  DataStatus,
  TextArea,
  Approval,
} from '../../../../components';
import { DeliveryOrderApi } from '../../../../api';
import { useHistory } from 'react-router-dom';
import { DateConvert } from '../../../../utilities';
import { Card, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';

const EditDeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  // data
  const [data, setData] = useState([]);
  // menampung data yang akan dihapus

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    status: false,
  });

  const { id } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([DeliveryOrderApi.getFormEdit(id)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle('Delivery Order');

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const TambahData = () => {
    const [isNomorLoading, setIsNomorLoading] = useState(false);
    const [kode, setKode] = useState('');

    const getNomor = (value, setFieldValue) => {
      // setIsNomorLoading(true);
      // DeliveryOrderApi.getKode(value)
      //   .then(({ data }) => {
      //     setKode(data.data);
      //     setFieldValue("no_delivery_order", data.data);
      //   })
      //   .catch((err) => alert("Gagal mengambil nomor delivery order"))
      //   .finally(() => setIsNomorLoading(false));
    };

    const formInitialValues = {
      id_delivery_order: id,
      tgl_delivery_order: data.tgl_delivery_order,
      id_sales_order: data.id_sales_order,
      catatan_delivery_order: data.catatan_delivery_order,
    };

    const formValidationSchema = Yup.object().shape({
      tgl_delivery_order: Yup.string().required(
        'Masukan tanggal delivery order '
      ),
      // no_delivery_order: Yup.string().required("Masukan no delivery order "),
      // catatan_delivery_order: Yup.string().required("Masukan catatan delivery order"),
    });

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      const detail = data?.detail?.map((val) => {
        return {
          id_sales_order: val.id_sales_order,
          id_sales_order_detail: val.id_sales_order_detail,
          qty: val.qty,
        };
      });

      const finalValues = {
        ...values,
        detail: detail,
        id_gudang: data.id_gudang,
      };

      DeliveryOrderApi.update(finalValues)
        .then(() => {
          setAlertConfig({
            variant: 'primary',
            text: 'Data berhasil diubah!',
            status: true,
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: 'Data gagal diubah!',
            status: true,
          });
        })
        .finally(() => {
          getData();
          setIsLoading(false);
        });
    };

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Delivery Order</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {data.stakeholder.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== 'PEN' && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === 'VER' ||
                          val.status_approval === 'REV'
                            ? `Pemeriksa ${
                                val.approval_level !== '0'
                                  ? val.approval_level
                                  : ''
                              }`
                            : val.status_approval === 'APP'
                            ? 'Pengesah'
                            : 'Di Tolak Oleh'
                        }
                        value1={val.nama_karyawan ?? '-'}
                        title2="Catatan"
                        value2={val.catatan ?? '-'}
                      />
                    </Col>
                  )
              )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          <span style={{ textTransform: 'capitalize' }}>{title}</span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {text ? text : '-'}
        </div>
      </div>
    );

    return (
      <>
        <Row>
          <Col lg="6">
            <ShowData
              title="Tgl. sales order"
              text={DateConvert(new Date(data.tgl_sales_order)).detail}
            />
          </Col>
          <Col lg="6">
            <ShowData title="No. sales order" text={data.no_sales_order} />
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <ShowData title="Customer" text={data.nama_customer} />
          </Col>
          <Col lg="6">
            <ShowData title="Sales" text={data.nama_sales} />
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <ShowData
              title="Gudang"
              text={data.nama_gudang ? data.nama_gudang : '-'}
            />
          </Col>
          <Col lg="6">
            <ShowData
              title="Proyek"
              text={data.nama_proyek ? data.nama_proyek : '-'}
            />
          </Col>
        </Row>
        <hr />
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col lg="6">
                  <Input
                    label="Tanggal delivery order"
                    type="date"
                    name="tgl_delivery_order"
                    value={values.tgl_delivery_order}
                    onChange={(e) => {
                      const value = e.target.value;

                      setFieldValue('tgl_delivery_order', value);
                      getNomor(value, setFieldValue);
                    }}
                    error={
                      errors.tgl_delivery_order &&
                      touched.tgl_delivery_order &&
                      true
                    }
                    errorText={errors.tgl_delivery_order}
                  />
                </Col>
                <Col lg="6">
                  <Input
                    label="No. delivery order"
                    type="text"
                    name="no_delivery_order"
                    value={data.no_delivery_order}
                    placeholder={
                      isNomorLoading
                        ? 'Memuat . . . '
                        : values.tgl_delivery_order
                        ? kode
                        : 'Pilih tanggal terlebih dahulu'
                    }
                    readOnly={true}
                    onChange={(e) => {
                      setFieldValue('no_delivery_order', e.target.value);
                    }}
                    error={
                      errors.no_delivery_order &&
                      touched.no_delivery_order &&
                      true
                    }
                    errorText={errors.no_delivery_order}
                  />
                </Col>

                <Col>
                  <TextArea
                    label="Catatan delivery order"
                    type="text"
                    name="catatan_delivery_order"
                    value={values.catatan_delivery_order}
                    onChange={handleChange}
                    rows={2}
                    error={
                      errors.catatan_delivery_order &&
                      touched.catatan_delivery_order &&
                      true
                    }
                    errorText={errors.catatan_delivery_order}
                  />
                </Col>
              </Row>
              <div className="mb-4"></div>
              <CRUDLayout.Table>
                <THead>
                  <Tr className="text-center">
                    <ThFixed>No</ThFixed>
                    <Th>Kode barang</Th>
                    <Th>Nama barang</Th>
                    <Th>Gudang</Th>
                    <Th>Qty SO</Th>
                    <Th>Satuan</Th>
                  </Tr>
                </THead>
                <TBody>
                  {data?.detail?.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_barang}</TdFixed>
                      <Td>{val.nama_barang}</Td>
                      <Td>{val.nama_gudang}</Td>
                      <Td>{val.qty}</Td>
                      <Td>{val.nama_satuan}</Td>
                    </Tr>
                  ))}
                </TBody>
              </CRUDLayout.Table>
              <Approval data={data} />
              <div className="d-flex justify-content-end">
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Simpan"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <b>Edit Delivery Order</b>
        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={alertConfig.status}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data !== undefined ? (
          <TambahData />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </Card.Body>
    </Card>
  );
};

export default EditDeliveryOrder;
