import { Fragment, useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Tab, Nav, Card } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
} from 'components';
import { PermintaanBahanProduksiApi } from 'api';
import {
  InfoDetailJobOrder,
  ModalFormTBP,
  TabBahanPenunjangFinishing,
  TabFactorySupply,
  TabHardwood,
  TabPlywood,
} from './Components';
import {
  TransferBahanProduksiContext,
  withDropdownTransferBahanProduksiProvider,
  withTransferBahanProduksiProvider,
} from './Context';
import { useMockNumber, useAllowSaveButton, useLocalStok } from './Hooks';
import { formatUpdateStatusPermintaan, formatValues } from './Helpers';
import axios from 'axios';

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);

const TambahTransferBahanProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id_permintaan_produksi } = useParams();
  const { getStok, decrementStok } = useLocalStok();
  const [generateMockNumber] = useMockNumber();
  // const [isAllowSave] = useAllowSaveButton();
  const {
    dataJobOrder,
    setJobOrder,
    dataPermintaan,
    setDataPermintaan,
    modalForm,
    setModalForm,
  } = useContext(TransferBahanProduksiContext);

  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const onSubmit = () => {
    setSubmitting(true);
    const finalValue = formatValues(dataPermintaan);
    const listUpdateStatusPermintaan =
      formatUpdateStatusPermintaan(dataPermintaan);

    axios
      .all([
        PermintaanBahanProduksiApi.update_permintaan(
          listUpdateStatusPermintaan
        ),
        PermintaanBahanProduksiApi.create(finalValue),
      ])
      .then(() =>
        history.push('/inventory/transaksi/transfer-bahan-produksi', {
          alert: {
            variant: 'primary',
            text: 'Tambah Data Transfer Bahan Produksi Berhasil',
          },
        })
      )
      .catch(({ response }) =>
        setAlert({
          variant: 'danger',
          text: `Gagal Menyimpan Data Transfer Bahan Produksi ${
            response?.data?.message ?? ''
          }`,
        })
      )
      .finally(() => setSubmitting(false));
  };

  const getInitialData = () => {
    setPageLoading(true);

    PermintaanBahanProduksiApi.single({ id_permintaan_produksi })
      .then((res) => {
        const {
          hardwood,
          plywood,
          factory_supply,
          bahan_penunjang,
          ...jobOrder
        } = res?.data?.data;

        setJobOrder(jobOrder ?? {});
        setDataPermintaan({
          hardwood: hardwood?.map((hw) => ({
            ...hw,
            status_permintaan: hw.status_permintaan ?? 'waiting',
          })),
          plywood: plywood?.map((ppw) => ({
            ...ppw,
            status_permintaan: ppw.status_permintaan ?? 'waiting',
          })),
          factory: factory_supply?.map((pfs) => ({
            ...pfs,
            status_permintaan: pfs.status_permintaan ?? 'waiting',
          })),
          penunjang: bahan_penunjang?.map((ppf) => ({
            ...ppf,
            status_permintaan: ppf.status_permintaan ?? 'waiting',
          })),
        });
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  const checkAlert = () => {
    const alertState = location?.state?.alert;
    if (alertState) {
      setAlert({ text: alertState.text, variant: alertState.variant });
    }
  };

  useEffect(() => {
    setNavbarTitle('Transfer Bahan Produksi');
    getInitialData();
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-end justify-content-between mb-2">
        <Col>
          <div>Detail Data Job Order </div>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoDetailJobOrder data={dataJobOrder} />

          <Card className="mt-4">
            <Tab.Container defaultActiveKey="hardwood">
              {/* Tab Navigation */}
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey="hardwood">
                  <NavTab eventKey="hardwood" title="Hardwood" />
                  <NavTab eventKey="plywood" title="Plywood" />
                  <NavTab eventKey="fs" title="Factory Supply" />
                  <NavTab eventKey="bpf" title="Bahan Penunjang Finishing" />
                </Nav>
              </Card.Header>

              {/* Tab Content */}
              <Card.Body>
                <Tab.Content>
                  <TabPane eventKey="hardwood" component={<TabHardwood />} />
                  <TabPane eventKey="plywood" component={<TabPlywood />} />
                  <TabPane eventKey="fs" component={<TabFactorySupply />} />
                  <TabPane
                    eventKey="bpf"
                    component={<TabBahanPenunjangFinishing />}
                  />
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
          <Row className="d-flex justify-content-end align-items-center px-3 pt-4 pb-2 ">
            <ActionButton
              disable={isSubmitting}
              text="Simpan"
              loading={isSubmitting}
              onClick={onSubmit}
            />
          </Row>
        </Fragment>
      )}

      {modalForm.show && (
        <ModalFormTBP
          generateMockNumber={generateMockNumber}
          getStok={getStok}
          decrementStok={decrementStok}
          modal={modalForm}
          closeModal={() =>
            setModalForm({
              show: false,
              data: null,
              type: '',
            })
          }
        />
      )}
    </CRUDLayout>
  );
};

export default withDropdownTransferBahanProduksiProvider(
  withTransferBahanProduksiProvider(TambahTransferBahanProduksi)
);
