import { createContext, useState } from 'react';

export const TransferBahanProduksiContext = createContext();

export const TransferBahanProduksiProvider = ({ children }) => {
  const [dataJobOrder, setJobOrder] = useState({});
  const [dataPermintaan, setDataPermintaan] = useState({
    hardwood: [],
    plywood: [],
    factory: [],
    penunjang: [],
  });
  const [modalForm, setModalForm] = useState({
    show: false,
    type: 'hardwood',
    data: null,
  });

  return (
    <TransferBahanProduksiContext.Provider
      value={{
        dataJobOrder,
        setJobOrder,
        dataPermintaan,
        setDataPermintaan,
        modalForm,
        setModalForm,
      }}
    >
      {children}
    </TransferBahanProduksiContext.Provider>
  );
};

export const withTransferBahanProduksiProvider = (Component) => (props) => {
  return (
    <TransferBahanProduksiProvider>
      <Component {...props} />
    </TransferBahanProduksiProvider>
  );
};
