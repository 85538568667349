import { Services } from '../../../services';

class KartuStockMaterialApi {
  getDropdownKelompok(params) {
    return Services.get('dropdown/kelompok_material');
  }

  getDropdownGudang() {
    return Services.get('dropdown/gudang');
  }

  getDropdownJenis(params) {
    return Services.get('/dropdown/jenis_material', { params });
  }

  getDropdownMaterial(params) {
    return Services.get('/dropdown/material', { params });
  }

  getGudangList(params) {
    return Services.get('/kartu_stok/front', { params });
  }

  getKartuStockMaterial(params) {
    return Services.get('/kartu_stok/', { params });
  }
}

export default new KartuStockMaterialApi();
