import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  BackButton,
  DataStatus,
} from '../../../../components';
import { DeliveryOrderApi } from '../../../../api';
import { useHistory } from 'react-router-dom';
import { DateConvert, PageNumber, TableNumber } from '../../../../utilities';

const ListSo = ({ setNavbarTitle }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const history = useHistory();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [totalData, setTotalData] = useState('');

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    Axios.all([DeliveryOrderApi.getSales(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.total_data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setNavbarTitle('List Sales Order Yang Siap Dijadikan Delivery Order');

    getData();
    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th>Tanggal Sales Order</Th>
              <Th>No sales Order</Th>
              <Th>Gudang</Th>
              <Th>Customer</Th>
              <Th>Sales</Th>
              <Th>Batas Waktu</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                <TdFixed className="align-middle">
                  {DateConvert(new Date(val.tgl_sales_order)).defaultDMY}
                </TdFixed>
                <TdFixed className="align-middle">{val.no_sales_order}</TdFixed>
                <Td className="align-middle">
                  {val.nama_gudang ? val.nama_gudang : '-'}
                </Td>
                <Td className="align-middle">{val.nama_customer}</Td>
                <Td className="align-middle">{val.nama_sales}</Td>
                <TdFixed className="align-middle">
                  {DateConvert(new Date(val.batas_waktu)).defaultDMY}
                </TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ActionButton
                      text="Tambah"
                      size="sm"
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/delivery-order/tambah-delivery-order/${val.id_sales_order}/${val.id_gudang}`,
                          {
                            data: {
                              nama_gudang: val.nama_gudang,
                            },
                          }
                        );
                      }}
                    />
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index === data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index === 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => setDataLength(e.target.value)}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
                // if (searchKey !== "") {
                //   setShowAlert(true);
                // }
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default ListSo;
