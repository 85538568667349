import React from 'react';
import { Table, Th, Td, ThFixed, DataStatus } from 'components';
import { DateConvert } from 'utilities';

const TableSection = ({ dataTable }) => {
  return (
    <div>
      <div className="p-1 py-2">
        <b>Histori Transfer Prelim</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No.</ThFixed>
            <Th noPadding width={100}>
              Tgl. Transfer Prelim
            </Th>
            <Th noPadding width={150}>
              No. Transfer Prelim
            </Th>
            <Th>Item Prelim</Th>
            <Th noPadding width={100}>
              Qty. Transfer Prelim
            </Th>
            <Th noPadding width={150}>
              Gudang Asal
            </Th>
            <Th noPadding width={150}>
              Gudang Tujuan
            </Th>
          </tr>
        </thead>
        <tbody>
          {dataTable && dataTable.length > 0 ? (
            dataTable.map((val, index) => (
              <tr key={index}>
                <Td textCenter>{index + 1}</Td>
                <Td>
                  {val.tgl_transfer_prelim
                    ? DateConvert(new Date(val.tgl_transfer_prelim)).defaultDMY
                    : '-'}
                </Td>
                <Td>{val.no_transfer_prelim ?? '-'}</Td>
                <Td>{`${
                  val.kode_item_prelim ? `(${val.kode_item_prelim})` : '-'
                } ${val.nama_item_prelim ?? '-'}`}</Td>
                <Td textRight>
                  {val.qty_transfer
                    ? `${parseFloat(val.qty_transfer)} ${val.kode_satuan ?? ''}`
                    : '-'}
                </Td>
                <Td>{val.nama_gudang_asal ?? '-'}</Td>
                <Td>{val.nama_gudang_tujuan ?? '-'}</Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableSection;
