import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
  InfoItemHorizontal,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { TransferPrelimApi } from 'api';

const ListTransferPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataTransferPrelim, setDataTransferPrelim] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    TransferPrelimApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataTransferPrelim(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase();
    if (newStatus === 'REJ') {
      return 'REJECTED';
    }

    if (newStatus === 'REV') {
      return 'REVISI';
    }

    if (newStatus === 'APP') {
      return 'APPROVED';
    }

    if (newStatus === 'VER') {
      return 'VERIFIED';
    }

    return 'PENDING';
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    setAlertConfig({ show: false, variant: 'primary', text: '' });

    const locationState = location.state;
    let state = { ...locationState };
    delete state.alert;
    history.replace({ ...state });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('List Transfer Prelim');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const ModalSection = () => {
    const DATA = modalConfig.data;

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            show: false,
            data: {},
          })
        }
      >
        <Modal.Header closeButton>
          <b>Detail Transfer Prelim</b>
        </Modal.Header>
        <Modal.Body>
          <InfoItemHorizontal
            label="Tgl. Transfer Prelim"
            text={
              DATA.tgl_transfer_prelim
                ? DateConvert(new Date(DATA.tgl_transfer_prelim)).defaultDMY
                : '-'
            }
            className="mb-2"
          />
          <InfoItemHorizontal
            label="No. Transfer Prelim"
            text={DATA.no_transfer_prelim ?? '-'}
            className="mb-2"
          />
          <InfoItemHorizontal
            label="No. Permintaan Prelim"
            text={DATA.no_permintaan_prelim ?? '-'}
            className="mb-2"
          />
          <InfoItemHorizontal
            label="No. Job Order"
            text={DATA.no_job_order ?? '-'}
            className="mb-2"
          />
          <InfoItemHorizontal
            label="Item Bahan"
            text={`${
              DATA.kode_item_prelim ? `(${DATA.kode_item_prelim})` : '-'
            } ${DATA.nama_item_prelim ?? '-'}`}
            className="mb-2"
          />
          <InfoItemHorizontal
            label="Qty. Transfer Bahan"
            text={
              DATA.qty_transfer
                ? `${parseFloat(DATA.qty_transfer)} ${DATA.kode_satuan ?? ''}`
                : '-'
            }
            className="mb-2"
          />
          <InfoItemHorizontal
            label="Gudang Asal"
            text={DATA.nama_gudang_asal ?? '-'}
            className="mb-2"
          />
          <InfoItemHorizontal
            label="Gudang Tujuan"
            text={DATA.nama_gudang_tujuan ?? '-'}
            className="mb-2"
          />
        </Modal.Body>
      </Modal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th noPadding width={100}>
              Tgl. Transfer Prelim
            </Th>
            <Th noPadding width={100}>
              No. Transfer Prelim
            </Th>
            <Th noPadding width={100}>
              No. Permintaan Prelim
            </Th>
            <Th>Item Prelim</Th>
            <Th noPadding width={100}>
              Qty. Transfer
            </Th>
            <Th noPadding width={120}>
              Gudang Asal
            </Th>
            <Th noPadding width={120}>
              Gudang Tujuan
            </Th>
          </tr>
        </thead>
        <tbody>
          {dataTransferPrelim.map((val, index) => (
            <tr key={index}>
              <Td textCenter>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>
                <ActionButton
                  size="sm"
                  onClick={() =>
                    setModalConfig({
                      show: true,
                      data: val,
                    })
                  }
                >
                  <IoEyeOutline />
                </ActionButton>
              </Td>
              <Td>
                {val.tgl_transfer_prelim
                  ? DateConvert(new Date(val.tgl_transfer_prelim)).defaultDMY
                  : '-'}
              </Td>
              <Td>{val.no_transfer_prelim ?? '-'}</Td>
              <Td>{val.no_permintaan_prelim ?? '-'}</Td>
              <Td>{`${
                val.kode_item_prelim ? `(${val.kode_item_prelim})` : '-'
              } ${val.nama_item_prelim ?? '-'}`}</Td>
              <Td textRight>
                {val.qty_transfer
                  ? `${parseFloat(val.qty_transfer)} ${val.kode_satuan ?? ''}`
                  : '-'}
              </Td>
              <Td>{val.nama_gudang_asal ?? '-'}</Td>
              <Td>{val.nama_gudang_tujuan ?? '-'}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!dataTransferPrelim || dataTransferPrelim.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push('/inventory/transaksi/transfer-prelim/permintaan')
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* Content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {/* Modal */}
      {modalConfig.show && <ModalSection />}
    </CRUDLayout>
  );
};

export default ListTransferPrelim;
