import { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, ActionButton, BackButton, DataStatus } from 'components';
import { TransferPrelimApi } from 'api';
import { InfoSection, FormSection, TableSection } from '../Section';

const TambahTransferPrelim = ({ setNavbarTitle }) => {
  const params = useParams();
  const history = useHistory();
  const ID = params.id;
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  const formInitialValues = {
    tgl_transfer: '',
    no_transfer: '',
    id_gudang_asal: '',
    id_gudang_tujuan: '',
    qty_transfer: 0,
    qty_stok: '',
    satuan: '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_transfer: Yup.string().required('Pilih tgl. transfer'),
    no_transfer: Yup.string().required('Pilih tgl untuk menentukan nomor'),
    id_gudang_asal: Yup.string().required('Pilih gudang asal'),
    id_gudang_tujuan: Yup.string().required('Pilih gudang tujuan'),
    qty_transfer: Yup.number()
      .required('Masukan qty. transfer')
      .positive('Qty. transfer harus lebih besar daripada 0')
      .test({
        name: 'checkQtyTransfer',
        test: function (value) {
          const qtyStok = parseFloat(this.parent.qty_stok ?? 0);
          const qtyTransfer = parseFloat(value);
          if (qtyStok >= qtyTransfer) {
            return true;
          }

          return false;
        },
        message: 'Qty. transfer tidak boleh lebih dari qty. stok',
      }),
  });

  const formSubmitHandler = async (values, { setSubmitting }) => {
    const finalValues = {
      id_permintaan_prelim: ID,
      tgl_transfer_prelim: values.tgl_transfer,
      id_item_prelim: dataInfo.id_item_buaso_prelim,
      id_gudang_asal: values.id_gudang_asal,
      id_gudang_tujuan: values.id_gudang_tujuan,
      qty_transfer: values.qty_transfer,
    };

    return await TransferPrelimApi.save(finalValues)
      .then(() => {
        history.push('/inventory/transaksi/transfer-prelim', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil disimpan!',
          },
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal disimpan!',
        });
      })
      .finally(() => setSubmitting(false));
  };

  const getInitialData = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    return await TransferPrelimApi.getSingle({ id_permintaan_prelim: ID })
      .then((res) => {
        const data = res.data.data;

        setDataInfo(data ?? {});
        setDataTable(data.histori ?? []);
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(async () => {
    setNavbarTitle('Transfer Prelim');
    await getInitialData();
  }, []);

  // Tampilan header pada card
  const CardTitle = () => {
    return (
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Tambah Transfer Prelim</b>
        <BackButton size="sm" onClick={() => history.goBack()} />
      </Card.Header>
    );
  };

  // Tampilan pada saat loading / data gagal dimuat
  if (fetchingStatus.loading || !fetchingStatus.success) {
    return (
      <Card>
        <CardTitle />
        <Card.Body>
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : (
            <DataStatus text="Data gagal dimuat!" />
          )}
        </Card.Body>
      </Card>
    );
  }

  // Tampilan pada saat data berhasil dimuat
  return (
    <Card>
      <CardTitle />
      <Card.Body>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <InfoSection dataInfo={dataInfo} />
              <hr />
              <FormSection formik={formik} dataInfo={dataInfo} />
              <hr />
              <TableSection dataTable={dataTable} />
              <hr />
              <div className="d-flex justify-content-end py-3">
                <ActionButton
                  type="submit"
                  text="Simpan Transfer Prelim"
                  loading={formik.isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default TambahTransferPrelim;
