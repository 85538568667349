import { Modal } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import GambarSecton from './GambarSection';
import { ActionButton, DataStatus } from 'components';
import { PurchaseRequestApi } from '../../../../../api';

const ModalGambar = ({
  modalConfig,
  setModalConfig,
  list,
  setList,
  dataGambar,
  setDataGambar,
  type,
}) => {
  const data = modalConfig.data;
  // const path = modalConfig.path;
  const indexList = modalConfig.index;

  const SaveGambarToList = () => {
    const formData = new FormData();

    dataGambar
      .filter((val) => val.data)
      .map((val, index) => {
        formData.append(`file_${index}`, val.data);
      });

    PurchaseRequestApi.upload(formData)
      .then((data) => {
        setList(
          list.map((val, ind) => {
            const newStr = dataGambar
              .filter((val) => !val.data)
              .map((val) =>
                val.link
                  .split('/')
                  .filter((_, i) => i > 2)
                  .join('/')
              );

            return type === 'update'
              ? ind === indexList
                ? {
                    ...val,
                    path_gambar: [...newStr, ...Object.values(data.data.data)],
                    view_gambar: dataGambar,
                  }
                : val
              : ind === indexList
              ? {
                  ...val,
                  path_gambar: data.data.data,
                  view_gambar: dataGambar,
                }
              : val;
          })
        );
      })
      .finally(() => setDataGambar([]));

    setModalConfig({ ...modalConfig, show: false });
  };

  const CancelGambarToList = () => {
    setList(
      list.map((val, ind) =>
        ind === indexList ? { ...val, path_gambar: [], view_gambar: [] } : val
      )
    );
    setDataGambar([]);
    setModalConfig({ ...modalConfig, show: false });
  };

  const InfoSection = () => {
    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div
          className="text-secondary mb-2 mr-2"
          style={{ width: 100, fontSize: 14 }}
        >
          {title}
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            paddingLeft: 20,
            fontSize: 14,
          }}
        >
          {text ? text : '-'}
        </div>
      </div>
    );
    return (
      <>
        <ShowData title="Kode Item" text={data?.kode_item ?? '-'} />
        <ShowData title="Nama Item" text={data?.nama_item ?? '-'} />
        <ShowData title="Qty." text={data?.qty ?? '-'} />
        <ShowData title="Satuan" text={data?.satuan_beli ?? '-'} />
      </>
    );
  };

  return (
    <div>
      <Modal
        show={modalConfig.show}
        onHide={() => {
          setModalConfig({ ...modalConfig, show: false });
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          Upload Gambar Item Purchase Request
        </Modal.Header>
        <Modal.Body>
          <InfoSection />
          <hr />
          <GambarSecton
            dataGambar={dataGambar}
            setDataGambar={setDataGambar}
            type={type}
          />
          <div className="d-flex justify-content-end mt-3">
            {type === 'DETAIL' ? (
              <ActionButton
                variant="outline-secondary"
                text="Kembali"
                className="m-1"
                onClick={() => setModalConfig({ ...modalConfig, show: false })}
              />
            ) : (
              <>
                <ActionButton
                  variant="outline-secondary"
                  text="Batal"
                  className="m-1"
                  onClick={() => CancelGambarToList()}
                />
                <ActionButton
                  variant="primary"
                  text="Simpan"
                  className="m-1"
                  onClick={() => SaveGambarToList()}
                />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalGambar;
