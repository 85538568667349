// React
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
// Component
import {
  CRUDLayout,
  InputSearch,
  Input,
  SelectSearch,
  TextArea,
  DatePicker,
  TdFixed,
  DataStatus,
  Tr,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  BackButton,
  CreateModal,
  ActionButton,
} from '../../../../components';
import { Modal, Row, Col } from 'react-bootstrap';
import { PageNumber, DateConvert, RupiahConvert } from '../../../../utilities';

// API
import { RealisasiSisaProduksiApi } from '../../../../api';

const ListTransferSisaProduksi = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const location = useLocation();
  const title = 'List Transfer Sisa Produksi';

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);

  // data jenis
  const [data, setData] = useState([]);
  const [dropdownGudang, setDropdownGudang] = useState([]);
  // DETAIL MODAL STATE
  const [modalConfig, setModalConfig] = useState({ show: false });
  const [dataDetail, setDataDetail] = useState({});

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    Axios.all([
      RealisasiSisaProduksiApi.getListTransfer({
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
      RealisasiSisaProduksiApi.getDropdownGudang(),
    ])
      .then(
        Axios.spread((res, gudang) => {
          setData(res?.data?.data ?? []);
          setDropdownGudang(gudang?.data?.data ?? []);
          setPaginationConfig({
            ...paginationConfig,
            dataCount: res.data.data_count,
            totalPage: res.data.total_page,
          });
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    setAlertConfig({ show: false, variant: 'primary', text: '' });

    const locationState = location.state;
    let state = { ...locationState };
    delete state.alert;
    history.replace({ ...state });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);
    // jalankan function request data ke server
    getData();
    checkAlert();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  // MODAL TAMBAH COMPONENT
  const ModalTambahData = () => {
    const decimalConvert = (value) => {
      const newValue = value.toString().replace(/[^0-9\.]/g, '');
      const convert = newValue.substring(0, 7);

      return convert;
    };

    const formInitialValues = {
      id_transfer_sisa_produksi: dataDetail.id_transfer_sisa_produksi,
      id_item_buaso: dataDetail.id_item_buaso,
      tgl_transfer_sisa_produksi: dataDetail.tgl_transfer_sisa_produksi
        ? DateConvert(new Date(dataDetail.tgl_transfer_sisa_produksi)).detail
        : '-',
      no_transfer_sisa_produksi: dataDetail.no_transfer_sisa_produksi,
      no_job_order: dataDetail.no_job_order,
      nama_item: dataDetail.nama_item,
      id_satuan: dataDetail.id_satuan,
      satuan: dataDetail.nama_satuan,
      id_gudang_asal: dataDetail.id_gudang_asal ?? '62',
      id_gudang_tujuan: dataDetail.id_gudang_tujuan ?? '',
      tgl_realisasi_sisa_produksi: '',
      no_realisasi_sisa_produksi: '',
      qty_realisasi: dataDetail.qty_transfer,
      harga_satuan_sisa_produksi: dataDetail.harga_satuan_sisa_produksi ?? 0,
    };

    const formValidationSchema = Yup.object().shape({
      tgl_realisasi_sisa_produksi: Yup.string().required('Pilih tanggal'),
      no_realisasi_sisa_produksi: Yup.string().required(
        'Pilih tanggal untuk menentukan nomor'
      ),
      qty_realisasi: Yup.string().required('Masukkan qty realisasi'),
      id_gudang_tujuan: Yup.string().required('Pilih gudang tujuan'),
      harga_satuan_sisa_produksi: Yup.string().required(
        'Masukkan harga satuan sisa produksi'
      ),
    });

    const formSubmitHandler = (values) => {
      const finalValues = {
        id_transfer_sisa_produksi: values.id_transfer_sisa_produksi,
        tgl_realisasi_sisa_produksi: values.tgl_realisasi_sisa_produksi,
        id_item_buaso: values.id_item_buaso,
        id_gudang_asal: values.id_gudang_asal,
        id_gudang_tujuan: values.id_gudang_tujuan,
        id_satuan: values.id_satuan,
        qty_realisasi: values.qty_realisasi,
        harga_satuan_sisa_produksi: values.harga_satuan_sisa_produksi,
      };
      RealisasiSisaProduksiApi.save(finalValues)
        .then(() =>
          setAlertConfig({
            variant: 'primary',
            text: 'Berhasil simpan data',
            show: true,
          })
        )
        .catch(({ response }) => {
          const message = response.data.message ?? '';
          setAlertConfig({
            variant: 'danger',
            text: `Gagal simpan data ${message ? `(${message})` : ''}`,
            show: true,
          });
        })
        .finally(() => {
          setModalConfig({ show: false });
          getData();
        });
    };

    const InfoItem = ({ label, value }) => (
      <tr>
        <td>{label}</td>
        <td className="pl-3 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    const ModalForm = ({ formik }) => {
      const {
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setValues,
        handleChange,
        handleSubmit,
      } = formik;

      // Generate nomor ke server
      const getNoHandler = (date) =>
        RealisasiSisaProduksiApi.getNomor({ tanggal: date })
          .then(({ data }) => {
            const nomor = data.data;
            setValues({
              ...values,
              tgl_realisasi_sisa_produksi: date,
              no_realisasi_sisa_produksi: nomor,
            });
          })
          .catch(() => {
            setValues({
              ...values,
              tgl_realisasi_sisa_produksi: date,
              no_realisasi_sisa_produksi: '',
            });
            window.alert('Gagal memuat nomor!');
          });

      return (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <table style={{ fontSize: 12 }}>
              <tbody>
                <InfoItem
                  label="Tgl. Transfer Sisa Produksi"
                  value={values.tgl_transfer_sisa_produksi}
                />
                <InfoItem
                  label="No. Transfer Sisa Produksi"
                  value={values.no_transfer_sisa_produksi}
                />
                <InfoItem label="No. Job Order" value={values.no_job_order} />
              </tbody>
            </table>
            <hr />
            <Row>
              <Col md>
                <Input
                  label="Tgl. Realisasi Sisa Produksi"
                  type="date"
                  placeholder="Pilih tanggal"
                  value={values.tgl_realisasi_sisa_produksi}
                  onChange={(val) => {
                    getNoHandler(val.target.value);
                  }}
                  error={
                    Boolean(errors.tgl_realisasi_sisa_produksi) &&
                    touched.tgl_realisasi_sisa_produksi
                  }
                  errorText={
                    Boolean(errors.tgl_realisasi_sisa_produksi) &&
                    touched.tgl_realisasi_sisa_produksi &&
                    errors.tgl_realisasi_sisa_produksi
                  }
                />
              </Col>
              <Col md>
                <Input
                  label="No. Realisasi Sisa Produksi"
                  placeholder="Pilih tanggal realisasi sisa produksi"
                  value={values.no_realisasi_sisa_produksi}
                  error={
                    Boolean(errors.no_realisasi_sisa_produksi) &&
                    touched.no_realisasi_sisa_produksi
                  }
                  errorText={
                    Boolean(errors.no_realisasi_sisa_produksi) &&
                    touched.no_realisasi_sisa_produksi &&
                    errors.no_realisasi_sisa_produksi
                  }
                  readOnly
                />
              </Col>
            </Row>
            <Input label="Item Bahan" value={values.nama_item} readOnly />
            <Row>
              <Col md>
                <SelectSearch
                  isDisabled
                  label="Gudang Asal"
                  placeholder="Pilih gudang asal"
                  defaultValue={{
                    value: values.id_gudang_asal,
                    label: dropdownGudang.find(
                      (gudang) => gudang.id_gudang === values.id_gudang_asal
                    ).nama_gudang,
                  }}
                  option={dropdownGudang.map((val) => {
                    return {
                      value: val.id_gudang,
                      label: val.nama_gudang,
                    };
                  })}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_gudang_tujuan: val.value,
                    });
                  }}
                />
              </Col>
              <Col md>
                <SelectSearch
                  label="Gudang Tujuan"
                  placeholder="Pilih gudang tujuan"
                  defaultValue={{
                    value: values.id_gudang_tujuan,
                    label: dropdownGudang.find(
                      (gudang) => gudang.id_gudang === values.id_gudang_tujuan
                    ).nama_gudang,
                  }}
                  option={dropdownGudang.map((val) => {
                    return {
                      value: val.id_gudang,
                      label: val.nama_gudang,
                    };
                  })}
                  onChange={(val) => {
                    setValues({
                      ...values,
                      id_gudang_tujuan: val.value,
                    });
                  }}
                  error={
                    Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan
                  }
                  errorText={
                    Boolean(errors.id_gudang_tujuan) &&
                    touched.id_gudang_tujuan &&
                    errors.id_gudang_tujuan
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md>
                <Input
                  label="Satuan"
                  placeholder="Satuan"
                  value={values.satuan}
                  readOnly
                />
              </Col>
              <Col md>
                <Input
                  label="Qty. Realisasi Sisa Produksi"
                  placeholder="Masukkan qty"
                  value={values.qty_realisasi}
                  onChange={(val) => {
                    const value = val.target.value;
                    const convert = decimalConvert(value);
                    setFieldValue('qty_realisasi', convert);
                  }}
                  error={Boolean(errors.qty_realisasi) && touched.qty_realisasi}
                  errorText={
                    Boolean(errors.qty_realisasi) &&
                    touched.qty_realisasi &&
                    errors.qty_realisasi
                  }
                />
              </Col>
            </Row>
            <Input
              label="Harga Satuan Sisa Produksi"
              placeholder="Masukkan harga satuan sisa produksi"
              value={
                RupiahConvert(String(values.harga_satuan_sisa_produksi || 0))
                  .detail
              }
              onChange={(val) => {
                const harga = RupiahConvert(val.target.value).default;
                setValues({
                  ...values,
                  harga_satuan_sisa_produksi: harga,
                });
              }}
              error={
                Boolean(errors.harga_satuan_sisa_produksi) &&
                touched.harga_satuan_sisa_produksi
              }
              errorText={
                Boolean(errors.harga_satuan_sisa_produksi) &&
                touched.harga_satuan_sisa_produksi &&
                errors.harga_satuan_sisa_produksi
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              type="submit"
              text="Simpan"
              className="px-4"
              loading={isSubmitting}
            />
          </Modal.Footer>
        </form>
      );
    };

    return (
      <CreateModal
        closeButton
        show={modalConfig.show}
        onHide={() => {
          setDataDetail({});
          setModalConfig({ show: false });
        }}
        title="Realisasi Sisa Produksi"
      >
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => <ModalForm formik={formik} />}
        </Formik>
      </CreateModal>
    );
  };

  // Tabel
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Tgl. Transfer Sisa Produksi</Th>
              <Th>No. Transfer Sisa Produksi</Th>
              <Th>No. Job Order</Th>
              <Th>Item Sisa Produksi</Th>
              <Th>Gudang Asal</Th>
              <Th>Gudang Tujuan</Th>
              <Th>Qty. Transfer</Th>
              <Th>Aksi</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {PageNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  {val?.tgl_transfer_sisa_produksi
                    ? DateConvert(new Date(val.tgl_transfer_sisa_produksi))
                        .defaultDMY
                    : '-'}
                </TdFixed>
                <TdFixed>{val?.no_transfer_sisa_produksi ?? '-'}</TdFixed>
                <TdFixed>{val?.no_job_order ?? '-'}</TdFixed>
                <Td>{`(${val?.kode_item ?? '-'}) ${val?.nama_item ?? '-'}`}</Td>
                <Td>{val?.nama_gudang_asal ?? '-'}</Td>
                <Td>{val?.nama_gudang_tujuan ?? '-'}</Td>
                <Td>{`${val?.qty_transfer?.replace('.', ',') ?? 0} ${
                  val?.nama_satuan ?? ''
                }`}</Td>
                <Td>
                  <div className="d-flex flex-column justify-content-center">
                    <ActionButton
                      size="sm"
                      text="Realisasi"
                      onClick={() => {
                        setModalConfig({ show: true });
                        setDataDetail(val);
                      }}
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <BackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* Table Section */}
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      <ModalTambahData />
    </CRUDLayout>
  );
};

export default ListTransferSisaProduksi;
