import { Services, UploadServices } from 'services';

class LaporanPenerimaanBarangApi {
  getPage(params) {
    return Services.get('/penerimaan_barang/page', { params });
  }
  getAtribut(params) {
    return Services.get(`/penerimaan_barang/dropdown`, { params });
  }
  getDropdownVendor() {
    return Services.get('/dropdown/vendor');
  }
  getDropdownItemBarang() {
    return Services.get('/dropdown/material');
  }
  getDropdownKaryawan() {
    return Services.get('/dropdown/karyawan');
  }
  export(params) {
    return Services.get('/penerimaan_barang/export', { params });
  }
}

export default new LaporanPenerimaanBarangApi();
