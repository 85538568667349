import { createContext } from 'react';
import { useQuery } from 'react-query';
import { PermintaanBahanProduksiApi } from 'api';

export const DropdownTransferBahanProduksiContext = createContext();

export const DropdownTransferBahanProduksiProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES
  const dropdownMenuKey = 'transfer_bahan_produksi';

  const dropdownGudang = useQuery(
    ['dropdown', 'gudang', dropdownMenuKey],
    () =>
      PermintaanBahanProduksiApi.dropdown({ tipe: 'gudang' }).then((res) => {
        return (
          res.data.data.map((j) => ({
            value: j.id_gudang,
            label: j.nama_gudang,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownKaryawan = useQuery(
    ['dropdown', 'karyawan', dropdownMenuKey],
    () =>
      PermintaanBahanProduksiApi.dropdown({ tipe: 'karyawan' }).then((res) => {
        return (
          res.data.data.map((j) => ({
            value: j.id_karyawan,
            label: j.nama_karyawan,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownCustomer = useQuery(
    ['dropdown', 'customer', dropdownMenuKey],
    () =>
      PermintaanBahanProduksiApi.dropdown({ tipe: 'customer' }).then((res) => {
        return res.data.data.map((c) => ({
          value: c.id_customer,
          label: c.nama_customer,
        }));
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownPermintaan = useQuery(
    ['dropdown', 'permintaan_produksi', dropdownMenuKey],
    () =>
      PermintaanBahanProduksiApi.dropdown({ tipe: 'permintaan_produksi' }).then(
        (res) => {
          return res.data.data.map((p) => ({
            value: p.id_permintaan_produksi,
            label: `${p.kode_permintaan_produksi} - ${p.no_permintaan_produksi}`,
          }));
        }
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownBahan = useQuery(
    ['dropdown', 'bahan', dropdownMenuKey],
    () =>
      PermintaanBahanProduksiApi.dropdown({ tipe: 'bahan' }).then((res) => {
        return (
          res.data.data.map((v) => ({
            ...v,
            value: v.id_item_buaso,
            label: `${v.kode_item} - ${v.nama_item}`,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownStatusPermintaan = [
    { value: 'waiting', label: 'Waiting' },
    { value: 'on_progress', label: 'On Progress' },
    { value: 'done', label: 'Done' },
  ];

  return (
    <DropdownTransferBahanProduksiContext.Provider
      value={{
        dropdownCustomer,
        dropdownKaryawan,
        dropdownGudang,
        dropdownBahan,
        dropdownPermintaan,
        dropdownStatusPermintaan,
      }}
    >
      {children}
    </DropdownTransferBahanProduksiContext.Provider>
  );
};

export const withDropdownTransferBahanProduksiProvider =
  (Component) => (props) => {
    return (
      <DropdownTransferBahanProduksiProvider>
        <Component {...props} />
      </DropdownTransferBahanProduksiProvider>
    );
  };
