import React, { useState, useEffect } from 'react';
import { Table, Spinner, ButtonGroup, Modal } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import ReactSelect from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, ActionButton } from 'components';
import { RupiahConvert } from 'utilities';
import { SuratPerjanjianKerjaApi } from 'api';

const TableMachineProcess = ({ dataMachineProcess, setDataMachineProcess }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [dataItemMP, setDataItemMP] = useState([]);
  const [editedData, setEditedData] = useState({});
  const [deletedData, setDeletedData] = useState({
    status: false,
    index: '',
    data: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // Fetch data awal server
  const getInitialData = () => {
    setIsFetching(true);

    SuratPerjanjianKerjaApi.getDropdownMachineProcess()
      .then(({ data }) => {
        setDataItemMP(data.data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Gagal memuat item barang jadi',
        });
      })
      .finally(() => setIsFetching(false));
  };

  // Menangani konversi menjadi desimal
  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 5);

    return convert;
  };

  useEffect(() => {
    getInitialData();

    return () => {};
  }, []);

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        padding: 0,
        fontSize: '13px',
      }}
    >
      {props.children}
    </td>
  );

  const SelectSearch = (props) => (
    <ReactSelect
      {...props}
      options={props.options ? props.options : ''}
      classNamePrefix={props.error ? 'react-select-invalid' : 'react-select'}
      noOptionsMessage={() => 'Tidak ada data'}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 28,
          maxHeight: 31,
          fontSize: 14,
        }),
        valueContainer: (base) => ({
          ...base,
          paddingLeft: 5,
          margin: 0,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: 0,
          paddingLeft: 5,
          paddingRight: 5,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 13,
        }),
      }}
    />
  );

  const Input = ({ error, ...props }) => (
    <input
      {...props}
      className={`${props.className} form-control form-control-sm ${
        error ? 'is-invalid' : ''
      }`}
    />
  );

  // Pembungkus body tabel
  const TableWrapper = ({
    dataMachineProcess,
    children,
    alertConfig,
    setAlertConfig,
  }) => {
    const getTotal = () => {
      const grandTotal = dataMachineProcess.reduce((prev, current) => {
        const qty = current.qty ? current.qty : 0;
        const price = current.unit_price ? current.unit_price : 0;
        const total = parseFloat(qty) * parseInt(price);
        const subTotal = parseInt(prev) + parseInt(total);

        return subTotal;
      }, 0);

      return grandTotal;
    };

    return (
      <>
        <div className="px-1 py-2">
          <b>List Item Machine Process On-Site</b>
        </div>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        <Table bordered>
          <thead className="bg-light">
            <tr>
              <Th className="text-center" style={{ width: 30 }}>
                No
              </Th>
              <Th className="text-center" style={{ width: 80 }}>
                Kode Item
              </Th>
              <Th className="text-center">Item Machine Process On-Site</Th>
              <Th className="text-center">Qty</Th>
              <Th className="text-center">Unit</Th>
              <Th className="text-center">Unit Price</Th>
              <Th className="text-center">Konst.</Th>
              <Th className="text-center">Sub Total</Th>
              <Th className="text-center" style={{ width: 10 }}>
                Aksi
              </Th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
          <tfoot>
            <tr>
              <Th colSpan={7} className="p-1 pr-2 bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(parseInt(getTotal()).toString()).detail}
              </Th>
              <Th className="bg-light text-right"></Th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  // Template form
  const FormTable = ({ formik, button, nomor }) => {
    const { values, errors, touched, setValues, setFieldValue, handleChange } =
      formik;
    const Button = button;

    // Menangani perhitungan total
    const getTotal = () => {
      const qty = values.qty ? values.qty : 0;
      const price = values.unit_price ? values.unit_price : 0;
      const total = parseFloat(qty) * parseInt(price);

      return RupiahConvert(parseInt(total).toString()).detail;
    };

    return (
      <tr className="bg-light">
        <Td className="px-1 align-middle text-center">{nomor}</Td>
        <Td className="px-1 align-middle">{values.kode_item}</Td>
        <Td style={{ minWidth: 175 }}>
          <SelectSearch
            placeholder={
              isFetching ? 'Memuat data . . .' : 'Pilih item machine process'
            }
            defaultValue={
              values.id_item
                ? {
                    value: values.id_item,
                    label: values.nama_item,
                  }
                : ''
            }
            options={dataItemMP?.map((val) => {
              return {
                value: val.id_item_buaso,
                label: val.nama_item,
                kode: val.kode_item,
                unit: val.nama_satuan,
                qty: val.qty,
                unit_price: val.unit_price,
                konstanta: val.konstanta,
              };
            })}
            onChange={(val) => {
              setValues({
                ...values,
                id_item: val.value,
                nama_item: val.label,
                kode_item: val.kode,
                unit: val.unit,
                konstanta: val.konstanta ? val.konstanta : 1,
              });
            }}
            isDisabled={isFetching}
            error={errors.id_item && touched.id_item && true}
          />
        </Td>
        <Td style={{ width: 60 }}>
          <Input
            placeholder="Qty"
            value={values.qty}
            onChange={(e) => {
              const value = e.target.value;
              const convert = decimalConvert(value);
              setFieldValue('qty', convert);
            }}
            error={errors.qty && touched.qty && true}
          />
        </Td>
        <Td className="px-1 align-middle">{values.unit}</Td>
        <Td>
          <Input
            placeholder="Unit Price"
            value={
              values.unit_price
                ? RupiahConvert(parseInt(values.unit_price).toString()).detail
                : ''
            }
            onChange={(e) => {
              const value = e.target.value;
              const convert = RupiahConvert(value.toString()).default;
              convert
                ? setFieldValue('unit_price', convert)
                : setFieldValue('unit_price', '');
            }}
            error={errors.unit_price && touched.unit_price && true}
          />
        </Td>
        <Td style={{ width: 60 }}>
          <Input
            placeholder="Konst."
            value={values.konstanta}
            onChange={(e) => {
              const value = e.target.value;
              const convert = decimalConvert(value);
              setFieldValue('konstanta', convert);
            }}
            error={errors.konstanta && touched.konstanta && true}
          />
        </Td>
        <Td className="px-1 align-middle text-right">{getTotal()}</Td>
        <Td className="align-middle">
          <Button />
        </Td>
      </tr>
    );
  };

  // Menangani form tambah
  const CreateFormTable = ({
    dataMachineProcess,
    setDataMachineProcess,
    setAlertConfig,
  }) => {
    const noTable = dataMachineProcess ? dataMachineProcess.length + 1 : 1;

    // Data awal form
    const formInitialValues = {
      id_item: '',
      kode_item: '',
      nama_item: '',
      qty: '',
      unit: '',
      konstanta: 1,
      unit_price: '',
    };

    // Skema validasi form
    const formValidationSchema = Yup.object().shape({
      id_item: Yup.string().required(),
      qty: Yup.string().required(),
      unit_price: Yup.string().required(),
      konstanta: Yup.string().required(),
    });

    // Menangani submit form
    const formSubmitHandler = (values) => {
      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil ditambah!',
        });
        setDataMachineProcess([...dataMachineProcess, { ...values }]);
      }, 200);
    };

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => (
          <FormTable
            formik={formik}
            nomor={noTable}
            button={() => (
              <div className="d-flex justify-content-center">
                <ActionButton
                  tooltip
                  size="sm"
                  className="col"
                  tooltipText="Tambah"
                  text={
                    formik.isSubmitting ? (
                      <Spinner size="sm" animation="border" variant="light" />
                    ) : (
                      <IoAddOutline />
                    )
                  }
                  onClick={formik.handleSubmit}
                  disable={formik.isSubmitting}
                />
              </div>
            )}
          />
        )}
      </Formik>
    );
  };

  // Menangani form ubah
  const UpdateFormTable = ({
    editedData,
    setEditedData,
    dataMachineProcess,
    setDataMachineProcess,
    setAlertConfig,
  }) => {
    // Data awal form
    const formInitialValues = {
      id_item: editedData.id_item ? editedData.id_item : '',
      kode_item: editedData.kode_item ? editedData.kode_item : '',
      nama_item: editedData.nama_item ? editedData.nama_item : '',
      qty: editedData.qty ? editedData.qty : '',
      unit: editedData.unit ? editedData.unit : '',
      konstanta: editedData.konstanta ? editedData.konstanta : 1,
      unit_price: editedData.unit_price ? editedData.unit_price : '',
    };
    // Skema validasi form
    const formValidationSchema = Yup.object().shape({
      nama_item: Yup.string().required(),
      qty: Yup.string().required(),
      unit_price: Yup.string().required(),
      konstanta: Yup.string().required(),
    });

    // Menangani submit form
    const formSubmitHandler = (values) => {
      const index = editedData.index;
      const updatedData = dataMachineProcess;
      updatedData[index] = { ...values };

      setTimeout(() => {
        setDataMachineProcess(updatedData);
        setEditedData({});
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!',
        });
      }, 200);
    };

    return (
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => (
          <FormTable
            formik={formik}
            nomor={editedData.index + 1}
            button={() => (
              <ButtonGroup>
                <ActionButton
                  tooltip
                  size="sm"
                  variant="outline-success"
                  tooltipText="Simpan"
                  text={
                    formik.isSubmitting ? (
                      <Spinner size="sm" animation="border" variant="success" />
                    ) : (
                      <IoCheckmarkOutline />
                    )
                  }
                  onClick={formik.handleSubmit}
                  disable={formik.isSubmitting}
                />
                <ActionButton
                  tooltip
                  size="sm"
                  variant="outline-danger"
                  tooltipText="Batal"
                  text={<IoCloseOutline />}
                  onClick={() => setEditedData({})}
                />
              </ButtonGroup>
            )}
          />
        )}
      </Formik>
    );
  };

  // Menampilkan data pada tabel
  const TableContent = ({ index, val }) => {
    // Menangani perhitungan total
    const getTotal = () => {
      const qty = val.qty ? val.qty : 0;
      const price = val.unit_price ? val.unit_price : 0;
      const total = parseFloat(qty) * parseInt(price);

      return RupiahConvert(parseInt(total).toString()).detail;
    };

    return (
      <tr>
        <Td className="text-center">{index + 1}</Td>
        <Td className="px-1">{val.kode_item}</Td>
        <Td className="px-1">{val.nama_item}</Td>
        <Td className="px-1 text-right">{val.qty}</Td>
        <Td className="px-1">{val.unit}</Td>
        <Td className="px-1 text-right">
          {val.unit_price
            ? RupiahConvert(parseInt(val.unit_price).toString()).detail
            : ''}
        </Td>
        <Td className="px-1 text-right">{val.konstanta}</Td>
        <Td className="px-1 text-right">{getTotal()}</Td>
        <Td>
          <ButtonGroup>
            <ActionButton
              tooltip
              size="sm"
              variant="success"
              tooltipText="Ubah"
              text={<IoPencilOutline />}
              onClick={() =>
                setEditedData({
                  ...val,
                  index,
                })
              }
            />
            <ActionButton
              tooltip
              size="sm"
              variant="danger"
              tooltipText="Hapus"
              text={<IoTrashOutline />}
              onClick={() =>
                setDeletedData({
                  status: true,
                  index: index,
                  data: val.nama_item,
                })
              }
            />
          </ButtonGroup>
        </Td>
      </tr>
    );
  };

  const DeleteModal = ({
    deletedData,
    setDeletedData,
    dataMachineProcess,
    setDataMachineProcess,
  }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Menangani submit hapus data
    const deleteDataHandler = () => {
      setIsSubmitting(true);

      setTimeout(() => {
        const filter = dataMachineProcess.filter(
          (val, index) => index !== deletedData.index
        );

        setDataMachineProcess(filter);
        setDeletedData({
          ...deletedData,
          status: false,
        });
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Hapus data berhasil!',
        });
      }, 200);
    };

    return (
      <Modal
        show={deletedData.status}
        onHide={() =>
          setDeletedData({
            ...deletedData,
            status: false,
          })
        }
      >
        <Modal.Header closeButton className="d-flex align-items-center">
          <b className="text-danger">Hapus Data</b>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Hapus data dengan nama: <br />
            <b>{deletedData.data}</b> <br />
            <small className="text-danger">
              *Data yang telah dihapus tidak dapat diembalikan
            </small>
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="m-1"
            onClick={() =>
              setDeletedData({
                ...deletedData,
                status: false,
              })
            }
          />
          <ActionButton
            variant="danger"
            text="Hapus"
            className="m-1"
            loading={isSubmitting}
            onClick={deleteDataHandler}
          />
        </Modal.Footer>
      </Modal>
    );
  };

  // Menampilkan status ketika tidak ada data
  if (!dataMachineProcess || dataMachineProcess.length < 1) {
    return (
      <TableWrapper
        dataMachineProcess={dataMachineProcess}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
      >
        <CreateFormTable
          dataMachineProcess={dataMachineProcess}
          setDataMachineProcess={setDataMachineProcess}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
        />
        <tr className="bg-light">
          <td colSpan={9}>
            <div className="py-3 text-center">
              <b>Tidak ada data</b>
            </div>
          </td>
        </tr>
      </TableWrapper>
    );
  }

  // Menampilkan keseluruhan data & fitur tabel
  return (
    <>
      <TableWrapper
        dataMachineProcess={dataMachineProcess}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
      >
        {dataMachineProcess.map((val, index) => {
          // Menangani row yang diubah
          if (editedData.index === index) {
            return (
              <UpdateFormTable
                key={index}
                editedData={editedData}
                setEditedData={setEditedData}
                dataMachineProcess={dataMachineProcess}
                setDataMachineProcess={setDataMachineProcess}
                alertConfig={alertConfig}
                setAlertConfig={setAlertConfig}
              />
            );
          }

          // Tampilkan data kedalam tabel
          return <TableContent key={index} index={index} val={val} />;
        })}

        {/* Menampilkan & menangani form tambah */}
        <CreateFormTable
          dataMachineProcess={dataMachineProcess}
          setDataMachineProcess={setDataMachineProcess}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
        />
      </TableWrapper>

      {/* Modal konfirmasi hapus data */}
      <DeleteModal
        deletedData={deletedData}
        setDeletedData={setDeletedData}
        dataMachineProcess={dataMachineProcess}
        setDataMachineProcess={setDataMachineProcess}
      />
    </>
  );
};

export default TableMachineProcess;
