import { DatePicker, FilterModal, SelectSearch } from 'components';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { DateConvert } from 'utilities';
import { DropdownTransferBahanProduksiContext } from '../Context';

export const ModalFilterFilterPreformTBP = ({
  show,
  setShow,
  filter,
  setFilter,
}) => {
  const { dropdownCustomer, dropdownPermintaan, dropdownBahan } = useContext(
    DropdownTransferBahanProduksiContext
  );
  const defaultDrodown = { value: null, label: 'Semua' };
  const initialValues = {
    tgl_job_order_start: filter?.tgl_job_order_start,
    tgl_job_order_end: filter?.tgl_job_order_end,
    item_job_order: filter?.item_job_order,
    id_customer: filter?.id_customer,
    id_permintaan_produksi: filter?.id_permintaan_produksi,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter((prev) => ({ ...prev, ...values, filter: false }));
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_job_order_start: undefined,
      tgl_job_order_end: undefined,
      item_job_order: undefined,
      id_customer: undefined,
      id_permintaan_produksi: undefined,
    }));

    setShow(false);
  };

  const formik = useFormik({ initialValues, onSubmit });

  const { values, setFieldValue, handleSubmit } = formik;

  return (
    <FilterModal
      show={show}
      setShow={setShow}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Job Order"
        placeholderText="Pilih Tgl. Job Order"
        startDate={
          values.tgl_job_order_start ? new Date(values.tgl_job_order_start) : ''
        }
        endDate={
          values.tgl_job_order_end ? new Date(values.tgl_job_order_end) : ''
        }
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('tgl_job_order_start', startDate);
          setFieldValue('tgl_job_order_end', endDate);
        }}
      />

      <SelectSearch
        label="Item Job Order"
        placeholder="Pilih Item Job Order"
        loading={dropdownBahan.isLoading}
        option={[defaultDrodown].concat(dropdownBahan?.data)}
        defaultValue={
          values.item_job_order
            ? dropdownBahan?.data?.find(
                (v) => values.item_job_order === v.value
              )
            : defaultDrodown
        }
        onChange={({ value }) => setFieldValue('item_job_order', value)}
      />

      <SelectSearch
        label="Customer"
        placeholder="Pilih Customer"
        loading={dropdownCustomer.isLoading}
        option={[defaultDrodown].concat(dropdownCustomer?.data)}
        defaultValue={
          values.id_customer
            ? dropdownCustomer?.data?.find(
                (v) => values.id_customer === v.value
              )
            : defaultDrodown
        }
        onChange={({ value }) => setFieldValue('id_customer', value)}
      />

      <SelectSearch
        label="Item Permintaan Produksi"
        placeholder="Pilih Item Permintaan Produksi"
        loading={dropdownPermintaan.isLoading}
        option={[defaultDrodown].concat(dropdownPermintaan?.data)}
        defaultValue={
          values.id_permintaan_produksi
            ? dropdownPermintaan?.data?.find(
                (v) => values.id_permintaan_produksi === v.value
              )
            : defaultDrodown
        }
        onChange={({ value }) => setFieldValue('id_permintaan_produksi', value)}
      />
    </FilterModal>
  );
};
