// React
import React, { useState, useEffect, useRef, memo } from 'react';

// Component
import { Row, Col, Modal, Card } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  TBody,
  Table,
  Th,
  Td,
  Input,
  DatePicker,
  ReadButton,
  THead,
  Tr,
  ThFixed,
  TdFixed,
  Pagination,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';

// API
import { LaporanPurchaseRequestApi } from 'api';

// Print
import { useReactToPrint } from 'react-to-print';

// View
import { CetakPurchaseRequest } from '../Transaksi/PurchaseRequest/CetakPurchaseRequest';

const LaporanPurchaseRequest = ({ setNavbarTitle }) => {
  const title = 'Laporan Purchase Request';
  const componentRef = useRef();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterSelected, setFilterSelected] = useState({
    status: 'b',
    no_purchase_request: '',
    tanggal_pr_start: `${DateConvert(new Date()).defaultYear}-${
      DateConvert(new Date()).defaultMonth
    }-01`,
    tanggal_pr_end: DateConvert(new Date()).default,
    status_pr: 'all',
    status_approval: 'all',
  });

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  // Modal
  const [readModalShow, setReadModalShow] = useState({
    show: false,
    id_purchase_request: '',
  });

  // Print
  const [printData, setPrintData] = useState(0);
  const [isPrint, setIsPrint] = useState(false);

  const getInitialData = () => {
    const query = {
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    };

    if (filterSelected.status === 'a') {
      query.no_purchase_request = filterSelected.no_purchase_request;
    } else {
      query.tanggal_pr_start = filterSelected.tanggal_pr_start;
      query.tanggal_pr_end = filterSelected.tanggal_pr_end;
      query.status_pr = filterSelected.status_pr;
      query.status_approval = filterSelected.status_approval;
    }

    setIsPageLoading(true);

    LaporanPurchaseRequestApi.page(query)
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    paginationConfig.page,
    paginationConfig.dataLength,
    filterSelected.status,
  ]);

  const SelectFilter = (props) => (
    <select
      {...props}
      className={`custom-select custom-select-sm ${props.className}`}
    >
      {props.children}
    </select>
  );

  const reactToPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setIsPrint(false),
  });

  const handleDataPrint = (id) => {
    setPrintData(id);
    setIsPrint(true);
  };

  const handlePrint = () => {
    reactToPrint();
  };

  const PageContent = () => {
    const DataTable = () => (
      <>
        <Table>
          <thead>
            <tr>
              <Th width="12px">No</Th>
              <Th width="12px">Aksi</Th>
              <Th width="12px">Tgl. Purchase Request</Th>
              <Th width="12px">No. Purchase Request</Th>
              <Th width="12px">Karyawan Pengaju</Th>
              <Th width="12px">Keperluan</Th>
              <Th width="12px">Tgl. Pemakaian</Th>
              <Th>Catatan</Th>
              <Th width="12px">Status Purchase Request</Th>
              <Th width="12px">Status Approval</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <Td className="align-middle text-nowrap text-center">
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td className="align-middle text-center">
                  {isPrint === true ? (
                    <>
                      <div style={{ display: 'none' }}>
                        <CetakPurchaseRequest
                          ref={componentRef}
                          idRef={printData}
                          idVendor={0}
                        />
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  <ReadButton
                    onClick={() => {
                      setReadModalShow({
                        show: true,
                        id_purchase_request: val.id_purchase_request,
                      });
                      handleDataPrint(val.id_purchase_request);
                    }}
                  />
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.tgl_purchase_request
                    ? DateConvert(new Date(val.tgl_purchase_request.toString()))
                        .defaultDMY
                    : '-'}
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.no_purchase_request ? val.no_purchase_request : '-'}
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.nama_karyawan_pengaju ? val.nama_karyawan_pengaju : '-'}
                </Td>
                <Td className="align-middle text-capitalize text-nowrap">
                  {val.keperluan ? val.keperluan : '-'}
                </Td>
                <Td className="align-middle text-nowrap text-center">
                  {val.tgl_pemakaian
                    ? DateConvert(new Date(val.tgl_pemakaian.toString()))
                        .defaultDMY
                    : '-'}
                </Td>
                <Td className="align-middle">
                  {val.catatan_purchase_request
                    ? val.catatan_purchase_request
                    : '-'}
                </Td>
                <Td className="align-middle text-uppercase text-center text-nowrap">
                  {val.status_pr ? val.status_pr : '-'}
                </Td>
                <Td className="align-middle text-uppercase text-center text-nowrap">
                  {val.status_approval === 'REV'
                    ? 'Revisi'
                    : val.status_approval === 'PEN'
                    ? 'Pending'
                    : val.status_approval === 'APP'
                    ? 'Approved'
                    : 'Reject'}
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return <DataTable />;
  };

  const ReadModal = () => {
    const [isLoadingRead, setIsLoadingRead] = useState(false);
    const [isErrorFetch, setIsErrorFetch] = useState(false);
    const [readData, setReadData] = useState({
      detail: [],
    });

    const getReadData = (id_purchase_request) => {
      setIsLoadingRead(true);

      LaporanPurchaseRequestApi.single({ id_purchase_request })
        .then((res) => {
          setReadData(res.data.data);
          setIsLoadingRead(false);
        })
        .catch(() => {
          setIsLoadingRead(false);
          setIsErrorFetch(true);
        });
    };

    useEffect(() => {
      getReadData(readModalShow.id_purchase_request);
    }, []);

    const FormCard = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Purchase Request</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {readData.stakeholder === undefined
                ? 'Memuat Data'
                : readData.stakeholder.map(
                    (val, index) =>
                      index !== 0 &&
                      val.status_approval !== 'PEN' && (
                        <Col sm>
                          <InfoItem
                            title1={
                              val.status_approval === 'VER' ||
                              val.status_approval === 'REV'
                                ? `Pemeriksa ${
                                    val.approval_level !== '0'
                                      ? val.approval_level
                                      : ''
                                  }`
                                : val.status_approval === 'APP'
                                ? 'Pengesah'
                                : 'Di Tolak Oleh'
                            }
                            value1={val.nama_karyawan ?? '-'}
                            title2="Catatan"
                            value2={val.catatan ?? '-'}
                          />
                        </Col>
                      )
                  )}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          {title}
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            paddingLeft: 5,
          }}
        >
          {text ? text : '-'}
        </div>
      </div>
    );

    return (
      <>
        <Modal
          size="xl"
          show={readModalShow.show}
          onHide={() => {
            setReadModalShow({
              ...readModalShow,
              show: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{`Detail ${title}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoadingRead || isErrorFetch ? (
              <DataStatus
                loading={isLoadingRead}
                text={isLoadingRead ? 'Memuat data . . .' : 'Data gagal dimuat'}
              />
            ) : (
              <>
                <div className="mb-2">
                  <Row>
                    <Col lg="6">
                      <ShowData
                        title="Tgl. Purchase Request"
                        text={
                          DateConvert(new Date(readData.tgl_purchase_request))
                            .detail
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <ShowData
                        title="No. Purchase Request"
                        text={readData.no_purchase_request}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <ShowData
                        title="Tgl. Pemakaian"
                        text={
                          DateConvert(new Date(readData.tgl_pemakaian)).detail
                        }
                      />
                    </Col>
                    <Col lg="6">
                      <ShowData title="Keperluan" text={readData.keperluan} />
                    </Col>
                  </Row>

                  <CRUDLayout.Table>
                    <THead>
                      <Tr className="text-center">
                        <ThFixed>No</ThFixed>
                        <Th>Kode Item</Th>
                        <Th>Nama Item</Th>
                        <Th>Satuan</Th>
                        <Th>Qty</Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {readData.detail.map((val, index) => (
                        <Tr key={index}>
                          <TdFixed>{index + 1}</TdFixed>
                          <TdFixed>{val.kode_item}</TdFixed>
                          <Td>
                            {/* {val.dimensi === "3"
                              ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                              : val.dimensi === "0"
                                ? val.nama_item
                                : val.dimensi === "1"
                                  ? `${val.nama_item} (${val.pj})`
                                  : val.dimensi === "2"
                                    ? `${val.nama_item} (${val.pj} X ${val.lb})`
                                    : ""} */}
                            {val.nama_item}
                          </Td>
                          <Td>{val.nama_satuan}</Td>
                          <Td>
                            <div className="text-right">{val.qty}</div>
                          </Td>
                        </Tr>
                      ))}
                    </TBody>
                  </CRUDLayout.Table>

                  <div>
                    <strong>Catatan : </strong>
                    <br></br>
                    {readData.catatan_purchase_request}
                  </div>
                </div>
                <FormCard />
              </>
            )}
          </Modal.Body>
          {!isLoadingRead && !isErrorFetch && (
            <Modal.Footer>
              <ActionButton
                type="button"
                variant="primary"
                text="Cetak Purchase Request"
                onClick={handlePrint}
              />
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <Row className="mb-2">
        <Col lg="4">
          <Card>
            <Card.Header>
              <small className="font-weight-bold">
                Pencarian Data Berdasarkan No. Transaksi
              </small>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={8}>
                  <Input
                    label="No. Purchase Request"
                    onChange={(e) =>
                      setFilterSelected({
                        ...filterSelected,
                        no_purchase_request: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={4}>
                  <br />
                  <ActionButton
                    text="Cari"
                    size="sm"
                    onClick={() => {
                      setPaginationConfig({
                        ...paginationConfig,
                        page: '1',
                      });
                      setFilterSelected({
                        ...filterSelected,
                        status: 'a',
                      });

                      getInitialData();
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="8">
          <Card>
            <Card.Header>
              <small className="font-weight-bold">
                Pencarian Data Berdasarkan Periode dan Status
              </small>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={2}>
                  <DatePicker
                    label="Periode Awal"
                    type="date"
                    placeholderText="dd/mm/yyyy"
                    value={filterSelected.tanggal_pr_start}
                    onChange={(e) =>
                      setFilterSelected({
                        ...filterSelected,
                        tanggal_pr_start: DateConvert(e).default,
                      })
                    }
                  />
                </Col>
                <Col md={2}>
                  <DatePicker
                    label="Periode Akhir"
                    type="date"
                    placeholderText="dd/mm/yyyy"
                    value={filterSelected.tanggal_pr_end}
                    onChange={(e) =>
                      setFilterSelected({
                        ...filterSelected,
                        tanggal_pr_end: DateConvert(e).default,
                      })
                    }
                  />
                </Col>
                <Col md={3}>
                  <small>Status PR</small>
                  <br />
                  <SelectFilter
                    defaultValue={filterSelected.status_pr}
                    onChange={(e) =>
                      setFilterSelected({
                        ...filterSelected,
                        status_pr: e.target.value,
                      })
                    }
                  >
                    <option value="open">Open</option>
                    <option value="close">Close</option>
                    <option value="all">Semua Status</option>
                  </SelectFilter>
                </Col>
                <Col md={3}>
                  <small>Status Approval</small>
                  <br />
                  <SelectFilter
                    defaultValue={filterSelected.status_approval}
                    onChange={(e) =>
                      setFilterSelected({
                        ...filterSelected,
                        status_approval: e.target.value,
                      })
                    }
                  >
                    <option value="PEN">Pending</option>
                    <option value="REV">Revisi</option>
                    <option value="APP">Approved</option>
                    <option value="REJ">Reject</option>
                    <option value="all">Semua Status</option>
                  </SelectFilter>
                </Col>
                <Col md={2}>
                  <br />
                  <ActionButton
                    text="Cari"
                    size="sm"
                    onClick={() => {
                      if (
                        filterSelected.tanggal_pr_start !== '' &&
                        filterSelected.tanggal_pr_end !== '' &&
                        filterSelected.status_pr !== '' &&
                        filterSelected.status_approval !== ''
                      ) {
                        setPaginationConfig({
                          ...paginationConfig,
                          page: '1',
                        });
                        setFilterSelected({
                          ...filterSelected,
                          status: 'b',
                        });

                        getInitialData();
                      } else {
                        window.alert('Semua Bidang wajib diisi');
                      }
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <PageContent />
          {readModalShow.show && <ReadModal />}
        </>
      )}
    </CRUDLayout>
  );
};

export default LaporanPurchaseRequest;
