// React
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Component
import { Card, Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  Input,
  DataStatus,
  Alert,
  SelectSearch,
  ActionButton,
  BackButton,
  THead,
  Tr,
  ThFixed,
  TBody,
  TdFixed,
  ReadButton,
  DeleteButton,
  Th,
  Td,
  DatePicker,
} from '../../../../components';

// Form
import { Formik } from 'formik';
import * as Yup from 'yup';

// API
import Axios from 'axios';
import { PenerimaanBarangApi } from '../../../../api';

// File Uploader
import { useDropzone } from 'react-dropzone';
import ImageViewer from 'react-simple-image-viewer';

// Icon
import { IoCloudUploadOutline } from 'react-icons/io5';
import { DateConvert } from 'utilities';
import { TimeStamp } from './Components';

const inputStyle = {
  textAlign: 'center',
  padding: 20,
  borderRadius: 20,
  border: '3px dashed #eeeeee',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  height: 180,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  minWidth: 0,
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const TambahPenerimaanBarang = ({ setNavbarTitle }) => {
  const title = 'Penerimaan Barang';
  const {
    location: {
      state: { qty_sisa },
    },
  } = useHistory();
  const history = useHistory();
  const { id } = useParams();
  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // STATE MENAMPUNG DATA
  const [data, setData] = useState({
    penerimaan_barang: [],
  });
  const [dataAtribut, setDataAtribut] = useState({
    gudang: [],
    karyawan: [],
    satuan: [],
  });
  const [dataNo, setDataNo] = useState('');
  const [dataStore, setDataStore] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [filesUpload, setFilesUpload] = useState([]);
  const [currentImage, setCurrentImage] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const path = require('path');
  const typeImage = ['.jpg', '.png', '.gif', '.jpeg'];
  const typeDoc = ['.pdf', '.xlsx', '.dwg', '.docx', '.skp'];

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    Axios.all([
      PenerimaanBarangApi.getSingle('id_purchase_order_detail', id),
      PenerimaanBarangApi.getAtribut('gudang'),
      PenerimaanBarangApi.getAtribut('karyawan'),
      PenerimaanBarangApi.getAtribut('satuan'),
    ])
      .then(
        Axios.spread((data, gudang, karyawan, satuan) => {
          setData(data.data.data);
          setDataAtribut({
            gudang: gudang.data.data,
            karyawan: karyawan.data.data,
            satuan: satuan.data.data,
          });
        })
      )
      .catch((err) => alert(err.response.data.message))
      .finally(() => setIsLoading(false));
  };

  const getDataNo = (value) => {
    if (isNaN(value)) {
      PenerimaanBarangApi.getNo(value).then((res) => {
        setDataNo(res.data.data);
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpg, .png, .gif, .jpeg, .pdf, .xlsx, .dwg, .docx, .skp',
    onDrop: (acceptedFiles) => {
      setFilesUpload(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple: false,
  });

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const removeImg = (i) => {
    let temp = filesUpload.filter((res) => res.name !== i);
    setFilesUpload([...temp]);
  };

  const filesViewer = filesUpload.map((res) => res.preview);

  const thumbs = filesUpload
    .filter((res) => {
      return typeImage.includes(path.extname(res.name));
    })
    .map((val, index) => {
      return (
        <div style={thumb}>
          <div style={thumbInner}>
            <img
              src={val.preview}
              style={img}
              onClick={() => openImageViewer(index)}
            />
            <ActionButton
              className="mt-2 mb-4"
              variant="danger"
              text="Delete"
              size="sm"
              onClick={() => removeImg(val.name)}
            />
          </div>
          {isViewerOpen && (
            <ImageViewer
              src={filesViewer}
              currentIndex={currentImage}
              disableScroll={false}
              onClose={closeImageViewer}
            />
          )}
        </div>
      );
    });

  // ON COMPONENT MOUNT
  useEffect(() => {
    // setIsLoading(true)
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER
  }, []);

  // INITIAL VALUES FORM TAMBAH DATA TIPE BARANG JADI
  const formInitialValues = {
    tgl_penerimaan_barang: dataStore.tgl_penerimaan_barang,
    id_purchase_order_detail: id,
    no_surat_jalan: dataStore.no_surat_jalan,
    petugas_penerimaan: dataStore.petugas_penerimaan,
    gudang_penerimaan: dataStore.gudang_penerimaan,
    qty_beli: dataStore.qty_beli,
    id_satuan_beli: dataStore.id_satuan_beli,
    qty_pakai: dataStore.qty_pakai,
    id_satuan_pakai: dataStore.id_satuan_pakai,
    id_img: filesUpload,
  };

  // FORMIK VALIDATION SCHEMA TIPE BARANG JADI DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    tgl_penerimaan_barang: Yup.string().required('Tanggal wajib diisi'),
    no_surat_jalan: Yup.string().required('No. Surat Jalan wajib diisi'),
    petugas_penerimaan: Yup.string().required('Pilih Petugas'),
    gudang_penerimaan: Yup.string().required('Pilih Gudang'),
    id_img: Yup.array().min(1, 'Masukan Gambar Terlebih Dahulu'),
    qty_beli: Yup.string()
      .required('Qty. Beli harus isi')
      .test('check-qty', 'Qty. beli tidak boleh melebihi Qty. sisa', (val) =>
        parseFloat(val) > parseFloat(qty_sisa) || parseFloat(val) === 0
          ? false
          : true
      ),
  });

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 7);

    return convert;
  };

  // KIRIM UPDATE DATA KE SERVER
  const formSubmitHandler = (values) => {
    const formData = new FormData();

    filesUpload.reduce((acc, file, index) => {
      formData.append('file', file);
      return file;
    }, {});

    PenerimaanBarangApi.upload(formData).then((data) => {
      let valueUpload = {
        ...values,
        foto_surat_jalan: data.data.data,
      };
      PenerimaanBarangApi.create(valueUpload)
        .then(() => {
          history.push('/inventory/transaksi/penerimaan-barang', {
            registerAlertConfig: {
              variant: 'primary',
              text: 'Tambah data berhasil!',
            },
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: 'danger',
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        });
    });
  };

  return (
    <CRUDLayout>
      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Barang Jadi</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        {isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Card.Body>
                  <Row className="mb-3">
                    <Col sm="6">
                      <Row>
                        <Col md="4">
                          <small>Tgl. Purchase Order</small>
                        </Col>
                        <Col md="8">
                          <small>
                            {' '}
                            :{' '}
                            {`
                            ${data.tgl_purchase_order}
                            ${data.created_at_purchase_order ? '-' : ''} 
                            ${
                              data.created_at_purchase_order
                                ? TimeStamp(data.created_at_purchase_order)
                                : ''
                            }`}
                          </small>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <small>Vendor</small>
                        </Col>
                        <Col md="8">
                          <small> : {data.nama_vendor}</small>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <small>Qty Purchase Order</small>
                        </Col>
                        <Col md="8">
                          <small>
                            {' '}
                            : {data.qty_order ?? 0} {data.satuan_beli}
                          </small>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <small>Qty Sisa</small>
                        </Col>
                        <Col md="8">
                          <small>
                            : {qty_sisa ?? 0} {data.satuan_beli}
                          </small>
                        </Col>
                      </Row>
                    </Col>

                    <Col sm="6">
                      <Row>
                        <Col md="4">
                          <small>No. Purchase Order</small>
                        </Col>
                        <Col md="8">
                          <small> : {data.no_purchase_order}</small>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <small>Item</small>
                        </Col>
                        <Col md="8">
                          <small>: {data.nama_item}</small>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                          <small>Qty Telah Diterima</small>
                        </Col>
                        <Col md="8">
                          <small>
                            : {data.qty_diterima ?? 0} {data.satuan_beli}
                          </small>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <small>Satuan Pakai</small>
                        </Col>
                        <Col md="8">
                          <small>: {data.nama_satuan}</small>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row>
                    <Col md="6">
                      {/* <Input
                        label="Tgl. Penerimaan Barang"
                        type="date"
                        name="tgl_penerimaan_barang"
                        onChange={(val) => {
                          setFieldValue("tgl_penerimaan_barang", val.target.value);
                          getDataNo(val.target.value);
                          setDataStore({
                            ...dataStore,
                            tgl_penerimaan_barang: val.target.value,
                          });
                        }}
                        error={
                          errors.tgl_penerimaan_barang && touched.tgl_penerimaan_barang && true
                        }
                        errorText={errors.tgl_penerimaan_barang}
                      /> */}
                      <DatePicker
                        label="Tgl. Penerimaan Barang"
                        placeholderText="Pilih tanggal"
                        selected={
                          values.tgl_penerimaan_barang
                            ? new Date(values.tgl_penerimaan_barang)
                            : ''
                        }
                        onChange={(date) => {
                          const newDate = DateConvert(date).default;
                          setFieldValue('tgl_penerimaan_barang', newDate);
                          getDataNo(newDate);
                          setDataStore({
                            ...dataStore,
                            tgl_penerimaan_barang: newDate,
                          });
                        }}
                        error={
                          errors.tgl_penerimaan_barang &&
                          touched.tgl_penerimaan_barang &&
                          true
                        }
                        errorText={errors.tgl_penerimaan_barang}
                      />

                      <Input
                        label="No. Surat Jalan"
                        type="text"
                        name="no_surat_jalan"
                        onChange={(e) => {
                          setDataStore({
                            ...dataStore,
                            no_surat_jalan: e.target.value,
                          });
                        }}
                        error={
                          errors.no_surat_jalan &&
                          touched.no_surat_jalan &&
                          true
                        }
                        errorText={errors.no_surat_jalan}
                      />

                      <SelectSearch
                        label="Gudang Penerimaan"
                        name="gudang_penerimaan"
                        onChange={(val) => {
                          setFieldValue('gudang_penerimaan', val.value);
                          setDataStore({
                            ...dataStore,
                            gudang_penerimaan: val.value,
                          });
                        }}
                        option={dataAtribut.gudang.map((val) => {
                          return {
                            value: val.id_gudang,
                            label: val.nama_gudang,
                          };
                        })}
                        defaultValue={{
                          value: '',
                          label: 'Pilih Gudang Penerimaan',
                        }}
                        error={
                          errors.gudang_penerimaan &&
                          touched.gudang_penerimaan &&
                          true
                        }
                        errorText={
                          errors.gudang_penerimaan &&
                          touched.gudang_penerimaan &&
                          true
                        }
                      />
                    </Col>

                    <Col md="6">
                      <Input
                        label="No. Penerimaan Barang"
                        type="text"
                        readOnly={true}
                        value={dataNo}
                      />

                      <SelectSearch
                        label="Petugas Penerimaan"
                        name="petugas_penerimaan"
                        onChange={(val) => {
                          setFieldValue('petugas_penerimaan', val.value);
                          setDataStore({
                            ...dataStore,
                            petugas_penerimaan: val.value,
                          });
                        }}
                        option={dataAtribut.karyawan.map((val) => {
                          return {
                            value: val.id_karyawan,
                            label: val.nama_karyawan,
                          };
                        })}
                        defaultValue={{
                          value: '',
                          label: 'Pilih Petugas',
                        }}
                        error={
                          errors.petugas_penerimaan &&
                          touched.petugas_penerimaan &&
                          true
                        }
                        errorText={
                          errors.petugas_penerimaan &&
                          touched.petugas_penerimaan &&
                          true
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="3" md="6">
                      <Input
                        label="Qty. Beli"
                        type="text"
                        name="qty_beli"
                        value={values.qty_beli}
                        onChange={(e) => {
                          const value = e.target.value;
                          const decimal = decimalConvert(value);
                          setDataStore({
                            ...dataStore,
                            qty_beli: decimal,
                          });
                          setFieldValue('qty_beli', decimal);
                        }}
                        error={errors.qty_beli && touched.qty_beli && true}
                        errorText={errors.qty_beli}
                      />
                    </Col>

                    <Col lg="3" md="6">
                      <SelectSearch
                        label="Satuan Beli"
                        name="id_satuan_beli"
                        onChange={(val) => {
                          setFieldValue('id_satuan_beli', val.value);
                          setDataStore({
                            ...dataStore,
                            id_satuan_beli: val.value,
                          });
                        }}
                        option={dataAtribut.satuan.map((val) => {
                          return {
                            value: val.id_satuan,
                            label: val.nama_satuan,
                          };
                        })}
                        defaultValue={{
                          value: '',
                          label: 'Pilih Satuan',
                        }}
                      />
                    </Col>

                    <Col lg="3" md="6">
                      <Input
                        label="Qty. Pakai"
                        type="text"
                        name="qty_pakai"
                        value={values.qty_pakai}
                        onChange={(e) => {
                          const value = e.target.value;
                          const decimal = decimalConvert(value);
                          setDataStore({
                            ...dataStore,
                            qty_pakai: decimal,
                          });
                          setFieldValue('qty_pakai', decimal);
                        }}
                      />
                    </Col>

                    <Col lg="3" md="6">
                      <SelectSearch
                        label="Satuan Pakai"
                        name="id_satuan_pakai"
                        onChange={(val) => {
                          setFieldValue('id_satuan_pakai', val.value);
                          setDataStore({
                            ...dataStore,
                            id_satuan_pakai: val.value,
                          });
                        }}
                        option={dataAtribut.satuan.map((val) => {
                          return {
                            value: val.id_satuan,
                            label: val.nama_satuan,
                          };
                        })}
                        defaultValue={{
                          value: '',
                          label: 'Pilih Satuan',
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2} />

                    <Col>
                      <section
                        className="bg-light mb-4 mt-4"
                        style={inputStyle}
                      >
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <IoCloudUploadOutline size={50} />
                          <p>
                            Drag & drop some files here, or click to select
                            files
                          </p>
                        </div>
                        {errors.id_img && touched.id_img && (
                          <div className="text-center text-danger">
                            {errors.id_img}
                          </div>
                        )}
                      </section>
                    </Col>

                    <Col lg={2} />
                  </Row>

                  <Row>
                    <Col>{thumbs}</Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <div className="text-right">
                    <ActionButton
                      type="action"
                      className="btn btn-primary text-white"
                      size="md"
                      text="Simpan"
                      loading={isSubmitting}
                    />
                  </div>
                </Card.Footer>
              </form>
            )}
          </Formik>
        )}
      </Card>
    </CRUDLayout>
  );
};
export default TambahPenerimaanBarang;
