import { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Axios from 'axios';
import {
  Alert,
  BackButton,
  DataStatus,
  ActionButton,
  Approval,
} from 'components';
import { SuratJalanApi } from 'api';
import { InfoSection, TableSection } from './Section';
import { useReactToPrint } from 'react-to-print';
import PrintSuratJalan from './PrintSuratJalan';

const DetailSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataStakeholder, setDataStakeholder] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const refPrint = useRef();
  const reactToPrint = useReactToPrint({
    content: () => refPrint.current,
  });
  const handlePrint = () => {
    reactToPrint();
  };

  const mappingDataInfo = (data) => {
    return {
      id_sales_order: data.id_sales_order ?? '-',
      id_petugas_pengiriman: data.id_supir ?? null,
      id_armada_pengiriman: data.id_kendaraan ?? null,
      tgl_sales_order: data.tgl_sales_order ?? null,
      tgl_surat_jalan: data.tgl_surat_jalan ?? null,
      no_sales_order: data.no_sales_order ?? '-',
      no_surat_jalan: data.no_surat_jalan ?? '-',
      no_delivery_order: data.no_delivery_order ?? '-',
      customer: data.nama_customer ?? '-',
      armada_pengiriman: `${data.plat_nomor} - ${data.nama_item_aset}` ?? '-',
      petugas_pengiriman: data.nama_sopir ?? '-',
      sales: data.nama_sales ?? '-',
      gudang: data.nama_gudang ?? '-',
      tgl_batas_waktu: data.batas_waktu ?? null,
      alamat_pengiriman: data.alamat_pengiriman ?? null,
      diambil_customer: data.diambil_customer ?? false,
      nama_proyek: data.nama_proyek ?? '-',
      catatan_delivery_order: data.catatan_delivery_order ?? null,
    };
  };

  const mappingDataTable = (data) =>
    data.map((val) => {
      const qtyDo = val.qty_do ? parseFloat(val.qty_do) : 0;
      const qtyTerkirim = val.qty_sj ? parseFloat(val.qty_sj) : 0;
      const qtySisaDo = parseFloat(qtyDo - qtyTerkirim);
      const qtySJ = val.qty ? parseFloat(val.qty) : '-';

      return {
        id_delivery_order_detail: val.id_delivery_order_detail ?? null,
        id_delivery_order: val.id_delivery_order ?? null,
        kode_item: val.kode_barang ?? '-',
        nama_item: val.nama_barang ?? '-',
        satuan: val.nama_satuan ?? '',
        qty_do: qtyDo,
        qty_terkirim: qtyTerkirim,
        qty_sisa_do: qtySisaDo,
        qty_surat_jalan: qtySJ,
        harga_satuan_jual: val.harga_satuan_jual ?? 0,
      };
    });

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([SuratJalanApi.getSingle({ id_surat_jalan: id })])
      .then(
        Axios.spread((dataDO) => {
          const getData = dataDO.data.data;
          const getDataStakeholder = dataDO.data.data;
          const mapDataInfo = mappingDataInfo(getData ?? {});
          const mapDataTable = mappingDataTable(getData.detail ?? []);

          setDataInfo(mapDataInfo);
          setDataTable(mapDataTable);
          setDataStakeholder(getDataStakeholder ?? []);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!',
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle('Surat Jalan');
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Surat Jalan</b>
          <BackButton size="md" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body className="pb-5">
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <>
              <InfoSection dataInfo={dataInfo} />
              <hr />
              <TableSection dataTable={dataTable} setDataTable={setDataTable} />
              <div className="d-flex justify-content-end">
                <div style={{ display: 'none' }}>
                  <PrintSuratJalan
                    ref={refPrint}
                    idRef={id}
                    printData={dataInfo}
                    dataTable={dataTable}
                  />
                </div>
                <ActionButton
                  size="md"
                  text="Cetak Surat Jalan"
                  onClick={handlePrint}
                />
              </div>
            </>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Card.Body>
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && (
        <Approval data={dataStakeholder} />
      )}
    </>
  );
};

export default DetailSuratJalan;
