import { useState } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import { InfoItemVertical } from 'components';
import { DateConvert } from 'utilities';

const ModalDetailPenerimaanBarang = ({ modalConfig, setModalConfig }) => {
  const [isViewImg, setIsViewImg] = useState(false);
  const { data } = modalConfig;

  return (
    <Modal
      size="lg"
      show={modalConfig.show && modalConfig.type === 'detail'}
      onHide={() => setModalConfig((prev) => ({ ...prev, show: false }))}
    >
      <Modal.Header closeButton>
        <b>Detail Data Penerimaan Barang</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Penerimaan Barang"
              text={`
              ${DateConvert(new Date(data.tgl_penerimaan_barang)).detail}
              ${
                data.created_at
                  ? ` - ${
                      new Date(data.created_at).toTimeString().split(' ')[0]
                    }`
                  : ''
              }
            `}
            />
            <InfoItemVertical
              label="No. Penerimaan Barang"
              text={data.no_penerimaan_barang ?? '-'}
            />
            <InfoItemVertical
              label="Pembuat Penerimaan Barang"
              text={data.nama_karyawan_pengaju ?? '-'}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Tgl. Purchase Order"
              text={`
              ${DateConvert(new Date(data.tgl_purchase_order)).detail}
              ${
                data.created_at_purchase_order
                  ? ` - ${
                      new Date(data.created_at_purchase_order)
                        .toTimeString()
                        .split(' ')[0]
                    }`
                  : ''
              }
            `}
            />
            <InfoItemVertical
              label="No. Purchase Order"
              text={data.no_purchase_order ?? '-'}
            />
            <InfoItemVertical
              label="No. Surat Jalan"
              text={data.no_surat_jalan ?? '-'}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <InfoItemVertical
              label="Item Barang"
              text={data.nama_item ?? '-'}
            />
            <InfoItemVertical
              label="Qty. Penerimaan"
              text={
                data.qty_beli
                  ? `${data.qty_beli} ${data.nama_satuan_beli ?? ''}`
                  : '-'
              }
            />
            <InfoItemVertical
              label="Gudang Penerimaan"
              text={data.nama_gudang ?? '-'}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Penerima"
              text={data.nama_karyawan ?? '-'}
            />
            <InfoItemVertical
              label="Qty. Purchase Order"
              text={
                data.qty_order
                  ? `${data.qty_order} ${data.nama_satuan_order ?? ''}`
                  : '-'
              }
            />
            <InfoItemVertical label="Vendor" text={data.nama_vendor ?? ''} />
          </Col>
        </Row>
        <hr />
        <div className="row justify-content-center mt-2">
          <Col lg="10">
            <div className="text-center">
              <img
                src={data.foto_surat_jalan_full}
                className="img-fluid"
                width="400"
                onClick={() => setIsViewImg(true)}
              />

              {isViewImg && (
                <ImageViewer
                  src={[data.foto_surat_jalan]}
                  currentIndex={0}
                  disableScroll={false}
                  onClose={() => setIsViewImg(false)}
                />
              )}
            </div>
          </Col>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setModalConfig((prev) => ({ ...prev, show: false }))}
        >
          {' '}
          TUTUP{' '}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetailPenerimaanBarang;
