import { Fragment, useEffect, useContext, useRef } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  Input,
  InputCurrency,
  SelectSearch,
  TextArea,
} from 'components';
import {
  DropdownTransferBahanProduksiContext,
  TransferBahanProduksiContext,
} from '../Context';

export const ModalFormTBP = ({
  modal,
  closeModal,
  generateMockNumber,
  getStok,
  decrementStok,
}) => {
  const { type, data } = modal;
  const { dropdownBahan, dropdownKaryawan, dropdownGudang } = useContext(
    DropdownTransferBahanProduksiContext
  );
  const { dataPermintaan, setDataPermintaan } = useContext(
    TransferBahanProduksiContext
  );
  const formikRef = useRef(null);

  const TRF = {
    hardwood: {
      title: 'Hardwood',
      label: 'Item Hardwood',
      uniqueKey: 'id_permintaan_produksi_hardwood',
      dropdown: dropdownBahan?.data?.filter(({ id_jenis }) => id_jenis === '2'),
    },
    plywood: {
      title: 'Plywood',
      label: 'Item Plywood',
      uniqueKey: 'id_permintaan_produksi_plywood',
      dropdown: dropdownBahan?.data?.filter(({ id_jenis }) => id_jenis === '3'),
    },
    factory: {
      title: 'Factory Supply',
      label: 'Item Barang',
      uniqueKey: 'id_permintaan_produksi_finishing',
      dropdown: dropdownBahan?.data?.filter(
        ({ id_kelompok }) => id_kelompok === '2'
      ),
    },
    penunjang: {
      title: 'Bahan Penunjang Finishing',
      label: 'Item Barang',
      uniqueKey: 'id_permintaan_produksi_finishing',
      dropdown: dropdownBahan?.data?.filter(
        ({ id_kelompok }) => id_kelompok === '2'
      ),
    },
  };

  const initialValues = {
    tgl_transfer_produksi_bahan: data?.tgl_transfer_produksi_bahan ?? '',
    no_transfer_produksi_bahan: data?.no_transfer_produksi_bahan ?? '',
    id_item_buaso: data?.id_item_buaso ?? '',
    nama_item_buaso: data?.nama_item_buaso ?? '',
    id_gudang_tujuan: data?.id_gudang_tujuan ?? '62',
    nama_gudang_tujuan: data?.nama_gudang_tujuan ?? 'Gudang Produksi',
    id_gudang_asal: data?.id_gudang_asal ?? '',
    nama_gudang_asal: data?.nama_gudang_asal ?? '',
    qty_available: '',
    qty_transfer: data?.qty_transfer ?? '',
    nama_satuan: data?.nama_satuan ?? 'Batang',
    id_karyawan: data?.id_karyawan ?? '',
    diserahkan: data?.diserahkan ?? '',
    keterangan: data?.keterangan ?? '',
  };

  const validationSchema = yup.object().shape({
    tgl_transfer_produksi_bahan: yup
      .date()
      .required('Tgl. Transfer Bahan Produksi diperlukan!'),
    id_item_buaso: yup.string().required('Pilih Salah Satu Item'),
    id_gudang_asal: yup.string().required('Pilih Gudang Asal'),
    id_gudang_tujuan: yup.string().required('Pilih Gudang Tujuan'),
    diserahkan: yup.string().required('Pilih Karyawan'),
    qty_transfer: yup
      .number()
      .required('Qty. Transfer diperlukan')
      .test(
        'largerThanZero',
        'Qty. Transfer tidak dapat bernilai 0',
        (value, _) => parseFloat(value) > 0
      )
      .test(
        'lessThanQtyAvailable',
        'Qty. Transfer tidak dapat melebihi Stok Item yang Tersedia di Gudang Asal',
        (value, values) =>
          parseFloat(value) <= parseFloat(values.parent.qty_available ?? 0)
      ),
  });

  const onSubmit = (values, { resetForm }) => {
    const uniqueKey = TRF[type].uniqueKey;
    const copyData = [...dataPermintaan[type]];
    const findIndex = copyData.findIndex(
      (item) => item[uniqueKey] === data[uniqueKey]
    );
    const updatedItem = copyData[findIndex];

    Object.assign(updatedItem, values);

    setDataPermintaan((prev) => ({
      ...prev,
      [type]: copyData,
    }));

    decrementStok({
      id_barang: values.id_item_buaso,
      id_gudang: values.id_gudang_asal,
      amount: values.qty_transfer,
    });
    resetForm();
    closeModal();
  };

  useEffect(() => {
    if (!formikRef.current) return;

    (async () => {
      if (data.id_item_buaso && data.id_gudang_asal) {
        const stok = await getStok({
          id_barang: data.id_item_buaso,
          id_gudang: data.id_gudang_asal,
        });

        formikRef?.current?.setFieldValue(
          'qty_available',
          parseFloat(stok) + parseFloat(data.qty_transfer)
        );
      }
    })();
  }, [formikRef.current]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        setFieldValue,
        setValues,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Modal show={modal.show} size="md" onHide={closeModal}>
          <Modal.Header closeButton className="text-capitalize">
            Tambah Data Transfer Bahan Produksi ({TRF[type]?.title})
          </Modal.Header>
          <Modal.Body>
            <InfoSection type={type} data={data} />

            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Transfer Bahan Produksi"
                  dateFormat="dd/MM/yyyy"
                  onChange={async (date) => {
                    setFieldValue('tgl_transfer_produksi_bahan', date);

                    const mockNumberResult = await generateMockNumber(date);
                    setFieldValue(
                      'no_transfer_produksi_bahan',
                      mockNumberResult
                    );
                  }}
                  selected={
                    values?.tgl_transfer_produksi_bahan
                      ? new Date(values?.tgl_transfer_produksi_bahan)
                      : null
                  }
                  error={
                    errors.tgl_transfer_produksi_bahan &&
                    touched.tgl_transfer_produksi_bahan
                  }
                  errorText={errors.tgl_transfer_produksi_bahan}
                />
              </Col>
              <Col>
                <Input
                  readOnly
                  label="No. Transfer Bahan Produksi"
                  value={values.no_transfer_produksi_bahan}
                />
              </Col>
            </Row>

            <SelectSearch
              loading={dropdownBahan.isLoading}
              label={TRF[type]?.label}
              placeholder="Pilih salah satu . . ."
              option={TRF[type].dropdown}
              defaultValue={{
                value: values.id_item_buaso,
                label: values.nama_item_buaso,
              }}
              onChange={(val) =>
                setValues({
                  ...values,
                  id_item_buaso: val.value,
                  nama_item_buaso: val.nama_item,
                  id_gudang_asal: '',
                  nama_gudang_asal: '',
                  qty_available: '',
                })
              }
              error={errors.id_item_buaso && touched.id_item_buaso}
              errorText={errors.id_item_buaso}
            />

            <SelectSearch
              key={values.id_item_buaso}
              isDisabled={!values.id_item_buaso}
              label="Gudang Asal"
              loading={dropdownGudang.isLoading}
              placeholder={
                values.id_item_buaso
                  ? 'Pilih salah satu . . .'
                  : `Pilih ${TRF[type]?.label} terlebih dahulu`
              }
              option={dropdownGudang.data}
              defaultValue={
                values?.id_gudang_asal
                  ? dropdownGudang?.data?.find(
                      (v) => v.value === values.id_gudang_asal
                    )
                  : null
              }
              onChange={async (val) => {
                const stok = await getStok({
                  id_barang: values.id_item_buaso,
                  id_gudang: val.value,
                });

                setValues({
                  ...values,
                  id_gudang_asal: val.value,
                  nama_gudang_asal: val.label,
                  qty_available: stok,
                });
              }}
              error={errors.id_gudang_asal && touched.id_gudang_asal}
              errorText={errors.id_gudang_asal}
            />

            <Input
              textRight
              readOnly
              label="Stok Item Yang Tersedia Di Gudang Asal"
              value={values.qty_available}
            />

            <SelectSearch
              isDisabled
              loading={dropdownGudang.isLoading}
              label="Gudang Tujuan"
              option={dropdownGudang?.data}
              placeholder="Pilih salah satu . . ."
              defaultValue={
                values?.id_gudang_tujuan
                  ? dropdownGudang?.data?.find(
                      (v) => v.value === values.id_gudang_tujuan
                    )
                  : null
              }
              error={errors.id_gudang_tujuan && touched.id_gudang_tujuan}
              errorText={errors.id_gudang_tujuan}
            />

            <Row>
              <Col>
                <InputCurrency
                  prefix=""
                  label="Qty. Transfer"
                  className="text-right"
                  defaultValue={values.qty_transfer}
                  onChange={(val) => setFieldValue('qty_transfer', val)}
                  error={errors.qty_transfer && touched.qty_transfer}
                  errorText={errors.qty_transfer}
                />
              </Col>
            </Row>

            <SelectSearch
              label="Diserahkan Kepada"
              option={dropdownKaryawan.data}
              loading={dropdownKaryawan.isLoading}
              placeholder="Pilih salah satu . . ."
              defaultValue={
                values?.id_karyawan
                  ? dropdownKaryawan?.data?.find(
                      (k) => k.value === values.id_karyawan
                    )
                  : null
              }
              onChange={(val) =>
                setValues({
                  ...values,
                  id_karyawan: val.value,
                  diserahkan: val.label,
                })
              }
              error={errors.diserahkan && touched.diserahkan}
              errorText={errors.diserahkan}
            />

            <TextArea
              label="Keterangan"
              rows={3}
              value={values.keterangan}
              onChange={(val) => setFieldValue('keterangan', val.target.value)}
              error={errors.keterangan && touched.keterangan}
              errorText={errors.keterangan}
            />
          </Modal.Body>

          <Modal.Footer>
            <ActionButton
              className="btn btn-light"
              text="Batal"
              onClick={closeModal}
            />
            <ActionButton text="Simpan" onClick={handleSubmit} />
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

const InfoSection = ({ type, data }) => {
  const isHardwood = type === 'hardwood';
  const formatDimensi = ({ t, w, l }) =>
    `${parseFloat(t)} x ${parseFloat(w)} x ${parseFloat(l)}`;

  const InfoBaku = () => (
    <Fragment>
      <InfoItemHorizontal label="Deskripsi" text={data?.deskripsi} />
      <InfoItemHorizontal
        label="Spesifikasi Kayu"
        text={data?.nama_jenis_kayu ?? data?.nama_item}
      />
      {isHardwood && (
        <InfoItemHorizontal label="Part Kayu" text={data?.nama_part_kayu} />
      )}

      <InfoItemHorizontal
        label="Tipe Finishing"
        text={data?.nama_finishing_barang_jadi ?? 'Tanpa Finishing'}
      />
      <InfoItemHorizontal label="Tipe Sisi" text={data?.nama_tipe_sisi} />
      <InfoItemHorizontal
        label="Final Dimensi (cm)"
        text={formatDimensi({
          t: data.t_final,
          w: data.w_final,
          l: data.l_final,
        })}
      />
      <InfoItemHorizontal
        label="Raw Dimensi (cm)"
        text={formatDimensi({
          t: data.t_raw,
          w: data.w_raw,
          l: data.l_raw,
        })}
      />
      <InfoItemHorizontal
        label="Qty. Permintaan"
        text={`${parseFloat(data.qty_raw)} ${data.nama_satuan ?? 'Batang'}`}
      />
    </Fragment>
  );

  const InfoFSBPF = () => (
    <Fragment>
      <InfoItemHorizontal label="Item Barang" text={data?.nama_item} />
      <InfoItemHorizontal
        label="Qty. Permintaan"
        text={`${parseFloat(data?.qty)} ${data?.nama_satuan}`}
      />
    </Fragment>
  );

  return (
    <Fragment>
      {type === 'hardwood' || type === 'plywood' ? <InfoBaku /> : <InfoFSBPF />}
      <hr className="my-2 " />
    </Fragment>
  );
};
