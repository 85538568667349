import { Services } from 'services';

class LaporanStockUpdateApi {
  getReport(params) {
    return Services.get('/laporan_stok_update', { params });
  }

  getDropdown(params) {
    return Services.get('/laporan_stok_update/dropdown', { params });
  }

  export(params) {
    return Services.get('/laporan_stok_update/export', { params });
  }
}

export default new LaporanStockUpdateApi();
