import { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TdFixed, Th, Table, InfoItemHorizontal } from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import config from 'config';

const KopSuratSection = ({ dataKop }) => {
  return (
    <Row className="d-flex justify-content-around align-items-start">
      <Col sm="3" className="d-flex flex-row justify-content-start">
        <img src={config.LOGO} width={110} alt="LOGO" />
      </Col>

      <Col
        sm="6"
        className="d-flex flex-row justify-content-center"
        style={{ textAlign: 'center' }}
      >
        <p>
          <b>PT. MARDIKA GRIYA PRASTA</b>
          <br />
          Contractor & Suplier, Padasambian & Jasa Produksi Kayu Olahan
          <br />
          Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia <br />
          Telp (0361) 467342, 467343. Fax (0361) 467301
          <br />
        </p>
      </Col>

      <Col sm="3" className="d-flex flex-row justify-content-end">
        <InfoItemHorizontal
          width={40}
          label="Tgl. Input"
          text={DateConvert(new Date(dataKop.tgl_surat_jalan)).defaultDMY}
        />
      </Col>
    </Row>
  );
};

const TTDItems = ({ title, name, jabatan }) => (
  <Col className="text-center">
    <div>{title}</div>
    <div style={{ height: 80 }}></div>
    <div>
      <pre style={{ border: 'none' }}>
        {name ? `( ${name} )` : '(                     )'}
      </pre>
    </div>
    <div>{jabatan}</div>
  </Col>
);

export class PrintSuratJalan extends Component {
  render() {
    const data = this.props.printData;
    const dataTable = this.props.dataTable;
    const total = dataTable?.reduce(
      (acc, { harga_satuan_jual, qty_surat_jalan }) =>
        acc +
        Math.round(parseInt(harga_satuan_jual) * parseFloat(qty_surat_jalan)),
      0
    );

    return (
      <div style={{ marginTop: 80 }} className="px-4 mx-3">
        <KopSuratSection dataKop={data} />

        <div className="row mt-4">
          <div className="col">
            <InfoItemHorizontal
              className="font-weight-bold"
              label="Nota"
              text={data.no_delivery_order}
            />
          </div>
          <div className="col">
            <InfoItemHorizontal
              label="Denpasar"
              text={DateConvert(new Date(data.tgl_surat_jalan)).defaultDMY}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <InfoItemHorizontal
              label="Kendaraan"
              text={data.armada_pengiriman}
            />
          </div>
          <Col className="col">
            <InfoItemHorizontal label="Kepada" text={data.nama_customer} />
          </Col>
        </div>

        <div className="row">
          <div className="col">
            <InfoItemHorizontal label="No." text={data.no_surat_jalan} />
          </div>
          <Col className="col">
            <InfoItemHorizontal label="Alamat" text={data.alamat_pengiriman} />
          </Col>
        </div>

        <div className="row">
          <div className="col"></div>
          <Col className="col">
            <InfoItemHorizontal label="No. Order" text={data.no_sales_order} />
          </Col>
        </div>

        <div className="mt-4">List Barang</div>
        <Table>
          <thead className="bg-light">
            <Th>No</Th>
            <Th>Kode Barang</Th>
            <Th>Item Barang</Th>
            <Th>Satuan</Th>
            <Th>Qty. Surat Jalan</Th>
            <Th>Harga Satuan</Th>
            <Th>Total Harga</Th>
          </thead>
          <tbody>
            {dataTable?.map((val, index) => {
              const subTotal =
                parseInt(val.harga_satuan_jual) * parseInt(val.qty_surat_jalan);
              return (
                <tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <td>{val.kode_item}</td>
                  <td>{val.nama_item}</td>
                  <td>{val.satuan}</td>
                  <td className="text-right">{val.qty_surat_jalan}</td>
                  <td className="text-right">
                    {
                      RupiahConvert(String(parseInt(val.harga_satuan_jual)))
                        .detail
                    }
                  </td>
                  <td className="text-right">
                    {RupiahConvert(subTotal.toString()).detail}
                  </td>
                </tr>
              );
            })}
            <tr>
              <th colSpan={6} className="text-right">
                Total
              </th>
              <td className="text-right">
                {RupiahConvert(total.toString()).detail}
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row mt-3">
          <div className="col-6">
            <label>Catatan :</label>
            <p>{data.catatan_delivery_order}</p>
          </div>
        </div>
        <div className="row mt-5">
          <TTDItems
            jabatan="BAG. DELIVERY"
            title="Penanggungjawab Pengiriman"
            name="Kadek Ari Budiawan"
          />
          <TTDItems jabatan="SAT - PAM" title="Petugas" />
          <TTDItems jabatan="Pemesan" title="Penerima" />
        </div>
      </div>
    );
  }
}

export default PrintSuratJalan;
