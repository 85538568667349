import { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { DatePicker, Input, TextArea, InfoItemVertical } from 'components';
import { DateConvert } from 'utilities';
import { ConvertBarangApi } from 'api';

export const FormConvertBarang = ({ type }) => {
  const { values, handleChange, setValues, setFieldValue } = useFormikContext();
  const TODAY = new Date();
  const STATUS_APPROVAL = {
    PEN: 'PENDING',
    VER: 'VERIFIED',
    REV: 'REVISED',
    REJ: 'REJECTED',
    APP: 'APPROVED',
  };

  const fetchNomor = (tanggal = TODAY) => {
    ConvertBarangApi.getKode({ tanggal })
      .then(({ data }) =>
        setValues((prev) => ({
          ...prev,
          tgl_convert_barang: tanggal,
          no_convert_barang: data.data,
        }))
      )
      .catch(() => alert('Gagal memuat nomor'));
  };

  useEffect(() => type === 'CREATE' && fetchNomor(), []);

  if (type === 'DETAIL') {
    return (
      <>
        <Row>
          <Col md="3">
            <InfoItemVertical
              label="Tgl. Convert Barang"
              text={DateConvert(new Date(values.tgl_convert_barang)).detail}
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="No. Convert Barang"
              text={values.no_convert_barang}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Catatan Convert Barang"
              text={values.catatan_convert_barang ?? '-'}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <InfoItemVertical
              label="Status Approval"
              text={STATUS_APPROVAL[values.status_approval ?? 'PEN']}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Row>
      <Col md="3">
        <DatePicker
          label="Tgl. Convert Barang"
          name="tgl_convert_barang"
          placeholder="Pilih tanggal convert barang"
          selected={
            values.tgl_convert_barang
              ? new Date(values.tgl_convert_barang)
              : null
          }
          onChange={(date) => {
            if (type === 'CREATE') return fetchNomor(date);

            const newDate = DateConvert(date).default;
            setFieldValue('tgl_convert_barang', newDate);
          }}
        />
      </Col>
      <Col md="3">
        <Input
          readOnly
          label="No. Convert Barang"
          value={values.no_convert_barang}
          style={{ paddingBottom: '5px' }}
        />
      </Col>
      <Col>
        <TextArea
          label="Catatan Convert Barang"
          name="catatan_convert_barang"
          value={values.catatan_convert_barang}
          rows={3}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
};
