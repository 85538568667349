import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { IoAddOutline } from 'react-icons/io5';
import { Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  InputSearch,
  Alert,
  DataStatus,
  FilterButton,
  THead,
  Tr,
  ThFixed,
  Th,
  Td,
  TBody,
  Pagination,
  ActionButton,
  BackButton,
} from 'components';
import { TableNumber, DateConvert } from 'utilities';
import { PermintaanBahanProduksiApi } from 'api';
import { useSessionStorage } from 'hooks';
import {
  ItemPermintaanCollapse,
  ModalFilterFilterPreformTBP,
} from './Components';
import { withDropdownTransferBahanProduksiProvider } from './Context';

const TBPPermintaanProduksiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const PAGINATION_KEY = `${location.pathname}_pagination`;

  const [isPageLoading, setPageLoading] = useState(true);
  const [dataPermintaanProduksi, setPermintaanProduksi] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',
    data_count: '0',
    total_page: '1',

    active: false,
    created_at_start: undefined,
    created_at_end: undefined,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    item_job_order: undefined,
    id_customer: undefined,
    id_permintaan_produksi: undefined,
  });

  const { data_count, total_page, ...deps } = pagination;

  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const getInitialData = () => {
    setPageLoading(true);

    const query = { ...pagination };

    ['data_count', 'total_page', 'active'].forEach((key) => delete query[key]);

    PermintaanBahanProduksiApi.preform_page(query)
      .then((res) => {
        setPermintaanProduksi(res.data.data ?? []);
        setPagination((prev) => ({
          ...prev,
          data_count: res.data.data_count,
          total_page: res.data.total_page,
        }));
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  const checkAlert = () => {
    const alertState = location?.state?.alert;
    if (alertState) {
      setAlert({ text: alertState.text, variant: alertState.variant });
    }
  };

  useEffect(() => getInitialData(), [JSON.stringify(deps)]);

  useEffect(() => {
    setNavbarTitle('Transfer Bahan Produksi');
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch onChange={debounce(searchHandler, 1500)} />
          <FilterButton
            active={pagination.active}
            onClick={() => setModalFilter(true)}
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>
      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPermintaanProduksi.length > 0 ? (
        <TableContent
          pagination={pagination}
          data={dataPermintaanProduksi}
          setPagination={setPagination}
          history={history}
        />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter && (
        <ModalFilterFilterPreformTBP
          show={modalFilter}
          setShow={setModalFilter}
          filter={pagination}
          setFilter={setPagination}
        />
      )}
    </CRUDLayout>
  );
};

const TableContent = ({ data, pagination, setPagination, history }) => {
  return (
    <>
      <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
        List Data Permintaan Produksi
      </div>

      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <Th>Customer</Th>
            <Th>Proyek</Th>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th style={{ minWidth: 300 }}>Item Job Order</Th>
            <Th style={{ minWidth: 300 }}>Item Permintaan Produksi</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => {
              return (
                <Tr key={`${val.id_seleksi_vendor_detail}${index}`}>
                  <Td className="text-center">
                    {TableNumber(pagination.page, pagination.per_page, index)}
                  </Td>

                  <Td width={120}>{val.nama_customer}</Td>

                  <Td width={120}>{val.nama_proyek}</Td>

                  <Td>
                    <div>
                      {val.tgl_job_order
                        ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_job_order}</div>
                  </Td>

                  <Td>
                    {val.nama_barang_jadi}{' '}
                    {val.qty_rap ? parseFloat(val.qty_rap) : ''}{' '}
                    {val.nama_satuan ?? ''}
                  </Td>

                  <Td>
                    <ItemPermintaanCollapse data={val.detail} />
                  </Td>

                  <Td>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <ActionButton
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/inventory/transaksi/transfer-bahan-produksi/tambah/${val.id_permintaan_produksi}`
                          )
                        }
                      >
                        <IoAddOutline />
                      </ActionButton>
                    </div>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>

      <Pagination
        dataLength={pagination?.per_page}
        dataCount={pagination?.data_count}
        currentPage={pagination?.page}
        totalPage={pagination?.total_page}
        dataNumber={
          pagination?.page * pagination?.per_page - pagination?.per_page + 1
        }
        dataPage={
          pagination?.data_count < pagination?.per_page
            ? pagination?.data_count
            : pagination?.page * pagination?.per_page
        }
        onPaginationChange={({ selected }) =>
          setPagination((prev) => ({
            ...prev,
            page: selected + 1,
          }))
        }
        onDataLengthChange={(e) =>
          setPagination((prev) => ({
            ...prev,
            page: 1,
            per_page: e.target.value,
          }))
        }
      />
    </>
  );
};

export default withDropdownTransferBahanProduksiProvider(
  TBPPermintaanProduksiList
);
